import './Main.scss'

import _debounce from 'lodash/debounce'
import { FunctionComponent, ReactNode, useEffect, useState } from 'react'

import { useAppSelector } from '../../hooks'
import { RootState } from '../../store'

interface MainContainerProps {
  children: ReactNode
  withHeader: boolean
}

const Main: FunctionComponent<MainContainerProps> = ({
  withHeader,
  children,
}: MainContainerProps) => {
  const { completedList, show } = useAppSelector(
    (state: RootState) => state.warningMessage
  )

  const [headerHeight, setHeaderHeight] = useState<number>(0)
  const [warningMessageHeight, setWarningMessageHeight] = useState<number>(0)

  const resizeHeaderContainerHeight = () => {
    const headerContainerHeight =
      document.querySelector('#header')?.clientHeight || 0
    setHeaderHeight(headerContainerHeight)
  }

  useEffect(() => {
    resizeHeaderContainerHeight()
    window.addEventListener('resize', resizeHeaderContainerHeight)

    return () => {
      window.removeEventListener('resize', resizeHeaderContainerHeight)
    }
  }, [])

  useEffect(() => {
    const updateMarginTop = (): void => {
      if (completedList.length === 0) return
      const warningMessageContainerHeight =
        document.querySelector('#warning-message-container')?.clientHeight || 0
      setWarningMessageHeight(warningMessageContainerHeight)
    }

    const updateMarginTopDebounced = _debounce(updateMarginTop, 150)

    if (completedList.length > 0 && show) {
      updateMarginTop()
    } else {
      setWarningMessageHeight(0)
    }
    window.addEventListener('resize', updateMarginTopDebounced)
    return () => {
      window.removeEventListener('resize', updateMarginTopDebounced)
    }
  }, [completedList, show])

  useEffect(() => {
    if (completedList.length === 0) {
      setWarningMessageHeight(0)
    }
  }, [completedList])

  return (
    <main
      className={
        withHeader
          ? 'main-container main-container__with-header'
          : 'main-container'
      }
      id='main'
      data-testid='main-container'
      style={{ marginTop: `${warningMessageHeight + headerHeight}px` }}
    >
      {children}
    </main>
  )
}

export default Main
