import { FunctionComponent } from 'react'

import getCountriesSpecific from '../../helpers/countriesSpecific'
import HKBar from './RiskRatingBarHK'
import TWBar from './RiskRatingBarTW'

const RiskRatingBar: FunctionComponent = () => {
  const CountrySpecificRiskRatingBar = getCountriesSpecific({
    HK: HKBar,
    TW: TWBar,
  })
  return <CountrySpecificRiskRatingBar />
}

export default RiskRatingBar
