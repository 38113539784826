import { FunctionComponent } from 'react'
import { Link as RouterLink, LinkProps } from 'react-router-dom'

interface IProps extends LinkProps {
  className?: string
}

const Link: FunctionComponent<IProps> = ({
  children,
  to,
  className,
  ...props
}: IProps) => {
  return (
    <RouterLink to={to} className={className} {...props}>
      {children}
    </RouterLink>
  )
}
Link.defaultProps = {
  className: '',
}
export default Link
