export const TAB_ACTIVE_STYLES_HIGHLIGHT = 'highlight'
export const TAB_ACTIVE_STYLES_UNDERLINE = 'underline'
export const TAB_THEME_PRIMARY = 'blue'
export const TAB_THEME_SECONDARY = 'violet'
export const TAB_THEME_TERTIARY = 'darkblue'
export const TAB_ACTIVE_STYLES_DEFAULT = TAB_ACTIVE_STYLES_UNDERLINE
export const TAB_THEME_DEFAULT = TAB_THEME_PRIMARY

export const TAB_CONTAINER_ITEM_ACTIVE_STYLES = TAB_ACTIVE_STYLES_UNDERLINE
export const TAB_CONTAINER_ITEM_THEME = TAB_THEME_SECONDARY
