/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { FunctionComponent } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import styles from './ImportantNotes.module.scss'

const ImportantNotes: FunctionComponent = () => {
  const { t } = useTranslation()
  const renderImportantNotesItems = () => {
    const lists: string | string[] = t(
      'generalSuitability:important-notes.list',
      { returnObjects: true }
    )
    if (typeof lists === 'string')
      return (
        <li data-testid='important-notes-list'>
          <Trans
            components={{
              b: <b />,
              morganSiteLink: (
                <a
                  href='https://www.jpmorgan.com/tw/am/ '
                  target='_blank'
                  rel='noreferrer'
                />
              ),
            }}
          >
            {lists}
          </Trans>
        </li>
      )
    if (typeof lists === 'object') {
      return lists.map((list) => (
        <li key={list} data-testid='important-notes-list'>
          <Trans
            components={{
              b: <b />,
              morganSiteLink: (
                <a
                  href='https://www.jpmorgan.com/tw/am/ '
                  target='_blank'
                  rel='noreferrer'
                />
              ),
            }}
          >
            {list}
          </Trans>
        </li>
      ))
    }
    return <></>
  }
  return (
    <div className={styles.importantNotes}>
      <p>{t('generalSuitability:important-notes.title')}</p>
      <ol>{renderImportantNotesItems()}</ol>
    </div>
  )
}

export default ImportantNotes
