import PropTypes from 'prop-types'
import { FunctionComponent } from 'react'

import styles from './Bar.module.scss'

const Bar: FunctionComponent = ({ children }) => {
  return (
    <ul className={styles.bar} data-testid='tab-bar'>
      {children}
    </ul>
  )
}

Bar.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Bar
