import { FunctionComponent } from 'react'

import { IconProps } from '../../typings/Icon'

const Gift: FunctionComponent<IconProps> = ({
  width,
  height,
  className,
  ...props
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
      className={className}
    >
      <path d='M7 2.34668L7 13.3467' stroke='black' strokeWidth='0.8' />
      <rect
        x='0.4'
        y='5.74668'
        width='13.2'
        height='7.2'
        rx='1.6'
        stroke='black'
        strokeWidth='0.8'
      />
      <rect
        x='0.4'
        y='2.74668'
        width='13.2'
        height='3.2'
        rx='0.6'
        stroke='black'
        strokeWidth='0.8'
      />
      <path
        d='M1.5 2.8468C3.41118 -0.497766 6.20367 1.45324 7 2.84681C7.79633 1.45324 10.5888 -0.497791 12.5 2.84678'
        stroke='black'
        strokeWidth='0.8'
      />
    </svg>
  )
}

Gift.defaultProps = {
  width: 14,
  height: 14,
  className: '',
}

export default Gift
