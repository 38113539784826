import { FunctionComponent } from 'react'

type MessageIconProps = {
  width?: number
  height?: number
  className?: string
  color?: string
}
const MessageOpenIcon: FunctionComponent<MessageIconProps> = ({
  width,
  height,
  className,
  color,
  ...props
}: MessageIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      {...props}
      viewBox='0 0 15 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.2024 5.55556H1.82737C1.10361 5.55556 0.514862 6.1443 0.514862 6.86806V14.7431C0.514862 15.4668 1.10361 16.0556 1.82737 16.0556H13.2024C13.9261 16.0556 14.5149 15.4668 14.5149 14.7431V6.86806C14.5149 6.1443 13.9261 5.55556 13.2024 5.55556ZM13.2024 6.43055C13.2618 6.43055 13.3183 6.44288 13.37 6.46442L7.51486 11.5391L1.65965 6.46442C1.71135 6.44291 1.7679 6.43055 1.82734 6.43055H13.2024ZM13.2024 15.1805H1.82737C1.58598 15.1805 1.38986 14.9845 1.38986 14.743V7.38887L7.22817 12.4487C7.31064 12.5201 7.41275 12.5556 7.51486 12.5556C7.61697 12.5556 7.71908 12.5201 7.80155 12.4487L13.6399 7.38887V14.7431C13.6398 14.9845 13.4437 15.1805 13.2024 15.1805Z'
        fill={color}
      />
      <path
        d='M13.3231 6.33333H2.07617C1.93498 6.33333 1.86681 6.50628 1.97003 6.60261L7.41208 11.6819C7.47044 11.7363 7.56057 11.7378 7.62065 11.6852L13.4255 6.60596C13.5336 6.51137 13.4667 6.33333 13.3231 6.33333Z'
        fill={color}
        stroke={color}
        strokeWidth='0.777778'
      />
      <rect
        x='1.29263'
        y='5.55556'
        width='12.4444'
        height='0.777778'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.718463 6.041C0.295892 6.42383 0.574979 7.11111 1.153 7.11111H13.8767C14.4547 7.11111 14.7338 6.42383 14.3113 6.041L7.9494 0.277368C7.70472 0.0556898 7.32501 0.0556927 7.08032 0.277369L0.718463 6.041ZM1.84546 6.22799L7.51486 1.0917L13.1843 6.22799H1.84546Z'
        fill={color}
      />
      <path
        d='M13.0522 6.10001H1.9775C1.87162 6.10001 1.82049 6.22972 1.8979 6.30197L7.43525 11.4702C7.48007 11.512 7.54963 11.512 7.59446 11.4702L13.1318 6.30197C13.2092 6.22972 13.1581 6.10001 13.0522 6.10001Z'
        fill='white'
      />
      <rect
        x='4.40375'
        y='6.33333'
        width='6.22222'
        height='0.777778'
        rx='0.388889'
        fill={color}
      />
    </svg>
  )
}

MessageOpenIcon.defaultProps = {
  width: 15,
  height: 17,
  color: '#ABADAF',
  className: '',
}

export default MessageOpenIcon
