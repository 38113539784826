import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { PIN_CHANGE_REMINDER } from '../../constants/warning-message'
import i18n from '../../i18n'
import { ImportantMessageType } from '../../typings/WarningMessage'
import loadWarningMessage from './actions/loadWarningMessage'

type WarningMessageState = {
  typhoon: ImportantMessageType[]
  accountRelated: ImportantMessageType[]
  completedList: ImportantMessageType[]
  read: boolean
  hasMessage: boolean
  show: boolean
}

const initialState: WarningMessageState = {
  typhoon: [],
  accountRelated: [],
  completedList: [],
  read: false,
  hasMessage: false,
  show: false,
}

export const warningMessageSlice = createSlice({
  name: 'warningMessage',
  initialState,
  reducers: {
    setShowMessage: (state, action: PayloadAction<boolean>) => {
      state.show = action.payload
    },
    setMessageRead: (state) => {
      state.completedList = [] // remove all messages
      state.show = false
    },
    addWarningMessage: (state, action: PayloadAction<string>) => {
      const zhHK = i18n.getFixedT('zh-HK')('importantMessage:change-password')
      const zhTW = i18n.getFixedT('zh-TW')('importantMessage:change-password')
      const enUS = i18n.getFixedT('en')('importantMessage:change-password')
      let message: ImportantMessageType | null = null

      switch (action.payload) {
        case PIN_CHANGE_REMINDER:
          message = {
            code: PIN_CHANGE_REMINDER,
            locale: {
              zh_HK: zhHK,
              zh_TW: zhTW,
              en_US: enUS,
            },
          }
          break
        default:
          break
      }
      if (message) {
        state.accountRelated = [message, ...state.accountRelated]
      }
    },
    addToDisplayList: (
      state,
      action: PayloadAction<ImportantMessageType[]>
    ) => {
      state.completedList = [...action.payload, ...state.completedList]
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      loadWarningMessage.fulfilled,
      (state, action: PayloadAction<ImportantMessageType[]>) => {
        state.typhoon = action.payload
        state.completedList = [...state.completedList, ...action.payload]
      }
    )
  },
})

export default warningMessageSlice.reducer
