export const TOTAL_ASSET_VALUE = 'total-asset-value'
export const PROFIT = 'profit'
export const RETURN_RATE = 'rate-return'
export const LAST_PURCHASE = 'last-purchased'
export const ASSET_PROPORTION = 'asset-proportion'

export const SORT_VALUES_FUND = [TOTAL_ASSET_VALUE, PROFIT, RETURN_RATE]
export const SORT_VALUES_ASSET_CLASS = [ASSET_PROPORTION]

export const SORT_DEFAULT_SELECT = TOTAL_ASSET_VALUE
