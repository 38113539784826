import classnames from 'classnames'
import { FunctionComponent } from 'react'

import {
  RISK_BAR_VARIANT_CLIENT_RISK,
  RISK_BAR_VARIANT_PORTFOLIO_RISK,
} from '../../../../constants/risk-rating'
import { getCurrentCountry } from '../../../../helpers/countriesSpecific'
import { UserCountryType } from '../../../../typings/UserInfo'
import styles from '../RiskBar.module.scss'

interface RiskRatingBarProps {
  value: number
  variant:
    | typeof RISK_BAR_VARIANT_CLIENT_RISK
    | typeof RISK_BAR_VARIANT_PORTFOLIO_RISK
  country: UserCountryType
}

const RiskRatingBar: FunctionComponent<RiskRatingBarProps> = ({
  value,
  variant,
  country: _country,
  ...props
}: RiskRatingBarProps) => {
  const { isHK } = getCurrentCountry()
  return (
    <div
      className={styles.riskBarContainer}
      {...props}
      data-testid={`risk-rating-bar-${variant}`}
    >
      {isHK ? (
        <>
          <div
            className={classnames(styles.riskBarLevelIndicator__HK, {
              [styles.tallBar]: variant === RISK_BAR_VARIANT_CLIENT_RISK,
              [styles.shortBar]: variant === RISK_BAR_VARIANT_PORTFOLIO_RISK,
            })}
            data-testid='risk-bar-divider-hk'
          />
          <div
            className={classnames(styles.riskBarLevelIndicator__HK, {
              [styles.tallBar]: variant === RISK_BAR_VARIANT_CLIENT_RISK,
              [styles.shortBar]: variant === RISK_BAR_VARIANT_PORTFOLIO_RISK,
            })}
            data-testid='risk-bar-divider-hk'
          />
          <div
            className={classnames(styles.riskBarLevelIndicator__HK, {
              [styles.tallBar]: variant === RISK_BAR_VARIANT_CLIENT_RISK,
              [styles.shortBar]: variant === RISK_BAR_VARIANT_PORTFOLIO_RISK,
            })}
            data-testid='risk-bar-divider-hk'
          />
          <div
            className={classnames(styles.riskBarLevelIndicator__HK, {
              [styles.tallBar]: variant === RISK_BAR_VARIANT_CLIENT_RISK,
              [styles.shortBar]: variant === RISK_BAR_VARIANT_PORTFOLIO_RISK,
            })}
            data-testid='risk-bar-divider-hk'
          />
        </>
      ) : (
        <>
          <div
            className={styles.riskBarLevelIndicator__TW}
            data-testid='risk-bar-divider-tw'
          />
          <div
            className={styles.riskBarLevelIndicator__TW}
            data-testid='risk-bar-divider-tw'
          />
        </>
      )}

      <div
        className={classnames(styles.riskBarOuterDiv, {
          [styles.riskBarOuterDiv__clientRiskRating]:
            variant === RISK_BAR_VARIANT_CLIENT_RISK,
          [styles.riskBarOuterDiv__portfolioRiskRating]:
            variant === RISK_BAR_VARIANT_PORTFOLIO_RISK,
        })}
        data-testid='risk-bar-bg'
      >
        <div className={styles.riskBarInnerLeftCut} />

        <div
          className={styles.riskBarInnerLeft}
          style={{ width: `${value}%` }}
          data-testid='risk-bar-filled'
        />
        <div
          className={styles.riskBarInnerRight}
          style={{ width: `calc(100% - ${value}%)` }}
        />
        {/* <div
          className={styles.riskBarInnerTriangle}
          style={{ left: `${value}%` }}
          data-testid='risk-bar-triangle'
        /> */}
        <div
          className={styles.riskBarRightCover}
          style={{ width: `calc(100% - ${value}%)` }}
        />
        <div className={styles.riskBarInnerRightCut} />
      </div>
    </div>
  )
}

export default RiskRatingBar
