import { SINGLE_SELECT_MULTIPLE_CHOICE } from '../../constants/question-types'
import { EnhancedSuitabilityQuestionGroupType } from '../../typings/EnhancedSuitability'

const data: EnhancedSuitabilityQuestionGroupType[] = [
  {
    type: '投資知識',
    questions: [
      {
        id: 1,
        type: SINGLE_SELECT_MULTIPLE_CHOICE,
        question:
          '請問您對基金、債券、股票、ETF等的投資，熟悉與了解的程度如何？',
        options: ['完全不了解', '有一點了解', '很了解'],
        selectedOption: false,
      },
      {
        id: 2,
        type: SINGLE_SELECT_MULTIPLE_CHOICE,
        question:
          '請問您對期貨、選擇權、結構型商品等衍生性金融商品的投資，熟悉與了解的程度如何？',
        options: ['完全不了解', '有一點了解', '很了解'],
        selectedOption: false,
      },
    ],
  },
  {
    type: '投資經驗',
    questions: [
      {
        id: 3,
        type: SINGLE_SELECT_MULTIPLE_CHOICE,
        question: '請問您曾經投資債券、股票、基金、ETF等金融商品有多長的時間？',
        options: ['未滿1年', '1年以上(含) - 未滿5年', '5年(含)以上'],
        selectedOption: false,
      },
      {
        id: 4,
        type: SINGLE_SELECT_MULTIPLE_CHOICE,
        question:
          '請問您曾經投資期貨、選擇權、結構型商品等衍生性金融商品有多長的時間？',
        options: ['未滿1年', '1年以上(含) - 未滿3年', '3年(含)以上'],
        selectedOption: false,
      },
    ],
  },
  {
    type: '財務狀況',
    questions: [
      {
        id: 5,
        type: SINGLE_SELECT_MULTIPLE_CHOICE,
        question: '請問您投資金融商品的資金來源是？',
        options: [
          '有其他用途，但短期(半年內)不會用到的資金',
          '有其他用途，但中長期沒有用到的資金',
          '閒置的資金',
        ],
        selectedOption: false,
      },
      {
        id: 6,
        type: SINGLE_SELECT_MULTIPLE_CHOICE,
        question: '請問您加上本次投資的金額，大約是你總財富的多少比率？',
        options: ['31%以上', '11% - 30%', '10%以下'],
        selectedOption: false,
      },
    ],
  },
  {
    type: '承受風險程度',
    questions: [
      {
        id: 7,
        type: SINGLE_SELECT_MULTIPLE_CHOICE,
        question:
          '請問您投資一檔基金，投資損益為以下哪個百分比時您會開始感到很擔憂與焦慮？',
        options: ['10%以下', '11% - 30%', '31%以上'],
        selectedOption: false,
      },
      {
        id: 8,
        type: SINGLE_SELECT_MULTIPLE_CHOICE,
        question:
          '請問您現有的金融商品投資，如果淨值下跌超過20%，對你日常生活的影響如何？',
        options: ['有非常嚴重的影響', '影響有限', '幾乎沒有影響'],
        selectedOption: false,
      },
    ],
  },
]

export default data
