import classnames from 'classnames'
import { isAfter, isBefore, isMatch, parse } from 'date-fns'
import _uniq from 'lodash/uniq'
import { FunctionComponent, useEffect, useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next'

import Button from '../../../components/button'
import GSQuestion from '../../../components/gs-question'
import GSQuestionDivider from '../../../components/gs-question-divider'
import ConfirmationModal from '../../../components/modal/confirmation-modal'
import { MULTI_SELECT_MULTIPLE_CHOICE } from '../../../constants/question-types'
import checkAgeFilter from '../../../helpers/checkAgeFilter'
import compareValue from '../../../helpers/compareValue'
import renderQuestion13B from '../../../helpers/renderQuestion13B'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import Section from '../../../layouts/section'
import {
  addQuestionsMandatory,
  removeAgentTwo,
  update4CQuestionRenderingStatus,
  updateQuestionAnswer,
  updateQuestionGroupsMandatory,
} from '../../../redux/general-suitability/actions'
import { RootState } from '../../../store'
import {
  FILLING_ROLE_AGENT_TWO,
  FILLING_ROLE_SELF,
  FillingRoleType,
  GeneralSuitabilityQuestion,
  GeneralSuitabilityQuestionCategory,
  GeneralSuitabilityQuestionGroup,
  OptionMatchConditionType,
  QUESTION_CATEGORY_BASIC_INFO,
  QUESTION_CATEGORY_FINANICAL_INFO,
  QUESTION_CATEGORY_HEALTH_INFO,
  QUESTION_CATEGORY_RISK_ASSESSMENT,
  QUESTION_CATEGORY_TRADING,
  QUESTION_VALIDATION_DATE,
  QuestionAnswers,
  QuestionnaireSubStepType,
  ValueCompareConditionType,
} from '../../../typings/GeneralSuitability'
import FormInstructionModal from '../form-instruction-modal'
import QuestionnaireControl from '../questionnaire-control'
import SubStepContentWrapper from '../sub-step-content-wrapper'
import styles from './Questionnaire.module.scss'

interface QuestionnaireProps {
  currentQuestionnaireStep: number
  clickNextStep: () => void // function to go to next questionniare step
  inputAnswer: () => void // when user start answering (i.e to track the questionnaire input status for Discard Questionnaire Modal)
  stepInfo: QuestionnaireSubStepType[] // questionnaire data for current questionnaire steps
  goToSubStep: number | undefined // to manipulate substep flow
  resetGoToSubStep: () => void
}

// CHECK IF ALL MANDATORY QUESTIONS ARE ANSWERED
/* This function will take in 
i,  answers object 
ii, questionniare data for current step

and cross check if answers object contains all answers from current sub step questions
and return an array of question number which have no answer recorded */

export const validateIsAllQuestionAnswered = (
  questionAnswers: QuestionAnswers, // questionAnswersObject
  currentQuestionGroups: GeneralSuitabilityQuestionGroup[] // which questionnaire step is the user current in
): {
  isAllQuestionAnswered: boolean
  missingQuestions: number[]
} => {
  const allQuestionNumbersInCurrentGroup: number[] = []

  // Get all question numbers in this sub step
  currentQuestionGroups.forEach(({ questions }) =>
    questions.forEach((question) => {
      allQuestionNumbersInCurrentGroup.push(question.questionNumber)
      if (question.dependentQuestions) {
        question.dependentQuestions.forEach((dQuestion) =>
          allQuestionNumbersInCurrentGroup.push(dQuestion.questionNumber)
        )
      }
    })
  )

  // Check answer from user if they have answer all mandatory
  const missingQuestions = allQuestionNumbersInCurrentGroup
    .filter(
      (questionInThisGroup) =>
        questionAnswers[questionInThisGroup] &&
        questionAnswers[questionInThisGroup].mandatory
    )
    .filter(
      (mandatoryQuestion) =>
        !questionAnswers[mandatoryQuestion].answer ||
        (questionAnswers[mandatoryQuestion].answer as string[]).length === 0
    )

  return {
    isAllQuestionAnswered: missingQuestions.length === 0,
    missingQuestions,
  }
}

/**
 * perform field level validation
 */
export const validateFields = (
  questionAnswers: QuestionAnswers, // questionAnswersObject
  currentQuestionGroups: GeneralSuitabilityQuestionGroup[], // which questionnaire step is the user current in
  t: TFunction<'translation'>
): {
  hasError: boolean
  errorMessages: { [key: number]: string }
} => {
  const allQuestionNumbersInCurrentGroup: {
    [key: number]: GeneralSuitabilityQuestion
  } = {}

  // Get all question numbers in this sub step
  currentQuestionGroups.forEach(({ questions }) =>
    questions.forEach((question) => {
      allQuestionNumbersInCurrentGroup[question.questionNumber] = question
      if (question.dependentQuestions) {
        question.dependentQuestions.forEach((dQuestion) => {
          allQuestionNumbersInCurrentGroup[dQuestion.questionNumber] = dQuestion
        })
      }
    })
  )

  const errorMessages: { [key: number]: string } = {}

  Object.keys(allQuestionNumbersInCurrentGroup).forEach(
    (questionNumber: string) => {
      const questionNo: number = parseInt(questionNumber, 10)
      const { answer = '' } = questionAnswers[questionNo] || {}
      const { validation = '' } =
        allQuestionNumbersInCurrentGroup[questionNo] || {}

      if (answer && validation) {
        switch (validation) {
          case QUESTION_VALIDATION_DATE:
            if (
              !isMatch(answer as string, 'yyyyMMdd') ||
              isAfter(
                parse(answer as string, 'yyyyMMdd', new Date()),
                new Date()
              ) ||
              isBefore(
                parse(answer as string, 'yyyyMMdd', new Date()),
                new Date(1900, 0, 1) // 1900-01-01
              )
            )
              errorMessages[questionNo] = t(
                'questions:error.incorrect-date-format'
              )
            break
          default:
        }
      }
    }
  )

  return {
    hasError: Object.keys(errorMessages).length > 0,
    errorMessages,
  }
}

/* 問卷介面 
content depends on currentQuestionniare step (i.e Part One and Part Two)
each step will also include sub steps (i.e 法定代理人1，法定代理人2，追問)
content display is controlled by currentSubStep variable
*/

const Questionnaire: FunctionComponent<QuestionnaireProps> = ({
  currentQuestionnaireStep,
  clickNextStep,
  inputAnswer,
  stepInfo,
  goToSubStep,
  resetGoToSubStep,
}: QuestionnaireProps) => {
  const [questionsWithError, setQuestionsWithError] = useState<number[]>([])
  const [questionsErrorMessages, setQuestionsErrorMessages] = useState<{
    [key: number]: string
  }>({})
  const [currentStepTitle, setCurrentStepTitle] = useState<
    QuestionnaireSubStepType['title'] | undefined
  >(undefined)
  const [currentQuestionGroups, setCurrentQuestionGroups] = useState<
    GeneralSuitabilityQuestionGroup[]
  >([])
  const [currentQuestionAnswers, setCurrentQuestionAnswers] =
    useState<QuestionAnswers>({})
  const [skipCurrentStep, setSkipCurrentStep] = useState<boolean>(false) // FOR 法定代理人2
  const [currentSubStep, setCurrentSubStep] = useState<number>(goToSubStep || 0)
  const [currentStepFillingRole, setCurrentStepFillingRole] =
    useState<FillingRoleType>(FILLING_ROLE_SELF)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { questionAnswers, age, shouldShowQuestion4C } = useAppSelector(
    (state: RootState) => state.generalSuitability
  )

  const [showPopupConfirmation, setShowPopupConfirmation] =
    useState<boolean>(false)
  const [confirmedTradingModal, setConfirmedTradingModal] =
    useState<boolean>(false)

  // Reset substep
  useEffect(() => {
    if (!goToSubStep) {
      setCurrentSubStep(0)
    } else {
      setCurrentSubStep(goToSubStep)
    }
  }, [currentQuestionnaireStep, goToSubStep])

  // reset show modal variable and skip current step variable
  useEffect(() => {
    setSkipCurrentStep(false)
    setConfirmedTradingModal(false)
  }, [currentSubStep, currentQuestionnaireStep])

  // Below functions will be called for each change in sub step
  // 1. update current question groups content (questions), current step title  and current step filling role (i.e 受益人/法定代理人)
  // 2. update mandatory questions to question answer object (reducer) and mandatory question groups (reducer), mainly for review section display useage
  // 3. scroll to top
  useEffect(() => {
    if (!stepInfo || !stepInfo[currentSubStep]) return
    setCurrentQuestionGroups(stepInfo[currentSubStep].questionGroups)
    setCurrentStepTitle(stepInfo[currentSubStep].title)
    setCurrentStepFillingRole(stepInfo[currentSubStep].currentFillingRole)
    if (!stepInfo[currentSubStep].questionGroups) return

    // 1. get all question number
    const allQuestionNumbers: number[] = []
    const allCategories: GeneralSuitabilityQuestionCategory[] = []

    stepInfo[currentSubStep].questionGroups.forEach(
      ({ category, questions }) => {
        questions.forEach((question) => {
          if (!question.ageFilter || checkAgeFilter(question.ageFilter)) {
            allQuestionNumbers.push(question.questionNumber)
          }
          // Make all dependent question to mandatory for DEMO PURPOSE
          // REMOVE THIS FOR PRODUCTION
          question.dependentQuestions.forEach((dQuestion) => {
            if (!dQuestion.ageFilter || checkAgeFilter(dQuestion.ageFilter)) {
              allQuestionNumbers.push(dQuestion.questionNumber)
            }
          })
        })
        allCategories.push(category)
      }
    )
    if (allQuestionNumbers.length > 0) {
      dispatch(
        addQuestionsMandatory({
          updatedQuestionsMandatory: allQuestionNumbers,
          currentFillingRole: stepInfo[currentSubStep].currentFillingRole,
        })
      )
      dispatch(
        updateQuestionGroupsMandatory({
          category: allCategories,
          updateAction: 'add',
          currentFillingRole: stepInfo[currentSubStep].currentFillingRole,
        })
      )
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [currentSubStep, stepInfo, dispatch])

  // update current question answers object
  useEffect(() => {
    const roleAnswers = questionAnswers[currentStepFillingRole]
    if (roleAnswers) {
      setCurrentQuestionAnswers(roleAnswers)
    } else {
      setCurrentQuestionAnswers({})
    }
  }, [questionAnswers, currentStepFillingRole])

  // SEND ANSWERS TO REDUCER
  const onValueChange = ({
    selectedId,
    selectedOption,
  }: {
    selectedId: number
    selectedOption: string | string[] | number | undefined
  }) => {
    inputAnswer() // USER START TO TYPE/INPUT SOMETHING will trigger isDirty

    // if question is currently a mandatory error, we will remove the error when user start typing or changing
    if (questionsWithError.includes(selectedId)) {
      setQuestionsWithError(
        questionsWithError.filter(
          (errorQuestion) => errorQuestion !== selectedId
        )
      )
    }
    dispatch(
      updateQuestionAnswer({
        selectedId,
        selectedOption,
        currentFillingRole: currentStepFillingRole,
      })
    )
  }

  // This function will check and return the correct next step or substep
  const checkNextStep = (nextStep: number): number => {
    const nextSubstepInfo = stepInfo[nextStep]
    const hasNextSubStep = nextSubstepInfo !== undefined
    if (!hasNextSubStep) return -1
    // check the next substep mandatory field
    if (nextSubstepInfo.mandatory === true) return nextStep
    // validate the conditions for the substep
    if (typeof nextSubstepInfo.mandatory !== 'boolean') {
      // ADDITIONAL CHECK for 追問 SUB STEP
      if (nextSubstepInfo.title === '追問') {
        // 1. Check question 13 answer
        if (renderQuestion13B(questionAnswers)) {
          return nextStep
        }
        return checkNextStep(nextStep + 1)
      }
      const conditionMatch = nextSubstepInfo.mandatory.conditions.map(
        (condition: ValueCompareConditionType | OptionMatchConditionType) => {
          if ('option' in condition) {
            const answerRole: FillingRoleType =
              ('role' in condition && condition.role) || currentStepFillingRole
            if (!questionAnswers[answerRole][condition.questionNumber]) {
              return false
            }
            if (
              condition.option.includes(
                questionAnswers[answerRole][condition.questionNumber]
                  .answer as string
              )
            ) {
              return true
            }
            return false
          }
          if (
            'compare' in condition &&
            'value1' in condition &&
            'value2' in condition
          ) {
            const value1 =
              questionAnswers[condition.value1.role as FillingRoleType][
                condition.value1.questionNumber
              ]

            const value2 =
              questionAnswers[condition.value2.role as FillingRoleType][
                condition.value2.questionNumber
              ]

            if (value1 && value2) {
              return compareValue({
                value1: value1.answer as number | string,
                value2: value2.answer as number | string,
                compare: condition.compare,
              })
            }
            return false
          }
          return false
        }
      )
      const isAllConditionsMatched =
        conditionMatch.filter(
          (conditionMatched: boolean) => conditionMatched !== true
        ).length === 0
      if (isAllConditionsMatched) {
        return nextStep
      }

      // if current next step does not fit the conditions, continue to the next step until
      // i, there is a substep match rendering condition
      // ii, no more substep (head to next main step)
      return checkNextStep(nextStep + 1)
    }
    return nextStep
  }

  // WHEN USER CLICK CONTINUE BUTTON, below functions will be called
  // 1. check if all questions in this page is answered
  // 2. additional check for 貿易往來資訊確認書 and show reminder popup
  // 3. check next step rendering condition and determine the correct next substep or next step
  // 4. check if 法定代理人2 is "disabled" and update reducer
  const onClickContinue = () => {
    if (!skipCurrentStep) {
      const { isAllQuestionAnswered, missingQuestions } =
        validateIsAllQuestionAnswered(
          currentQuestionAnswers,
          currentQuestionGroups
        )
      // field value validation
      const { hasError, errorMessages } = validateFields(
        currentQuestionAnswers,
        currentQuestionGroups,
        t
      )

      setQuestionsWithError(
        _uniq([
          ...missingQuestions,
          ...Object.keys(errorMessages).map((questionNumber: string) =>
            parseInt(questionNumber, 10)
          ),
        ])
      )

      if (Object.keys(errorMessages).length > 0) {
        setQuestionsErrorMessages(errorMessages)
      } else {
        setQuestionsErrorMessages({})
      }

      if (missingQuestions.length > 0) {
        // get first question with error
        const firstQuestionWithError = document.querySelector(
          `[data-gsquestion='${missingQuestions[0]}']`
        )

        if (firstQuestionWithError) {
          // get first question with error position
          const y =
            firstQuestionWithError.getBoundingClientRect().top +
            window.scrollY -
            (document.getElementById('header')?.clientHeight || 120) // header offset
          // scroll to the question
          window.scroll({
            top: y,
            behavior: 'smooth',
          })
        }
      }

      if (!isAllQuestionAnswered || hasError) return
    }

    if (!stepInfo) return
    if (currentStepTitle === '貿易往來資訊確認書') {
      if (!confirmedTradingModal) {
        if (
          currentQuestionAnswers[96].answer === '是' ||
          currentQuestionAnswers[97].answer === '是' ||
          currentQuestionAnswers[98].answer === '是' ||
          currentQuestionAnswers[99].answer === '是'
        ) {
          setShowPopupConfirmation(true)
          return
        }
      }
    }

    const nextSubStep = currentSubStep + 1
    const nextStep = checkNextStep(nextSubStep)
    const nextStepInfo = stepInfo[nextStep]

    if (nextStep < 0 || !nextStepInfo) {
      resetGoToSubStep()
      clickNextStep()
    } else {
      setCurrentSubStep(nextStep)
      setCurrentQuestionGroups(nextStepInfo.questionGroups)
    }
    if (currentStepFillingRole === FILLING_ROLE_AGENT_TWO && skipCurrentStep) {
      dispatch(removeAgentTwo())
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  return (
    <Section narrow>
      <SubStepContentWrapper
        currentStepFillingRole={currentStepFillingRole}
        currentStepTitle={currentStepTitle}
        setSkipCurrentStep={setSkipCurrentStep}
        skipCurrentStep={skipCurrentStep}
        age={age}
      >
        <div
          className={classnames({
            [styles.mask]: skipCurrentStep,
          })}
        >
          {currentQuestionGroups.map(
            (
              questionGroup: GeneralSuitabilityQuestionGroup,
              mIndex: number
            ) => (
              <div key={`${currentStepFillingRole}-${questionGroup.category}`}>
                {questionGroup.category !== QUESTION_CATEGORY_RISK_ASSESSMENT &&
                  mIndex !== 0 && (
                    <>
                      <div className='mdoip__general-suitability__divider--thick' />
                      <div className={styles.questionGroup__header}>
                        <p>
                          {questionGroup.category ===
                            QUESTION_CATEGORY_BASIC_INFO &&
                            t(
                              `generalSuitability:role.${currentStepFillingRole}`
                            )}
                          {t(
                            `generalSuitability:questionnaire-category.${questionGroup.category}`
                          )}
                        </p>
                      </div>
                    </>
                  )}
                <div
                  className={classnames(styles.questionGroup__group, {
                    [styles.questionGroup__group__basicInfo]:
                      questionGroup.category === QUESTION_CATEGORY_BASIC_INFO,
                    [styles.questionGroup__group__finanicalInfo]:
                      questionGroup.category ===
                      QUESTION_CATEGORY_FINANICAL_INFO,
                    [styles.questionGroup__group__healthInfo]:
                      questionGroup.category === QUESTION_CATEGORY_HEALTH_INFO,
                  })}
                >
                  {questionGroup.questions.map(
                    (question: GeneralSuitabilityQuestion, index: number) => (
                      <div
                        key={`${currentStepFillingRole}-${question.questionId}`}
                        className={classnames(styles.gsQuestionWrapper, {
                          [styles.questionGroup__basicInfoQuestion]:
                            questionGroup.category ===
                            QUESTION_CATEGORY_BASIC_INFO,
                          [styles.fullWidthInLg]:
                            (question.questionType ===
                              MULTI_SELECT_MULTIPLE_CHOICE &&
                              questionGroup.category ===
                                QUESTION_CATEGORY_FINANICAL_INFO) ||
                            questionGroup.category ===
                              QUESTION_CATEGORY_RISK_ASSESSMENT ||
                            questionGroup.category ===
                              QUESTION_CATEGORY_TRADING ||
                            question.questionNumber === 1,
                        })}
                      >
                        <GSQuestionDivider
                          questionId={question.questionId}
                          className={styles.questionGroup__divider}
                        />

                        {questionGroup.category ===
                          QUESTION_CATEGORY_RISK_ASSESSMENT &&
                          index !== 0 && (
                            <div
                              className={classnames(
                                'mdoip__general-suitability__divider',
                                styles.questionGroup__divider__lg
                              )}
                            />
                          )}
                        <GSQuestion
                          ageFilter={question.ageFilter}
                          key={`${currentStepFillingRole}-${question.questionId}`}
                          category={questionGroup.category}
                          question={question}
                          index={index}
                          onValueChange={onValueChange}
                          answers={
                            currentQuestionAnswers[question.questionNumber] ||
                            undefined
                          }
                          error={questionsWithError.includes(
                            question.questionNumber
                          )}
                          errorMessage={
                            questionsErrorMessages[question.questionNumber] ||
                            ''
                          }
                          inputProps={question.inputFieldProps}
                        />
                        <div className={styles.dependentQuestionBackground}>
                          {question.dependentQuestions &&
                            question.dependentQuestions.map(
                              (dependentQuestion, dQuestionIndex) => {
                                return (
                                  <GSQuestion
                                    ageFilter={dependentQuestion.ageFilter}
                                    key={`${currentStepFillingRole}-${dependentQuestion.questionId}`}
                                    question={dependentQuestion}
                                    index={dQuestionIndex}
                                    answers={
                                      currentQuestionAnswers[
                                        dependentQuestion.questionNumber
                                      ] || undefined
                                    }
                                    category={questionGroup.category}
                                    onValueChange={onValueChange}
                                    error={questionsWithError.includes(
                                      dependentQuestion.questionNumber
                                    )}
                                    errorMessage={
                                      questionsErrorMessages[
                                        dependentQuestion.questionNumber
                                      ] || ''
                                    }
                                    inputProps={
                                      dependentQuestion.inputFieldProps
                                    }
                                  />
                                )
                              }
                            )}
                        </div>
                        {question.instructionPopup && (
                          <FormInstructionModal
                            label={question.instructionPopup}
                          />
                        )}
                      </div>
                    )
                  )}
                </div>
              </div>
            )
          )}
        </div>
      </SubStepContentWrapper>
      <QuestionnaireControl
        buttonLabel={t('generalSuitability:button.continue')}
        currentStep={currentQuestionnaireStep}
        onClickContinue={onClickContinue}
      />
      {/* MODAL for confirming trading */}
      <ConfirmationModal
        options={{ initialOpen: showPopupConfirmation }}
        modalTitle={t(
          'generalSuitability:questionnaire-4d.confirmation-popup.title'
        )}
        modalContent={t(
          'generalSuitability:questionnaire-4d.confirmation-popup.content'
        )}
        mdoalConfirmButtonText={t(
          'generalSuitability:questionnaire-4d.confirmation-popup.confirm-button'
        )}
        mdoalCancelButtonText={t(
          'generalSuitability:questionnaire-4d.confirmation-popup.cancel-button'
        )}
        onConfirmButtonClick={() => {
          setConfirmedTradingModal(true)
          setShowPopupConfirmation(false)
        }}
        onCancelButtonClick={() => setShowPopupConfirmation(false)}
      />

      {/* FOR DEMO PURPOSE */}
      {currentQuestionnaireStep === 2 && (
        <div className='mb-4'>
          <p>問卷頁面</p>

          <>
            <Button
              onClick={() => {
                setCurrentSubStep(1)
              }}
            >
              貿易往來資訊確認書
            </Button>
            {age && age < 20 && (
              <>
                <Button
                  onClick={() => {
                    setCurrentSubStep(2)
                  }}
                >
                  法定代理人1基本資料
                </Button>
                <Button
                  onClick={() => {
                    setCurrentSubStep(4)
                  }}
                >
                  法定代理人2基本資料
                </Button>{' '}
              </>
            )}
            {currentSubStep === 0 && (
              <Button
                onClick={() =>
                  dispatch(
                    update4CQuestionRenderingStatus(!shouldShowQuestion4C)
                  )
                }
              >
                {shouldShowQuestion4C ? 'Hide' : 'Show'}Question 4C
              </Button>
            )}

            {age && age < 20 && currentSubStep !== 7 && (
              <Button onClick={() => setCurrentSubStep(6)}>追問</Button>
            )}
          </>

          <Button
            onClick={() => {
              clickNextStep()
            }}
          >
            風險評估問卷
          </Button>
        </div>
      )}
    </Section>
  )
}

export default Questionnaire
