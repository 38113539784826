const ApiRoutes = {
  userInformation: {
    HK: '/hk/authentication-account-info.json',
    TW: '/tw/authentication-account-info.json',
  },
  userMembershipTier: {
    HK: '/hk/account-client-tier.json',
    TW: '/tw/account-client-tier.json',
  },
  userRiskRating: {
    HK: '/hk/suitability-crr.json',
    TW: '/tw/suitability-crr.json',
  },
  userEPoints: {
    HK: '/hk/account-epoints.json',
    TW: '/tw/account-epoints.json',
  },
  portfolio: {
    HK: '/hk/portfolio-holdings.json',
    TW: '/tw/portfolio-holdings.json',
  },
  portfolioPie: {
    HK: '/hk/portfolio-pie-response.json',
    TW: '/tw/portfolio-pie-response.json',
  },
  fundPrice: {
    HK: '/hk/fund-price.json',
    TW: '/tw/fund-price.json',
  },
  // FOR testing purpose
  portfolioFundCategory: {
    HK: '/hk/portfolio-holdings-fund-category.json',
    TW: '/tw/portfolio-holdings-fund-category.json',
  },
  // FOR testing purpose
  portfolioFundMarket: {
    HK: '/tw/portfolio-holdings-fund-market.json',
    TW: '/tw/portfolio-holdings-fund-market.json',
  },
  portfolioFundCurrency: {
    HK: '/tw/portfolio-holdings-fund-market.json',
    TW: '/tw/portfolio-holdings-fund-currency.json',
  },
  // FOR testing purpose
  portfolioPieUsd: {
    HK: '/hk/portfolio-pie-response-usd.json',
    TW: '/tw/portfolio-pie-response-usd.json',
  },
  // for development and testing purpose
  portfolioPieLS: {
    HK: '/hk/portfolio-holdings-LS.json',
    TW: '/tw/portfolio-holdings-LS.json',
  },
  // for development and testing purpose
  portfolioPieRSP: {
    HK: '/hk/portfolio-holdings-RSP.json',
    TW: '/tw/portfolio-holdings-RSP.json',
  },
  // for development and testing purpose
  portfolioPieEC: {
    HK: '/hk/portfolio-holdings-EC.json',
    TW: '/tw/portfolio-holdings-EC.json',
  },
  // for development and testing purpose
  portfolioPieMA: {
    HK: '/hk/portfolio-holdings-MA.json',
    TW: '/tw/portfolio-holdings-MA.json',
  },
  portfolioPieMAFundCategory: {
    HK: '/hk/portfolio-holdings-MA-fund-category.json',
    TW: '/hk/portfolio-holdings-MA-fund-category.json',
  },
  portfolioPieREIP: {
    HK: '/hk/portfolio-holdings-REIP.json',
    TW: '/tw/portfolio-holdings-REIP.json',
  },
  // for development and testing purpose
  portfolioPieMALS: {
    HK: '/hk/portfolio-holdings-MA-LS.json',
    TW: '/tw/portfolio-holdings-MA-LS.json',
  },
  // for development and testing purpose
  portfolioHoldingMA: {
    HK: '/hk/portfolio-holdings-excel-MA.json',
    TW: '/hk/portfolio-holdings-excel-MA.json',
  },
  // for development and testing purpose
  portfolioHoldingMS: {
    HK: '/hk/portfolio-holdings-excel-MS.json',
    TW: '/hk/portfolio-holdings-excel-MS.json',
  },
  portfolioUsd: {
    HK: '/hk/portfolio-holdings-usd.json',
    TW: '/tw/portfolio-holdings-usd.json',
  },
  orderHistory: {
    HK: '/hk/deal-order-history.json',
    TW: '/tw/deal-order-history.json',
  },
  fund: {
    HK: '/hk/fund.json',
    TW: '/tw/fund.json',
  },
  topFundSearchList: {
    HK: '/hk/fund-search.json',
    TW: '/hk/fund-search.json',
  },
  fundCategory: {
    HK: '/hk/fund-category.json',
    TW: '/tw/fund-market.json',
  },
  fundMarket: {
    HK: '/hk/fund-market.json',
    TW: '/tw/fund-category.json',
  },
  recentlyViewed: {
    HK: '/hk/fund-recent-view.json',
    TW: '/tw/fund-recent-view.json',
  },
  topFiveFunds: {
    HK: '/hk/top-five-funds.json',
    TW: '/tw/top-five-funds.json',
  },
  announcement: {
    HK: '/hk/announcement.json',
    TW: '/tw/announcement.json',
  },
  coolKnowledge: {
    HK: '/hk/smart-tips.json',
    TW: '/tw/cool-knowledge.json',
  },
  coolKnowledgeLogin: {
    HK: '/hk/smart-tips-login.json',
    TW: '',
  },
  warningMessage: {
    HK: '/hk/typhoon.json',
    TW: '/tw/maintenance.json',
  },
  portfolioRiskRating: {
    HK: '/hk/portfolio-riskrating.json',
    TW: '',
  },
  priceAlert: {
    HK: '/hk/price-alert.json',
    TW: '/tw/price-alert.json',
  },
  promotions: {
    HK: '/hk/news.json',
    TW: '/tw/news.json',
  },
  newClientPromotions: {
    HK: '/hk/postlogin-side-bar-news.json',
    TW: '/tw/postlogin-side-bar-news.json',
  },
  search: {
    HK: '/hk/search-result.json',
    TW: '/tw/search-result.json',
  },
  urlTrading: {
    HK: '/hk/url-trading.json',
    TW: '/tw/url-trading.json',
  },
  cancelUrlTradingOrder: {
    HK: '/hk/cancel-trading-order.json',
    TW: '/tw/cancel-trading-order.json',
  },
  rspMigration: {
    HK: '/hk/rsp-migration.json',
    TW: '/tw/rsp-migration.json',
  },
  updateUserConsent: {
    HK: '/hk/update-user-consent.json',
    TW: '/tw/update-user-consent.json',
  },
  submitConfirmTradingOrders: {
    HK: '/hk/submit-orders.json',
    TW: '/tw/submit-orders.json',
  },
  validateAllotment: {
    HK: '/hk/validate-allotment.json',
    TW: '/tw/validate-allotment.json',
  },
  updateRedemptEstimateValue: {
    HK: '/hk/update-redempt-estimate-value.json',
    TW: '/tw/update-redempt-estimate-value.json',
  },
  redemptionInformation: {
    HK: '/hk/url-trading-redemption-info.json',
    TW: '/tw/url-trading-redemption-info.json',
  },
  // for development testing purpose
  emptyPortfolio: {
    HK: '/hk/empty-portfolio-holdings.json',
    TW: '/tw/empty-portfolio-holdings.json',
  },
  validateLoginCredentials: {
    HK: '/hk/validate-login-credentials.json',
    TW: '/tw/validate-login-credentials.json',
  },
  // for development testing purpose
  validateLoginCredentialsFailed: {
    HK: '/hk/validate-login-credentials-failed.json',
    TW: '/tw/validate-login-credentials-failed.json',
  },
  // for developemnt testing purpose
  allFundTableData: {
    HK: '/hk/fund-data.json',
    TW: '/tw/fund-data.json',
  },
  setting: {
    HK: '/hk/setting.json',
    TW: '',
  },
}

export default ApiRoutes
