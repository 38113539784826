import { store } from '../store'
import { GeneralSuitabilityQuestion } from '../typings/GeneralSuitability'
import compareValue from './compareValue'

const checkAgeFilter = (
  filterObj: GeneralSuitabilityQuestion['ageFilter']
): boolean => {
  if (!filterObj) return true

  const { value, compare } = filterObj

  const state = store.getState()
  const { age } = state.generalSuitability

  if (!age) return false
  return compareValue({ value1: age, value2: value, compare })
}

export default checkAgeFilter
