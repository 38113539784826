import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootStateOrAny } from 'react-redux'

import { appAxios, getRoute } from '../../../helpers'
import { AllFundsTableData } from '../../../typings/AllFundsTableDataResponse'

interface AllFundsTableDataResponse {
  list: AllFundsTableData[]
}

const transformAllFunds = (
  responseData: AllFundsTableDataResponse
): AllFundsTableData[] => {
  return responseData.list
}

const fetchAllFundTableData = createAsyncThunk<AllFundsTableData[] | undefined>(
  'fetchAllFundTableData',
  async (_, { getState }) => {
    const state: RootStateOrAny = getState()
    const { allFundTableDataList } = state.allFundTableData
    if (allFundTableDataList) {
      return undefined
    }
    const fundRes = await appAxios().get(
      getRoute({ route: 'allFundTableData' })
    )

    return transformAllFunds(fundRes.data)
  }
)

export default fetchAllFundTableData
