import {
  FILLING_ROLE_AGENT_ONE,
  FILLING_ROLE_AGENT_TWO,
  FILLING_ROLE_SELF,
  FillingRoleType,
  QuestionAnswers,
} from '../typings/GeneralSuitability'
import { parseOptionToNumber } from './compareValue'

const renderQuestion13B = (
  questionAnswers: {
    [key in FillingRoleType]: QuestionAnswers
  }
): boolean => {
  if (questionAnswers[FILLING_ROLE_SELF][13].answer !== '是') {
    return false
  }

  // 2. GET HIGHER VALUE from agent 1 and agent 2 income
  const agentOneIncome = parseOptionToNumber(
    (questionAnswers[FILLING_ROLE_AGENT_ONE][7].answer as string) || 0
  )
  const agentTwoIncome = parseOptionToNumber(
    (questionAnswers[FILLING_ROLE_AGENT_TWO][7].answer as string) || 0
  )
  const agentIncome =
    agentOneIncome > agentTwoIncome ? agentOneIncome : agentTwoIncome

  const userInvestmentAmount = parseOptionToNumber(
    questionAnswers[FILLING_ROLE_SELF][9].answer as string
  )

  // 3. Compare with user's investment amount
  return userInvestmentAmount > agentIncome
}

export default renderQuestion13B
