import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AnnouncementType } from '../../typings/Announcement'
import loadAnnouncement from './actions/loadAnnouncement'

type AnnouncementState = {
  list: AnnouncementType[]
  loading: boolean
}

const initialState: AnnouncementState = {
  list: [],
  loading: false,
}

export const announcementSlice = createSlice({
  name: 'announcement',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      loadAnnouncement.fulfilled,
      (state, action: PayloadAction<AnnouncementType[]>) => {
        // eslint-disable-next-line no-param-reassign
        state.list = action.payload
      }
    )
  },
})

export default announcementSlice.reducer
