import { createAsyncThunk } from '@reduxjs/toolkit'

const updateBreakdownFilteredIndex = createAsyncThunk<number, number>(
  'updateBreakdownFilteredIndex',
  async (index: number) => {
    return index
  }
)

export default updateBreakdownFilteredIndex
