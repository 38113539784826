export const PORTFOLIO_RISK_LEVEL_1_HK = 'conservative'
export const PORTFOLIO_RISK_LEVEL_2_HK = 'stable'
export const PORTFOLIO_RISK_LEVEL_3_HK = 'balanced'
export const PORTFOLIO_RISK_LEVEL_4_HK = 'growth'
export const PORTFOLIO_RISK_LEVEL_5_HK = 'aggressive'

export const CLIENT_RISK_LEVEL_1_HK = 'conservative'
export const CLIENT_RISK_LEVEL_2_HK = 'stable'
export const CLIENT_RISK_LEVEL_3_HK = 'balanced'
export const CLIENT_RISK_LEVEL_4_HK = 'growth'
export const CLIENT_RISK_LEVEL_5_HK = 'aggressive'

export const CLIENT_RISK_LEVEL_1_TW = 'a'
export const CLIENT_RISK_LEVEL_2_TW = 'b'
export const CLIENT_RISK_LEVEL_3_TW = 'c'

export const FUND_RISK_LEVEL_1 = 1
export const FUND_RISK_LEVEL_2 = 2
export const FUND_RISK_LEVEL_3 = 3
export const FUND_RISK_LEVEL_4 = 4
export const FUND_RISK_LEVEL_5 = 5

export const FUND_RISK_LABELS = [
  FUND_RISK_LEVEL_1,
  FUND_RISK_LEVEL_2,
  FUND_RISK_LEVEL_3,
  FUND_RISK_LEVEL_4,
  FUND_RISK_LEVEL_5,
]

export const RISK_BAR_VARIANT_CLIENT_RISK = 'clientRiskRating'
export const RISK_BAR_VARIANT_PORTFOLIO_RISK = 'portfolioRiskRating'
