import classnames from 'classnames'
import {
  FunctionComponent,
  KeyboardEvent,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from 'react'

import styles from './UserInputMultiDisplay.module.scss'

const KEY_CODE = {
  backspace: 8,
  leftArrow: 37,
  rightArrow: 39,
}

interface UserInputMultiDisplayProps {
  length: number
  type: 'text' | 'number'
  onValueChange: (change: string | undefined) => void
  id: string
  error: boolean
  value?: string | undefined
}
const UserInputMultiDisplay: FunctionComponent<UserInputMultiDisplayProps> = ({
  length,
  type,
  onValueChange,
  id,
  error,
  value,
  ...props
}: UserInputMultiDisplayProps) => {
  const [localInput, setLocalInput] = useState<string[]>(
    [...Array(length)].map((_) => '')
  )
  const [currentActiveIndex, setCurrentActiveIndex] = useState<number | null>(
    null
  )
  const inputRef = useRef<HTMLInputElement | null>(null)

  const onKeyUpHandler = (e: KeyboardEvent<HTMLInputElement>) => {
    if (currentActiveIndex === null) return

    const newInput = [...localInput]
    if (e.keyCode === KEY_CODE.backspace) {
      if (currentActiveIndex >= 1 && currentActiveIndex <= length - 1) {
        setCurrentActiveIndex(currentActiveIndex - 1)
        newInput[currentActiveIndex] = ''
      }
      if (currentActiveIndex === 0) {
        newInput[0] = ''
      }
    }

    if (e.keyCode === KEY_CODE.leftArrow) {
      if (currentActiveIndex === 0) return
      setCurrentActiveIndex(currentActiveIndex - 1)
    }

    if (e.keyCode === KEY_CODE.rightArrow) {
      if (currentActiveIndex === length - 1) return
      setCurrentActiveIndex(currentActiveIndex + 1)
    }
    if (
      type === 'number' &&
      ((e.keyCode >= 48 && e.keyCode <= 57) ||
        (e.keyCode >= 96 && e.keyCode <= 105))
    ) {
      if (currentActiveIndex >= length) return
      newInput[currentActiveIndex] = e.key
      if (currentActiveIndex < length - 1) {
        setCurrentActiveIndex(currentActiveIndex + 1)
      }
    } else if (
      type === 'text' &&
      ((e.keyCode >= 48 && e.keyCode <= 90) ||
        (e.keyCode >= 96 && e.keyCode <= 105))
    ) {
      if (currentActiveIndex >= length) return
      newInput[currentActiveIndex] = e.key
      if (currentActiveIndex < length - 1) {
        setCurrentActiveIndex(currentActiveIndex + 1)
      }
    }
    setLocalInput(newInput)
  }

  const onChangeHandler = () => {
    const inputLength = localInput.filter((char) => char !== '').length
    if (inputLength < length) {
      onValueChange(undefined)
    } else {
      onValueChange(localInput.join('')) // only sent the answer to the state when the input match the length
    }
  }

  const selectButton = (e: MouseEvent<HTMLButtonElement>) => {
    const index = e.currentTarget.getAttribute('data-button-index')
    if (!index) return
    setCurrentActiveIndex(parseInt(index, 10))
    inputRef.current?.focus()
  }

  useEffect(() => {
    if (!value) return
    setLocalInput(value.split(''))
  }, [value])

  return (
    <div className={styles.multiDisplay}>
      <input
        type='string'
        value={localInput.join('')}
        onChange={onChangeHandler}
        className={styles.multiDisplay__input}
        onKeyUp={onKeyUpHandler}
        ref={inputRef}
        onBlur={() => setCurrentActiveIndex(null)}
        {...props}
      />
      {[...Array(length)].map((_, index) => (
        <button
          data-testid='multi-display-button'
          data-button-index={index}
          // eslint-disable-next-line react/no-array-index-key
          key={`${id}-multi-select-${index}`}
          onClick={selectButton}
          className={classnames(styles.multiDisplay__button, {
            [styles.isActive]: currentActiveIndex === index,
            [styles.error]: error,
          })}
        >
          {localInput[index] ? (
            localInput[index]
          ) : (
            <span className={styles.multiDisplay__button__placeholder}>0</span>
          )}
        </button>
      ))}
    </div>
  )
}

UserInputMultiDisplay.defaultProps = {
  value: undefined,
}

export default UserInputMultiDisplay
