import { FunctionComponent } from 'react'

import { IconProps } from '../../typings/Icon'

const SearchIcon: FunctionComponent<IconProps> = ({
  className,
  height,
  width,
  ...props
}: IconProps) => (
  <svg
    height={height}
    width={width}
    className={className}
    {...props}
    viewBox='0 0 19 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.841 7.29875C12.841 10.3595 10.3598 12.8407 7.29912 12.8407C4.2384 12.8407 1.7572 10.3595 1.7572 7.29875C1.7572 4.23804 4.2384 1.75684 7.29912 1.75684C10.3598 1.75684 12.841 4.23804 12.841 7.29875ZM11.72 12.7803C10.5114 13.7563 8.97353 14.3407 7.29912 14.3407C3.40998 14.3407 0.257202 11.1879 0.257202 7.29875C0.257202 3.40961 3.40998 0.256836 7.29912 0.256836C11.1883 0.256836 14.341 3.40961 14.341 7.29875C14.341 8.97316 13.7566 10.5111 12.7807 11.7197L18.4331 17.3721C18.726 17.6649 18.726 18.1398 18.4331 18.4327C18.1402 18.7256 17.6653 18.7256 17.3724 18.4327L11.72 12.7803Z'
      fill='#3E3E3E'
    />
  </svg>
)

SearchIcon.defaultProps = {
  className: '',
  height: 19,
  width: 19,
}

export default SearchIcon
