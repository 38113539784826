import { FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import FormReviewAccordion from '../../../components/form-review-accordion'
import FormReviewDataGroup, {
  FORM_REVIEW_DISPLAY_HALF,
  FORM_REVIEW_DISPLAY_ONE_THIRD,
  GeneralSuitabilityFormDataType,
} from '../../../components/form-review-data-group/FormReviewDataGroup'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import Section from '../../../layouts/section'
import {
  updateShowResultFeedback,
  updateShowSpecialCriteriaPopup,
} from '../../../redux/general-suitability/actions'
import submitQuestionnaire from '../../../redux/general-suitability/actions/submitQuestionniare'
import submitSpecialCriteriaOption from '../../../redux/general-suitability/actions/submitSpecialCriteriaOption'
import routes from '../../../routes/SiteNavigation'
import { RootState } from '../../../store'
import {
  FILLING_ROLE_AGENT_ONE,
  FILLING_ROLE_AGENT_TWO,
  FILLING_ROLE_SELF,
  FillingRoleType,
  GeneralSuitabilityQuestion,
  GeneralSuitabilityQuestionGroup,
  QuestionAnswer,
  QuestionAnswers,
  QuestionnaireSubStepType,
  STATUS_LOADING,
} from '../../../typings/GeneralSuitability'
import FormResultFeedback from '../form-result-feedback'
import QuestionnaireControl from '../questionnaire-control'
import SpecialCriteriaPopup from '../special-criteria-popup'
import styles from './FormReviewSection.module.scss'

export type EditBackStepType = {
  step: number
  subStepIndexNumber: number
}
interface FormReviewSectionProps {
  goToStep: (step: EditBackStepType) => void
}

const formatAnswer = (answer: QuestionAnswer): string => {
  switch (typeof answer) {
    case 'string':
      return answer
    case 'number':
      return answer.toFixed(0)
    case 'object':
      return answer.join('，')
    default:
      return ''
  }
}

const renderFormData = (
  questions: GeneralSuitabilityQuestion[],
  questionAnswers: QuestionAnswers
): GeneralSuitabilityFormDataType[] => {
  const formData: GeneralSuitabilityFormDataType[] = []
  questions.forEach((mainQuestion) => {
    if (
      questionAnswers[mainQuestion.questionNumber] &&
      questionAnswers[mainQuestion.questionNumber].mandatory
    ) {
      formData.push({
        questionNumber: mainQuestion.questionNumber,
        question: mainQuestion.question,
        answer: questionAnswers[mainQuestion.questionNumber]
          ? formatAnswer(questionAnswers[mainQuestion.questionNumber].answer)
          : '',
      })
    }

    if (mainQuestion.dependentQuestions.length > 0) {
      mainQuestion.dependentQuestions.forEach((dependentQuestion) => {
        if (
          !questionAnswers[dependentQuestion.questionNumber] ||
          !questionAnswers[dependentQuestion.questionNumber].mandatory
        )
          return
        formData.push({
          questionNumber: dependentQuestion.questionNumber,
          question: dependentQuestion.question,
          answer: questionAnswers[dependentQuestion.questionNumber]
            ? formatAnswer(
                questionAnswers[dependentQuestion.questionNumber].answer
              )
            : '',
        })
      })
    }
  })
  return formData
}

const FormReviewSection: FunctionComponent<FormReviewSectionProps> = ({
  goToStep,
}: FormReviewSectionProps) => {
  const {
    questionAnswers,
    stepQuestionnaireData,
    age,
    mandatoryQuestionGroups,
    showResultFeedback,
    showSpecialCriteriaPopup,
    enhancedSuitabilityStatus,
    riskLevel,
    generalSuitabilityStatus,
  } = useAppSelector((state: RootState) => state.generalSuitability)

  const dispatch = useAppDispatch()
  const history = useHistory()

  const { t } = useTranslation()

  const onClickEditButton = (stepInfo: EditBackStepType) => {
    goToStep(stepInfo)
  }

  const [showModal, setShowModal] = useState<boolean>(false)

  // submit questionniare to reducer
  const onSubmitQuestionnaire = () => {
    dispatch(submitQuestionnaire(questionAnswers))
  }

  useEffect(() => {
    if (showResultFeedback || showSpecialCriteriaPopup.show) {
      setShowModal(true)
    }
  }, [showResultFeedback, showSpecialCriteriaPopup])

  // This function will cross check with reducer and return a list questiongroups that should be rendered in the section (i.e 貿易往來資訊確認書)
  const renderQuestionGroups = (
    currentFillingRole: FillingRoleType,
    questionnaireStep: number
  ): GeneralSuitabilityQuestionGroup[] => {
    let filteredQuestionGroups: GeneralSuitabilityQuestionGroup[] = []
    const currentStepInfo = stepQuestionnaireData[questionnaireStep - 1]
    if (!currentStepInfo) return filteredQuestionGroups
    currentStepInfo
      .filter(
        (subStep: QuestionnaireSubStepType) =>
          subStep.currentFillingRole === currentFillingRole
      )
      .forEach((filteredSubStep: QuestionnaireSubStepType) => {
        filteredQuestionGroups = [
          ...filteredQuestionGroups,
          ...filteredSubStep.questionGroups,
        ]
      })

    filteredQuestionGroups = filteredQuestionGroups.filter((group) =>
      mandatoryQuestionGroups[currentFillingRole].includes(group.category)
    )

    return filteredQuestionGroups
  }

  const stepOneSelfFillingQuestionsGroups = renderQuestionGroups(
    FILLING_ROLE_SELF,
    1
  )

  const stepOneAgentOneFillingQuestionsGroups = renderQuestionGroups(
    FILLING_ROLE_AGENT_ONE,
    1
  )

  const stepOneAgentTwoFillingQuestionsGroups = renderQuestionGroups(
    FILLING_ROLE_AGENT_TWO,
    1
  )

  const stepTwoFillingQuestionGroups = renderQuestionGroups(
    FILLING_ROLE_SELF,
    2
  )

  return (
    <div className={styles.formReviewSection__mainDiv}>
      <div className={styles.formReviewSection__description}>
        <Section narrow>
          <span> {t('generalSuitability:review.description')}</span>
        </Section>
      </div>
      <Section narrow>
        {/* 受益人(本人) */}
        <FormReviewAccordion
          accordionClassName='mb-4'
          header={t('generalSuitability:role.FILLING_ROLE_SELF')}
          editButtonCallBack={() =>
            onClickEditButton({
              step: 2,
              subStepIndexNumber: stepQuestionnaireData[0].findIndex(
                (subStep: QuestionnaireSubStepType) =>
                  subStep.currentFillingRole === FILLING_ROLE_SELF
              ),
            })
          }
        >
          {stepOneSelfFillingQuestionsGroups.map(
            (group: GeneralSuitabilityQuestionGroup, index: number) => (
              <>
                {index !== 0 && (
                  <div className={styles.formReviewSection__group__divider} />
                )}
                <FormReviewDataGroup
                  title={
                    t(
                      `generalSuitability:review.group-title.${group.category}`
                    ) || undefined
                  }
                  formData={renderFormData(
                    group.questions,
                    questionAnswers[
                      FILLING_ROLE_SELF as FillingRoleType
                    ] as QuestionAnswers
                  )}
                  display={FORM_REVIEW_DISPLAY_ONE_THIRD}
                />
              </>
            )
          )}
        </FormReviewAccordion>
        {/* 法定代理人1 */}
        {stepOneAgentOneFillingQuestionsGroups.length > 0 && (
          <FormReviewAccordion
            accordionClassName='mb-4'
            header={t('generalSuitability:role.FILLING_ROLE_AGENT_ONE')}
            editButtonCallBack={() =>
              onClickEditButton({
                step: 2,
                subStepIndexNumber: stepQuestionnaireData[0].findIndex(
                  (subStep: QuestionnaireSubStepType) =>
                    subStep.currentFillingRole === FILLING_ROLE_AGENT_ONE
                ),
              })
            }
          >
            {stepOneAgentOneFillingQuestionsGroups.map(
              (group: GeneralSuitabilityQuestionGroup, index: number) => (
                <>
                  {index !== 0 && (
                    <div className={styles.formReviewSection__group__divider} />
                  )}
                  <FormReviewDataGroup
                    title={
                      t(
                        `generalSuitability:review.group-title.${group.category}`
                      ) || undefined
                    }
                    formData={renderFormData(
                      group.questions,
                      questionAnswers[FILLING_ROLE_AGENT_ONE]
                    )}
                    display={FORM_REVIEW_DISPLAY_ONE_THIRD}
                  />
                </>
              )
            )}
          </FormReviewAccordion>
        )}
        {/* 法定代理人2 */}
        {stepOneAgentTwoFillingQuestionsGroups.length > 0 && (
          <FormReviewAccordion
            accordionClassName='mb-4'
            header={t('generalSuitability:role.FILLING_ROLE_AGENT_TWO')}
            editButtonCallBack={() =>
              onClickEditButton({
                step: 2,
                subStepIndexNumber: stepQuestionnaireData[0].findIndex(
                  (subStep: QuestionnaireSubStepType) =>
                    subStep.currentFillingRole === FILLING_ROLE_AGENT_TWO
                ),
              })
            }
          >
            {stepOneAgentTwoFillingQuestionsGroups.map((group, index) => (
              <>
                {index !== 0 && (
                  <div className={styles.formReviewSection__group__divider} />
                )}
                <FormReviewDataGroup
                  title={
                    t(
                      `generalSuitability:review.group-title.${group.category}`
                    ) || undefined
                  }
                  formData={renderFormData(
                    group.questions,
                    questionAnswers[FILLING_ROLE_AGENT_TWO]
                  )}
                  display={FORM_REVIEW_DISPLAY_ONE_THIRD}
                />
              </>
            ))}
          </FormReviewAccordion>
        )}
        {/* 風險屬性評估 */}
        <FormReviewAccordion
          accordionClassName='mb-4'
          header={t('generalSuitability:review.risk-assessment')}
          editButtonCallBack={() =>
            onClickEditButton({
              step: 3,
              subStepIndexNumber: stepQuestionnaireData[1].findIndex(
                (subStep: QuestionnaireSubStepType) =>
                  subStep.currentFillingRole === FILLING_ROLE_SELF
              ),
            })
          }
        >
          {age && age <= 20 && (
            <p className={styles.formReviewSection__subtitle}>
              {t('generalSuitability:review.risk-assessment-minor')}
            </p>
          )}
          {stepTwoFillingQuestionGroups.map((group) => (
            <FormReviewDataGroup
              displayQuestionNumber
              display={FORM_REVIEW_DISPLAY_HALF}
              formData={renderFormData(
                group.questions,
                questionAnswers[FILLING_ROLE_SELF]
              )}
            />
          ))}
        </FormReviewAccordion>

        {/* 重要提示介面 */}
        {/* showSpecialCriteriaPopup are controlled by reducer function */}
        {showSpecialCriteriaPopup.show && showModal && (
          <SpecialCriteriaPopup
            onCancel={() => {
              setShowModal(false)
              dispatch(
                updateShowSpecialCriteriaPopup({
                  show: false,
                })
              )
            }}
            onSubmit={(answer) => {
              dispatch(submitSpecialCriteriaOption(answer))
              setShowModal(false)
            }}
            questionData={showSpecialCriteriaPopup.question}
          />
        )}
        {/* 完成問卷MODAL */}
        {/* showResultFeedback are controlled by reducer function */}
        {showResultFeedback && showModal && (
          <FormResultFeedback
            riskLevel={riskLevel}
            onClose={() => {
              setShowModal(false)
              dispatch(updateShowResultFeedback(false))
              goToStep({ step: 1, subStepIndexNumber: 0 }) // back to step one
            }}
            enhancedSuitabilityStatus={enhancedSuitabilityStatus}
            goToEnhancedSuitability={() =>
              history.push(routes.enhancedSuitability)
            }
            isLoading={generalSuitabilityStatus === STATUS_LOADING}
          />
        )}

        <QuestionnaireControl
          currentStep={4}
          onClickContinue={onSubmitQuestionnaire}
          buttonLabel={t('generalSuitability:button.continue')}
        />
      </Section>
    </div>
  )
}

export default FormReviewSection
