import classnames from 'classnames'
import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  GeneralSuitabilityQuestion,
  RADIO_TEXT_INPUT_COMBO,
} from '../../typings/GeneralSuitability'
import ComboTextField from '../combo-text-field'
import DropdownSelect from '../dropdown-select'
import ErrorMessage from '../error-message'
import RadioButton from '../radio-button/RadioButton'
import styles from './SingleChoiceQuestion.module.scss'

export const OPTION_DISPLAY_HORIZONTAL = 'OPTION_DISPLAY_HORIZONTAL'
export const OPTION_DISPLAY_VERTICAL = 'OPTION_DISPLAY_VERTICAL'
export const OPTION_DISPLAY_WIDTH_50 = 'OPTION_DISPLAY_WIDTH_50'
export const OPTION_DISPLAY_WIDTH_AUTO = 'OPTION_DISPLAY_WIDTH_AUTO'
export const OPTION_DISPLAY_DROPDOWN = 'OPTION_DISPLAY_DROPDOWN'
export const OPTION_DISPLAY_HORIZONTAL_ONE_THIRD =
  'OPTION_DISPLAY_HORIZONTAL_ONE_THIRD'

export const GENERAL_SUITABILITY_QUESTION = 'GENERAL_SUITABILITY_QUESTION'

export type DisplayOptions =
  | typeof OPTION_DISPLAY_HORIZONTAL
  | typeof OPTION_DISPLAY_VERTICAL
  | typeof OPTION_DISPLAY_WIDTH_50
  | typeof OPTION_DISPLAY_WIDTH_AUTO
  | typeof OPTION_DISPLAY_DROPDOWN
  | typeof OPTION_DISPLAY_HORIZONTAL_ONE_THIRD

export type QuestionVariant = typeof GENERAL_SUITABILITY_QUESTION | undefined

export type SingleChoiceQuestionSelectedOptionType = {
  selectedId: number
  selectedOption: string
}

export interface SingleChoiceQuestionValueChangeProps {
  onValueChange?: (value: SingleChoiceQuestionSelectedOptionType) => void
}

export interface SingleChoiceQuestionProps
  extends SingleChoiceQuestionValueChangeProps {
  variant?: QuestionVariant
  className?: string
  optionPrefix?: string
  questionNumber: number
  displayQuestionNumber?: boolean | number
  question?: string
  options?: string[]
  display?: DisplayOptions
  error?: boolean
  selectedOption?: false | string
  extraDisplayOptions?: GeneralSuitabilityQuestion['extraDisplayOptions']
  disabled?: boolean
}

const SingleChoiceQuestion: FunctionComponent<SingleChoiceQuestionProps> = ({
  className,
  variant,
  optionPrefix,
  displayQuestionNumber,
  questionNumber,
  question,
  options,
  display,
  onValueChange,
  error,
  selectedOption,
  extraDisplayOptions,
  disabled,
  ...props
}) => {
  const { t } = useTranslation()
  const [currentValue, setCurrentValue] = useState(selectedOption)
  const handleChange = (val: React.SyntheticEvent<HTMLButtonElement>) => {
    if (disabled) return
    const currentSelectedOption: string = val.currentTarget.value || ''
    setCurrentValue(currentSelectedOption)

    if (
      extraDisplayOptions?.displayTextFieldOnAnswer === currentSelectedOption ||
      extraDisplayOptions?.displayTextFieldOnAnswer.includes(
        currentSelectedOption
      )
    ) {
      // should not send to state, since it should be considered as incompleted when text input is empty
      return
    }

    if (onValueChange) {
      onValueChange({
        selectedId: questionNumber,
        selectedOption: currentSelectedOption,
      })
    }
  }
  const handleTextInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    option: string
  ) => {
    setCurrentValue(option)
    if (onValueChange) {
      onValueChange({
        selectedId: questionNumber,
        selectedOption: `${option}::${e.currentTarget.value}`,
      })
    }
  }

  const onClickTextInput = (option: string) => {
    setCurrentValue(option)
  }

  useEffect(() => {
    setCurrentValue(selectedOption ? selectedOption.split('::')[0] : undefined)
  }, [selectedOption])

  return (
    <div
      className={classnames(className, styles.question, {
        [styles.generalSuitability]: variant === GENERAL_SUITABILITY_QUESTION,
        [styles.vertical]: display === OPTION_DISPLAY_VERTICAL,
        [styles.horizontal__half]: display === OPTION_DISPLAY_WIDTH_50,
        [styles.horizontal__auto]: display === OPTION_DISPLAY_WIDTH_AUTO,
        [styles.horizontal__oneThird]:
          display === OPTION_DISPLAY_HORIZONTAL_ONE_THIRD,
        [styles.hasError]: error,
      })}
      {...props}
    >
      <>
        <div className={styles.questionContainer}>
          {displayQuestionNumber && (
            <span className={styles.questionNumber}>
              {typeof displayQuestionNumber === 'number'
                ? displayQuestionNumber
                : questionNumber}
              .
            </span>
          )}
          {question && (
            <span
              className={styles.questionText}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: question }}
            />
          )}
        </div>
        {display === OPTION_DISPLAY_DROPDOWN ? (
          <div className={styles.dropdownWrapper}>
            {options && options.length && (
              <DropdownSelect
                disabled={disabled}
                error={error || false}
                headerText={currentValue || question || ''}
                list={options}
                onChangeSelect={(select: string) => {
                  if (onValueChange) {
                    onValueChange({
                      selectedId: questionNumber,
                      selectedOption: select,
                    })
                  }
                }}
              />
            )}
          </div>
        ) : (
          <div className={styles.optionWrapper}>
            {options &&
              options.length &&
              options.map((option, index) => {
                const key = `${optionPrefix}${questionNumber}${option}${index}`

                return (
                  <div key={key} className={styles.optionsContainer}>
                    <RadioButton
                      error={error}
                      value={option}
                      active={currentValue === option}
                      id={key}
                      className={styles.radioButton}
                      onClick={(event) => {
                        handleChange(event)
                      }}
                    />
                    <label htmlFor={key}>
                      {/* eslint-disable-next-line react/no-danger */}
                      <span dangerouslySetInnerHTML={{ __html: option }} />
                      {extraDisplayOptions &&
                        extraDisplayOptions.display ===
                          RADIO_TEXT_INPUT_COMBO && (
                          <ComboTextField
                            extraDisplayOptions={extraDisplayOptions}
                            onFocusHandler={onClickTextInput}
                            handleTextInputChange={handleTextInputChange}
                            error={(currentValue === option && error) || false}
                            option={option}
                            value={
                              selectedOption
                                ? selectedOption.split('::')[1]
                                : ''
                            }
                          />
                        )}
                    </label>
                  </div>
                )
              })}
          </div>
        )}

        {error && (
          <ErrorMessage
            containerClassName={styles.errorContainer}
            message={t('questions:error.question-required')}
          />
        )}
      </>
    </div>
  )
}

SingleChoiceQuestion.defaultProps = {
  optionPrefix: '',
  question: '',
  options: [],
  error: false,
  displayQuestionNumber: true,
  className: '',
  extraDisplayOptions: undefined,
  disabled: false,
}

export default SingleChoiceQuestion
