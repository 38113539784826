import { warningMessageSlice } from '../warningMessageSlice'

// eslint-disable-next-line import/prefer-default-export
export { default as loadWarningMessage } from './loadWarningMessage'
export const {
  setMessageRead,
  setShowMessage,
  addToDisplayList,
  addWarningMessage,
} = warningMessageSlice.actions
