import { MultiInputDisplayType } from '../components/text-field/TextField'
import {
  MULTI_SELECT_MULTIPLE_CHOICE,
  SINGLE_SELECT_MULTIPLE_CHOICE,
} from '../constants/question-types'

export const FILLING_ROLE_SELF = 'FILLING_ROLE_SELF' // 受益人
export const FILLING_ROLE_AGENT_ONE = 'FILLING_ROLE_AGENT_ONE' // 法定代理人1
export const FILLING_ROLE_AGENT_TWO = 'FILLING_ROLE_AGENT_TWO' // 法定代理人2

export type FillingRoleType =
  | typeof FILLING_ROLE_SELF
  | typeof FILLING_ROLE_AGENT_ONE
  | typeof FILLING_ROLE_AGENT_TWO

export const FORM_INSTRUCTION_OCCUPATION = 'FORM_INSTRUCTION_OCCUPATION' // 職稱填寫說明
export const FORM_INSTRUCTION_POLITICAL_RELATIONSHIP =
  'FORM_INSTRUCTION_POLITICAL_RELATIONSHIP' // 政治人物填寫說明

export type FormInstructionModalLabel =
  | typeof FORM_INSTRUCTION_OCCUPATION
  | typeof FORM_INSTRUCTION_POLITICAL_RELATIONSHIP

export const STATUS_LOADING = 'STATUS_LOADING'
export const STATUS_COMPLETED = 'STATUS_COMPLETED'
export const STATUS_INCOMPLETE = 'STATUS_INCOMPLETE'
export const STATUS_PASSED = 'STATUS_PASSED'
export const STATUS_FAILED = 'STATUS_FAILED'

export type QuestionnaireStatus =
  | typeof STATUS_COMPLETED
  | typeof STATUS_INCOMPLETE
  | typeof STATUS_PASSED
  | typeof STATUS_FAILED
  | typeof STATUS_LOADING

export const RISK_LOW = 'a'
export const RISK_MIDDLE = 'b'
export const RISK_HIGH = 'c'

export type RiskLevel = typeof RISK_LOW | typeof RISK_MIDDLE | typeof RISK_HIGH

// QUESTIONNAIRE TYPE
export const QUESTION_TYPE_FREE_INPUT = 'QUESTION_TYPE_FREE_INPUT'

export type QuestionType =
  | typeof SINGLE_SELECT_MULTIPLE_CHOICE // THIS OPTION WILL RENDER EITHER RADIO BUTTON OR DROPDOWN
  | typeof MULTI_SELECT_MULTIPLE_CHOICE // THIS OPTION WILL RENDER CHECKBOX
  | typeof QUESTION_TYPE_FREE_INPUT // THIS OPTION WILL RENDER TEXT FIELD

export const QUESTION_DISPLAY_RADIO = 'QUESTION_DISPLAY_RADIO'
export const QUESTION_DISPLAY_TRUE_OR_FALSE = 'QUESTION_DISPLAY_TRUE_OR_FALSE'
export const QUESTION_DISPLAY_CHECKBOX = 'QUESTION_DISPLAY_CHECKBOX'
export const QUESTION_DISPLAY_DROPDOWN = 'QUESIONT_DISPLAY_DROPDOWN'
export const QUESTION_DISPLAY_TEXT_FIELD = 'QUESTION_DISPLAY_TEXT_FIELD'
export const QUESTION_DISPLAY_DATE_FIELD = 'QUESTION_DISPLAY_DATE_FIELD'
export const QUESTION_DISPLAY_NUMEBR_FIELD = 'QUESTION_DISPLAY_NUMEBR_FIELD'
export type QuestionDisplayPerChar = MultiInputDisplayType

export type QuestionDisplayType =
  | typeof QUESTION_DISPLAY_RADIO // THIS OPTION WILL RENDER RADIO QUESTION COMPONENT
  | typeof QUESTION_DISPLAY_TRUE_OR_FALSE // THIS OPTION WILL RENDER RADIO QUESTION COMPONENT (TRUE OR FALSE has its own css styles)
  | typeof QUESTION_DISPLAY_DROPDOWN // THIS OPTION WILL RENDER DROPDOWN SELECT QUESTION COMPONENT
  | typeof QUESTION_DISPLAY_TEXT_FIELD // THIS OPTION WILL RENDER TEXT FIELD
  | typeof QUESTION_DISPLAY_DATE_FIELD // THIS OPTION WILL RENDER DATE FIELD
  | typeof QUESTION_DISPLAY_NUMEBR_FIELD // THIS OPTION WILL RENDER TEXT FIELD
  | typeof QUESTION_DISPLAY_CHECKBOX // THIS OPTION WILL RENDER CHECKBOX
  | QuestionDisplayPerChar // THIS OPTION WILL RENDER MULTIPLE TEXTBOX DISPLAY FIELD

export const RADIO_TEXT_INPUT_COMBO = 'RADIO_TEXT_INPUT_COMBO' // THIS OPTION will render a combo of radio buttons and textfield
export type RadioTextInputCombo = {
  display: typeof RADIO_TEXT_INPUT_COMBO
  displayTextFieldOnAnswer: string | string[] // determine which radio button will be followed by a text box
}

export const QUESTION_VALIDATION_DATE = 'date'
export type QuestionValidationTypes = typeof QUESTION_VALIDATION_DATE

export type GeneralSuitabilityInfo = {
  isCorporateAccount: boolean // whether this is a corporate account -> this will determine the questionnaire homepage content
  generalSuitabilityStatus: QuestionnaireStatus // keep track of whether general suitability questionniare is finished -> this will determine the questionnaire homepage content
  enhancedSuitabilityStatus: QuestionnaireStatus // keep track of enhanced suitability questionniare status (passed/incompleted/failed) -> this will determine the questionnaire homepage content, reminder message and result feedback modal
  showAgeOver65Message: boolean // keep track of user age -> this will determine the questionnaire homepage content
  riskLevel: RiskLevel // keep track of user risk level -> this will determine the questionnaire homepage content /  result modal
  lastAssessDate: string | null | number // keep track of last assess date -> this will determine the questionnaire homepage content
  showSpecialCriteriaPopup: {
    show: boolean
    question?: GeneralSuitabilityQuestion
  } // keep track of whether special criteria popup modal should be shown (and what question to be included in the popup modal)
  showResultFeedback: boolean // keep track of whether user has clicked submit the questionnaire and show feedback modal
  shouldShowQuestion4C: boolean
}

export type DependentQuestion = GeneralSuitabilityQuestion & {
  isDependent: true
}

export type QuestionAnswerType = string[] | string | number | undefined
export type QuestionExtraDisplayOptions = typeof RADIO_TEXT_INPUT_COMBO
export type DependentOptionCondition = {
  role?: FillingRoleType
  questionNumber: number
  option: string[]
}
export type TriggerType = Partial<QuestionAnswerObject> & {
  questionNumber?: number
  answerFrom?: GeneralSuitabilityQuestion['questionNumber']
}
export type UpdateType = Partial<QuestionAnswerObject> & {
  questionNumber: number
}
export type OptionMatchConditionType = DependentOptionCondition
export type ValueCompareOperator = 'gt' | 'gte' | 'lt' | 'lte' | 'eq' // refers to greater than | greater than or equal to | less than | less than or equal to | eq

export type ValueCompareConditionType = {
  compare: ValueCompareOperator
  value1: {
    role?: FillingRoleType
    questionNumber: number
  }
  value2: {
    role?: FillingRoleType
    questionNumber: number
  }
}

// Based on current questionnaire and substep rendering conditions, there are two types of conditions
export type RenderConditionType =
  | OptionMatchConditionType //  Question answer match a condition
  | ValueCompareConditionType //  Compare values of two answers

export type DependentOption = {
  conditions: RenderConditionType[] // an array of conditions , to trigger the rendering, ALL conditions have to be matched
  trigger: TriggerType // what updates to be applied to the question (i.e mandatory:true or autofill answer)
}

export type GeneralSuitabilityQuestion = {
  ageFilter?: {
    role: FillingRoleType
    value: number
    compare: ValueCompareOperator
  } // age fitler should be added to the question when the question mandatory status is age related (i.e question 15, 16)
  dependOptions?: DependentOption[] // dependOption should be added when the question mandatory status / autofill status are affected by the answers from other questions
  affectedQuestions?: number[] // an array of question number that will be affected by the answer of this question
  placeholder?: string // placeholder of in the text field or dropdown header text
  questionId: string
  questionNumber: number
  display: QuestionDisplayType // control how the option will be displayed (i.e Dropdown / radio / checkbox)
  questionType: QuestionType // control how the option will be displayed
  question: string // question string
  options: string[]
  instructionPopup?: FormInstructionModalLabel // whether this question has an instruction popup
  dependentQuestions: DependentQuestion[] // list of dependent questions
  inputFieldProps?: {
    [property: string]: string
  }
  extraDisplayOptions?: RadioTextInputCombo // this is for combo text input display options
  validation?: QuestionValidationTypes
}
export const QUESTION_CATEGORY_BASIC_INFO = 'QUESTION_CATEGORY_BASIC_INFO'
export const QUESTION_CATEGORY_FINANICAL_INFO =
  'QUESTION_CATEGORY_FINANICAL_INFO'
export const QUESTION_CATEGORY_HEALTH_INFO = 'QUESTION_CATEGORY_HEALTH_INFO'
export const QUESTION_CATEGORY_TRADING = 'QUESTION_CATEGORY_TRADING'
export const QUESTION_CATEGORY_RISK_ASSESSMENT =
  'QUESTION_CATEGORY_RISK_ASSESSMENT'
export const QUESTION_CATEGORY_EXTRA = 'QUESTION_CATEGORY_EXTRA'

// questions are grouped under below categories
export type GeneralSuitabilityQuestionCategory =
  | typeof QUESTION_CATEGORY_BASIC_INFO // 基本資料
  | typeof QUESTION_CATEGORY_FINANICAL_INFO // 個人財務狀況
  | typeof QUESTION_CATEGORY_HEALTH_INFO // 個人健康狀況
  | typeof QUESTION_CATEGORY_RISK_ASSESSMENT // 風險屬性評估問卷
  | typeof QUESTION_CATEGORY_TRADING // 貿易往來資訊確認書
  | typeof QUESTION_CATEGORY_EXTRA // 追問

export type GeneralSuitabilityQuestionGroup = {
  category: GeneralSuitabilityQuestionCategory
  questions: GeneralSuitabilityQuestion[]
}

// Defined the data response for next step after questionnaire is submitted and result status
export type QuestionnaireResult = {
  generalSuitabilityStatus: QuestionnaireStatus
  enhancedSuitabilityStatus: QuestionnaireStatus
  riskLevel: RiskLevel
  shouldShowSpecialCriteriaPopup: {
    show: boolean // determine whether 重要提示/special criteria popup modal should be shown
    question?: GeneralSuitabilityQuestion // question to be displayed inside the popup modal
  }
  shouldShowFeedbackModal: boolean // determine whether feedback modal should be shown
}

export type QuestionAnswer = undefined | string[] | string | number

export type QuestionAnswerObject = {
  mandatory: boolean
  answer: QuestionAnswer
  autofilled?: boolean
}

export type QuestionAnswers = {
  [questionNumber: number]: QuestionAnswerObject
}

export type SubStepRenderConditionType = {
  conditions: RenderConditionType[]
}

export type QuestionnaireSubStepType = {
  currentFillingRole: FillingRoleType
  mandatory: boolean | SubStepRenderConditionType // whether the substep is mandatory or render on certain conditions
  title: string // sub step title
  questionGroups: GeneralSuitabilityQuestionGroup[] // list of question groups to be displayed inside this step
}

export type QuestionnaireStepType = QuestionnaireSubStepType[]
