import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { AlertIcon } from '../../../components/icons'
import ReminderMessageCard from '../../../components/reminder-message-card'
import { REMINDER_MESSAGE_CARD_THEME_TEAL } from '../../../components/reminder-message-card/ReminderMessageCard'
import {
  QuestionnaireStatus,
  STATUS_COMPLETED,
} from '../../../typings/GeneralSuitability'

/*
風險屬性評估首頁 - Header Content
Content depends on 
1. GeneralSuitability Questionnaire Status - Completed VS Incomplete

2. whether current account is corporated account

3. whether the user is over 65 yo
*/

export interface HeaderSectionProps {
  generalSuitabilityStatus: QuestionnaireStatus
  isCorporateAccount: boolean
  showAgeOver65Message: boolean
}

const HeaderSection: FunctionComponent<HeaderSectionProps> = ({
  generalSuitabilityStatus,
  isCorporateAccount,
  showAgeOver65Message,
}) => {
  const { t } = useTranslation()

  if (generalSuitabilityStatus === STATUS_COMPLETED) {
    return <p>{t('generalSuitability:completed.description')}</p>
  }

  return isCorporateAccount ? (
    <p>{t('generalSuitability:corporate-account-description')}</p>
  ) : (
    <>
      <ReminderMessageCard
        cardClassName='mb-4'
        header={
          <>
            <AlertIcon
              width={20}
              height={20}
              className='mr-2'
              color='#096183'
            />
            <span>
              {t('generalSuitability:incompleted.reminder-message-card.title')}
            </span>
          </>
        }
        body={
          t(
            'generalSuitability:incompleted.reminder-message-card.body'
          ) as string
        }
        theme={REMINDER_MESSAGE_CARD_THEME_TEAL}
      />
      {showAgeOver65Message && (
        <span> {t('generalSuitability:incompleted.age65Message')}</span>
      )}
    </>
  )
}

export default HeaderSection
