const buildQueryString = (
  data: Record<string, string | number | boolean>
): string => {
  return `?${Object.entries(data)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join('&')}`
}

export default buildQueryString
