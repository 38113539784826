import { HoldingFundType } from '../typings/Portfolio'
import {
  HoldingFundPreviewType,
  PortfolioPieData,
  PortfolioPieResponse,
} from '../typings/PortfolioPie'
import { UserCountryType } from '../typings/UserInfo'

const transformHoldingPreviewDataHK = (
  resposneData: PortfolioPieResponse,
  fundList: HoldingFundType[]
): HoldingFundPreviewType[] => {
  const holdingFundPreview: HoldingFundPreviewType[] =
    resposneData.portfolioPieResponse.map((data: PortfolioPieData) => {
      const holdingFundPreviewObject: HoldingFundPreviewType = {
        fundCode: data.nodeData.accountNo,
        fundName: fundList.filter(
          (fund: HoldingFundType) => fund.fundCode === data.nodeData.accountNo
        )[0].name,
        distribution: data.nodeData.label,
        value: data.nodeData.value,
        valueInDouble: data.nodeData.valueInDouble,
        returnPercent: data.nodeData.returnPercent,
        profit: data.nodeData.profit,
      }
      return holdingFundPreviewObject
    })
  return holdingFundPreview
}

const transformHoldingPreviewDataTW = (
  resposneData: PortfolioPieResponse,
  fundList: HoldingFundType[]
): HoldingFundPreviewType[] => {
  const holdingFundPreview: HoldingFundPreviewType[] =
    resposneData.portfolioPieResponse.map((data: PortfolioPieData) => {
      const holdingFundPreviewObject: HoldingFundPreviewType = {
        fundCode: data.nodeData.accountNo,
        fundName: fundList.filter(
          (fund: HoldingFundType) => fund.fundCode === data.nodeData.accountNo
        )[0].name,
        distribution: data.nodeData.label,
        value: data.nodeData.value,
        valueInDouble: data.nodeData.valueInDouble,
        returnPercent: data.nodeData.returnPercent,
        profit: data.nodeData.profit,
      }
      return holdingFundPreviewObject
    })
  return holdingFundPreview
}

const transformHoldingPreviewData = (
  responseData: PortfolioPieResponse,
  country: UserCountryType,
  fundList: HoldingFundType[]
): HoldingFundPreviewType[] => {
  if (country === 'HK') {
    return transformHoldingPreviewDataHK(responseData, fundList)
  }
  return transformHoldingPreviewDataTW(responseData, fundList)
}
export default transformHoldingPreviewData
