import classnames from 'classnames'
import { AnchorHTMLAttributes, FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

import routes from '../../../routes/SiteNavigation'
import { MessageIcon } from '../../icons'
import styles from './InboxButton.module.scss'

interface InboxButtonProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  className?: string
  iconColor?: string
  showBadge: boolean
}

const InboxButton: FunctionComponent<InboxButtonProps> = ({
  className,
  iconColor,
  showBadge,
  ...anchorProps
}: InboxButtonProps) => {
  return (
    <Link
      to={routes.inbox}
      className={classnames(className, styles.inboxButton)}
      {...anchorProps}
    >
      {showBadge && (
        <span
          className={styles.inboxButton__messageAlert}
          data-testid='inbox-message-alert'
        />
      )}
      <MessageIcon color={iconColor} />
    </Link>
  )
}

InboxButton.defaultProps = {
  className: '',
  iconColor: '#41464A',
}

export default InboxButton
