import {
  FILLING_ROLE_AGENT_ONE,
  FILLING_ROLE_AGENT_TWO,
  FILLING_ROLE_SELF,
  GeneralSuitabilityQuestionCategory,
  QUESTION_CATEGORY_BASIC_INFO,
  QUESTION_CATEGORY_EXTRA,
  QUESTION_CATEGORY_FINANICAL_INFO,
  QUESTION_CATEGORY_HEALTH_INFO,
  QUESTION_CATEGORY_RISK_ASSESSMENT,
  QUESTION_CATEGORY_TRADING,
  QuestionAnswers,
} from '../../../typings/GeneralSuitability'

// eslint-disable-next-line import/prefer-default-export
export const sampleAllQuestionGroups: GeneralSuitabilityQuestionCategory[] = [
  QUESTION_CATEGORY_BASIC_INFO,
  QUESTION_CATEGORY_FINANICAL_INFO,
  QUESTION_CATEGORY_HEALTH_INFO,
  QUESTION_CATEGORY_TRADING,
  QUESTION_CATEGORY_RISK_ASSESSMENT,
  QUESTION_CATEGORY_EXTRA,
]

export const sampleAgentQuestionGroups: GeneralSuitabilityQuestionCategory[] = [
  QUESTION_CATEGORY_BASIC_INFO,
  QUESTION_CATEGORY_FINANICAL_INFO,
  QUESTION_CATEGORY_HEALTH_INFO,
  QUESTION_CATEGORY_TRADING,
]

export const sampleQuestionAnswersSELF: QuestionAnswers = {
  1: {
    mandatory: true,
    answer: '碩士/博士',
  },
  2: {
    mandatory: true,
    answer: '學生',
  },
  3: {
    mandatory: true,
    answer: '無',
  },
  4: {
    mandatory: true,
    answer: '無職業 / 退休 / 家管 / 學生 / 待業 / 無業',
  },
  5: {
    mandatory: true,
    answer: '與政治人物無關',
  },
  6: {
    mandatory: true,
    answer: '一年以上',
  },
  7: {
    mandatory: true,
    answer: '未滿70萬',
  },
  8: {
    mandatory: true,
    answer: '250萬以上(含) - 未滿500萬',
  },
  9: {
    mandatory: true,
    answer: '未滿50萬',
  },
  10: {
    mandatory: true,
    answer: '薪資',
  },
  11: {
    mandatory: true,
    answer: ['書報 / 雜誌', '網際網路', '社群媒體'],
  },
  12: {
    mandatory: true,
    answer: ['購屋'],
  },
  13: {
    mandatory: true,
    answer: '是',
  },
  14: {
    mandatory: true,
    answer: '否',
  },
  15: {
    mandatory: true,
    answer: '否',
  },
  16: {
    mandatory: true,
    answer: '與親友同住',
  },
  96: {
    mandatory: true,
    answer: '否',
  },
  97: {
    mandatory: true,
    answer: '否',
  },
  98: {
    mandatory: true,
    answer: '否',
  },
  99: {
    mandatory: true,
    answer: '否',
  },
  17: {
    mandatory: true,
    answer: '60 - 64歲',
  },
  18: {
    mandatory: true,
    answer: '未滿1年',
  },
  19: {
    mandatory: true,
    answer: '本金完全不能損失，不可以承擔任何風險',
  },
  20: {
    mandatory: true,
    answer: '非常高度的影響',
  },
  21: {
    mandatory: true,
    answer: '未滿1年',
  },
  22: {
    mandatory: true,
    answer: '定存利率',
  },
  23: {
    mandatory: true,
    answer: '5%以下',
  },
  24: {
    mandatory: true,
    answer: '純粹為資產保值',
  },
  25: {
    mandatory: true,
    answer: ['儲蓄保險/台幣存款', '外幣存款'],
  },
  26: {
    mandatory: true,
    answer: '貨幣型',
  },
  43: {
    mandatory: true,
    answer: '遺產/餽贈',
  },
}

export const sampleQuestionAnswersAgentOne: QuestionAnswers = {
  1: {
    mandatory: true,
    answer: '碩士/博士',
  },
  2: {
    mandatory: true,
    answer: '銀行、金融及保險',
  },
  3: {
    mandatory: true,
    answer: '無',
  },
  4: {
    mandatory: true,
    answer: '高階主管',
  },
  5: {
    mandatory: true,
    answer: '與政治人物無關',
  },
  6: {
    mandatory: true,
    answer: '一年以上',
  },
  7: {
    mandatory: true,
    answer: '210萬以上(含) - 未滿300萬',
  },
  8: {
    mandatory: true,
    answer: '250萬以上(含) - 未滿500萬',
  },
  9: {
    mandatory: true,
    answer: '未滿50萬',
  },
  10: {
    mandatory: true,
    answer: '薪資',
  },
  11: {
    mandatory: true,
    answer: ['書報 / 雜誌', '網際網路', '專業理財人員'],
  },
  12: {
    mandatory: true,
    answer: ['購屋'],
  },
  14: {
    mandatory: true,
    answer: '否',
  },
  15: {
    mandatory: true,
    answer: '否',
  },
  16: {
    mandatory: true,
    answer: '與親友同住',
  },
  96: {
    mandatory: true,
    answer: '否',
  },
  97: {
    mandatory: true,
    answer: '否',
  },
  98: {
    mandatory: true,
    answer: '否',
  },
  99: {
    mandatory: true,
    answer: '否',
  },
}

export const sampleQuestionAnswersAgentTwo: QuestionAnswers = {
  1: {
    mandatory: true,
    answer: '碩士/博士',
  },
  2: {
    mandatory: true,
    answer: '銀行、金融及保險',
  },
  3: {
    mandatory: true,
    answer: '無',
  },
  4: {
    mandatory: true,
    answer: '高階主管',
  },
  5: {
    mandatory: true,
    answer: '與政治人物無關',
  },
  6: {
    mandatory: true,
    answer: '一年以上',
  },
  7: {
    mandatory: true,
    answer: '210萬以上(含) - 未滿300萬',
  },
  8: {
    mandatory: true,
    answer: '250萬以上(含) - 未滿500萬',
  },
  9: {
    mandatory: true,
    answer: '未滿50萬',
  },
  10: {
    mandatory: true,
    answer: '薪資',
  },
  11: {
    mandatory: true,
    answer: ['書報 / 雜誌', '網際網路', '專業理財人員'],
  },
  12: {
    mandatory: true,
    answer: ['購屋'],
  },
  14: {
    mandatory: true,
    answer: '否',
  },
  15: {
    mandatory: true,
    answer: '否',
  },
  16: {
    mandatory: true,
    answer: '與親友同住',
  },
  96: {
    mandatory: true,
    answer: '否',
  },
  97: {
    mandatory: true,
    answer: '否',
  },
  98: {
    mandatory: true,
    answer: '否',
  },
  99: {
    mandatory: true,
    answer: '否',
  },
}

export const sampleAnswerData = {
  [FILLING_ROLE_SELF]: sampleQuestionAnswersSELF,
  [FILLING_ROLE_AGENT_ONE]: sampleQuestionAnswersAgentOne,
  [FILLING_ROLE_AGENT_TWO]: sampleQuestionAnswersAgentTwo,
}
