import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { PromotionType } from '../../typings/Promotion'
import loadNewClientPromotions from './actions/loadNewClientPromotions'
import loadPromotions from './actions/loadPromotions'

type PromotionsState = {
  promotions: PromotionType[]
  heroPromotion: PromotionType[] | undefined
  newClientPromotion: PromotionType[] | undefined
}

const initialState: PromotionsState = {
  promotions: [],
  heroPromotion: undefined,
  newClientPromotion: undefined,
}

export const promotionsSlice = createSlice({
  name: 'Promotions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      loadNewClientPromotions.fulfilled,
      (state, action: PayloadAction<PromotionType[] | undefined>) => {
        if (action.payload) {
          state.newClientPromotion = action.payload
        }
      }
    )
    builder.addCase(
      loadPromotions.fulfilled,
      (
        state,
        action: PayloadAction<{
          promotions: PromotionType[]
          heroPromotion: PromotionType[]
        }>
      ) => {
        state.promotions = action.payload.promotions
        state.heroPromotion = action.payload.heroPromotion
      }
    )
  },
})

export default promotionsSlice.reducer
