import './Header.scss'

import _debounce from 'lodash/debounce'
import PropTypes from 'prop-types'
import { FunctionComponent, useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'

import { useAppSelector } from '../../hooks'
import { RootState } from '../../store'

const Header: FunctionComponent = ({ children }) => {
  const { completedList, show } = useAppSelector(
    (state: RootState) => state.warningMessage
  )

  const [warningMessageHeight, setWarningMessageHeight] = useState<number>(0)

  useEffect(() => {
    if (completedList.length > 0 && show) {
      const warningMessageContainerHeight =
        document.querySelector('#warning-message-container')?.clientHeight || 0
      setWarningMessageHeight(warningMessageContainerHeight)
    }
  }, [completedList, show])

  useEffect(() => {
    const updateTopPosition = (): void => {
      if (completedList.length === 0 || !show) return
      const warningMessageContainerHeight =
        document.querySelector('#warning-message-container')?.clientHeight || 0
      setWarningMessageHeight(warningMessageContainerHeight)
    }

    const updateTopPositionDebounced = _debounce(updateTopPosition, 150)

    if (show) {
      updateTopPosition()
    } else {
      setWarningMessageHeight(0)
    }

    window.addEventListener('resize', updateTopPositionDebounced)
    return () => {
      window.removeEventListener('resize', updateTopPositionDebounced)
    }
  }, [completedList, show])

  useEffect(() => {
    if (completedList.length === 0 || !show) {
      setWarningMessageHeight(0)
    }
  }, [completedList, show])

  return (
    <Container
      data-testid='header'
      as='header'
      fluid
      className='header'
      id='header'
      style={{ top: `${warningMessageHeight}px` }}
    >
      {children}
    </Container>
  )
}

Header.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default Header
