import './NavBanner.scss'

import React, { FunctionComponent, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { BUTTON_SIZE_MEDIUM } from '../../../constants/button'
import getCountriesSpecificData from '../../../helpers/getCountriesSpecificData'
import isAuthenticated from '../../../helpers/isAuthenticated'
import updateContainerHeight from '../../../helpers/updateContainerHeight'
import { prodLangs } from '../../../i18n'
import profileRoutes from '../../../routes/navigation-legacy-profile/index'
import Button from '../../button'
import DropdownLegacy from '../../dropdown-legacy'
import InboxButton from '../inbox-button'
import LanguageBlock from '../language-block'

interface NavBannerProps {
  showInboxAlert: boolean
}

const NavBanner: FunctionComponent<NavBannerProps> = ({ showInboxAlert }) => {
  const profile = getCountriesSpecificData(profileRoutes)
  const [renderNavBanner, setRenderNavBanner] = useState<boolean>(false)
  const { t, i18n } = useTranslation()
  const { isAuth } = isAuthenticated()
  const displayLangs = getCountriesSpecificData(prodLangs)
  const { language: currentLanguage } = i18n
  const hasDisplayLangs =
    displayLangs.filter((c) => c !== currentLanguage).length >= 1

  useEffect(() => {
    if (!hasDisplayLangs && !isAuth) return
    setRenderNavBanner(true)
  }, [hasDisplayLangs, isAuth])

  useEffect(() => {
    if (renderNavBanner) {
      updateContainerHeight()
    }
  }, [renderNavBanner])

  // If there is no alternative language and not authenticated, show nothing
  if (!renderNavBanner) {
    return null
  }

  return (
    <div className='nav-banner-wrapper d-none d-xl-block'>
      <Row
        as='nav'
        className='align-items-center nav-banner navbar-expand-xl'
        noGutters
      >
        <Col
          className='nav__main align-items-center'
          data-testid='nav-main'
          id='nav-main'
        >
          <div className='d-none d-xl-flex nav-banner__language'>
            <LanguageBlock variant='xl' />
          </div>
          {isAuth && (
            <div
              className='nav__left justify-content-end align-items-center'
              id='nav-left'
              data-testid='nav-left'
            >
              {false && (
                <InboxButton
                  className='nav-banner__message-icon'
                  iconColor='#fff'
                  showBadge={showInboxAlert}
                />
              )}

              {profile.map((main) => {
                return (
                  <React.Fragment key={main.translationKeyName}>
                    <DropdownLegacy
                      list={main}
                      withIcon
                      activeIndex={0}
                      translationIndexName='nav'
                      className='nav-dropdown-menu'
                    />
                  </React.Fragment>
                )
              })}

              <Button size={BUTTON_SIZE_MEDIUM} className='nav-banner__button'>
                {t(`common:logout`)}
              </Button>
            </div>
          )}
        </Col>
      </Row>
    </div>
  )
}

export default NavBanner
