import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootStateOrAny } from 'react-redux'

import { appAxios, getRoute } from '../../../helpers'
import {
  PortfolioRiskRating,
  UserCountryType,
  UserRiskRatingObject,
  UserRiskRatingType,
} from '../../../typings/UserInfo'

/**
 * User Risk Rating API response data structure from HK
 */
interface UserRiskRatingReponseDataHkType {
  riskRating: UserRiskRatingType
  lastUpdateDate: string | null
}

/**
 * User API Epoints response data structure from TW
 */
interface UserRiskRatingReponseDataTwType {
  riskRating: UserRiskRatingType | null
  lastUpdateDate: string | null
  kycStatus: 'P' | 'O' | 'S' | 'R'
}

type UserEPointsReponseDataType =
  | UserRiskRatingReponseDataHkType
  | UserRiskRatingReponseDataTwType

type PortfolioRiskRatingAPIResponseType = {
  data: {
    riskScore: number
    riskRating: PortfolioRiskRating
  }
}

const transformUserDataTW = (
  responseData: UserRiskRatingReponseDataTwType
): UserRiskRatingObject => {
  const RiskRatingObject: UserRiskRatingObject = {
    clientRiskRating: responseData.riskRating || 'a',
    portfolioRiskRating: undefined,
    lastAssessDate: responseData.lastUpdateDate,
    alertStatus: responseData.kycStatus,
    isRiskRatingIncomplete: responseData.riskRating === null,
  }
  return RiskRatingObject
}

const transformUserDataHK = (
  responseData: UserRiskRatingReponseDataHkType,
  portfolioRiskRatingData: PortfolioRiskRatingAPIResponseType
): UserRiskRatingObject => {
  const RiskRatingObject: UserRiskRatingObject = {
    clientRiskRating: responseData.riskRating,
    portfolioRiskRating: portfolioRiskRatingData.data.riskRating,
    lastAssessDate: responseData.lastUpdateDate,
  }

  return RiskRatingObject
}

const transformUserData = (
  responseData: UserEPointsReponseDataType,
  country: UserCountryType,
  portfolioRiskRatingResponseData?: PortfolioRiskRatingAPIResponseType
): UserRiskRatingObject => {
  if (country === 'HK')
    return transformUserDataHK(
      responseData as UserRiskRatingReponseDataHkType,
      portfolioRiskRatingResponseData as PortfolioRiskRatingAPIResponseType
    )

  return transformUserDataTW(responseData as UserRiskRatingReponseDataTwType)
}

/**
 * fetchUser
 * =========
 *
 * To DEMO a case when we fetch user data from the internet, but the
 * data structure is different between the user API endpoint and
 * our predefined fake user data.
 *
 * The quickest way to integrate the user API data with the app is to
 * transform it into the same data structure with our predefined fake
 * user data structure. In this way, we don't have to touch the React
 * app.
 */
const fetchUserRiskRating = createAsyncThunk<UserRiskRatingObject>(
  'fetchUserRiskRating',
  async (_, { getState }) => {
    const state: RootStateOrAny = getState()
    const { country } = state.system

    const userRiskRatingResponse = await appAxios().get(
      getRoute({ route: 'userRiskRating' })
    )

    let portfolioRiskRatingResponse

    if (country === 'HK') {
      portfolioRiskRatingResponse = await appAxios().get(
        getRoute({ route: 'portfolioRiskRating' })
      )
    }

    const userRiskRatingResponseData = userRiskRatingResponse.data

    return transformUserData(
      userRiskRatingResponseData,
      country,
      portfolioRiskRatingResponse
    )
  }
)

export default fetchUserRiskRating
