import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootStateOrAny } from 'react-redux'

import { appAxios, getRoute } from '../../../helpers'
import checkTWPaymentType from '../../../helpers/checkTWPaymentType'
import sorted from '../../../helpers/sorted'
import transformHoldingPreviewData from '../../../helpers/transformHoldingPreviewData'
import {
  transformPortfolioHoldingFundHK,
  transformPortfolioHoldingFundTW,
} from '../../../helpers/transformPortfolioHoldingFund'
import { FundDetailsResponseData } from '../../../typings/FundDetails'
import { FundPriceData } from '../../../typings/FundPreview'
import {
  AssetClassData,
  FundResponseHKType,
  HoldingFundType,
  HoldingFundTypeWithSetting,
  PortfolioRequestParams,
  PortfolioState,
  PortfolioSummaryBreakdown,
} from '../../../typings/Portfolio'
import { HoldingFundPreviewType } from '../../../typings/PortfolioPie'
import updateInitialSortedFunds from '../../fund-filter/actions/updateInitialSortedFunds'
import { updateOrderHistoryList } from '../../order-history/actions'
import { samplePortfolioBreakdownData } from './__data'
import {
  PORTFOLIO_REQUEST_DEFAULT_HK,
  PORTFOLIO_REQUEST_DEFAULT_TW,
} from './constants'
import updateFundCategoryList from './updateFundCategoryList'
import updateFundMarketList from './updateFundMarketList'

type PortfolioHoldingResponseType = {
  totalCost: string
  totalValue: string
  returnPercent: string
  profit: string
  value: number
  holdings: FundResponseHKType[]
  summary: PortfolioSummaryBreakdown | null
}

const transformHKFundData = (
  responseData: PortfolioHoldingResponseType,
  fundCategoriesList: AssetClassData[],
  fundDetailsList: FundDetailsResponseData[],
  holdingPriceDataList: FundPriceData[]
): PortfolioState => {
  const allFunds: HoldingFundTypeWithSetting[] = []
  responseData.holdings.forEach((data: FundResponseHKType) => {
    const currentFundDetails: FundDetailsResponseData = fundDetailsList.filter(
      (fund: FundDetailsResponseData) => fund.fundInfo.code === data.fundCode
    )[0]
    const currentFundPriceData: FundPriceData = holdingPriceDataList.filter(
      (priceData: FundPriceData) => priceData.fundCode === data.fundCode
    )[0]
    const fund: HoldingFundType = transformPortfolioHoldingFundHK(
      data,
      currentFundDetails,
      fundCategoriesList,
      currentFundPriceData
    )
    allFunds.push({ ...fund, viewInBaseCurrency: false })
  })
  const portfolioObject: PortfolioState = {
    fundList: allFunds,
    portfolioTotalCost: responseData.totalCost,
    portfolioTotalValue: {
      string: responseData.totalValue,
      double: responseData.value,
    },
    portfolioReturnPercent: responseData.returnPercent,
    portfolioProfit: responseData.profit,
    portfolioSummaryBreakdown: null,
  }

  return portfolioObject
}

const transformTWFundData = (
  responseData: PortfolioHoldingResponseType,
  fundCategoriesList: AssetClassData[],
  fundDetailsList: FundDetailsResponseData[],
  fundMarketTypeList: AssetClassData[],
  holdingPriceDataList: FundPriceData[]
): PortfolioState => {
  const allFunds: HoldingFundTypeWithSetting[] = []
  responseData.holdings.forEach((data: FundResponseHKType) => {
    const currentFundDetails: FundDetailsResponseData = fundDetailsList.filter(
      (fund: FundDetailsResponseData) => fund.fundInfo.code === data.fundCode
    )[0]
    const currentFundPriceData: FundPriceData = holdingPriceDataList.filter(
      (priceData: FundPriceData) => priceData.fundCode === data.fundCode
    )[0]

    const fund: HoldingFundType = transformPortfolioHoldingFundTW(
      data,
      currentFundDetails,
      fundCategoriesList,
      fundMarketTypeList,
      currentFundPriceData
    )
    allFunds.push({ ...fund, viewInBaseCurrency: false })
  })

  checkTWPaymentType(allFunds)
  const portfolioObject: PortfolioState = {
    fundList: allFunds,
    portfolioTotalCost: responseData.totalCost,
    portfolioTotalValue: {
      string: responseData.totalValue,
      double: responseData.value,
    },
    portfolioReturnPercent: responseData.returnPercent,
    portfolioProfit: responseData.profit,
    portfolioSummaryBreakdown: samplePortfolioBreakdownData,
  }

  return portfolioObject
}

const loadMyPortfolioInitial = createAsyncThunk<{
  data: PortfolioState
  fundPreviewList: HoldingFundPreviewType[]
}>('loadMyPortfolioInitial', async (_, { dispatch, getState }) => {
  const store: RootStateOrAny = getState()
  const { country } = store.system
  const { list: stateOrderHistoryList } = store.orderHistory

  // GET DEFAULT PORTFOLIO REQUEST PARAMS
  let portfolioRequest: PortfolioRequestParams =
    country === 'HK'
      ? PORTFOLIO_REQUEST_DEFAULT_HK
      : PORTFOLIO_REQUEST_DEFAULT_TW

  // SET PORTFOLIO REQUEST CURRENCY
  let userSetting = store.user.setting
  if (country === 'HK') {
    if (!userSetting) {
      const settingRes = await appAxios().get(
        `${getRoute({ route: `setting` })}`
      )
      userSetting = settingRes.data
    }

    portfolioRequest = {
      ...portfolioRequest,
      currency: userSetting.showCcy,
    }
  }

  const fundDetailsRes = await appAxios().get(`${getRoute({ route: `fund` })}`)

  const fundCategoriesRes = await appAxios().get(
    `${getRoute({ route: `fundCategory` })}`
  )

  let fundMarketTypeList: AssetClassData[] = []

  if (country === 'TW') {
    const fundMarketTypeRes = await appAxios().get(
      `${getRoute({ route: `fundMarket` })}`
    )
    fundMarketTypeList = fundMarketTypeRes.data.list.map(
      (data: { fundCategory: AssetClassData }) => data.fundCategory
    )
    dispatch(updateFundMarketList(fundMarketTypeList))
    if (stateOrderHistoryList.length === 0) {
      const orderHistoryRes = await appAxios().get(
        `${getRoute({ route: `orderHistory` })}`
      )
      dispatch(updateOrderHistoryList(orderHistoryRes.data.list))
    }
  }

  const fundRes = await appAxios().post(`${getRoute({ route: `portfolio` })}`, {
    ...portfolioRequest,
    isPieChart: false,
  })

  const holdingList = fundRes.data.holdings.map(
    (holding: FundResponseHKType) => holding.fundCode
  )
  const holdingPriceDataList: FundPriceData[] = await Promise.all(
    holdingList.map(async (fundCode: string) => {
      const fundPriceRes = await appAxios().get(
        `${getRoute({ route: `fundPrice` })}`,
        { params: { code: fundCode } }
      )
      return {
        fundCode,
        bid: fundPriceRes.data.list[0].bid,
        date: fundPriceRes.data.list[0].date,
        baseCurrency: fundPriceRes.data.list[0].baseCurrency,
      }
    })
  )

  const holdingResponseData: PortfolioHoldingResponseType = fundRes.data

  const fundCategoriesList: AssetClassData[] =
    country === 'HK'
      ? fundCategoriesRes.data.map(
          (data: { fundCategory: AssetClassData }) => data.fundCategory
        )
      : fundCategoriesRes.data.fundMarketTypes.map(
          (data: AssetClassData) => data
        )

  dispatch(updateFundCategoryList(fundCategoriesList))

  const portfolio =
    country === 'HK'
      ? transformHKFundData(
          holdingResponseData,
          fundCategoriesList,
          fundDetailsRes.data.list,
          holdingPriceDataList
        )
      : transformTWFundData(
          holdingResponseData,
          fundCategoriesList,
          fundDetailsRes.data.list,
          fundMarketTypeList,
          holdingPriceDataList
        )

  const portfolioPieResponse = await appAxios().post(
    `${getRoute({ route: `portfolioPie` })}`,
    { ...portfolioRequest, isPieChart: true }
  )

  const transformedData = transformHoldingPreviewData(
    portfolioPieResponse.data,
    country,
    portfolio.fundList
  )

  const sortedList = sorted({ data: transformedData })
  dispatch(updateInitialSortedFunds(sortedList))

  return {
    data: portfolio,
    fundPreviewList: transformedData,
  }
})

export default loadMyPortfolioInitial
