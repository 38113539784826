import { store } from '../store'

const isAuthenticated = (): { isAuth: boolean } => {
  const state = store.getState()
  return {
    isAuth: state.user.isAuth,
  }
}

export default isAuthenticated
