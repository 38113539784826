import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootStateOrAny } from 'react-redux'

import { appAxios, getRoute } from '../../../helpers'
import {
  FundResponseHKType,
  HoldingFundTypeWithSetting,
  PortfolioState,
  PortfolioSummaryBreakdown,
} from '../../../typings/Portfolio'

type PortfolioHoldingResponseType = {
  totalCost: string
  totalValue: string
  returnPercent: string
  profit: string
  value: number
  holdings: FundResponseHKType[]
  summary: PortfolioSummaryBreakdown | null
}

const updateHKPortfolioData = (
  responseData: PortfolioHoldingResponseType,
  currentHoldingList: HoldingFundTypeWithSetting[]
): PortfolioState => {
  const updatedFunds: HoldingFundTypeWithSetting[] = currentHoldingList.map(
    (data: HoldingFundTypeWithSetting) => {
      const updatedResponse = responseData.holdings.filter(
        (res: FundResponseHKType) => res.fundCode === data.fundCode
      )[0]
      const updatedFundObj: HoldingFundTypeWithSetting = {
        ...data,
        totalCost: updatedResponse.cost,
        value: updatedResponse.value,
        valueInDouble: updatedResponse.valueInDouble,
        profit: updatedResponse.profit,
        grossDividend: updatedResponse.grossDividend,
        bidPrice: updatedResponse.bidPrice,
      }
      return updatedFundObj
    }
  )
  const portfolioObject: PortfolioState = {
    fundList: updatedFunds,
    portfolioTotalCost: responseData.totalCost,
    portfolioTotalValue: {
      string: responseData.totalValue,
      double: responseData.value,
    },
    portfolioReturnPercent: responseData.returnPercent,
    portfolioProfit: responseData.profit,
    portfolioSummaryBreakdown: null,
  }

  return portfolioObject
}

const updateTWPortfolioData = (
  responseData: PortfolioHoldingResponseType,
  currentHoldingList: HoldingFundTypeWithSetting[]
): PortfolioState => {
  const updatedFunds: HoldingFundTypeWithSetting[] = currentHoldingList.map(
    (data: HoldingFundTypeWithSetting) => {
      const updatedResponse = responseData.holdings.filter(
        (res: FundResponseHKType) => res.fundCode === data.fundCode
      )[0]
      const updatedFundObj: HoldingFundTypeWithSetting = {
        ...data,
        totalCost: updatedResponse.cost,
        value: updatedResponse.value,
        valueInDouble: updatedResponse.valueInDouble,
        profit: updatedResponse.profit,
        grossDividend: updatedResponse.grossDividend,
        bidPrice: updatedResponse.bidPrice,
      }
      return updatedFundObj
    }
  )
  const portfolioObject: PortfolioState = {
    fundList: updatedFunds,
    portfolioTotalCost: responseData.totalCost,
    portfolioTotalValue: {
      string: responseData.totalValue,
      double: responseData.value,
    },
    portfolioReturnPercent: responseData.returnPercent,
    portfolioProfit: responseData.profit,
    portfolioSummaryBreakdown: null,
  }

  return portfolioObject
}

type UpdatePortfolioHoldingsParams = {
  currency?: string
}

const updatePortfolioHoldings = createAsyncThunk<
  PortfolioState,
  UpdatePortfolioHoldingsParams
>(
  'updatePortfolioHoldings',
  async (params: UpdatePortfolioHoldingsParams, { getState }) => {
    const store: RootStateOrAny = getState()
    const { country } = store.system
    const {
      data: { fundList: currentHoldingList },
    } = store.holding

    const { accountType, type, groupType } = store.fundFilter
    const defaultCurrency = country === 'HK' ? 'hkd' : 'twd'

    const portfolioRequest = {
      accountType,
      type,
      groupType,
      ...params,
    }

    let fundRes = await appAxios().post(`${getRoute({ route: `portfolio` })}`, {
      ...portfolioRequest,
      isPieChart: false,
    })

    if (process.env.NODE_ENV !== 'production') {
      if (params.currency && params.currency !== defaultCurrency) {
        fundRes = await appAxios().post(
          `${getRoute({ route: `portfolioUsd` })}`,
          {
            ...portfolioRequest,
            isPieChart: false,
          }
        )
      }
    }

    const holdingResponseData: PortfolioHoldingResponseType = fundRes.data

    const portfolio =
      country === 'HK'
        ? updateHKPortfolioData(holdingResponseData, currentHoldingList)
        : updateTWPortfolioData(holdingResponseData, currentHoldingList)

    return portfolio
  }
)

export default updatePortfolioHoldings
