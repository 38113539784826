import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootStateOrAny } from 'react-redux'

import { appAxios, getRoute } from '../../../helpers'
import {
  AllotmentSubmitFundType,
  BuyConfirmedOrderType,
  BuyFundType,
  ErrorResponse,
  OrderConfirmationResponse,
  RedemptionConfirmedOrderType,
  RedemptionFundType,
  RedemptionSubmitFundType,
  SwitchConfirmedOrderType,
  SwitchFundType,
  SwitchSubmitFundType,
} from '../../../typings/UrlTrading'

type SubmitRequestType = {
  accountNo: string
  vpin: string
  allotmentList: AllotmentSubmitFundType[]
  switchList: SwitchSubmitFundType[]
  redemptionList: RedemptionSubmitFundType[]
}

type SubmitResponseType = {
  orderReceivedDate: string
  allotmentResponse: {
    order: BuyConfirmedOrderType[]
    failOrder: unknown
    ppsURL: null
    receipt: null
  }
  switchResponseList: SwitchConfirmedOrderType[]
  redemptionResponse: {
    orderNumbers: string[]
    order: RedemptionConfirmedOrderType[]
    failOrder: unknown
  }
}

const transformRequestData = ({
  userPin,
  userAccountNo,
  userOrders,
}: {
  userPin: string
  userAccountNo: string
  userOrders: {
    allotmentList: BuyFundType[]
    redemptionList: RedemptionFundType[]
    switchList: SwitchFundType[]
  }
}): SubmitRequestType => {
  const { allotmentList, redemptionList, switchList } = userOrders

  const allotmentRequestList = allotmentList.map(
    (allotmentFund: BuyFundType) => {
      const fundObj: AllotmentSubmitFundType = {
        fundCode: allotmentFund.fundCode,
        amount: allotmentFund.amount,
        currency: allotmentFund.currency,
        discountCode: allotmentFund.discountCode,
        agreements: allotmentFund.agreements,
      }
      return fundObj
    }
  )
  const redemptionRequestList = redemptionList.map(
    (redemptionFund: RedemptionFundType) => {
      const fundObj: RedemptionSubmitFundType = {
        fundCode: redemptionFund.fundCode,
        unit: redemptionFund.unit,
        percent: redemptionFund.percent.toString(),
        currency: redemptionFund.currency,
        fundInfo: {
          code: redemptionFund.fundCode,
          locale: redemptionFund.fundName,
          jfFundCode: redemptionFund.jfFundCode,
          isinCode: redemptionFund.isinCode,
        },
        totalHoldings: redemptionFund.availableHoldings, // TODO: TBC
        availableHoldings: redemptionFund.availableHoldings,
        redUnitPrecision: redemptionFund.redUnitPrecision,
        redUnitPricePrecision: redemptionFund.redUnitPricePrecision,
        baseCurrency: redemptionFund.baselineCurrency,
        fundFeeType: redemptionFund.fundFeeType,
        unitsBreakdown: {
          // TODO:TBC
          total: 1419.885,
          newUnits: 0,
          oldUnits: 0,
        },
        redemptionCharge: 0, // TODO: TBC,
        bankAccountNo: redemptionFund.bankAccountNo,
      }
      return fundObj
    }
  )
  const switchRequestList: SwitchSubmitFundType[] = switchList.map(
    (switchFund: SwitchFundType) => {
      const fundObj: SwitchSubmitFundType = {
        outFundCode: switchFund.outFundCode,
        inFundCode: switchFund.inFundCode,
        switchUnit: switchFund.switchUnit,
        percent: switchFund.percent.toString(),
        discountCode: switchFund.discountCode,
        holdingCurrency: switchFund.currency,
        agreements: switchFund.agreements,
        outFundFeeType: switchFund.outFund.fundFeeType,
      }
      return fundObj
    }
  )
  return {
    accountNo: userAccountNo,
    vpin: userPin,
    allotmentList: allotmentRequestList,
    switchList: switchRequestList,
    redemptionList: redemptionRequestList,
  }
}

const transformResponseData = (
  responseData: SubmitResponseType
): OrderConfirmationResponse => {
  const allotmentOrderList: BuyConfirmedOrderType[] =
    responseData.allotmentResponse.order
  const redemptionList: RedemptionConfirmedOrderType[] =
    responseData.redemptionResponse.order
  const switchOrderList: SwitchConfirmedOrderType[] =
    responseData.switchResponseList
  return {
    error: false,
    orderReceivedDate: responseData.orderReceivedDate,
    allotmentOrderList,
    switchOrderList,
    redemptionList,
  }
}

const submitTradingFundList = createAsyncThunk<
  OrderConfirmationResponse | ErrorResponse,
  string
>('submitTradingFundList', async (userPin: string, { getState }) => {
  const state: RootStateOrAny = getState()
  const userOrders = state.urlTrading.orders.list
  const userAccountNo = state.urlTrading.clientId

  //  transform our state to request data type
  const submitRequestObject: SubmitRequestType = transformRequestData({
    userPin,
    userOrders,
    userAccountNo,
  })

  // Post Request to submit orders
  const response = await appAxios().post(
    getRoute({ route: 'submitConfirmTradingOrders' }),
    { ...submitRequestObject }
  )

  // assuming if incorrect pin will generate 401
  if (response.status === 401) {
    return {
      error: true,
      incorrectPin: true,
    }
  }

  // Transform the response data into our state
  return transformResponseData(response.data as SubmitResponseType)
})

export default submitTradingFundList
