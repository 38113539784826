import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootStateOrAny } from 'react-redux'

import { PIN_CHANGE_REMINDER } from '../../../constants/warning-message'
import { appAxios, getRoute } from '../../../helpers'
import { UserCountryType, UserInfoType } from '../../../typings/UserInfo'
import { updateShowWelcomeOverlayStatus } from '../../portfolio/actions'
import { addWarningMessage } from '../../warning-message/actions'

/**
 * User API response data structure from HK
 */
interface UserAccountInfoApiReponseDataHkType {
  accountNo: string
  msAccountNo: string[]
  lastLoginDate: string
  etradingRight: string
  dvtCompleted: boolean
  investorType: string
  email: string
  pinChangeReminder: boolean
  mfaIndicator: string // "N"
  accountSidMap: {
    [accountNo: string]: string
  }
  showPromoCode: boolean
  mfaReminderMessage: string
  hasUsername: boolean
  showUsernameTooltip: boolean
  accountSupplementalInfo: { [key: string]: string }[]
  isThisMonthIsBirthDayMonth: boolean
  isStaff: boolean
  isStaffOnsMoneyApproval: boolean
  isStaff7days: boolean
  isStaff60days: boolean
  isStaffApproval: boolean
  isMA: boolean
  msAccountIsStaffApprovalMap?: { [key: string]: string }
  vcclient: boolean
  isOA2: boolean
  accountNameInfo: {
    locale: {
      [locale: string]: string
    }
  }
  tier: string
}

/**
 * User API response data structure from TW
 */
interface UserAccountInfoApiReponseDataTwType {
  accountNo: string
  msAccountNo: string[]
  lastLoginDate: string
  etradingRight: string
  dvtCompleted: boolean
  investorType: string
  email: string
  pinChangeReminder: boolean
  mfaIndicator: string // "N"
  accountSidMap: {
    [accountNo: string]: string
  }
  showPromoCode: boolean
  mfaReminderMessage: string
  hasUsername: boolean
  showUsernameTooltip: boolean
  accountSupplementalInfo: { [key: string]: string }[]
  isThisMonthIsBirthDayMonth: boolean
  isStaff: boolean
  isStaffOnsMoneyApproval: boolean
  isStaff7days: boolean
  isStaff60days: boolean
  isStaffApproval: boolean
  isMA: boolean
  msAccountIsStaffApprovalMap?: { [key: string]: string }
  accountNameInfo: {
    locale: {
      [locale: string]: string
    }
  }
}

type UserReponseDataType =
  | UserAccountInfoApiReponseDataHkType
  | UserAccountInfoApiReponseDataTwType

const transformUserDataTW = (
  responseData: UserAccountInfoApiReponseDataTwType
): UserInfoType => {
  const UserObject: UserInfoType = {
    ...responseData,
    name: responseData.accountNameInfo,
    rspAccountNo: responseData.msAccountNo[0],
  }

  return UserObject
}

const transformUserDataHK = (
  responseData: UserAccountInfoApiReponseDataHkType
): UserInfoType => {
  const UserObject: UserInfoType = {
    ...responseData,
    name: responseData.accountNameInfo,
    rspAccountNo: responseData.msAccountNo[0],
  }

  return UserObject
}

const transformUserData = (
  responseData: UserReponseDataType,
  country: UserCountryType
): UserInfoType => {
  if (country === 'HK')
    return transformUserDataHK(
      responseData as UserAccountInfoApiReponseDataHkType
    )

  return transformUserDataTW(
    responseData as UserAccountInfoApiReponseDataTwType
  )
}

/**
 * fetchUser
 * =========
 *
 * To DEMO a case when we fetch user data from the internet, but the
 * data structure is different between the user API endpoint and
 * our predefined fake user data.
 *
 * The quickest way to integrate the user API data with the app is to
 * transform it into the same data structure with our predefined fake
 * user data structure. In this way, we don't have to touch the React
 * app.
 */
const fetchUserAccountInfo = createAsyncThunk<UserInfoType>(
  'fetchUserAccountInfo',
  async (_, { getState, dispatch }) => {
    const state: RootStateOrAny = getState()
    const { country } = state.system

    const userAccountInfoResponse = await appAxios().get(
      getRoute({ route: 'userInformation' })
    )

    const responseData = userAccountInfoResponse.data

    if (country === 'HK' && responseData.pinChangeReminder) {
      dispatch(addWarningMessage(PIN_CHANGE_REMINDER))
    }

    // TODO: some logic to determine showing welcome tutorial overlay, by default show welcome overlay is undefined
    dispatch(updateShowWelcomeOverlayStatus(true))
    return transformUserData(responseData, country)
  }
)

export default fetchUserAccountInfo
