import { parseISO } from 'date-fns'
import enLocale from 'date-fns/locale/en-GB'
import zhLocale from 'date-fns/locale/zh-TW'
import { format as fnsFormat } from 'date-fns-tz'

import i18n from '../i18n'

export const formateMonthYear = (date: Date): string => {
  return date
    .toLocaleString('en-GB', {
      month: 'numeric',
      year: 'numeric',
    })
    .replace('/', '-')
}

type FormateDateOptions = {
  time?: boolean
  numeric?: boolean
  withoutDate?: boolean
  customFormat?: string
  amPm?: boolean
}
export const getLocaleDateString = (
  date: string | number,
  options?: FormateDateOptions
): string => {
  const { language } = i18n
  const defaultFormat = language.includes('en') ? 'dd MMM yyyy' : 'PPP'
  let formatedDateString: Date

  if (typeof date === 'string') {
    formatedDateString = parseISO(date)
  } else {
    formatedDateString = new Date(date)
  }
  let format = options?.customFormat ? options?.customFormat : defaultFormat

  if (options?.time) {
    format = language.includes('en') ? 'dd MMM yyyy hh:mm' : 'PPP hh:mm'
  }
  if (options?.numeric) {
    format = language.includes('en') ? 'dd/MM/yyyy' : 'yyyy/MM/dd'
  }
  if (options?.withoutDate) {
    format = language.includes('en') ? 'MMM yyyy' : 'yyyy年MMM'
  }
  // Add AM/PM to the format string if the amPm option is true
  if (options?.amPm) {
    format = language.includes('en') ? 'dd MMM yyyy hh:mm aa' : 'PPP aa hh:mm'
  }
  const locale = language.includes('en') ? enLocale : zhLocale

  return fnsFormat(formatedDateString, format, {
    locale,
  })
}
