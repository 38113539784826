import classnames from 'classnames'
import { FunctionComponent } from 'react'

import {
  MULTI_SELECT_MULTIPLE_CHOICE,
  SINGLE_SELECT_MULTIPLE_CHOICE,
} from '../../constants/question-types'
import checkAgeFilter from '../../helpers/checkAgeFilter'
import { useAppMediaQuery } from '../../hooks'
import {
  DependentQuestion,
  GeneralSuitabilityQuestion,
  GeneralSuitabilityQuestionCategory,
  QUESTION_CATEGORY_RISK_ASSESSMENT,
  QUESTION_DISPLAY_DROPDOWN,
  QUESTION_DISPLAY_TRUE_OR_FALSE,
  QUESTION_TYPE_FREE_INPUT,
  QuestionAnswerObject,
} from '../../typings/GeneralSuitability'
import MultiSelectQuestion from '../multi-select-question'
import SingleChoiceQuestion, {
  GENERAL_SUITABILITY_QUESTION,
  OPTION_DISPLAY_DROPDOWN,
  OPTION_DISPLAY_HORIZONTAL_ONE_THIRD,
  OPTION_DISPLAY_VERTICAL,
  OPTION_DISPLAY_WIDTH_50,
  OPTION_DISPLAY_WIDTH_AUTO,
  SingleChoiceQuestionProps,
} from '../single-choice-question/SingleChoiceQuestion'
import TextField from '../text-field'
import { MultiInputDisplayType } from '../text-field/TextField'
import styles from './GSQuestion.module.scss'

interface GSQuestionProps {
  question: GeneralSuitabilityQuestion | DependentQuestion
  index: number
  category: GeneralSuitabilityQuestionCategory
  onValueChange: ({
    selectedId,
    selectedOption,
  }: {
    selectedId: number
    selectedOption: string | string[] | number | undefined
  }) => void
  error: boolean
  errorMessage?: string
  answers: QuestionAnswerObject | undefined
  inputProps?: GeneralSuitabilityQuestion['inputFieldProps']
  ageFilter?: GeneralSuitabilityQuestion['ageFilter']
}
const GSQuestion: FunctionComponent<GSQuestionProps> = ({
  ageFilter,
  question,
  index,
  category,
  onValueChange,
  answers,
  error,
  errorMessage,
  inputProps,
}: GSQuestionProps) => {
  const { isLgUp, isMdUp } = useAppMediaQuery()

  // Check if the dependent question is mandatory
  // Comment out for demo purpose
  // if ('isDependent' in question) {
  //   // check questionAnswers
  //   if (!answers) return null
  //   if (answers && !answers.mandatory) {
  //     return null
  //   }
  if (question.questionNumber === 100) {
    return <p className={styles.politicalForm__title}>{question.question}</p>
  }
  // }

  if (!checkAgeFilter(ageFilter)) {
    return null
  }

  const {
    questionId,
    question: questionString = '',
    questionNumber,
    options,
    display,
    placeholder,
  } = question

  // determine if the question number will be shown
  const shouldDisplayQuestionNumber =
    category === QUESTION_CATEGORY_RISK_ASSESSMENT ? index + 1 : false

  let displayOption: SingleChoiceQuestionProps['display'] =
    OPTION_DISPLAY_VERTICAL

  if (display === QUESTION_DISPLAY_DROPDOWN) {
    displayOption = OPTION_DISPLAY_DROPDOWN
  }
  if (display === QUESTION_DISPLAY_TRUE_OR_FALSE || questionId === '1') {
    displayOption = OPTION_DISPLAY_WIDTH_AUTO // the option width is different from other radio question
  }
  // Risk Assessment Question should display one third in laptop device size
  if (questionNumber >= 17 && questionNumber <= 26) {
    displayOption = OPTION_DISPLAY_HORIZONTAL_ONE_THIRD
  }

  if (questionId === '1') {
    if (isLgUp) {
      displayOption = OPTION_DISPLAY_WIDTH_AUTO
    } else if (isMdUp) {
      displayOption = OPTION_DISPLAY_WIDTH_50
    } else {
      displayOption = OPTION_DISPLAY_VERTICAL
    }
  }

  const renderQuestion = () => {
    switch (question.questionType) {
      case SINGLE_SELECT_MULTIPLE_CHOICE:
        return (
          <SingleChoiceQuestion
            variant={GENERAL_SUITABILITY_QUESTION}
            questionNumber={questionNumber}
            displayQuestionNumber={shouldDisplayQuestionNumber}
            question={`${questionString}*`}
            display={displayOption}
            options={options}
            selectedOption={
              (answers && (answers.answer as string)) || placeholder || false
            }
            onValueChange={onValueChange}
            error={error}
            extraDisplayOptions={question.extraDisplayOptions}
            disabled={(answers && answers.autofilled) || false}
          />
        )
      case QUESTION_TYPE_FREE_INPUT:
        return (
          <TextField
            error={error}
            errorMessage={errorMessage}
            question={`${questionString}*`}
            questionNumber={questionNumber}
            displayQuestionNumber={shouldDisplayQuestionNumber}
            onValueChange={(input: string | number | undefined) =>
              onValueChange({
                selectedId: questionNumber,
                selectedOption: input,
              })
            }
            value={(answers && (answers.answer as string | number)) || ''}
            display={
              typeof display === 'string'
                ? 'NORMAL'
                : (display as MultiInputDisplayType)
            }
            placeholder={placeholder || questionString}
            inputProps={{
              ...inputProps,
              disabled: answers ? answers.autofilled : false,
            }}
          />
        )
      case MULTI_SELECT_MULTIPLE_CHOICE:
        return (
          <MultiSelectQuestion
            error={error}
            question={`${questionString}*`}
            displayQuestionNumber={shouldDisplayQuestionNumber}
            variant={GENERAL_SUITABILITY_QUESTION}
            options={options}
            onValueChange={onValueChange}
            selectedOptions={answers ? (answers.answer as string[]) : []}
            questionNumber={questionNumber}
          />
        )
      default:
        return <></>
    }
  }

  return (
    <div
      className={classnames(styles.questionWrapper, {
        [styles.isDependent]: 'isDependent' in question,
      })}
      data-gsquestion={questionNumber}
    >
      {renderQuestion()}
    </div>
  )
}

GSQuestion.defaultProps = {
  inputProps: {},
  ageFilter: undefined,
  errorMessage: '',
}

export default GSQuestion
