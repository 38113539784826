import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootStateOrAny } from 'react-redux'

import { appAxios, getRoute } from '../../../helpers'
import {
  EPointsListType,
  EPointsResponse,
  UserCountryType,
  UserEPointsType,
} from '../../../typings/UserInfo'

const EPOINTS_THREHOLD = 30

/**
 * UserEpoints API response data structure from HK
 */
interface UserEPointsInfoApiReponseDataHkType {
  list: EPointsResponse[]
  pointsAboutToExpire: number | null
  totalPoints: number | null
}

/**
 * User API Epoints response data structure from TW
 */
interface UserEPointsInfoApiReponseDataTwType {
  list: EPointsResponse[]
  pointsAboutToExpire: number | null
  totalPoints: number | null
}

type UserEPointsReponseDataType =
  | UserEPointsInfoApiReponseDataHkType
  | UserEPointsInfoApiReponseDataTwType

const transformUserDataTW = (
  responseData: UserEPointsInfoApiReponseDataTwType
): UserEPointsType => {
  const EPointsObject: UserEPointsType = {
    ...responseData,
    pointsToWaiver: 1, // number of points convert to one dollar, data from JP MORGAN team
    list: responseData.list.map((epointsList: EPointsResponse) => {
      const listObj: EPointsListType = {
        ...epointsList,
        expiryDate: epointsList.expiryDate,
      }
      return listObj
    }),

    totalPoints: {
      number: responseData.totalPoints
        ? responseData.totalPoints
        : responseData.list.reduce(
            (accumulator: number, ePoint: EPointsResponse) =>
              accumulator + ePoint.ePoints,
            0
          ),
    },
  }

  return EPointsObject
}

const transformUserDataHK = (
  responseData: UserEPointsInfoApiReponseDataHkType
): UserEPointsType => {
  const epointList = responseData.list.map((epointsList: EPointsResponse) => {
    const listObj: EPointsListType = {
      ...epointsList,
      expiryDate: epointsList.expiryDate,
    }
    return listObj
  })
  const getEpointsCardData = () => {
    const earliestExpirePoints = epointList
      .filter((epointEntry: EPointsListType) => epointEntry.aboutToExpire)
      .sort((a: EPointsListType, b: EPointsListType) => {
        if (a.expiryDate && b.expiryDate) {
          return (
            new Date(a.expiryDate.replace('+0000', 'Z')).getTime() -
            new Date(b.expiryDate.replace('+0000', 'Z')).getTime()
          )
        }
        return 0
      })

    if (!earliestExpirePoints) return undefined

    if (
      earliestExpirePoints[0].expiryDate &&
      new Date(earliestExpirePoints[0].expiryDate).getTime() -
        new Date().getTime() >
        EPOINTS_THREHOLD * 24 * 60 * 60 * 1000
    )
      return undefined

    return {
      epoints: earliestExpirePoints[0].ePoints,
      date: earliestExpirePoints[0].expiryDate as string,
    }
  }
  const EPointsObject: UserEPointsType = {
    ...responseData,
    pointsToWaiver: 50, // number of points convert to one dollar, data from JP MORGAN team
    list: epointList,
    totalPoints: {
      number: responseData.totalPoints
        ? responseData.totalPoints
        : responseData.list.reduce(
            (accumulator: number, ePoint: EPointsResponse) =>
              accumulator + ePoint.ePoints,
            0
          ),
    },
    showEpointsCard: getEpointsCardData(),
  }

  return EPointsObject
}

const transformUserData = (
  responseData: UserEPointsReponseDataType,
  country: UserCountryType
): UserEPointsType => {
  if (country === 'HK')
    return transformUserDataHK(
      responseData as UserEPointsInfoApiReponseDataHkType
    )

  return transformUserDataTW(
    responseData as UserEPointsInfoApiReponseDataTwType
  )
}

/**
 * fetchUser
 * =========
 *
 * To DEMO a case when we fetch user data from the internet, but the
 * data structure is different between the user API endpoint and
 * our predefined fake user data.
 *
 * The quickest way to integrate the user API data with the app is to
 * transform it into the same data structure with our predefined fake
 * user data structure. In this way, we don't have to touch the React
 * app.
 */
const fetchUserEpoints = createAsyncThunk<UserEPointsType>(
  'fetchUserEpoints',
  async (_, { getState }) => {
    const state: RootStateOrAny = getState()
    const { country } = state.system

    // const responseData = user basic information + membership tier + risk rating
    const userEPointsInfoResponse = await appAxios().get(
      getRoute({ route: 'userEPoints' })
    )

    const responseData = userEPointsInfoResponse.data

    return transformUserData(responseData, country)
  }
)

export default fetchUserEpoints
