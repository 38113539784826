import classnames from 'classnames'
import { FunctionComponent } from 'react'

import { AlertIcon, Icon } from '../icons'
import styles from './ErrorMessage.module.scss'

interface ErrorMessageProps {
  message: string
  containerClassName?: string
}

const ErrorMessage: FunctionComponent<ErrorMessageProps> = ({
  message,
  containerClassName,
  ...props
}): JSX.Element => {
  return (
    <div
      className={classnames(styles.errorContainer, containerClassName)}
      {...props}
    >
      <Icon className={styles.errorIcon}>
        <AlertIcon width={16} height={16} />
      </Icon>
      <div className={styles.errorMessage}>{message}</div>
    </div>
  )
}

ErrorMessage.defaultProps = {
  containerClassName: '',
}

export default ErrorMessage
