import {
  IS_INTEGRATED,
  IS_OFF_SHORE,
  IS_ON_SHORE,
} from '../constants/payment-type'
import { updateNumOfDisplayType } from '../redux/fund-filter/actions'
import { store } from '../store'
import {
  HoldingFundType,
  HoldingFundTypeWithSetting,
} from '../typings/Portfolio'

const checkTWPaymentType = (
  portfolioFundList: HoldingFundType[] | HoldingFundTypeWithSetting[]
): void => {
  let numOfPaymentType = 0
  const hasEC: boolean =
    portfolioFundList.findIndex(
      (fund: HoldingFundType) =>
        fund.paymentType &&
        (IS_ON_SHORE.includes(fund.paymentType) ||
          IS_OFF_SHORE.includes(fund.paymentType))
    ) >= 0
  const hasMA: boolean =
    portfolioFundList.findIndex(
      (fund: HoldingFundType) =>
        fund.paymentType && IS_INTEGRATED.includes(fund.paymentType)
    ) >= 0

  if (hasEC) {
    numOfPaymentType += 1
  }
  if (hasMA) {
    numOfPaymentType += 1
  }
  store.dispatch(updateNumOfDisplayType(numOfPaymentType))
}

export default checkTWPaymentType
