import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { validateAnswerAndDependentQuestions } from '../../helpers/updateAnswerAndMandatory'
import {
  FILLING_ROLE_AGENT_ONE,
  FILLING_ROLE_AGENT_TWO,
  FILLING_ROLE_SELF,
  FillingRoleType,
  GeneralSuitabilityInfo,
  GeneralSuitabilityQuestion,
  GeneralSuitabilityQuestionCategory,
  QuestionAnswer,
  QuestionAnswers,
  QuestionnaireResult,
  QuestionnaireStatus,
  QuestionnaireStepType,
  RISK_HIGH,
  RiskLevel,
  STATUS_INCOMPLETE,
  STATUS_LOADING,
} from '../../typings/GeneralSuitability'
import {
  sampleAgentQuestionGroups,
  sampleAllQuestionGroups,
  sampleAnswerData,
} from './actions/__testing__data'
import submitQuestionnaire from './actions/submitQuestionniare'
import submitSpecialCriteriaOption from './actions/submitSpecialCriteriaOption'
import {
  stepQuestionnaireDataMinor,
  stepQuestionnaireDataNonMinor,
} from './data'

export type GeneralSuitabililtyState = GeneralSuitabilityInfo & {
  age: number | undefined // 受益人年齡，determine whether certain questionnaire groups / question to be rendered
  questionAnswers: {
    [key in FillingRoleType]: QuestionAnswers
  } // store the question answers from user input
  stepQuestionnaireData: QuestionnaireStepType[] // store the questionnaire data
  mandatoryQuestionGroups: {
    [key in FillingRoleType]: GeneralSuitabilityQuestionCategory[]
  } // store which categories are mandatory along the questionnaire journey -> determine review section rendering
  // review section will cross check and render only the question groups within mandatory question groups
}

const getStepQuestionnaireData = (age: number): QuestionnaireStepType[] => {
  if (age < 20) {
    return stepQuestionnaireDataMinor
  }
  return stepQuestionnaireDataNonMinor
}

const initialState: GeneralSuitabililtyState = {
  isCorporateAccount: false,
  generalSuitabilityStatus: STATUS_INCOMPLETE,
  enhancedSuitabilityStatus: STATUS_INCOMPLETE,
  showAgeOver65Message: false,
  riskLevel: RISK_HIGH,
  lastAssessDate: new Date().getTime(),
  showSpecialCriteriaPopup: {
    show: false,
  },
  showResultFeedback: false,
  age: 18, // PLACEDHOLDER for demo purpose
  stepQuestionnaireData: getStepQuestionnaireData(18), // PLACEDHOLDER for demo purpose
  questionAnswers: {
    [FILLING_ROLE_SELF]: {},
    [FILLING_ROLE_AGENT_ONE]: {},
    [FILLING_ROLE_AGENT_TWO]: {},
  },
  mandatoryQuestionGroups: {
    [FILLING_ROLE_SELF]: [],
    [FILLING_ROLE_AGENT_ONE]: [],
    [FILLING_ROLE_AGENT_TWO]: [],
  },
  shouldShowQuestion4C: false,
}

export const generalSuitabilitySlice = createSlice({
  name: 'generalSuitability',
  initialState,
  reducers: {
    // when user exit the questionniare step, all answers will be reset
    resetQuestionAnswers: (state) => {
      state.questionAnswers = {
        [FILLING_ROLE_SELF]: {},
        [FILLING_ROLE_AGENT_ONE]: {},
        [FILLING_ROLE_AGENT_TWO]: {},
      }
      state.mandatoryQuestionGroups = {
        [FILLING_ROLE_SELF]: [],
        [FILLING_ROLE_AGENT_ONE]: [],
        [FILLING_ROLE_AGENT_TWO]: [],
      }
    },
    update4CQuestionRenderingStatus: (
      state,
      action: PayloadAction<boolean>
    ) => {
      // call this function to render question 4C
      state.shouldShowQuestion4C = action.payload
      state.questionAnswers = {
        ...state.questionAnswers,
        [FILLING_ROLE_SELF]: {
          ...state.questionAnswers[FILLING_ROLE_SELF],
          41: {
            ...state.questionAnswers[FILLING_ROLE_SELF][41],
            mandatory: action.payload,
          },
        },
      }
    },
    updateGSStatus: (state, action: PayloadAction<QuestionnaireStatus>) => {
      state.generalSuitabilityStatus = action.payload
    },
    updateESStatus: (state, action: PayloadAction<QuestionnaireStatus>) => {
      state.enhancedSuitabilityStatus = action.payload
    },
    updateRiskLevel: (state, action: PayloadAction<RiskLevel>) => {
      state.riskLevel = action.payload
    },
    updateIsCorporateAccount: (state, action: PayloadAction<boolean>) => {
      state.isCorporateAccount = action.payload
    },
    updateUserAge: (state, action: PayloadAction<number>) => {
      const updatedAge = action.payload
      state.age = updatedAge
      state.showAgeOver65Message = updatedAge >= 65
      state.stepQuestionnaireData = getStepQuestionnaireData(updatedAge)
    },
    // this reducer function will update question answers object and cross check the dependent questions conditions each time when user update a question answer
    updateQuestionAnswer: (
      state,
      action: PayloadAction<{
        selectedId: number
        selectedOption: QuestionAnswer
        currentFillingRole: FillingRoleType
      }>
    ) => {
      const { selectedId, selectedOption, currentFillingRole } = action.payload
      const originalAnswers = { ...state.questionAnswers[currentFillingRole] }

      const updatedQuestionAnswers = validateAnswerAndDependentQuestions(
        originalAnswers,
        selectedId,
        selectedOption
      )
      state.questionAnswers = {
        ...state.questionAnswers,
        [currentFillingRole]: updatedQuestionAnswers,
      }
    },
    // this reducer function will update a list of questions to be mandatory each time when user head to a new substep / mainstep
    addQuestionsMandatory: (
      state,
      action: PayloadAction<{
        updatedQuestionsMandatory: number[]
        currentFillingRole: FillingRoleType
      }>
    ) => {
      const { updatedQuestionsMandatory, currentFillingRole } = action.payload
      let updatedQuestionAnswers = {
        ...state.questionAnswers[currentFillingRole],
      }
      updatedQuestionsMandatory.forEach((questionNumber: number) => {
        updatedQuestionAnswers = {
          ...updatedQuestionAnswers,
          [questionNumber]: {
            ...updatedQuestionAnswers[questionNumber],
            mandatory: true,
          },
        }
      })

      state.questionAnswers = {
        ...state.questionAnswers,
        [currentFillingRole]: updatedQuestionAnswers,
      }
    },
    // this reducer function will update a list of questiongroup to be mandatory each time when user head to a new substep / mainstep -> help review section to determine whether certain question group should be displayed
    updateQuestionGroupsMandatory: (
      state,
      action: PayloadAction<{
        updateAction: 'remove' | 'add'
        category: GeneralSuitabilityQuestionCategory[]
        currentFillingRole: FillingRoleType
      }>
    ) => {
      const { category, currentFillingRole, updateAction } = action.payload
      let currentMandatoryGroups =
        state.mandatoryQuestionGroups[currentFillingRole]
      if (updateAction === 'add') {
        currentMandatoryGroups = [...currentMandatoryGroups, ...category]
      } else {
        currentMandatoryGroups = currentMandatoryGroups.filter(
          (currentCategory) => !category.includes(currentCategory)
        )
      }

      state.mandatoryQuestionGroups = {
        ...state.mandatoryQuestionGroups,
        [currentFillingRole]: currentMandatoryGroups,
      }
    },
    updateShowResultFeedback: (state, action: PayloadAction<boolean>) => {
      state.showResultFeedback = action.payload
    },
    removeAgentTwo: (state) => {
      state.mandatoryQuestionGroups.FILLING_ROLE_AGENT_TWO = []
    },
    updateShowSpecialCriteriaPopup: (
      state,
      action: PayloadAction<{
        show: boolean
        question?: GeneralSuitabilityQuestion | undefined
      }>
    ) => {
      const { show, question } = action.payload
      state.showSpecialCriteriaPopup = {
        show,
        question,
      }
    },
    // FOR TESTING AND DEVELOPMENT PURPOSE ONLY
    testRandomAnswers: (state) => {
      if (state.age && state.age < 20) {
        state.mandatoryQuestionGroups = {
          [FILLING_ROLE_SELF]: sampleAllQuestionGroups,
          [FILLING_ROLE_AGENT_ONE]: sampleAgentQuestionGroups,
          [FILLING_ROLE_AGENT_TWO]: sampleAgentQuestionGroups,
        }
        state.questionAnswers = sampleAnswerData
      } else {
        state.mandatoryQuestionGroups = {
          [FILLING_ROLE_SELF]: sampleAllQuestionGroups,
          [FILLING_ROLE_AGENT_ONE]: [],
          [FILLING_ROLE_AGENT_TWO]: [],
        }
        state.questionAnswers = {
          ...sampleAnswerData,
          [FILLING_ROLE_AGENT_ONE]: {},
          [FILLING_ROLE_AGENT_TWO]: {},
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(submitQuestionnaire.pending, (state) => {
      state.generalSuitabilityStatus = STATUS_LOADING
    })
    // update reducer variables after questionnaire is submitted
    builder.addCase(
      submitQuestionnaire.fulfilled,
      (state, action: PayloadAction<QuestionnaireResult>) => {
        const {
          generalSuitabilityStatus,
          enhancedSuitabilityStatus,
          riskLevel,
          shouldShowFeedbackModal,
          shouldShowSpecialCriteriaPopup,
        } = action.payload

        state.generalSuitabilityStatus = generalSuitabilityStatus
        state.enhancedSuitabilityStatus = enhancedSuitabilityStatus
        state.riskLevel = riskLevel
        state.showSpecialCriteriaPopup = shouldShowSpecialCriteriaPopup
        state.showResultFeedback = shouldShowFeedbackModal
      }
    )
    builder.addCase(submitSpecialCriteriaOption.fulfilled, (state) => {
      state.showSpecialCriteriaPopup = {
        show: false,
      }
      state.showResultFeedback = true
    })
  },
})

export default generalSuitabilitySlice.reducer
