import { createAsyncThunk } from '@reduxjs/toolkit'

import { AssetClassData } from '../../../typings/Portfolio'

const updateFundCategoryList = createAsyncThunk<
  AssetClassData[],
  AssetClassData[]
>('updateFundCategoryList', async (data: AssetClassData[]) => {
  return data
})

export default updateFundCategoryList
