import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootStateOrAny } from 'react-redux'

import { appAxios, getRoute } from '../../../helpers'
import {
  LoginCredentialsParamsType,
  LoginValidationType,
} from '../../../typings/LoginCredentials'
import { UserCountryType } from '../../../typings/UserInfo'

type LoginApiResponse = {
  code: string
  detail: {
    type: string
    errors: {
      code: string
      locale: {
        [locale: string]: string
      }
    }[]
  }
}

const transformResponseDataHK = (): LoginValidationType => {
  // if (
  //   responseData.detail.type === 'ErrorResponse' &&
  //   responseData.detail.errors.length >= 1
  // ) {
  //   return {
  //     error: true,
  //     errorMessage: responseData.detail.errors[0].locale,
  //     succeed: false,
  //   }
  // }
  // console.log(responseData)

  return {
    error: false,
    errorMessage: undefined,
    succeed: true,
  }
}

const transformResponseDataTW = (
  responseData: LoginApiResponse
): LoginValidationType => {
  if (
    responseData.detail.type === 'ErrorResponse' &&
    responseData.detail.errors.length >= 1
  ) {
    return {
      error: true,
      errorMessage: responseData.detail.errors[0].locale,
      succeed: false,
    }
  }
  return {
    error: false,
    errorMessage: undefined,
    succeed: true,
  }
}

const transformResponseData = (
  loginRes: LoginApiResponse,
  country: UserCountryType
): LoginValidationType => {
  if (country === 'HK') {
    return transformResponseDataHK()
  }
  return transformResponseDataTW(loginRes)
}

const validateLoginCredentials = createAsyncThunk<
  LoginValidationType,
  LoginCredentialsParamsType
>(
  'validateLoginCredentials',
  async (loginCredentials: LoginCredentialsParamsType, { getState }) => {
    const state: RootStateOrAny = getState()
    const { country } = state.system

    const { accountNo, pin } = loginCredentials

    let loginRes

    if (accountNo.length < 12 || (pin && pin.length < 6)) {
      loginRes = await appAxios().post(
        `${getRoute({ route: `validateLoginCredentialsFailed` })}`,
        { ...loginCredentials }
      )
    } else {
      loginRes = await appAxios().post(
        `${getRoute({ route: `validateLoginCredentials` })}`,
        { ...loginCredentials }
      )
    }

    return transformResponseData(loginRes.data, country)
  }
)

export default validateLoginCredentials
