import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { OrderHistoryData } from '../../typings/OrderHistory'
import loadOrderHistory from './actions/loadOrderHistory'

type OrderHistoryState = {
  list: OrderHistoryData[]
  loading: boolean
}

const initialState: OrderHistoryState = {
  list: [],
  loading: false,
}

export const orderHistorySlice = createSlice({
  name: 'orderHistory',
  initialState,
  reducers: {
    updateOrderHistoryList: (
      state,
      action: PayloadAction<OrderHistoryData[]>
    ) => {
      state.list = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      loadOrderHistory.fulfilled,
      (state, action: PayloadAction<OrderHistoryData[]>) => {
        // eslint-disable-next-line no-param-reassign
        state.list = action.payload
      }
    )
  },
})

export default orderHistorySlice.reducer
