import { RootState, store } from '../store'
import { FundDetailsData } from '../typings/FundDetailsResponse'

const getFundDetail = (fundCode: string): FundDetailsData | null => {
  const state: RootState = store.getState()
  const { fundDetailsList } = state.allFund

  if (!fundDetailsList) {
    return null
  }
  return (
    fundDetailsList.filter(
      (fund: FundDetailsData) => fund.fundInfo.code === fundCode
    )[0] || null
  )
}

export default getFundDetail
