export const PORTFOLIO_VIEW_PORTFOLIO = 'portfolio'
export const PORTFOLIO_VIEW_ALL_FUNDS = 'all-funds'
export const PORTFOLIO_VIEW_DEFAULT = PORTFOLIO_VIEW_PORTFOLIO

export const PORTFOLIO_VIEW_TUTORIAL = 'tutorial'

export type PortfolioViewTypes =
  | typeof PORTFOLIO_VIEW_PORTFOLIO
  | typeof PORTFOLIO_VIEW_ALL_FUNDS
  | typeof PORTFOLIO_VIEW_DEFAULT
  | typeof PORTFOLIO_VIEW_TUTORIAL
