import { Action, Location } from 'history'
import { FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Prompt, useHistory } from 'react-router'

import Button from '../../../components/button'
import { ChevronIcon, Icon } from '../../../components/icons'
import ConfirmationModal from '../../../components/modal/confirmation-modal'
import { CHEVRON_ORIENTATION_LEFT } from '../../../constants/chevron'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import Page from '../../../layouts/page'
import Section from '../../../layouts/section'
import {
  resetQuestionAnswers,
  testRandomAnswers,
  updateESStatus,
  updateGSStatus,
  updateIsCorporateAccount,
  updateShowResultFeedback,
  updateShowSpecialCriteriaPopup,
  updateUserAge,
} from '../../../redux/general-suitability/actions'
import { specialCriteriaPopupQuestion } from '../../../redux/general-suitability/data'
import routes from '../../../routes/SiteNavigation'
import { RootState } from '../../../store'
import {
  STATUS_COMPLETED,
  STATUS_FAILED,
  STATUS_INCOMPLETE,
  STATUS_PASSED,
} from '../../../typings/GeneralSuitability'
import FormReviewSection from '../../../widgets/general-suitability/form-review-section'
import HeaderSection from '../../../widgets/general-suitability/header-section/HeaderSection'
import ImportantNotes from '../../../widgets/general-suitability/important-notes'
import Questionnaire from '../../../widgets/general-suitability/questionnaire'
import RiskAssessmentInfo from '../../../widgets/general-suitability/risk-assessment-info'

/* GeneralSuitability Page internal navigation is directed by currentStep
currentStep is an aggreated number starting from 1
e.g
1. Homepage 
2. Questionnaire Part One
3. Questionnaire Part Two
4. review page
5. Result page
*/

const GeneralSuitabilityTW: FunctionComponent = () => {
  const history = useHistory()
  const onClickBackIcon = () => history.push(routes.myPortfolio)
  const { t } = useTranslation()
  const {
    isCorporateAccount,
    generalSuitabilityStatus,
    enhancedSuitabilityStatus,
    showAgeOver65Message,
    riskLevel,
    lastAssessDate,
    stepQuestionnaireData,
    age,
  } = useAppSelector((state: RootState) => state.generalSuitability)
  const dispatch = useAppDispatch()
  const [currentStep, setCurrentStep] = useState<number>(1)
  const [
    discardChangesConfirmationModalOpen,
    setDiscardChangesConfirmationModalOpen,
  ] = useState<boolean>(false)
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [lastLocation, setLastLocation] = useState<Location | null>(null)
  const [confirmedNavigation, setConfirmedNavigation] = useState<boolean>(false)
  const [goToSubStep, setGoToSubStep] = useState<number | undefined>(undefined)

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [currentStep])

  const handleBlockedNavigation = (
    nextLocation: Location,
    _action: Action
  ): boolean => {
    if (!confirmedNavigation && isDirty) {
      setDiscardChangesConfirmationModalOpen(true)
      setLastLocation(nextLocation)
      return false
    }
    return true
  }

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation.pathname)
    }
  }, [confirmedNavigation, history, lastLocation])

  return (
    <Page>
      <Prompt when={isDirty} message={handleBlockedNavigation} />

      {/* ------ START OF DISCARD CURRENT CHANGE MODAL ------ */}
      <ConfirmationModal
        options={{
          closeModal: !discardChangesConfirmationModalOpen,
        }}
        modalTitle={t('modal:discard-changes.title')}
        modalContent={t('modal:discard-changes.content')}
        mdoalConfirmButtonText={t('modal:confirm-discard')}
        mdoalCancelButtonText={t('modal:continue-editing')}
        onConfirmButtonClick={() => {
          setConfirmedNavigation(true) // continue to navigation function
          setDiscardChangesConfirmationModalOpen(false) // close modal
          dispatch(resetQuestionAnswers()) // reset all question answers
        }}
        onCancelButtonClick={() => {
          setConfirmedNavigation(false) // exit navigation function
          setDiscardChangesConfirmationModalOpen(false) // close modal
        }}
      />
      {/* ------ END OF DISCARD CURRENT CHANGE MODAL ------ */}

      <Section narrow>
        <div className='mdoip__general-suitability__header'>
          <Icon
            clickable
            onClick={onClickBackIcon}
            className='mdoip__general-suitability__header__icon'
          >
            <ChevronIcon orientation={CHEVRON_ORIENTATION_LEFT} />
          </Icon>
          <h1 className='mdoip__general-suitability__header__text'>
            {t('generalSuitability:title')}
          </h1>
        </div>
      </Section>
      <div className='mdoip__general-suitability__divider' />

      {/* ------ START OF 風險屬性評估首頁 ------ */}
      {currentStep === 1 && (
        <Section narrow className='mdoip__general-suitability__body'>
          <HeaderSection
            isCorporateAccount={isCorporateAccount}
            generalSuitabilityStatus={generalSuitabilityStatus}
            showAgeOver65Message={showAgeOver65Message}
          />
          <div className='mdoip__general-suitability__divider--thick' />

          <RiskAssessmentInfo
            enhancedSuitabiilityStatus={enhancedSuitabilityStatus}
            isCorporateAccount={isCorporateAccount}
            generalSuitabilityStatus={generalSuitabilityStatus}
            riskLevel={riskLevel}
            lastAssessDate={lastAssessDate}
            startQuestionnaire={() => setCurrentStep(currentStep + 1)}
          />

          {!isCorporateAccount &&
            generalSuitabilityStatus === STATUS_INCOMPLETE && (
              <>
                <div className='mdoip__general-suitability__divider' />
                <ImportantNotes />
              </>
            )}
        </Section>
      )}
      {/* ------ END OF 風險屬性評估首頁 ------*/}

      {/* ------ START OF 任何問卷介面  ------*/}
      {currentStep !== 1 && currentStep <= stepQuestionnaireData.length + 1 && (
        <Questionnaire
          stepInfo={stepQuestionnaireData[currentStep - 2]}
          currentQuestionnaireStep={currentStep}
          clickNextStep={() => setCurrentStep(currentStep + 1)}
          inputAnswer={() => setIsDirty(true)}
          goToSubStep={goToSubStep}
          resetGoToSubStep={() => setGoToSubStep(undefined)}
        />
      )}
      {/* ------ END OF 任何問卷介面  ------*/}

      {/* ------ START OF 任何回顧介面 ------ */}
      {currentStep === stepQuestionnaireData.length + 2 && (
        <FormReviewSection
          goToStep={({ step, subStepIndexNumber }) => {
            setCurrentStep(step)
            setGoToSubStep(subStepIndexNumber)
          }}
        />
      )}
      {/* ------ END OF 任何回顧介面 ------ */}

      {/* FOR DEMO PURPOSE */}
      <Section narrow>
        <div className='mb-4'>
          <p>Current User age: {age}</p>
          {currentStep === 1 && generalSuitabilityStatus === STATUS_INCOMPLETE && (
            <div className='mb-4'>
              <p>選取年齡</p>
              <Button
                onClick={() => {
                  dispatch(updateUserAge(19))
                  setCurrentStep(2)
                }}
              >
                {'Minor <20 YO'}
              </Button>
              <Button
                onClick={() => {
                  dispatch(updateUserAge(45))
                  setCurrentStep(2)
                }}
              >
                {'Adult (>=20 and <65 YO)'}
              </Button>
              <Button
                onClick={() => {
                  dispatch(updateUserAge(70))
                  setCurrentStep(2)
                }}
              >
                {'Eldery >=65 YO'}
              </Button>
            </div>
          )}
        </div>

        {currentStep === 1 && (
          <>
            <div className='mb-4'>
              <p> 未完成GS 問卷 (首面，問卷第一步)</p>
              <Button
                onClick={() => {
                  dispatch(updateIsCorporateAccount(false))
                  dispatch(updateGSStatus(STATUS_INCOMPLETE))
                }}
              >
                未完成GS 問卷 - 普通客戶
              </Button>
              <Button onClick={() => dispatch(updateIsCorporateAccount(true))}>
                未完成GS 問卷 - Corporate客戶
              </Button>
            </div>
            <div className='mb-4'>
              <p>完成GS頁面</p>
              <Button
                onClick={() => {
                  dispatch(updateIsCorporateAccount(false))
                  dispatch(updateGSStatus(STATUS_COMPLETED))
                  dispatch(updateESStatus(STATUS_COMPLETED))
                }}
              >
                已完成ES
              </Button>
              <Button
                onClick={() => {
                  dispatch(updateIsCorporateAccount(false))
                  dispatch(updateGSStatus(STATUS_COMPLETED))
                  dispatch(updateESStatus(STATUS_INCOMPLETE))
                }}
              >
                未完成ES
              </Button>
              <Button
                onClick={() => {
                  dispatch(updateIsCorporateAccount(false))
                  dispatch(updateGSStatus(STATUS_COMPLETED))
                  dispatch(updateESStatus(STATUS_PASSED))
                }}
              >
                已完成ES (PASSED)
              </Button>
              <Button
                onClick={() => {
                  dispatch(updateIsCorporateAccount(false))
                  dispatch(updateGSStatus(STATUS_COMPLETED))
                  dispatch(updateESStatus(STATUS_FAILED))
                }}
              >
                ES 未成功 （FAILED）
              </Button>
            </div>
          </>
        )}
        {currentStep === 4 && (
          <div className='mb-4'>
            <p>GS問卷完成 modal</p>
            <Button
              onClick={() => {
                dispatch(testRandomAnswers())
                dispatch(
                  updateShowSpecialCriteriaPopup({
                    show: true,
                    question: specialCriteriaPopupQuestion,
                  })
                )
                setCurrentStep(4)
              }}
            >
              重要提示
            </Button>

            <Button
              onClick={() => {
                dispatch(updateShowResultFeedback(true))
                dispatch(updateESStatus(STATUS_COMPLETED))
                setCurrentStep(4)
              }}
            >
              完成MODAL （已完成ES）
            </Button>
            <Button
              onClick={() => {
                dispatch(updateShowResultFeedback(true))
                dispatch(updateESStatus(STATUS_INCOMPLETE))
                setCurrentStep(4)
              }}
            >
              完成MODAL （未完成ES）
            </Button>
          </div>
        )}
        {currentStep !== 4 && generalSuitabilityStatus !== STATUS_COMPLETED && (
          <div className='mb-4'>
            <p>跳過問卷</p>
            <Button
              onClick={() => {
                dispatch(testRandomAnswers())
                setCurrentStep(4)
              }}
            >
              問卷REVIEW （答案隨機）
            </Button>
          </div>
        )}
      </Section>
    </Page>
  )
}

export default GeneralSuitabilityTW
