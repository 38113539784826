/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { FunctionComponent } from 'react'
import { Trans } from 'react-i18next'

import { ModalBody, ModalHeader } from '../modal-components/ModalComponents'
import styles from '../occupation-instruction/OccupationInstruction.module.scss'

const PoliticalRelationship: FunctionComponent = () => {
  return (
    <>
      <ModalHeader />
      <ModalBody>
        <p className={styles.occupationInstruction}>
          <Trans
            i18nKey='modal:instruction-political-relationship.description'
            components={{
              withLink: (
                <a
                  href='https://www.mjib.gov.tw/mlpc'
                  target='_blank'
                  rel='noreferrer'
                />
              ),
            }}
          />
        </p>
      </ModalBody>
    </>
  )
}

export default PoliticalRelationship
