import { FunctionComponent } from 'react'

import { IconProps } from '../../typings/Icon'

const Close: FunctionComponent<IconProps> = ({
  width,
  height,
  className,
  ...props
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      {...props}
      viewBox='0 0 15 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='3.95752'
        y='3.3208'
        width='10'
        height='1'
        transform='rotate(45 3.95752 3.3208)'
        fill='black'
      />
      <rect
        x='3.45752'
        y='10.3208'
        width='10'
        height='1'
        transform='rotate(-45 3.45752 10.3208)'
        fill='black'
      />
    </svg>
  )
}

Close.defaultProps = {
  width: 15,
  height: 15,
  className: '',
}

export default Close
