import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { FundDetailsData } from '../../typings/FundDetailsResponse'
import fetchAllFundDetails from './actions/fetchAllFundDetails'

type AllFundState = {
  fundDetailsList: FundDetailsData[] | undefined
}

const initialState: AllFundState = {
  fundDetailsList: undefined,
}

export const allFundSlice = createSlice({
  name: 'allFund',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchAllFundDetails.fulfilled,
      (state, action: PayloadAction<FundDetailsData[] | undefined>) => {
        if (action.payload) {
          state.fundDetailsList = action.payload
        }
      }
    )
  },
})

export default allFundSlice.reducer
