// eslint-disable-next-line import/prefer-default-export
export const FILTER_MODAL = 'filter'
export const RATING_INFO_MODAL = 'ratingInfo'
export const FUND_INFO_MODAL = 'fundInfo'
export const EDIT_COST_MODAL = 'editCost'
export const COMPARE_FUNDS_MODAL = 'compareFunds'
export const EPOINTS_MODAL = 'ePoints'

export const MODAL_CONTENT_SIZE_FIT_CONTENT = 'fit-content'
export const MODAL_CONTENT_SIZE_S = 's'
export const MODAL_CONTENT_SIZE_M = 'm'
export const MODAL_CONTENT_SIZE_L = 'l'
export const MODAL_CONTENT_SIZE_DEFAULT = 'm'
export const MODAL_CONTENT_SIZE_HEIGHT_AUTO = 'height-auto'
