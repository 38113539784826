import {
  UpdateRedemptEstimateValueParams,
  UpdateRedemptEstimateValueResponse,
} from '../typings/UrlTrading'
import { appAxios, getRoute } from '.'

const fetchRedemptEstimateValue = async (
  params: UpdateRedemptEstimateValueParams
): Promise<UpdateRedemptEstimateValueResponse> => {
  const res = await appAxios({ showLoading: false }).post(
    getRoute({ route: 'updateRedemptEstimateValue' }),
    { ...params }
  )

  return {
    fundCode: params.fundCode,
    fundType: params.fundType,
    error: res.data.value === null,
    value: res.data.value,
    units: params.units,
    fxRate: res.data.fxRate,
  }
}

export default fetchRedemptEstimateValue
