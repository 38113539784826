import classnames from 'classnames'
import { FunctionComponent } from 'react'

import styles from './ProgressIndicator.module.scss'

interface ProgressIndicatorProps {
  totalSteps: number
  currentStep: number
  indicatorBarClassName?: string
  indicatorDivClassName?: string
}

const ProgressIndicator: FunctionComponent<ProgressIndicatorProps> = ({
  totalSteps,
  currentStep,
  indicatorBarClassName,
  indicatorDivClassName,
  ...props
}) => {
  return (
    <div
      className={classnames(
        styles.progressIndicator__div,
        indicatorDivClassName
      )}
      {...props}
    >
      {[...Array(totalSteps)]
        .map((_, index) => `step-${index + 1}`)
        .map((stepNo: string, index: number) => (
          <div
            data-testid='indicator-bar'
            key={stepNo}
            className={classnames(
              styles.progressIndicator__bar,
              indicatorBarClassName,
              {
                [styles.isCurrentStep]: index <= currentStep - 1,
              }
            )}
          />
        ))}
    </div>
  )
}

ProgressIndicator.defaultProps = {
  indicatorBarClassName: '',
  indicatorDivClassName: '',
}

export default ProgressIndicator
