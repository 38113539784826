import {
  PROFIT,
  RETURN_RATE,
  SORT_DEFAULT_SELECT,
  TOTAL_ASSET_VALUE,
} from '../constants/sorts'
import { HoldingFundPreviewType } from '../typings/PortfolioPie'
import { SortValueType } from '../typings/Sorts'

type SortedFunctionParams = {
  data: HoldingFundPreviewType[]
  sort?: SortValueType
}

const sorted = ({
  data,
  sort = SORT_DEFAULT_SELECT,
}: SortedFunctionParams): HoldingFundPreviewType[] => {
  let sortedData = Array.from(data)
  switch (sort) {
    case PROFIT:
      sortedData = sortedData.sort((a, b) => {
        let aReturn: number
        let bReturn: number
        if (typeof a.profit === 'string') {
          aReturn = parseFloat(a.profit.replace('%', ''))
        } else {
          aReturn = a.profit
        }
        if (typeof b.profit === 'string') {
          bReturn = parseFloat(b.profit.replace('%', ''))
        } else {
          bReturn = b.profit
        }

        return bReturn - aReturn
      })
      break
    case TOTAL_ASSET_VALUE:
      sortedData = sortedData.sort((a, b) => {
        return b.valueInDouble - a.valueInDouble
      })
      break
    case RETURN_RATE:
      sortedData = sortedData.sort((a, b) => {
        const numA = a.returnPercent.substring(0, a.returnPercent.length - 1)
        const numB = b.returnPercent.substring(0, b.returnPercent.length - 1)
        return parseFloat(numB) - parseFloat(numA)
      })
      break
    default:
      break
  }

  return sortedData
}

export default sorted
