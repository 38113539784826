export const CHEVRON_COLOR_PRIMARY = '#096183'
export const CHEVRON_COLOR_LIGHT = '#3E3E3E'
export const CHEVRON_COLOR_DARK = '#1C1C1C'
export const CHEVRON_COLOR_WHITE = '#FFFFFF'
export const CHEVRON_COLOR_DISABLED = '#8c8e90'
export const CHEVRON_ORIENTATION_UP = 'up'
export const CHEVRON_ORIENTATION_DOWN = 'down'
export const CHEVRON_ORIENTATION_LEFT = 'left'
export const CHEVRON_ORIENTATION_RIGHT = 'right'

export const CHEVRON_DEFAULT_COLOR = CHEVRON_COLOR_DARK
