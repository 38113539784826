import { FunctionComponent } from 'react'

type MessageIconProps = {
  width?: number
  height?: number
  className?: string
  color?: string
}

const MessageIcon: FunctionComponent<MessageIconProps> = ({
  width,
  height,
  className,
  color,
  ...props
}: MessageIconProps) => (
  <svg
    width={width}
    height={height}
    className={className}
    {...props}
    viewBox='0 0 18 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M16.3125 0H1.68751C0.75696 0 0 0.756961 0 1.68751V11.8125C0 12.7431 0.75696 13.5 1.68751 13.5H16.3125C17.243 13.5 18 12.7431 18 11.8125V1.68751C18 0.756961 17.243 0 16.3125 0ZM16.3125 1.125C16.3889 1.125 16.4616 1.14085 16.5281 1.16854L9 7.69319L1.47188 1.16854C1.53834 1.14089 1.61105 1.125 1.68747 1.125H16.3125ZM16.3125 12.375H1.68751C1.37715 12.375 1.125 12.1229 1.125 11.8125V2.35711L8.6314 8.86267C8.73743 8.95439 8.86872 9 9 9C9.13128 9 9.26256 8.95442 9.3686 8.86267L16.875 2.35711V11.8125C16.875 12.1229 16.6228 12.375 16.3125 12.375Z'
      fill={color}
    />
  </svg>
)

MessageIcon.defaultProps = {
  width: 18,
  height: 14,
  className: '',
  color: '#3E3E3E',
}

export default MessageIcon
