import { FunctionComponent } from 'react'

import { IconProps } from '../../typings/Icon'
import { ICON_CTA_COLOR } from './constants'

const InfoIcon: FunctionComponent<IconProps> = ({
  width,
  height,
  className,
  ...props
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      {...props}
      viewBox='0 0 3 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.24967 2.46964C0.938504 2.46964 0.670262 2.38211 0.444938 2.20706C0.230344 2.02171 0.123047 1.76428 0.123047 1.43477C0.123047 1.11556 0.230344 0.863276 0.444938 0.677927C0.670262 0.492577 0.938504 0.399902 1.24967 0.399902C1.5501 0.399902 1.80761 0.492577 2.0222 0.677927C2.24753 0.863276 2.36019 1.11556 2.36019 1.43477C2.36019 1.76428 2.24753 2.02171 2.0222 2.20706C1.80761 2.38211 1.5501 2.46964 1.24967 2.46964ZM0.300087 3.61263H2.18315V11.7526H0.300087V3.61263Z'
        fill={ICON_CTA_COLOR}
      />
    </svg>
  )
}

InfoIcon.defaultProps = {
  width: 3,
  height: 12,
  className: '',
}

export default InfoIcon
