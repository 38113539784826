import classnames from 'classnames'
import { FunctionComponent, HTMLAttributes } from 'react'
import { Link } from 'react-router-dom'

import {
  TAB_ACTIVE_STYLES_DEFAULT,
  TAB_ACTIVE_STYLES_HIGHLIGHT,
  TAB_ACTIVE_STYLES_UNDERLINE,
  TAB_THEME_DEFAULT,
  TAB_THEME_PRIMARY,
  TAB_THEME_SECONDARY,
  TAB_THEME_TERTIARY,
} from '../../constants/tab'
import styles from './Tab.module.scss'

interface TabProps extends HTMLAttributes<HTMLDivElement> {
  href?: string
  className?: string
  active?: boolean
  activeStyle?:
    | typeof TAB_ACTIVE_STYLES_HIGHLIGHT
    | typeof TAB_ACTIVE_STYLES_UNDERLINE
  navItem?: boolean
  theme?:
    | typeof TAB_THEME_PRIMARY
    | typeof TAB_THEME_SECONDARY
    | typeof TAB_THEME_TERTIARY
}

const Tab: FunctionComponent<TabProps> = ({
  children,
  active,
  activeStyle,
  href = undefined,
  className,
  navItem,
  theme,
  ...props
}: TabProps) => {
  const classNames = classnames(styles.tab, className, {
    [styles.underline]: activeStyle === TAB_ACTIVE_STYLES_UNDERLINE,
    [styles.blue]: theme === TAB_THEME_PRIMARY,
    [styles.violet]: theme === TAB_THEME_SECONDARY,
    [styles.darkblue]: theme === TAB_THEME_TERTIARY,
    [styles.active]: active,
    [styles.navItem]: navItem,
  })

  if (href) {
    return (
      <div className={classNames} {...props}>
        <Link to={href}>{children}</Link>
      </div>
    )
  }
  return (
    <div className={classNames} {...props}>
      {children}
    </div>
  )
}

Tab.defaultProps = {
  href: undefined,
  className: '',
  active: false,
  activeStyle: TAB_ACTIVE_STYLES_DEFAULT,
  navItem: false,
  theme: TAB_THEME_DEFAULT,
}

export default Tab
