import { INVESTMENT_TIER_TW } from '../constants/tier-info'
import { TierInfoType } from '../typings/TierInfo'

const getInvestmentTier = (
  investmentTotal: number
): TierInfoType | undefined => {
  const allTierInfo = INVESTMENT_TIER_TW.sort(
    (a, b) => b.tierAmount - a.tierAmount
  )

  const eligibleTier: TierInfoType | undefined = allTierInfo.find(
    (tierInfo: TierInfoType) => investmentTotal > tierInfo.tierAmount
  )

  return eligibleTier
}

export default getInvestmentTier
