import classnames from 'classnames'
import { FunctionComponent, ReactNode, useEffect } from 'react'
import { useLocation } from 'react-router'

import Footer from '../../components/footer'
import Header from '../../components/header'
// import Navigation from '../../components/navigation'
import NavigationLegacy from '../../components/navigation-legacy'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { fetchUser } from '../../redux/user/actions'
import routes from '../../routes/SiteNavigation'
import { RootState } from '../../store'
import Main from '../main'
import styles from './Page.module.scss'

interface PageProps {
  children: ReactNode
  withHeader?: boolean
  options?: {
    headerComponent?: ReactNode | undefined
  }
  withFooter?: boolean
  className?: string
}

const Page: FunctionComponent<PageProps> = ({
  withFooter,
  withHeader = true,
  options: { headerComponent = undefined } = {},
  children,
  className,
  ...props
}: PageProps) => {
  const HeaderComponent = headerComponent as FunctionComponent
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const { messages } = useAppSelector((state: RootState) => state.inbox)

  /* Load and put data onto the reducer */
  useEffect(() => {
    if (!pathname.includes(routes.login)) {
      dispatch(fetchUser())
    }
  }, [dispatch, pathname])

  return (
    <div {...props} className={classnames(styles.page, className)}>
      {withHeader && (
        <Header>
          {headerComponent ? (
            <HeaderComponent />
          ) : (
            <NavigationLegacy showInboxAlert={messages.length > 0} />
          )}
        </Header>
      )}
      <Main withHeader={withHeader}>{children}</Main>
      {withFooter && <Footer />}
    </div>
  )
}

Page.defaultProps = {
  withFooter: true,
  withHeader: true,
  className: '',
  options: {
    headerComponent: undefined,
  },
}

export default Page
