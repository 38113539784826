import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { LoginValidationType } from '../../typings/LoginCredentials'
import validateLoginCredentials from './actions/validateLoginCredentials'

export interface LoginValidationState {
  loading: boolean
  error: boolean
  errorMessage:
    | {
        [locale: string]: string
      }
    | undefined
  succeed: boolean
}

const initialState: LoginValidationState = {
  loading: false,
  error: false,
  errorMessage: undefined,
  succeed: false,
}

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(validateLoginCredentials.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      validateLoginCredentials.fulfilled,
      (state, action: PayloadAction<LoginValidationType>) => {
        // eslint-disable-next-line no-param-reassign
        const { error, errorMessage, succeed } = action.payload
        state.error = error
        state.errorMessage = errorMessage
        state.succeed = succeed
        state.loading = false
      }
    )
  },
})

export default loginSlice.reducer
