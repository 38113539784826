import classnames from 'classnames'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import {
  CLIENT_RISK_LEVEL_1_HK,
  CLIENT_RISK_LEVEL_1_TW,
  CLIENT_RISK_LEVEL_2_HK,
  CLIENT_RISK_LEVEL_2_TW,
  CLIENT_RISK_LEVEL_3_HK,
  CLIENT_RISK_LEVEL_3_TW,
  CLIENT_RISK_LEVEL_4_HK,
  CLIENT_RISK_LEVEL_5_HK,
  RISK_BAR_VARIANT_CLIENT_RISK,
  RISK_BAR_VARIANT_PORTFOLIO_RISK,
} from '../../../../constants/risk-rating'
import { getCurrentCountry } from '../../../../helpers/countriesSpecific'
import { UserCountryType } from '../../../../typings/UserInfo'
import styles from '../RiskBar.module.scss'
import RiskRatingBar from './RiskRatingBar'

interface BarProps {
  variant:
    | typeof RISK_BAR_VARIANT_CLIENT_RISK
    | typeof RISK_BAR_VARIANT_PORTFOLIO_RISK
  riskLevel: string
  country: UserCountryType
}

const Bar: FunctionComponent<BarProps> = ({
  variant,
  riskLevel,
  country,
  ...props
}: BarProps) => {
  const { t } = useTranslation()
  const { isHK } = getCurrentCountry()

  const calculateValue = (): { progress: number; labelPosition: number } => {
    if (isHK) {
      switch (riskLevel.toLowerCase()) {
        case CLIENT_RISK_LEVEL_5_HK:
          return {
            progress: 100,
            labelPosition: 100,
          }
        case CLIENT_RISK_LEVEL_4_HK:
          return {
            progress: 80,
            labelPosition: 70,
          }
        case CLIENT_RISK_LEVEL_3_HK:
          return {
            progress: 60,
            labelPosition: 50,
          }
        case CLIENT_RISK_LEVEL_2_HK:
          return {
            progress: 40,
            labelPosition: 30,
          }
        case CLIENT_RISK_LEVEL_1_HK:
          return {
            progress: 20,
            labelPosition: 0,
          }
        default:
          return {
            progress: 0,
            labelPosition: 0,
          }
      }
    } else {
      switch (riskLevel.toLowerCase()) {
        case CLIENT_RISK_LEVEL_3_TW:
          return {
            progress: 100,
            labelPosition: 85,
          }
        case CLIENT_RISK_LEVEL_2_TW:
          return {
            progress: 66.7,
            labelPosition: 50,
          }
        case CLIENT_RISK_LEVEL_1_TW:
          return {
            progress: 33.3,
            labelPosition: 16.5,
          }
        default:
          return {
            progress: 0,
            labelPosition: 0,
          }
      }
    }
  }
  return (
    <div
      className={styles.riskBarContainer}
      {...props}
      data-testid={`${variant}-bar`}
    >
      <RiskRatingBar
        variant={variant}
        value={calculateValue().progress}
        country={country}
      />
      <div
        className={classnames(styles.riskLabel, {
          [styles.riskLabel__portfolioRiskRating]:
            variant === RISK_BAR_VARIANT_PORTFOLIO_RISK,
          [styles.riskLabel__leftMargin]:
            riskLevel.toLowerCase() === CLIENT_RISK_LEVEL_1_HK,
          [styles.riskLabel__rightMargin]:
            riskLevel.toLowerCase() === CLIENT_RISK_LEVEL_5_HK,
        })}
        style={{ left: `${calculateValue().labelPosition}%` }}
        data-testid={`risk-bar-${variant}-label-container`}
      >
        <span data-testid={`${variant}-label`}>
          {t(`riskRating:risk-rating-bar-label-${variant}`)}
        </span>
        <span className={styles.riskValue} data-testid={`${variant}-value`}>
          {t(`riskRating:client-risk-rating-${riskLevel.toLowerCase()}`)}
        </span>
      </div>
    </div>
  )
}

export default Bar
