import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import Modal from '../../../components/modal'
import OccupationInstruction from '../../../components/modal/occupation-instruction'
import PoliticalRelationship from '../../../components/modal/political-relationship'
import {
  MODAL_CONTENT_SIZE_L,
  MODAL_CONTENT_SIZE_S,
} from '../../../constants/modal-elements'
import { useAppMediaQuery } from '../../../hooks'
import {
  FORM_INSTRUCTION_OCCUPATION,
  FORM_INSTRUCTION_POLITICAL_RELATIONSHIP,
  FormInstructionModalLabel,
} from '../../../typings/GeneralSuitability'
import styles from './FormInstructionModal.module.scss'

// 填寫說明MODAL
// currently there are only two modals (i.e 職稱填寫說明 & 政治人物填寫說明)

interface FormInstructionModalProps {
  label: FormInstructionModalLabel // determine modal content
}

const labelTranslationKey = {
  [FORM_INSTRUCTION_OCCUPATION]: 'instruction-occupation.label',
  [FORM_INSTRUCTION_POLITICAL_RELATIONSHIP]:
    'instruction-political-relationship.label',
}
const FormInstructionModal: FunctionComponent<FormInstructionModalProps> = ({
  label,
}: FormInstructionModalProps) => {
  const { t } = useTranslation()
  const { isLgUp } = useAppMediaQuery()
  return (
    <div>
      <Modal
        modalContentSize={
          isLgUp && label === FORM_INSTRUCTION_OCCUPATION
            ? MODAL_CONTENT_SIZE_L
            : MODAL_CONTENT_SIZE_S
        }
        button={(onOpenHandler) => (
          <button
            data-testid='form-instruction-modal-label'
            onClick={onOpenHandler}
            className={styles.formInstructionModal__labelButton}
          >
            {t(`modal:${labelTranslationKey[label]}`)}
          </button>
        )}
        // options={options}
        modalElement={() =>
          label === FORM_INSTRUCTION_OCCUPATION ? (
            <OccupationInstruction />
          ) : (
            <PoliticalRelationship />
          )
        }
      />
    </div>
  )
}

export default FormInstructionModal
