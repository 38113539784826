import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootStateOrAny } from 'react-redux'

import { appAxios, getRoute } from '../../../helpers'
import {
  transformFundPreviewDataHK,
  transformFundPreviewDataTW,
} from '../../../helpers/transformFundPreviewData'
import {
  FundDetailsResponseData,
  FundDetailsResponseDataTW,
} from '../../../typings/FundDetails'
import {
  FundPreviewResponseData,
  FundPreviewType,
  FundPriceData,
} from '../../../typings/FundPreview'
import { UserCountryType } from '../../../typings/UserInfo'

const transformResponseData = (
  responseData: FundPreviewResponseData[],
  fundDetails: FundDetailsResponseData[] | FundDetailsResponseDataTW[],
  fundPriceData: FundPriceData[],
  country: UserCountryType
): FundPreviewType[] => {
  if (country === 'HK') {
    return transformFundPreviewDataHK(
      responseData,
      fundDetails as FundDetailsResponseData[],
      fundPriceData
    )
  }
  return transformFundPreviewDataTW(
    responseData,
    fundDetails as FundDetailsResponseDataTW[],
    fundPriceData
  )
}

const loadRecentlyViewed = createAsyncThunk<FundPreviewType[]>(
  'loadRecentlyViewed',
  async (_, { getState }) => {
    const state: RootStateOrAny = getState()
    const { country } = state.system

    const res = await appAxios().get(`${getRoute({ route: `recentlyViewed` })}`)
    const fundList = res.data.list.map(
      (list: FundPreviewResponseData) => list.fundInfo.code
    )
    const fundPriceData: FundPriceData[] = await Promise.all(
      fundList.map(async (fundCode: string) => {
        const fundPriceRes = await appAxios().get(
          `${getRoute({ route: `fundPrice` })}`,
          { params: { code: fundCode } }
        )
        return {
          fundCode,
          bid: fundPriceRes.data.list[0].bid,
          date: fundPriceRes.data.list[0].date,
          baseCurrency: fundPriceRes.data.list[0].baseCurrency,
        }
      })
    )

    const allFundsDetails = await appAxios().get(
      `${getRoute({ route: `fund` })}`
    )

    return transformResponseData(
      res.data.list,
      allFundsDetails.data.list,
      fundPriceData,
      country
    )
  }
)

export default loadRecentlyViewed
