import classnames from 'classnames'
import { FunctionComponent, useState } from 'react'
import { Dropdown as BootstrapDropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'

import { CHEVRON_ORIENTATION_DOWN } from '../../constants/chevron'
import { RouteType } from '../../typings/Routes'
import { TranslationIndexNameType } from '../../typings/TranslationIndex'
import { ChevronIcon, Icon } from '../icons'
import styles from './DropdownLegacy.module.scss'

interface DropdownLegacyProps {
  list: RouteType
  activeIndex: number
  translationIndexName: TranslationIndexNameType
  className?: string
  withIcon?: boolean
  fontSize?: string
  menuItemClass?: string
}

const DropdownLegacy: FunctionComponent<DropdownLegacyProps> = ({
  list,
  activeIndex,
  translationIndexName,
  className,
  withIcon,
  fontSize,
  menuItemClass,
  ...props
}: DropdownLegacyProps) => {
  const { t } = useTranslation()
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

  // Menu handling
  const openMenu = () => setIsMenuOpen(true)
  const closeMenu = () => setIsMenuOpen(false)
  return (
    <BootstrapDropdown
      bsPrefix={styles.dropdownDiv}
      className={classnames(className, {
        [styles.fontSizeSmall]: fontSize === 'small',
      })}
      onMouseEnter={openMenu}
      onMouseLeave={closeMenu}
      show={isMenuOpen}
      {...props}
    >
      <BootstrapDropdown.Toggle as='button' bsPrefix={styles.dropdownButton}>
        <span className={className}>
          {t(`${translationIndexName}:${list.translationKeyName}`)}
        </span>
        {withIcon && (
          <Icon className={styles.dropdownIcon} data-testid='dropdown-button'>
            <ChevronIcon orientation={CHEVRON_ORIENTATION_DOWN} />
          </Icon>
        )}
      </BootstrapDropdown.Toggle>
      <BootstrapDropdown.Menu
        bsPrefix={classnames(styles.dropdownContent, menuItemClass)}
      >
        {list.subMenu?.map((choice: RouteType, index: number) => (
          <BootstrapDropdown.Item
            className={classnames(
              styles.menuItem,
              styles.dropdownItem,
              {
                [styles.active]: activeIndex === index,
              },
              menuItemClass
            )}
            key={choice.translationKeyName}
            id={choice.translationKeyName}
            href={generatePath(choice.routeName)}
          >
            <span className={styles.menuItemText}>
              {t(`${translationIndexName}:${choice.translationKeyName}`)}
            </span>
          </BootstrapDropdown.Item>
        ))}
      </BootstrapDropdown.Menu>
    </BootstrapDropdown>
  )
}

DropdownLegacy.defaultProps = {
  className: '',
  withIcon: false,
  fontSize: 'normal',
  menuItemClass: '',
}

export default DropdownLegacy
