import { RedemptionResponseType } from '../typings/UrlTrading'
import { appAxios, getRoute } from '.'
import { getCountry } from './countriesSpecific'

/**
 * Redmption response data TW
 */

export interface RedemptionHKResponseType {
  list: RedemptionResponseType[]
}

export interface RedemptionTWResponseType {
  list: RedemptionResponseType[]
}

const transformRedemptionDataListHK = (
  responseData: RedemptionHKResponseType
): RedemptionHKResponseType => {
  return responseData
}

const transformRedemptionDataListTW = (
  responseData: RedemptionTWResponseType
): RedemptionTWResponseType => {
  return responseData
}

const transformRedemptionDataList = (
  responseData: RedemptionTWResponseType | RedemptionHKResponseType
) => {
  const country = getCountry()

  if (country === 'TW') {
    return transformRedemptionDataListTW(responseData)
  }
  return transformRedemptionDataListHK(responseData)
}

const fetchUrlTradingRedemptionList = async (): Promise<
  RedemptionTWResponseType | RedemptionHKResponseType
> => {
  const redemptionInfoResponse = await appAxios().get(
    getRoute({ route: 'redemptionInformation' })
  )

  const responseData = redemptionInfoResponse.data
  return transformRedemptionDataList(responseData)
}

export default fetchUrlTradingRedemptionList
