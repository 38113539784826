import classnames from 'classnames'
import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react'

import { GeneralSuitabilityQuestion } from '../../typings/GeneralSuitability'
import styles from '../text-field/TextField.module.scss'

interface ComboTextFieldProps {
  extraDisplayOptions: GeneralSuitabilityQuestion['extraDisplayOptions']
  onFocusHandler: (option: string) => void
  handleTextInputChange: (
    e: ChangeEvent<HTMLInputElement>,
    option: string
  ) => void
  error: boolean
  option: string
  value: string
}
const ComboTextField: FunctionComponent<ComboTextFieldProps> = ({
  extraDisplayOptions,
  onFocusHandler,
  handleTextInputChange,
  error,
  value,
  option,
}) => {
  const [textInput, setTextInput] = useState<string>('')
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setTextInput(e.target.value)
    handleTextInputChange(e, option)
  }
  useEffect(() => {
    if (!value) {
      setTextInput('')
    }
    setTextInput(value as string)
  }, [value])

  if (!extraDisplayOptions) return null
  const { displayTextFieldOnAnswer } = extraDisplayOptions
  if (
    displayTextFieldOnAnswer.includes(option) ||
    displayTextFieldOnAnswer === option
  ) {
    return (
      <div
        className={classnames(
          styles.userInput__inputDiv,
          styles.comboTextField,
          {
            [styles.userInput]: error,
          }
        )}
      >
        <input
          type='text'
          onFocus={() => {
            onFocusHandler(option)
          }}
          onChange={onChangeHandler}
          value={textInput || ''}
        />
      </div>
    )
  }

  return null
}

export default ComboTextField
