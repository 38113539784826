import classnames from 'classnames'
import { FunctionComponent, ReactNode, useState } from 'react'
import { Card } from 'react-bootstrap'

import { BUTTON_SIZE_SMALL } from '../../constants/button'
import {
  CHEVRON_COLOR_PRIMARY,
  CHEVRON_ORIENTATION_DOWN,
  CHEVRON_ORIENTATION_UP,
} from '../../constants/chevron'
import Button from '../button'
import { ChevronIcon } from '../icons'
import styles from './FormReviewAccordion.module.scss'

interface FormReviewAccordionProps {
  defaultExpanded?: boolean
  accordionClassName?: string
  header: string
  subHeader?: string
  editButtonCallBack?: () => void
  children: ReactNode
}

const FormReviewAccordion: FunctionComponent<FormReviewAccordionProps> = ({
  defaultExpanded,
  accordionClassName,
  header,
  subHeader,
  editButtonCallBack,
  children,
  ...props
}: FormReviewAccordionProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(
    defaultExpanded || false
  )
  return (
    <Card
      as='div'
      className={classnames(accordionClassName, styles.formReviewAccordion, {
        [styles.expanded]: isExpanded,
      })}
      {...props}
    >
      <Card.Header
        data-testid='form-accordion-header'
        className={styles.formReviewAccordion__headerDiv}
      >
        <span className={styles.formReviewAccordion__headerText}>{header}</span>
        {editButtonCallBack && (
          <Button
            data-testid='form-accordion-button'
            size={BUTTON_SIZE_SMALL}
            outlined
            onClick={editButtonCallBack}
            className={styles.formReviewAccordion__headerButton}
          >
            修改
          </Button>
        )}
      </Card.Header>
      <Card.Body data-testid='form-accordion-body'>
        {subHeader && <span>{subHeader}</span>}
        {children}
      </Card.Body>
      {!isExpanded && (
        <div
          className={styles.collapseMask}
          data-testid='form-accordion-collapse-mask'
        />
      )}
      <Card.Footer bsPrefix={classnames(styles.formReviewAccordion__buttonDiv)}>
        <button
          data-testid='form-accordion-toggle-expand-button'
          className={classnames(styles.formReviewAccordion__expandButton)}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <span>{isExpanded ? '更少' : '更多'}</span>
          <ChevronIcon
            color={CHEVRON_COLOR_PRIMARY}
            orientation={
              isExpanded ? CHEVRON_ORIENTATION_UP : CHEVRON_ORIENTATION_DOWN
            }
          />
        </button>
      </Card.Footer>
    </Card>
  )
}

FormReviewAccordion.defaultProps = {
  defaultExpanded: false,
  accordionClassName: '',
  subHeader: undefined,
  editButtonCallBack: undefined,
}

export default FormReviewAccordion
