import { createAsyncThunk } from '@reduxjs/toolkit'

import updateInitialSortedFunds from '../../../fund-filter/actions/updateInitialSortedFunds'

const testLoadEmptyPortfolio = createAsyncThunk(
  'testLoadEmptyPortfolio',
  async (_, { dispatch }) => {
    dispatch(updateInitialSortedFunds([]))

    return {
      data: {
        portfolioTotalCost: '',
        portfolioTotalValue: {
          string: '',
          double: 0,
        },
        portfolioReturnPercent: '',
        portfolioProfit: '',
        fundList: [],
        portfolioSummaryBreakdown: null,
      },
    }
  }
)

export default testLoadEmptyPortfolio
