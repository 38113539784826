import classnames from 'classnames'
import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  FunctionComponent,
} from 'react'
import { Link } from 'react-router-dom'

import {
  BUTTON_SIZE_DEFAULT,
  BUTTON_SIZE_LARGE,
  BUTTON_SIZE_MEDIUM,
  BUTTON_SIZE_SMALL,
  BUTTON_THEME_DEFAULT,
  BUTTON_THEME_OUTLINED,
  BUTTON_THEME_PRIMARY,
} from '../../constants/button'
import styles from './Button.module.scss'

type ButtonTypes = HTMLAnchorElement | HTMLButtonElement

type ButtonSizeType =
  | typeof BUTTON_SIZE_SMALL
  | typeof BUTTON_SIZE_MEDIUM
  | typeof BUTTON_SIZE_LARGE

type ButtonTheme = typeof BUTTON_THEME_PRIMARY | typeof BUTTON_THEME_OUTLINED

export interface ButtonProps<T extends ButtonTypes>
  extends AnchorHTMLAttributes<T>,
    ButtonHTMLAttributes<T> {
  size?: ButtonSizeType
  fullWidth?: boolean
  outlined?: boolean
  theme?: ButtonTheme
  className?: string
  href?: string
  type?: ButtonHTMLAttributes<T>['type']
}

const Button: FunctionComponent<ButtonProps<ButtonTypes>> = <
  T extends ButtonTypes
>({
  outlined,
  theme,
  children,
  className,
  size,
  fullWidth,
  href,
  ...otherProps
}: ButtonProps<T>) => {
  const props: ButtonProps<T> = {
    className: classnames(
      { [styles.small]: size === BUTTON_SIZE_SMALL },
      { [styles.medium]: size === BUTTON_SIZE_MEDIUM },
      { [styles.large]: size === BUTTON_SIZE_LARGE },
      { [styles.fullWidth]: fullWidth },
      { [styles.outlined]: theme === BUTTON_THEME_OUTLINED || outlined },
      { [styles.primary]: theme === BUTTON_THEME_PRIMARY },
      {
        [styles.disabled]: otherProps.disabled,
      },
      className
    ),
    ...otherProps,
  }

  if (href) {
    if (href.includes('https://') || href.includes('http://')) {
      return (
        <a {...(props as AnchorHTMLAttributes<HTMLAnchorElement>)} href={href}>
          {children}
        </a>
      )
    }
    return (
      <Link to={href} {...(props as AnchorHTMLAttributes<HTMLAnchorElement>)}>
        {children}
      </Link>
    )
  }

  return (
    <button {...(props as ButtonHTMLAttributes<HTMLButtonElement>)}>
      {children}
    </button>
  )
}
Button.defaultProps = {
  outlined: false,
  theme: BUTTON_THEME_DEFAULT,
  size: BUTTON_SIZE_DEFAULT,
  className: '',
  fullWidth: false,
}

export default Button
