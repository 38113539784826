import { FunctionComponent, useCallback } from 'react'
import { Modal as BootstrapModal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import Button from '../../../components/button'
import LoadingModal from '../../../components/modal/loading'
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '../../../components/modal/modal-components/ModalComponents'
import { MODAL_CONTENT_SIZE_HEIGHT_AUTO } from '../../../constants/modal-elements'
import { GeneralSuitabililtyState } from '../../../redux/general-suitability/generalSuitabilitySlice'
import {
  RiskLevel,
  STATUS_INCOMPLETE,
} from '../../../typings/GeneralSuitability'
import styles from './FormResultFeedback.module.scss'

interface FormResultFeedbackProps {
  goToEnhancedSuitability: () => void // callback function when user click on go to enhanced suitability questionniare button
  onClose: () => void // callback function when user close the modal
  enhancedSuitabilityStatus: GeneralSuitabililtyState['enhancedSuitabilityStatus'] // user enhancedSuitabiilityStatus will determine the content of modal
  isLoading: boolean // when questionniare sent to API and not yet get back the result, show loading modal
  riskLevel: RiskLevel // riskLevel determine the content of modal
}
const FormResultFeedback: FunctionComponent<FormResultFeedbackProps> = ({
  goToEnhancedSuitability,
  onClose,
  enhancedSuitabilityStatus,
  isLoading,
  riskLevel,
}) => {
  const { t } = useTranslation()
  const riskLevelT = t(`riskRating:client-risk-rating-${riskLevel}`)

  const renderModalTitle = useCallback(() => {
    if (enhancedSuitabilityStatus === STATUS_INCOMPLETE) {
      return t('generalSuitability:result-modal.es-incomplete.title')
    }
    return t('generalSuitability:result-modal.es-completed.title')
  }, [enhancedSuitabilityStatus, t])

  const renderModalContent = useCallback(() => {
    if (enhancedSuitabilityStatus === STATUS_INCOMPLETE) {
      return t('generalSuitability:result-modal.es-incomplete.content', {
        level: riskLevelT,
      })
    }
    return t('generalSuitability:result-modal.es-completed.content')
  }, [enhancedSuitabilityStatus, t, riskLevelT])

  const renderModalFooter = useCallback(() => {
    return (
      <>
        <Button
          outlined
          onClick={onClose}
          className={styles.feedbackModal__button}
        >
          {t('generalSuitability:result-modal.es-completed.button-confirm')}
        </Button>
        {enhancedSuitabilityStatus === STATUS_INCOMPLETE && (
          <Button
            onClick={goToEnhancedSuitability}
            className={styles.feedbackModal__button}
          >
            {t('generalSuitability:result-modal.es-incomplete.button-go-to-es')}
          </Button>
        )}
      </>
    )
  }, [goToEnhancedSuitability, onClose, enhancedSuitabilityStatus, t])

  if (isLoading) return <LoadingModal />

  return (
    <BootstrapModal
      onHide={onClose}
      show
      centered
      backdrop
      contentClassName={styles.feedbackModal}
    >
      <>
        <ModalHeader title={renderModalTitle()} closeModalHandler={onClose} />
        <ModalBody
          contentHeight={
            enhancedSuitabilityStatus !== STATUS_INCOMPLETE
              ? MODAL_CONTENT_SIZE_HEIGHT_AUTO
              : undefined
          }
        >
          <p>{renderModalContent()}</p>
        </ModalBody>
        <ModalFooter className={styles.feedbackModal__footer}>
          {renderModalFooter()}
        </ModalFooter>
      </>
    </BootstrapModal>
  )
}
export default FormResultFeedback
