import classnames from 'classnames'
import { FunctionComponent, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'

import { ZH_HK, ZH_TW } from '../../constants/langauge-code'
import routes from '../../routes/SiteNavigation'
import { PORTFOLIO_VIEW_DEFAULT } from '../../widgets/my-portfolio/portfolio/constants'
import styles from './Logo.module.scss'
import LogoEN from './logo-en'
import LogoZH from './logo-zh'

interface LogoProps {
  logoClassName?: string
  isClickable?: boolean
}

const Logo: FunctionComponent<LogoProps> = ({
  isClickable,
  logoClassName,
}: LogoProps) => {
  const { i18n } = useTranslation()
  const { language: currentLanguage } = i18n
  const history = useHistory()

  const backToHomePage = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    history.push({
      pathname: routes.home,
      // passing the state to portfolio page to render portfolio default view
      // for showcase purpose, it could skip if it is going to portfolio default section
      state: {
        currentView: PORTFOLIO_VIEW_DEFAULT,
      },
    })
  }

  if (isClickable) {
    return (
      <Link
        to={routes.home}
        onClick={backToHomePage}
        className={classnames(styles.container, logoClassName)}
        data-testid='logo-container'
      >
        {currentLanguage.includes(ZH_TW) || currentLanguage.includes(ZH_HK) ? (
          <LogoZH />
        ) : (
          <LogoEN />
        )}
      </Link>
    )
  }

  return (
    <div
      className={classnames(styles.container, logoClassName)}
      data-testid='logo-container'
    >
      {currentLanguage.includes(ZH_TW) || currentLanguage.includes(ZH_HK) ? (
        <LogoZH />
      ) : (
        <LogoEN />
      )}
    </div>
  )
}

Logo.defaultProps = {
  isClickable: true,
  logoClassName: '',
}

export default Logo
