import { createAsyncThunk } from '@reduxjs/toolkit'

const submitSpecialCriteriaOption = createAsyncThunk<
  {
    shouldShowFeedbackModal: boolean
  },
  string
>('submitSpecialCriteriaOption', async (selectedOption) => {
  // Submit special criteria option
  // DO Something with answer
  // eslint-disable-next-line no-console
  console.log(selectedOption)

  return {
    shouldShowFeedbackModal: true,
  }
})

export default submitSpecialCriteriaOption
