import classnames from 'classnames'
import { FunctionComponent } from 'react'

import { BUTTON_THEME_OUTLINED } from '../../../constants/button'
import { ModalProps, OnCloseHandlerType } from '../../../typings/Modal'
import Button from '../../button'
import Modal from '../Modal'
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '../modal-components/ModalComponents'
import styles from './ConfirmationModal.module.scss'

type ConfirmationModalType = {
  button?: ModalProps['button']
  options?: ModalProps['options']
  modalTitle: string
  modalContent: JSX.Element
  mdoalConfirmButtonText: string
  mdoalCancelButtonText: string
  onConfirmButtonClick?: () => void
  onCancelButtonClick?: () => void
  modalHeaderClassName?: string
  modalBodyClassName?: string
  modalContentClassNames?: string
  modalFooterClassName?: string
}

const ConfirmationModal: FunctionComponent<ConfirmationModalType> = ({
  button,
  options,
  modalTitle,
  modalContent,
  mdoalConfirmButtonText,
  mdoalCancelButtonText,
  onConfirmButtonClick,
  onCancelButtonClick,
  modalHeaderClassName = '',
  modalBodyClassName = '',
  modalContentClassNames = '',
  modalFooterClassName = '',
}): JSX.Element => {
  return (
    <Modal
      button={button}
      options={options}
      modalElement={(onCloseHandler: OnCloseHandlerType) => (
        <>
          <ModalHeader title={modalTitle} className={modalHeaderClassName} />
          <ModalBody
            modalBodyClassNames={classnames(styles.body, modalBodyClassName)}
            modalContentClassNames={classnames(
              styles.content,
              modalContentClassNames
            )}
          >
            {modalContent}
          </ModalBody>
          <ModalFooter
            className={classnames(styles.footer, modalFooterClassName)}
          >
            <Button
              theme={BUTTON_THEME_OUTLINED}
              onClick={() => {
                onCancelButtonClick?.()
                onCloseHandler()
              }}
              className={styles.cancelButton}
            >
              {mdoalCancelButtonText}
            </Button>
            <Button
              onClick={() => {
                onConfirmButtonClick?.()
                onCloseHandler()
              }}
            >
              {mdoalConfirmButtonText}
            </Button>
          </ModalFooter>
        </>
      )}
      modalContentSize='s'
    />
  )
}

ConfirmationModal.defaultProps = {
  button: undefined,
  options: undefined,
  modalHeaderClassName: '',
  modalBodyClassName: '',
  modalContentClassNames: '',
  modalFooterClassName: '',
  onConfirmButtonClick: undefined,
  onCancelButtonClick: undefined,
}

export default ConfirmationModal
