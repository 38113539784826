import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { UserCountryType } from '../../typings/UserInfo'

export interface UserState {
  country: UserCountryType
}

const initialState: UserState = {
  country: (process.env.REACT_APP_COUNTRY as UserCountryType) || 'HK',
}

export const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    updateCountry: (state, action: PayloadAction<UserCountryType>) => {
      state.country = action.payload
    },
  },
})

export default systemSlice.reducer
