import { createAsyncThunk } from '@reduxjs/toolkit'
import _groupBy from 'lodash/groupBy'
import { RootStateOrAny } from 'react-redux'

import { appAxios, getRoute } from '../../../helpers'
import { CoolKnowledgeDataType } from '../../../typings/CoolKnowledge'
import { UserCountryType } from '../../../typings/UserInfo'

export type SmartTipsHKAPIResponseType = {
  tips: string
  tipsURL: string
  tipsTarget: string
  descText: string
  position: string
  locale: string
  image: string | null
}

export type CoolKnowledgeHKAPIResponse = {
  smartTips: SmartTipsHKAPIResponseType[]
}

type CoolKnowledgeTWAPIResponse = {
  list: CoolKnowledgeDataType[]
}

type CoolKnowledgeAPIResponse =
  | CoolKnowledgeHKAPIResponse
  | CoolKnowledgeTWAPIResponse

const transformResponseDataHK = (
  responseData: CoolKnowledgeHKAPIResponse
): CoolKnowledgeDataType[] => {
  const groupedByPosition = _groupBy(responseData.smartTips, 'position')
  const coolKnowledgeList: CoolKnowledgeDataType[] = []
  Object.entries(groupedByPosition).forEach(([position, smartTipsList]) => {
    const enUSData = smartTipsList.filter(
      (smartTips) => smartTips.locale === 'en_US'
    )[0]
    const zhHKData = smartTipsList.filter(
      (smartTips) => smartTips.locale === 'zh_HK'
    )[0]
    const coolKnowledgeObject: CoolKnowledgeDataType = {
      code: position,
      locale: {
        en_US: {
          header: enUSData.tips || '',
          link: enUSData.tipsURL || undefined,
          target:
            enUSData.tipsTarget === '_new'
              ? '_blank'
              : enUSData.tipsTarget || '_blank',
          description: enUSData.descText || '',
          image: enUSData.image || undefined,
        },
        zh_HK: {
          header: zhHKData.tips || '',
          link: zhHKData.tipsURL || undefined,
          target:
            zhHKData.tipsTarget === '_new'
              ? '_blank'
              : zhHKData.tipsTarget || '_blank',
          description: zhHKData.descText || '',
          image: zhHKData.image || undefined,
        },
      },
    }
    coolKnowledgeList.push(coolKnowledgeObject)
  })
  return coolKnowledgeList
}
const transformResponseDataTW = (
  responseData: CoolKnowledgeTWAPIResponse
): CoolKnowledgeDataType[] => {
  return responseData.list
}

const transformResponseData = (
  responseData: CoolKnowledgeAPIResponse,
  country: UserCountryType
): CoolKnowledgeDataType[] => {
  if (country === 'HK') {
    return transformResponseDataHK(responseData as CoolKnowledgeHKAPIResponse)
  }
  return transformResponseDataTW(responseData as CoolKnowledgeTWAPIResponse)
}

const loadCoolKnowledge = createAsyncThunk<CoolKnowledgeDataType[]>(
  'loadCoolKnowledge',
  async (_, { getState }) => {
    const state: RootStateOrAny = getState()
    const { country } = state.system

    const res = await appAxios().get(`${getRoute({ route: 'coolKnowledge' })}`)

    return transformResponseData(res.data, country)
  }
)

export default loadCoolKnowledge
