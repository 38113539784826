import { FunctionComponent } from 'react'

import { IconProps } from '../../typings/Icon'

interface VideoIconProps extends IconProps {
  width?: number
  height?: number
  className?: string
  color?: string
}

const Video: FunctionComponent<VideoIconProps> = ({
  width,
  height,
  className,
  color,
  ...props
}: VideoIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      {...props}
      viewBox='0 0 19 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='3.75'
        y='0.5'
        width='14.75'
        height='12.7143'
        rx='1.5'
        stroke={color}
      />
      <path
        d='M1 2.28516V13.9994C1 15.104 1.89543 15.9994 3 15.9994H16.75'
        stroke={color}
      />
      <path
        d='M14.5 6.85631L9.4375 9.82554L9.4375 3.88708L14.5 6.85631Z'
        fill={color}
      />
    </svg>
  )
}

Video.defaultProps = {
  width: 19,
  height: 17,
  className: '',
  color: '#096183',
}

export default Video
