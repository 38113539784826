import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { CoolKnowledgeDataType } from '../../typings/CoolKnowledge'
import loadCoolKnowledge from './actions/loadCoolKnowledge'
import loadCoolKnowledgeLogin from './actions/loadCoolKnowledgeLogin'

type CoolKnowledgeState = {
  list: CoolKnowledgeDataType[]
  loading: boolean
}

const initialState: CoolKnowledgeState = {
  list: [],
  loading: false,
}

export const coolKnowledgeSlice = createSlice({
  name: 'coolKnowledge',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      loadCoolKnowledge.fulfilled,
      (state, action: PayloadAction<CoolKnowledgeDataType[]>) => {
        state.list = action.payload
      }
    )
    builder.addCase(
      loadCoolKnowledgeLogin.fulfilled,
      (state, action: PayloadAction<CoolKnowledgeDataType[]>) => {
        state.list = action.payload
      }
    )
  },
})

export default coolKnowledgeSlice.reducer
