import { FunctionComponent } from 'react'

import { IconProps } from '../../typings/Icon'

const Pen: FunctionComponent<IconProps> = ({
  width,
  height,
  className,
  ...props
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      {...props}
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.28551 2.31055L1.41697 8.27304C1.38745 8.30312 1.36613 8.34111 1.35598 8.38194L0.705506 11.0344C0.686048 11.1142 0.709144 11.1992 0.766501 11.2574C0.809899 11.3015 0.869033 11.3259 0.92952 11.3259C0.948047 11.3259 0.966997 11.3236 0.985439 11.3189L3.59629 10.658C3.63698 10.6476 3.67395 10.6261 3.70348 10.5961L9.57252 4.63401L7.28551 2.31055Z'
        fill='#3E3E3E'
      />
      <path
        d='M11.0256 1.49619L10.3724 0.832514C9.93574 0.388947 9.17479 0.389377 8.73869 0.832514L7.93848 1.64548L10.2254 3.96885L11.0256 3.15589C11.2437 2.93441 11.3638 2.63961 11.3638 2.32608C11.3638 2.01255 11.2437 1.71776 11.0256 1.49619Z'
        fill='#3E3E3E'
      />
    </svg>
  )
}

Pen.defaultProps = {
  width: 12,
  height: 12,
  className: '',
}

export default Pen
