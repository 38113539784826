import './MobileMenuLegacy.scss'

import classnames from 'classnames'
import _debounce from 'lodash/debounce'
import { FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from '../../../hooks'
import { RootState } from '../../../store'
import LanguageBlock from '../language-block'
import NavList from '../navlist'

interface MobileMenuProps {
  open: boolean
  closeMobileMenu: () => void
}

const MobileMenu: FunctionComponent<MobileMenuProps> = ({
  open,
  closeMobileMenu,
}: MobileMenuProps) => {
  const { t } = useTranslation()
  const { accountRelated, typhoon, hasMessage, read } = useAppSelector(
    (state: RootState) => state.warningMessage
  )
  const [mobileMenuHeight, setMobileMenuHeight] = useState<number>(
    window.innerHeight
  )

  const mobileMenuClassnames: string = classnames('mobile-menu', 'd-xl-none', {
    'mobile-menu--open': open,
  })

  const setupMobileMenuHeight = (): void => {
    const warningMessageContainerHeight =
      document.querySelector('#warning-message-container')?.clientHeight || 0
    setMobileMenuHeight(window.innerHeight - warningMessageContainerHeight)
  }

  useEffect(() => {
    if ([...accountRelated, ...typhoon].length === 0 && !hasMessage) return
    setupMobileMenuHeight()
  }, [accountRelated, typhoon, hasMessage])

  useEffect(() => {
    setupMobileMenuHeight()

    const setupMobileMenuHeightDebounced = _debounce(setupMobileMenuHeight, 150)

    window.addEventListener('resize', setupMobileMenuHeightDebounced)

    return () => {
      window.removeEventListener('resize', setupMobileMenuHeightDebounced)
    }
  }, [read])

  return (
    <div
      className={mobileMenuClassnames}
      id='mobile-menu'
      data-testid='mobile-menu'
      style={{ height: `${mobileMenuHeight}px` }}
    >
      <button className='mobile-menu__close-btn' onClick={closeMobileMenu}>
        {t('common:btn-close')}
      </button>
      <div className='mobile-menu__content-container'>
        <div className='mobile-menu__main'>
          <NavList variant='mobile' closeMobileMenu={closeMobileMenu} />
        </div>
        <LanguageBlock variant='mobile' />
      </div>
    </div>
  )
}

export default MobileMenu
