import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootStateOrAny } from 'react-redux'

import { appAxios, buildQueryString, getRoute } from '../../../helpers'
import { SearchResultType } from '../../../typings/Search'
import { UserCountryType } from '../../../typings/UserInfo'

interface SearchStringType {
  searchString?: string
}

/**
 * Search result API response data structure from HK
 */
interface SearchResultApiReponseDataHkType {
  stocks: string[]
}

/**
 * Search result API response data structure from TW
 */
interface SearchResultApiReponseDataTwType {
  stockList: string[]
}

type SearchResultReponseDataType =
  | SearchResultApiReponseDataHkType
  | SearchResultApiReponseDataTwType

const transformSearchResultDataTW = (
  responseData: SearchResultApiReponseDataTwType
): SearchResultType => {
  const searchResults: SearchResultType = responseData.stockList

  return searchResults
}

const transformSearchResultDataHK = (
  responseData: SearchResultApiReponseDataHkType
): SearchResultType => {
  const searchResults: SearchResultType = responseData.stocks

  return searchResults
}

const transformSearchResultData = (
  responseData: SearchResultReponseDataType,
  country: UserCountryType
): SearchResultType => {
  if (country === 'HK')
    return transformSearchResultDataHK(
      responseData as SearchResultApiReponseDataHkType
    )

  return transformSearchResultDataTW(
    responseData as SearchResultApiReponseDataTwType
  )
}

const fetchSearch = createAsyncThunk<SearchResultType, SearchStringType>(
  'fetchSearch',
  async ({ searchString = '' }, { getState }) => {
    const state: RootStateOrAny = getState()
    const { country } = state.system
    /**
     * Posting data to server
     *
     * const searchResultResponse = await appAxios().post(
     *   getRoute('search'),
     *   { searchString }
     * )
     */
    const searchResultResponse = await appAxios().get(
      getRoute({ route: 'search' }) +
        buildQueryString({
          searchString,
        })
    )

    return transformSearchResultData(searchResultResponse.data, country)
  }
)

export default fetchSearch
