import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import { CHEVRON_ORIENTATION_DOWN } from '../../../constants/chevron'
import {
  SCREEN_VARIANT_LG,
  SCREEN_VARIANT_MD,
  SCREEN_VARIANT_XL,
} from '../../../constants/screen-variant'
import changeLanguage from '../../../helpers/changeLanguage'
import { useAppDispatch } from '../../../hooks'
import { prodLangs } from '../../../i18n'
import { addLoading, removeLoading } from '../../../redux/loading/actions'
import { ChevronIcon } from '../../icons'
import Tab from '../../tab'

interface LanguageBlockProps {
  variant:
    | typeof SCREEN_VARIANT_XL
    | typeof SCREEN_VARIANT_LG
    | typeof SCREEN_VARIANT_MD
}

const LanguageBlockHK: FunctionComponent<LanguageBlockProps> = ({
  variant,
}: LanguageBlockProps) => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const { language: currentLanguage } = i18n

  const isXL = useMediaQuery({ minWidth: 1200 })
  const displayLangs = isXL
    ? prodLangs.HK.filter((c) => c !== currentLanguage)
    : prodLangs.HK

  return (
    <div
      className={
        variant === SCREEN_VARIANT_MD
          ? 'mobile-menu__language'
          : 'd-none d-xl-flex'
      }
      id='nav-language'
      data-testid='mobile-menu-language'
    >
      {displayLangs.map((language: string) => (
        <Tab
          key={language}
          id={language}
          active={currentLanguage.includes(language)}
          activeStyle={
            variant === SCREEN_VARIANT_MD ? 'underline' : 'highlight'
          }
          className={
            variant === SCREEN_VARIANT_MD
              ? 'mobile-menu__language-tab'
              : 'nav__language__tab'
          }
          navItem
          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
            event.preventDefault()
            dispatch(addLoading())
            changeLanguage(language)
            setTimeout(() => {
              dispatch(removeLoading())
            }, 1000)
          }}
          data-testid='language-tab'
        >
          <span>{t(`common:${language}`)}</span>
          <ChevronIcon orientation={CHEVRON_ORIENTATION_DOWN} color='white' />
        </Tab>
      ))}
    </div>
  )
}

export default LanguageBlockHK
