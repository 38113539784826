import { FunctionComponent } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { ModalBody, ModalHeader } from '../modal-components/ModalComponents'
import styles from './OccupationInstruction.module.scss'

const OccupationInstruction: FunctionComponent = () => {
  const { t } = useTranslation()
  const renderTableHeader = () => {
    const headers: string | string[] = t(
      `modal:instruction-occupation.occupation-title-example.header`,
      {
        returnObjects: true,
      }
    )
    if (typeof headers === 'string') return <th>{headers}</th>
    if (typeof headers === 'object') {
      return headers.map((header) => <th key={header}>{header}</th>)
    }
    return null
  }

  const renderTableContent = () => {
    const contents: string | string[] = t(
      `modal:instruction-occupation.occupation-title-example.content`,
      {
        returnObjects: true,
      }
    )
    if (typeof contents === 'string') return <td>{contents}</td>
    if (typeof contents === 'object') {
      return contents.map((content) => (
        <td key={content}>
          <ul>
            <Trans
              components={{
                ol: <ul />,
                li: <li />,
              }}
            >
              {content}
            </Trans>
          </ul>
        </td>
      ))
    }
    return null
  }

  return (
    <>
      <ModalHeader />
      <ModalBody modalContentClassNames={styles.occupationInstruction}>
        <p
          className={styles.occupationInstruction__title}
          data-testid='occupation-instruction-title'
        >
          {t(`modal:instruction-occupation.title`)}
        </p>
        <ol>
          <li>
            <span>
              {
                t(`modal:instruction-occupation.points`, {
                  returnObjects: true,
                })[0]
              }
            </span>
            <div className={styles.occupationInstruction__exampleTable}>
              <table data-testid='occupation-instruction-table'>
                <thead>
                  <tr>{renderTableHeader()}</tr>
                </thead>
                <tbody>
                  <tr>{renderTableContent()}</tr>
                </tbody>
              </table>
            </div>
          </li>
          <li>
            <span>
              {
                t(`modal:instruction-occupation.points`, {
                  returnObjects: true,
                })[1]
              }
            </span>
          </li>
        </ol>
      </ModalBody>
    </>
  )
}

export default OccupationInstruction
