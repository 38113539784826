import { FunctionComponent } from 'react'

import { IconProps } from '../../typings/Icon'

const HamburgerIcon: FunctionComponent<IconProps> = ({
  width,
  height,
  className,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    className={className}
    {...props}
    viewBox='0 0 17 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <line
      x1='1.03516'
      y1='1.59277'
      x2='15.9923'
      y2='1.59277'
      stroke='#3E3E3E'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <line
      x1='1.03516'
      y1='7.76416'
      x2='15.9923'
      y2='7.76416'
      stroke='#3E3E3E'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <line
      x1='1.03516'
      y1='13.9355'
      x2='15.9923'
      y2='13.9355'
      stroke='#3E3E3E'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
  </svg>
)

HamburgerIcon.defaultProps = {
  width: 17,
  height: 15,
  className: '',
}

export default HamburgerIcon
