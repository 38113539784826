import { FunctionComponent } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import styles from './LoadingModal.module.scss'

const LoadingModal: FunctionComponent = () => {
  const { t } = useTranslation()
  return (
    <Modal.Body className={styles.loadingModalContainer}>
      {t('common:loading')}
    </Modal.Body>
  )
}

export default LoadingModal
