import { FunctionComponent } from 'react'

import { IconProps } from '../../typings/Icon'

const CapsLock: FunctionComponent<IconProps> = ({
  width,
  height,
  className,
  ...props
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox='0 0 37 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <circle cx='18.2168' cy='18' r='18' fill='white' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.1066 9.38598C17.5695 8.89387 18.3434 8.86927 18.8381 9.32974L18.8947 9.38598L26.6653 17.6359C27.4003 18.4127 26.8455 19.6887 25.7712 19.6887H22.287V20.9084C22.287 21.5798 21.7393 22.1281 21.0608 22.1281H14.9369C14.262 22.1281 13.7107 21.5833 13.7107 20.9084V19.6851H10.2265C9.15575 19.6851 8.60096 18.4127 9.33244 17.6323L17.1066 9.38598ZM13.7142 24.5605C13.7142 23.8856 14.262 23.3408 14.9404 23.3408H21.0644C21.7428 23.3408 22.2905 23.8856 22.2905 24.5605V25.7803C22.2905 26.4517 21.7428 27 21.0644 27H14.9369C14.262 27 13.7107 26.4552 13.7107 25.7803V24.5605H13.7142Z'
        fill='#A4A4A4'
      />
    </svg>
  )
}

CapsLock.defaultProps = {
  width: 37,
  height: 36,
  className: '',
}

export default CapsLock
