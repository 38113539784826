import { FunctionComponent, lazy, Suspense, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import PackageJSON from '../package.json'
import Modal from './components/modal'
import LoadingModal from './components/modal/loading'
import TestingFloatingButton from './components/testing-floating-button'
import { EN, ZH_TW } from './constants/langauge-code'
import { useAppDispatch, useAppSelector } from './hooks'
import GeneralSuitability from './pages/general-suitability'
import { updateCountry } from './redux/system/actions'
import { updateCurrenciesPreference } from './redux/user/actions'
import routes from './routes/SiteNavigation'
import { RootState } from './store'
import { UserCountryType } from './typings/UserInfo'

// Route-based code splitting
const MyPortfolio = lazy(() => import('./pages/my-portfolio'))
// const FundDetailsPage = lazy(() => import('./pages/fund-details'))
const ClientTier = lazy(() => import('./pages/client-tier'))
const UrlTrading = lazy(() => import('./pages/url-trading'))
const LoginPage = lazy(() => import('./pages/login'))
const Inbox = lazy(() => import('./pages/inbox'))
const EnhancedSuitability = lazy(() => import('./pages/enhanced-suitability'))

const App: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const { numberOfLoading } = useAppSelector(
    (state: RootState) => state.loading
  )
  const { i18n, t } = useTranslation()

  // for development only
  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      const localStorageCountry = window.localStorage.getItem('country')
      if (localStorageCountry) {
        dispatch(updateCountry(localStorageCountry as UserCountryType))
        dispatch(
          updateCurrenciesPreference(
            localStorageCountry === 'HK' ? 'hkd' : 'twd'
          )
        )
        i18n.changeLanguage(localStorageCountry === 'HK' ? EN : ZH_TW)
        document.documentElement.lang =
          localStorageCountry === 'HK' ? EN : ZH_TW
      }
    }
  }, [dispatch, i18n])

  useEffect(() => {
    document.title = t('common:mdoip')
  }, [t, i18n.language])

  return (
    <Router basename={PackageJSON.homepage}>
      <Suspense
        fallback={
          <Modal
            options={{
              initialOpen: true,
            }}
            modalContentSize='fit-content'
            modalElement={LoadingModal}
          />
        }
      >
        <Switch>
          <Route exact path={routes.myPortfolio} component={MyPortfolio} />
          <Route exact path={routes.login} component={LoginPage} />
          {/* <Route
            exact
            path={routes.funds}
            component={() => <h1>All funds / fund search page</h1>}
          />
          <Route path={routes.fundDetails} component={FundDetailsPage} /> */}
          <Route exact path={routes.clientTier} component={ClientTier} />
          <Route exact path={routes.urlTrading} component={UrlTrading} />
          <Route exact path={routes.inbox} component={Inbox} />
          <Route
            exact
            path={routes.enhancedSuitability}
            component={EnhancedSuitability}
          />
          <Route
            exact
            path={routes.generalSuitabilty}
            component={GeneralSuitability}
          />
          <Route path='*' component={() => <h1>404</h1>} />
        </Switch>
      </Suspense>
      {process.env.NODE_ENV === 'development' && <TestingFloatingButton />}
      <Modal
        options={{
          initialOpen: true,
          closeModal: numberOfLoading === 0,
          // override the default onCloseHandler
          onCloseHandler: () => {},
        }}
        modalContentSize='fit-content'
        modalElement={LoadingModal}
      />
    </Router>
  )
}

export default App
