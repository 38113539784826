export const BUTTON_SIZE_LARGE = 'BUTTON_SIZE_LARGE'
export const BUTTON_SIZE_MEDIUM = 'BUTTON_SIZE_MEDIUM'
export const BUTTON_SIZE_SMALL = 'BUTTON_SIZE_SMALL'
export const BUTTON_SIZE_DEFAULT = BUTTON_SIZE_MEDIUM

export const BUTTON_THEME_PRIMARY = 'BUTTON_THEME_PRIMARY'
export const BUTTON_THEME_OUTLINED = 'BUTTON_THEME_OUTLINED'
export const BUTTON_THEME_DEFAULT = BUTTON_THEME_PRIMARY

export const DROPDOWN_BUTTON_SIZE_SMALL = 'DROPDOWN_BUTTON_SIZE_SMALL'
export const DROPDOWN_BUTTON_SIZE_MEDIUM = 'DROPDOWN_BUTTON_SIZE_MEDIUM'
export const DROPDOWN_BUTTON_SIZE_LARGE = 'DROPDOWN_BUTTON_SIZE_LARGE'
export const DROPDOWN_BUTTON_SIZE_DEFAULT = DROPDOWN_BUTTON_SIZE_MEDIUM
