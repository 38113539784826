import { createAsyncThunk } from '@reduxjs/toolkit'

import { setShowMessage } from '../../warning-message/actions'

const updateShowWelcomeOverlayStatus = createAsyncThunk<boolean, boolean>(
  'updateShowWelcomeOverlayStatus',
  async (status, { dispatch }) => {
    dispatch(setShowMessage(!status))
    return status
  }
)

export default updateShowWelcomeOverlayStatus
