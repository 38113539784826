import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootStateOrAny } from 'react-redux'

import fetchActiveRsp from './fetchActiveRsp'
import fetchClientTierInfo from './fetchClientTierInfo'
import fetchPriceAlert from './fetchPriceAlert'
import fetchSetting from './fetchSetting'
import fetchUserAccountInfo from './fetchUserAccountInfo'
import fetchUserEpoints from './fetchUserEPoints'
import fetchUserRiskRating from './fetchUserRiskRating'

const fetchUser = createAsyncThunk(
  'fetchUser',
  async (_, { dispatch, getState }) => {
    const state: RootStateOrAny = getState()
    const { country } = state.system
    dispatch(fetchUserAccountInfo())
    dispatch(fetchClientTierInfo())
    dispatch(fetchUserEpoints())
    dispatch(fetchUserRiskRating())
    dispatch(fetchPriceAlert())

    if (country === 'HK') {
      dispatch(fetchActiveRsp())
      dispatch(fetchSetting()) // Fetch currency setting only available in HK
    }
  }
)

export default fetchUser
