import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootStateOrAny } from 'react-redux'

import {
  INVESTMENT_TIER_HK,
  INVESTMENT_TIER_TW_TIER_10M_SALES_CODE_DIRECT,
} from '../../../constants/tier-info'
import { appAxios, getRoute } from '../../../helpers'
import getInvestmentTier from '../../../helpers/getInvestmentTier'
import { TierInfoType } from '../../../typings/TierInfo'
import { ClientTierType, UserTierType } from '../../../typings/UserInfo'

/**
 * Currently only TW has this API endpoint to fetch a list of client tier
 * User Client Tier Info response data structure from TW
 */
interface UserClientTierInfoApiReponseDataTwType {
  latestTier: string
  list: {
    holdingAmountTwd: number
    startDate: string | null
    endDate: string | null
    clientTier: string
  }[]
}

type UserClientTierInfoApiResponseType = UserClientTierInfoApiReponseDataTwType

const transformUserDataTW = (
  responseData: UserClientTierInfoApiReponseDataTwType
): UserTierType => {
  const userTierObject: UserTierType = {
    investmentTotal: responseData.list[0].holdingAmountTwd,
    investmentTier:
      responseData.latestTier === 'Direct' // mocking client sales code === 'Direct' || 'Digital'
        ? INVESTMENT_TIER_TW_TIER_10M_SALES_CODE_DIRECT
        : getInvestmentTier(responseData.list[0].holdingAmountTwd),
    membershipTier: responseData.list.map((tier) => {
      const tierObject: ClientTierType = {
        investmentTotal: tier.holdingAmountTwd,
        startDate: tier.startDate,
        endDate: tier.endDate,
        clientTier: tier.clientTier,
      }
      return tierObject
    }),
  }
  return userTierObject
}

const transformUserData = (
  responseData: UserClientTierInfoApiResponseType
): UserTierType => {
  return transformUserDataTW(
    responseData as UserClientTierInfoApiReponseDataTwType
  )
}

/**
 * fetchUser
 * =========
 *
 * To DEMO a case when we fetch user data from the internet, but the
 * data structure is different between the user API endpoint and
 * our predefined fake user data.
 *
 * The quickest way to integrate the user API data with the app is to
 * transform it into the same data structure with our predefined fake
 * user data structure. In this way, we don't have to touch the React
 * app.
 */
const fetchClientTierInfo = createAsyncThunk<UserTierType>(
  'fetchClientTierInfo',
  async (_, { getState }) => {
    const state: RootStateOrAny = getState()
    const { country } = state.system

    if (country === 'HK') {
      const userAccountRes = await appAxios().get(
        getRoute({ route: 'userMembershipTier' })
      )

      const latestMembershipList = userAccountRes.data.list[0]

      return {
        membershipTier: userAccountRes.data.latestTier,
        investmentTotal: latestMembershipList.holdingAmount,
        investmentTier: INVESTMENT_TIER_HK.filter(
          (investmentTierHK: TierInfoType) =>
            latestMembershipList.clientTier
              .toLowerCase()
              .includes(investmentTierHK.name.toLowerCase())
        )[0],
      }
    }
    const userMemebershipTierResponse = await appAxios().get(
      getRoute({ route: 'userMembershipTier' })
    )

    const responseData = userMemebershipTierResponse.data

    return transformUserData(responseData)
  }
)

export default fetchClientTierInfo
