import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  NO_BREAKDOWN_FITLER_INDEX,
  VIEW_BREAKDOWN,
  VIEW_DEFAULT,
} from '../../constants/breakdown-filter'
import { FUND_CURRENCY_TW_DEFAULT } from '../../constants/fund-currency-type'
import { FUND_ACCOUNT_TYPE_TW_DEFAULT } from '../../constants/funds-account-type'
import { FUND_GROUP_TYPE_HK_DEFAULT } from '../../constants/funds-group-type'
import { FUND_TYPE_HK_DEFAULT } from '../../constants/funds-type'
import { SORT_DEFAULT_SELECT } from '../../constants/sorts'
import { BreakdownData, FilterState, FiltersType } from '../../typings/Filters'
import { HoldingFundPreviewType } from '../../typings/PortfolioPie'
import { SortValueType } from '../../typings/Sorts'
import updateBreakdownData from './actions/updateBreakdownData'
import updateBreakdownFilteredIndex from './actions/updateBreakdownFilteredIndex'
import updateFilters from './actions/updateFilters'
import updateInitialSortedFunds from './actions/updateInitialSortedFunds'
import updateSortValue from './actions/updateSortValue'

export const initialState: FilterState = {
  type: FUND_TYPE_HK_DEFAULT,
  accountType: FUND_ACCOUNT_TYPE_TW_DEFAULT,
  currencyType: FUND_CURRENCY_TW_DEFAULT,
  sort: SORT_DEFAULT_SELECT,
  view: VIEW_DEFAULT,
  default: {
    loading: true,
    fundList: [],
  },
  breakdown: {
    label: FUND_GROUP_TYPE_HK_DEFAULT,
    loading: true,
    filteredIndex: NO_BREAKDOWN_FITLER_INDEX,
    data: [],
  },
  display: {
    numOfType: 0,
  },
  barData: [],
}

export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    updateNumOfDisplayType: (state, action: PayloadAction<number>) => {
      state.display.numOfType = action.payload
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateBarData: (state, action: PayloadAction<any[]>) => {
      state.barData = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateInitialSortedFunds.pending, (state) => {
      state.default.loading = true
    })
    builder.addCase(
      updateInitialSortedFunds.fulfilled,
      (state, action: PayloadAction<HoldingFundPreviewType[]>) => {
        state.default.fundList = action.payload
        state.default.loading = false
      }
    )
    builder.addCase(updateSortValue.pending, (state) => {
      state.default.loading = true
    })
    builder.addCase(
      updateSortValue.fulfilled,
      (state, action: PayloadAction<SortValueType>) => {
        state.sort = action.payload
        state.default.loading = false
      }
    )
    builder.addCase(
      updateFilters.fulfilled,
      (state, action: PayloadAction<FiltersType>) => {
        const { type, accountType, groupType, currencyType } = action.payload
        state.type = type
        state.accountType = accountType
        state.currencyType = currencyType
        state.breakdown.label = groupType
        if (groupType === FUND_GROUP_TYPE_HK_DEFAULT) {
          state.view = VIEW_DEFAULT
        } else {
          state.view = VIEW_BREAKDOWN
        }
      }
    )
    builder.addCase(
      updateBreakdownData.fulfilled,
      (state, action: PayloadAction<BreakdownData[]>) => {
        state.breakdown.data = action.payload
      }
    )
    builder.addCase(
      updateBreakdownFilteredIndex.fulfilled,
      (state, action: PayloadAction<number>) => {
        state.breakdown.filteredIndex = action.payload
      }
    )
  },
})

export default filterSlice.reducer
