import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootStateOrAny } from 'react-redux'

import { appAxios, getRoute } from '../../../helpers'
import { OrderHistoryData } from '../../../typings/OrderHistory'
import { UserCountryType } from '../../../typings/UserInfo'

const transformResponseDataHK = (
  responseData: OrderHistoryData[]
): OrderHistoryData[] => {
  return responseData
}

const transformResponseDataTW = (
  responseData: OrderHistoryData[]
): OrderHistoryData[] => {
  return responseData
}

const transformResponseData = (
  responseData: OrderHistoryData[],
  country: UserCountryType
): OrderHistoryData[] => {
  if (country === 'HK') {
    return transformResponseDataHK(responseData)
  }
  return transformResponseDataTW(responseData)
}

const loadOrderHistory = createAsyncThunk<OrderHistoryData[]>(
  'loadOrderHistory',
  async (_, { getState }) => {
    const state: RootStateOrAny = getState()
    const { country } = state.system

    const res = await appAxios().get(`${getRoute({ route: `orderHistory` })}`)

    return transformResponseData(res.data.list, country)
  }
)

export default loadOrderHistory
