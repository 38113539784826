import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { InboxMessageDataType } from '../../components/inbox-card/Type'
import dummyData from './dummyData'

type InboxState = {
  messages: InboxMessageDataType[]
}

const initialState: InboxState = {
  messages: dummyData,
}

export const inboxSlice = createSlice({
  name: 'inbox',
  initialState,
  reducers: {
    deleteMessages: (state, action: PayloadAction<string[]>) => {
      const deleteMessageIds = action.payload
      let updateStateMessages = state.messages
      deleteMessageIds.forEach((id: string) => {
        updateStateMessages = updateStateMessages.filter(
          (message: InboxMessageDataType) => message.messageId !== id
        )
      })
      state.messages = updateStateMessages
    },
  },
  extraReducers: () => {},
})

export default inboxSlice.reducer
