import { createSlice } from '@reduxjs/toolkit'

export interface LoadingState {
  numberOfLoading: number
}

const initialState: LoadingState = {
  numberOfLoading: 0,
}

/**
 * The nummber of loading will add up whenever a request is submitted via appAxios
 * The number of loading will reduce whenever a response is received in appAxios
 * The number of loading suggests how many axios requests are currently in process
 */

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    addLoading: (state) => {
      state.numberOfLoading += 1
    },
    removeLoading: (state) => {
      if (state.numberOfLoading <= 0) {
        state.numberOfLoading = 0
      } else {
        state.numberOfLoading -= 1
      }
    },
  },
})

export default loadingSlice.reducer
