import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { FundPreviewType } from '../../typings/FundPreview'
import loadTopFiveFunds from './actions/loadTopFiveFunds'

type TopFiveFundsState = {
  list: FundPreviewType[]
  loading: boolean
}

const initialState: TopFiveFundsState = {
  list: [],
  loading: false,
}

export const topFiveFundsSlice = createSlice({
  name: 'topFiveFunds',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      loadTopFiveFunds.fulfilled,
      (state, action: PayloadAction<FundPreviewType[]>) => {
        // eslint-disable-next-line no-param-reassign
        state.list = action.payload
      }
    )
  },
})

export default topFiveFundsSlice.reducer
