import { FunctionComponent } from 'react'

import { IconProps } from '../../typings/Icon'

export const TICK_ICON_THEME_BLUE = 'TICK_ICON_THEME_BLUE'
export const TICK_ICON_THEME_WHITE = 'TICK_ICON_THEME_WHITE'

type TickIconTheme = typeof TICK_ICON_THEME_BLUE | typeof TICK_ICON_THEME_WHITE

interface TickIconProps extends IconProps {
  theme?: TickIconTheme
}

const TickIcon: FunctionComponent<TickIconProps> = ({
  className = '',
  height = 8,
  width = 12,
  theme = TICK_ICON_THEME_BLUE,
  ...props
}: TickIconProps) => (
  <svg
    height={height}
    width={width}
    className={className}
    {...props}
    viewBox='0 0 12 8'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M1.44531 4L4.44531 7L10.4453 1'
      stroke={theme === TICK_ICON_THEME_BLUE ? '#565a5d' : 'white'}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

TickIcon.defaultProps = {
  theme: TICK_ICON_THEME_BLUE,
}

export default TickIcon
