import about from './about.json'
import announcement from './announcement.json'
import common from './common.json'
import countries from './countries.json'
import currencies from './currencies.json'
import labels from './data-labels.json'
import disclaimer from './disclaimer.json'
import enhancedSuitability from './enhanced-suitability.json'
import ePoints from './ePoints.json'
import exportFunds from './export-funds.json'
import filters from './filters.json'
import footer from './footer.json'
import fundDetailsPage from './fund-details-page.json'
import fundInfoModal from './fund-info-modal.json'
import generalSuitability from './general-suitability.json'
import greetings from './greetings.json'
import home from './home.json'
import importantNotes from './important-notes.json'
import inbox from './inbox.json'
import login from './login.json'
import modal from './modal.json'
import nav from './nav.json'
import orderHistory from './order-history.json'
import paymentTypeSwitch from './payment-type-switch.json'
import periodSelector from './period-selector.json'
import promotion from './promotion.json'
import questions from './questions.json'
import riskRating from './risk-rating.json'
import rspMigration from './rsp-migration.json'
import selected from './selected.json'
import sort from './sort.json'
import tier from './tier.json'
import tierDetails from './tier-details.json'
import tierInstructions from './tier-instruction.json'
import topFund from './top-fund.json'
import tutorial from './tutorial.json'
import typeDropdown from './type-dropdown.json'
import urlTrading from './url-trading.json'

export default {
  announcement,
  common,
  home,
  about,
  footer,
  tier,
  filters,
  sort,
  nav,
  currencies,
  labels,
  selected,
  riskRating,
  fundDetailsPage,
  periodSelector,
  countries,
  greetings,
  tierInstructions,
  tierDetails,
  urlTrading,
  topFund,
  disclaimer,
  importantNotes,
  rspMigration,
  fundInfoModal,
  login,
  orderHistory,
  promotion,
  paymentTypeSwitch,
  typeDropdown,
  ePoints,
  exportFunds,
  tutorial,
  inbox,
  modal,
  enhancedSuitability,
  questions,
  generalSuitability,
}
