import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootStateOrAny } from 'react-redux'

import { appAxios, getRoute } from '../../../helpers'
import { parsePromotionContent } from '../../../helpers/xmlParser'
import { PromotionDataType, PromotionType } from '../../../typings/Promotion'

export const NEW_CODE = 'NEW'
export const ORSO_CODE = 'ORSO'
export const JPM_CODE = 'JPM'

type NewClientPromotion = {
  code: string
  locale: {
    [locale: string]: string
  }
}

type PromotionResponse = {
  list: NewClientPromotion[]
}

const transformNewClientPromotionData = (
  data: PromotionResponse
): PromotionType[] | undefined => {
  if (data.list.length === 0) return undefined
  const promotionList: PromotionType[] = data.list.map(
    (entry: NewClientPromotion) => {
      const languages = Object.keys(entry.locale)
      let locale: {
        [locale: string]: PromotionDataType | null
      } = {}
      languages.forEach((language: string) => {
        locale = {
          ...locale,
          [language]: parsePromotionContent(entry.locale[language]),
        }
      })

      const promotionDataObj: PromotionType = {
        code: entry.code,
        position: -1,
        isHero: false,
        isNewClientPromo:
          !!entry.code.match(NEW_CODE) ||
          !!entry.code.match(ORSO_CODE) ||
          !!entry.code.match(JPM_CODE) ||
          false,
        isSticky: false,
        locale,
      }
      return promotionDataObj
    }
  )

  // In case post login side bar promotion return not only New client promotion and ORSO promotion
  return promotionList.filter((item: PromotionType) => item.isNewClientPromo)
}

const loadNewClientPromotions = createAsyncThunk<PromotionType[] | undefined>(
  'loadNewClientPromotions',
  async (_, { getState }) => {
    const state: RootStateOrAny = getState()

    const { country } = state.system
    if (country === 'TW') return undefined

    const promotionRes = await appAxios().get(
      `${getRoute({ route: 'newClientPromotions' })}`
    )

    return transformNewClientPromotionData(promotionRes.data)
  }
)

export default loadNewClientPromotions
