import { createAsyncThunk } from '@reduxjs/toolkit'

import fetchRedemptEstimateValue from '../../../helpers/fetchRedemptEstimateValue'
import {
  UpdateRedemptEstimateValueParams,
  UpdateRedemptEstimateValueResponse,
} from '../../../typings/UrlTrading'

const updateRedemptEstimateValue = createAsyncThunk<
  UpdateRedemptEstimateValueResponse,
  UpdateRedemptEstimateValueParams
>(
  'updateRedemptEstimateValue',
  async (
    params: UpdateRedemptEstimateValueParams
  ): Promise<UpdateRedemptEstimateValueResponse> => {
    // Mock Exceed available holdings
    let res

    if (params.units > params.availableHoldings) {
      res = {
        fundCode: '',
        fundType: '',
        error: null,
        value: 0,
        units: 0,
        fxRate: 0,
      }
    } else {
      res = await fetchRedemptEstimateValue({ ...params })
    }

    return {
      fundCode: params.fundCode,
      fundType: params.fundType,
      error: res.error === null,
      value: res.value,
      units: params.units,
      fxRate: res.fxRate,
    }
  }
)

export default updateRedemptEstimateValue
