import classnames from 'classnames'
import { FunctionComponent } from 'react'

import styles from './FormReviewDataGroup.module.scss'

export type GeneralSuitabilityTitle = string
export type GeneralSuitabilityFormDataType = {
  questionNumber: number
  question: string
  answer: string
}

export const FORM_REVIEW_DISPLAY_ONE_THIRD = 'FORM_REVIEW_DISPLAY_ONE_THIRD'
export const FORM_REVIEW_DISPLAY_HALF = 'FORM_REVIEW_DISPLAY_HALF'
export const FORM_REVIEW_DISPLAY_FULL_WIDTH = 'FORM_REVIEW_DISPLAY_FULL_WIDTH'
export type FormReviewDataDisplay =
  | typeof FORM_REVIEW_DISPLAY_ONE_THIRD
  | typeof FORM_REVIEW_DISPLAY_HALF
  | typeof FORM_REVIEW_DISPLAY_FULL_WIDTH

export interface FormReviewGroupProps {
  title?: GeneralSuitabilityTitle
  formData: GeneralSuitabilityFormDataType[]
  dataClassName?: string
  displayQuestionNumber?: boolean
  display?: FormReviewDataDisplay
}

const FormReviewGroup: FunctionComponent<FormReviewGroupProps> = ({
  title,
  formData,
  dataClassName,
  displayQuestionNumber,
  display,
  ...props
}) => {
  return (
    <div className={styles.formReviewData} {...props}>
      {title && (
        <p
          data-testid='form-review-data-group-title'
          className={styles.formReviewData__title}
        >
          {title}
        </p>
      )}
      {displayQuestionNumber ? (
        <div className={styles.displayQuestionNumber__flex}>
          {formData.map(
            (
              {
                question,
                answer,
                questionNumber,
              }: GeneralSuitabilityFormDataType,
              index: number
            ) => {
              const questionWithQuestion = `${index + 1}. ${question}`
              return (
                <div
                  key={`form-review-${questionNumber}-${question}`}
                  data-testid='form-review-data-item'
                  className={classnames(dataClassName, {
                    [styles.halfWidth]: display === FORM_REVIEW_DISPLAY_HALF,
                    [styles.fullWidth]:
                      display === FORM_REVIEW_DISPLAY_FULL_WIDTH,
                    [styles.oneThird]:
                      display === FORM_REVIEW_DISPLAY_ONE_THIRD,
                    [styles.wider]:
                      display === FORM_REVIEW_DISPLAY_ONE_THIRD &&
                      (question.length > 16 || answer.length > 16),
                  })}
                >
                  <p
                    className={styles.formReviewData__question}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: questionWithQuestion }}
                  />

                  <p
                    className={styles.formReviewData__answer}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: answer }}
                  />
                </div>
              )
            }
          )}
        </div>
      ) : (
        <>
          {formData.map(
            ({
              questionNumber,
              question,
              answer,
            }: GeneralSuitabilityFormDataType) => (
              <div
                key={`form-review-${questionNumber}-${question}`}
                data-testid='form-review-data-item'
                className={classnames(
                  dataClassName,
                  styles.formReviewData__data,
                  {
                    [styles.halfWidth]: display === FORM_REVIEW_DISPLAY_HALF,
                    [styles.fullWidth]:
                      display === FORM_REVIEW_DISPLAY_FULL_WIDTH,
                    [styles.oneThird]:
                      display === FORM_REVIEW_DISPLAY_ONE_THIRD,
                    [styles.wider]: question.length > 15 || answer.length > 15,
                  }
                )}
              >
                <p
                  className={styles.formReviewData__question}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: question }}
                />
                <p
                  className={styles.formReviewData__answer}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: answer }}
                />
              </div>
            )
          )}
        </>
      )}
    </div>
  )
}

FormReviewGroup.defaultProps = {
  title: undefined,
  dataClassName: '',
  displayQuestionNumber: false,
  display: FORM_REVIEW_DISPLAY_FULL_WIDTH,
}

export default FormReviewGroup
