import { RouteType } from '../../typings/Routes'

const PROFILE_TW: RouteType[] = [
  {
    translationKeyName: 'profile',
    routeName: 'profile',
    subMenu: [
      {
        translationKeyName: 'profile-details',
        routeName: 'profile',
      },
      {
        translationKeyName: 'profile-payment',
        routeName: 'profile',
      },
      {
        translationKeyName: 'profile-pin',
        routeName: 'profile',
      },
      {
        translationKeyName: 'profile-alert',
        routeName: 'profile',
      },
      {
        translationKeyName: 'profile-questionnaire',
        routeName: 'profile',
      },
    ],
  },
]

export default PROFILE_TW
