import { FunctionComponent } from 'react'

import { IconProps } from '../../typings/Icon'

const Bookmark: FunctionComponent<IconProps> = ({
  width,
  height,
  className,
  ...props
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      {...props}
      viewBox='0 0 15 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.33398 1.61133V18.5653C1.33398 18.7516 1.56633 18.8367 1.68667 18.6945L7.9834 11.2518C8.06574 11.1545 8.2169 11.158 8.29462 11.259L13.9754 18.6452C14.0919 18.7965 14.334 18.7142 14.334 18.5232V1.61133C14.334 1.33519 14.1101 1.11133 13.834 1.11133H1.83398C1.55784 1.11133 1.33398 1.33519 1.33398 1.61133Z'
        stroke='#3E3E3E'
        strokeWidth='1.2'
        strokeLinejoin='round'
      />
    </svg>
  )
}

Bookmark.defaultProps = {
  width: 15,
  height: 20,
  className: '',
}

export default Bookmark
