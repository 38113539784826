import classnames from 'classnames'
import { FunctionComponent, ReactChild } from 'react'
import Card from 'react-bootstrap/Card'

import styles from './ReminderMessageCard.module.scss'

export const REMINDER_MESSAGE_CARD_THEME_TEAL = 'teal'

interface ReminderMessageCardProps {
  cardClassName?: string
  cardHeaderClassName?: string
  cardBodyClassName?: string
  header?: ReactChild | string
  theme: typeof REMINDER_MESSAGE_CARD_THEME_TEAL
  body: ReactChild | string
}

const ReminderMessageCard: FunctionComponent<ReminderMessageCardProps> = ({
  cardClassName,
  cardHeaderClassName,
  cardBodyClassName,
  header,
  theme,
  body,
  ...props
}: ReminderMessageCardProps) => {
  return (
    <Card
      bsPrefix={classnames(cardClassName, styles.reminderMessageCard, {
        [styles.reminderMessageCard__teal]:
          theme === REMINDER_MESSAGE_CARD_THEME_TEAL,
      })}
      {...props}
    >
      {header && (
        <Card.Header
          data-testid='reminder-message-card-header'
          className={classnames(
            cardHeaderClassName,
            styles.reminderMessageCard__header
          )}
        >
          {header}
        </Card.Header>
      )}
      <Card.Body
        data-testid='reminder-message-card-body'
        className={classnames(
          cardBodyClassName,
          styles.reminderMessageCard__body
        )}
      >
        {body}
      </Card.Body>
    </Card>
  )
}

ReminderMessageCard.defaultProps = {
  header: undefined,
  cardClassName: '',
  cardHeaderClassName: '',
  cardBodyClassName: '',
}

export default ReminderMessageCard
