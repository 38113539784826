import { FundDetailsResponseData } from '../typings/FundDetails'
import { FundPriceData } from '../typings/FundPreview'
import {
  AssetClassData,
  FundResponseHKType,
  HoldingFundType,
} from '../typings/Portfolio'

export const transformPortfolioHoldingFundHK = (
  portfolioResponse: FundResponseHKType,
  fundDetail: FundDetailsResponseData,
  fundCategoriesList: AssetClassData[],
  currentFundPrice: FundPriceData
): HoldingFundType => {
  let oipIsLoss: boolean | undefined
  let oipFundBaseIsLoss: boolean | undefined

  if (
    parseFloat(portfolioResponse.profit) < 0 ||
    parseFloat(portfolioResponse.returnPercent) < 0
  ) {
    oipIsLoss = true
  } else if (
    parseFloat(portfolioResponse.profit) > 0 ||
    parseFloat(portfolioResponse.returnPercent) > 0
  ) {
    oipIsLoss = false
  } else {
    oipIsLoss = undefined
  }

  if (
    parseFloat(portfolioResponse.fundBaseCcyProfit || '0') < 0 ||
    parseFloat(portfolioResponse.fundBaseCcyReturnPercent || '0') < 0
  ) {
    oipFundBaseIsLoss = true
  } else if (
    parseFloat(portfolioResponse.fundBaseCcyProfit || '0') > 0 ||
    parseFloat(portfolioResponse.fundBaseCcyReturnPercent || '0') > 0
  ) {
    oipFundBaseIsLoss = false
  } else {
    oipFundBaseIsLoss = undefined
  }

  return {
    oipIsLoss,
    oipFundBaseIsLoss,
    name: {
      locale: {
        ...fundDetail.fundInfo.locale,
      },
    },
    totalCost: portfolioResponse.cost,
    return: portfolioResponse.returnPercent,
    baselineCurrency: portfolioResponse.baseCurrency,
    nav: fundDetail.navPrecision,
    riskRating: fundDetail.fundRiskRating,
    lastPurchasedDate: portfolioResponse.dealingDate,
    assetClass: fundCategoriesList.filter(
      (fundCategory: AssetClassData) =>
        fundCategory.code === fundDetail.fundCategoryCode
    )[0],
    paymentTypeSwitch: portfolioResponse.paymentType,
    navData: currentFundPrice,
    ...portfolioResponse,
  }
}

export const transformPortfolioHoldingFundTW = (
  portfolioResponse: FundResponseHKType,
  fundDetail: FundDetailsResponseData,
  fundCategoriesList: AssetClassData[],
  fundMarketList: AssetClassData[],
  currentFundPrice: FundPriceData
): HoldingFundType => {
  let oipIsLoss: boolean | undefined
  let oipFundBaseIsLoss: boolean | undefined

  if (
    parseFloat(portfolioResponse.profit) < 0 ||
    parseFloat(portfolioResponse.returnPercent) < 0
  ) {
    oipIsLoss = true
  } else if (
    parseFloat(portfolioResponse.profit) > 0 ||
    parseFloat(portfolioResponse.returnPercent) > 0
  ) {
    oipIsLoss = false
  } else {
    oipIsLoss = undefined
  }

  if (
    parseFloat(portfolioResponse.fundBaseCcyProfit || '0') < 0 ||
    parseFloat(portfolioResponse.fundBaseCcyReturnPercent || '0') < 0
  ) {
    oipFundBaseIsLoss = true
  } else if (
    parseFloat(portfolioResponse.fundBaseCcyProfit || '0') > 0 ||
    parseFloat(portfolioResponse.fundBaseCcyReturnPercent || '0') > 0
  ) {
    oipFundBaseIsLoss = false
  } else {
    oipFundBaseIsLoss = undefined
  }

  return {
    oipIsLoss,
    oipFundBaseIsLoss,
    name: {
      locale: {
        ...fundDetail.fundInfo.locale,
      },
    },
    totalCost: portfolioResponse.cost,
    return: portfolioResponse.returnPercent,
    baselineCurrency: portfolioResponse.baseCurrency,
    nav: fundDetail.navPrecision,
    riskRating: fundDetail.fundRiskRating,
    lastPurchasedDate: portfolioResponse.dealingDate,
    assetClass: fundCategoriesList.filter(
      (fundCategory: AssetClassData) =>
        fundCategory.code === portfolioResponse.fundMarketTypeCode
    )[0],
    fundMarket: fundMarketList.filter(
      (fundMarket: AssetClassData) =>
        fundMarket.code === portfolioResponse.fundCategoryCode
    )[0],
    paymentTypeSwitch: portfolioResponse.paymentType,
    navData: currentFundPrice,
    ...portfolioResponse,
  }
}
