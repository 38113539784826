import { FunctionComponent, useState } from 'react'
import { Tab as BootstrapTab, TabContainerProps } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { TAB_DEFAULT_INDEX } from '../../../constants/translation-index-name'
import { TranslationIndexNameType } from '../../../typings/TranslationIndex'
import TabBar from '../bar'
import TabItem from '../item'
import styles from './Container.module.scss'

interface ITabContainerProps extends TabContainerProps {
  defaultActiveKey: string
  tabList: string[]
  translationIndexName?: TranslationIndexNameType
  tabClassname?: string
}

const TabContainer: FunctionComponent<ITabContainerProps> = ({
  tabList,
  defaultActiveKey,
  translationIndexName,
  children,
  tabClassname,
  ...props
}: ITabContainerProps) => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState<string>(defaultActiveKey)

  const onSelectHandler: (tabKey: string | null) => void = (tabKey) => {
    if (!tabKey) return
    if (tabKey === activeTab) return
    setActiveTab(tabKey)
  }

  return (
    <BootstrapTab.Container
      defaultActiveKey={defaultActiveKey}
      onSelect={onSelectHandler}
      {...props}
    >
      <TabBar>
        {tabList.map((tab) => (
          <TabItem
            key={tab}
            eventKey={tab}
            active={activeTab === tab}
            data-testid='tab-item'
            className={tabClassname}
          >
            {t(`${translationIndexName}:tab-${tab}`)}
          </TabItem>
        ))}
      </TabBar>
      <BootstrapTab.Content className={styles.TabContent}>
        {children}
      </BootstrapTab.Content>
    </BootstrapTab.Container>
  )
}

TabContainer.defaultProps = {
  translationIndexName: TAB_DEFAULT_INDEX,
  tabClassname: '',
}

export default TabContainer
