import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import { EN, ZH_HK, ZH_TW } from './constants/langauge-code'
import { store } from './store'
import enTranslations from './translations/en'
import zhHkTranslations from './translations/zh-HK'
import zhTwTranslations from './translations/zh-TW'

// eslint-disable-next-line import/no-mutable-exports
let defaultLanguage: string
// eslint-disable-next-line import/no-mutable-exports
let resources
// eslint-disable-next-line import/no-mutable-exports
let languages: string[]
// eslint-disable-next-line import/no-mutable-exports
let namespaces: string[]

const devLangs = [EN, ZH_HK, ZH_TW]
const prodLangs = { HK: [ZH_HK, EN], TW: [ZH_TW] }

if (process.env.NODE_ENV !== 'production') {
  defaultLanguage = ZH_TW
  resources = {
    [EN]: enTranslations,
    [ZH_HK]: zhHkTranslations,
    [ZH_TW]: zhTwTranslations,
  }
  languages = devLangs
  namespaces = [...Object.keys(enTranslations)]
} else {
  const {
    system: { country },
  } = store.getState()

  if (country === 'HK') {
    defaultLanguage = EN
    resources = {
      [EN]: enTranslations,
      [ZH_HK]: zhHkTranslations,
    }
    languages = prodLangs.HK
    namespaces = [...Object.keys(enTranslations)]
  } else {
    defaultLanguage = ZH_TW
    resources = {
      [ZH_TW]: zhTwTranslations,
    }
    languages = prodLangs.TW
    namespaces = [...Object.keys(zhTwTranslations)]
  }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    resources,
    ns: namespaces,
    supportedLngs: languages,
    fallbackLng: defaultLanguage,
    cleanCode: true,
    interpolation: {
      escapeValue: false, // no need for react, react already safes from XSS
    },
  })
document.documentElement.lang = i18n.language

export {
  defaultLanguage,
  devLangs,
  languages,
  namespaces,
  prodLangs,
  resources,
}
export default i18n
