import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootStateOrAny } from 'react-redux'

import {
  DEFAULT_CURRENCY_HK,
  DEFAULT_CURRENCY_TW,
} from '../../../constants/currencies'
import {
  FUND_ACCOUNT_TYPE_TW_EC,
  FUND_ACCOUNT_TYPE_TW_MA,
} from '../../../constants/funds-account-type'
import {
  FUND_GROUP_TYPE_HK_FUND_CATEGORY,
  FUND_GROUP_TYPE_HK_FUND_CODE,
  FUND_GROUP_TYPE_TW_FUND_BASE_CURRENCY,
  FUND_GROUP_TYPE_TW_FUND_CATEGORY,
  FUND_GROUP_TYPE_TW_FUND_MARKET_TYPE,
} from '../../../constants/funds-group-type'
import {
  FUND_TYPE_HK_MASTER_ACCOUNT,
  FUND_TYPE_HK_REIP,
  FUND_TYPE_TW_LS,
  FUND_TYPE_TW_RSP,
} from '../../../constants/funds-type'
import { appAxios, getRoute } from '../../../helpers'
import sorted from '../../../helpers/sorted'
import transformHoldingPreviewData from '../../../helpers/transformHoldingPreviewData'
import { LoadMyPortfolioParams } from '../../../typings/Portfolio'
import updateBreakdownData from '../../fund-filter/actions/updateBreakdownData'
import updateInitialSortedFunds from '../../fund-filter/actions/updateInitialSortedFunds'

const loadPieData = createAsyncThunk(
  'loadPieData',
  async (params: LoadMyPortfolioParams, { getState, dispatch }) => {
    const state: RootStateOrAny = getState()
    const { country } = state.system
    const { fundList } = state.holding.data
    const { accountType, type, groupType } = state.fundFilter
    const defaultCurrency =
      country === 'HK' ? DEFAULT_CURRENCY_HK : DEFAULT_CURRENCY_TW

    const portfolioRequest = {
      accountType,
      type,
      groupType,
      ...params,
    }
    let portfolioPieResponse = await appAxios().post(
      `${getRoute({ route: `portfolioPie` })}`,
      { ...portfolioRequest, isPieChart: true }
    )

    // FOR DEMO PURPOSE , PLEASE DELETE THIS AFTER DEMOSTRATION
    if (process.env.NODE_ENV === 'production') {
      if (
        country === 'TW' &&
        params.groupType === FUND_GROUP_TYPE_TW_FUND_CATEGORY
      ) {
        portfolioPieResponse = await appAxios().post(
          `${getRoute({ route: `portfolioFundCategory` })}`
        )
      } else if (
        country === 'TW' &&
        params.groupType === FUND_GROUP_TYPE_TW_FUND_MARKET_TYPE
      ) {
        portfolioPieResponse = await appAxios().post(
          `${getRoute({ route: `portfolioFundMarket` })}`
        )
      } else if (
        country === 'TW' &&
        params.groupType === FUND_GROUP_TYPE_TW_FUND_BASE_CURRENCY
      ) {
        portfolioPieResponse = await appAxios().post(
          `${getRoute({ route: `portfolioFundCurrency` })}`
        )
      } else if (
        country === 'HK' &&
        params.type === FUND_TYPE_HK_MASTER_ACCOUNT &&
        params.groupType === FUND_GROUP_TYPE_HK_FUND_CATEGORY
      ) {
        portfolioPieResponse = await appAxios().post(
          `${getRoute({ route: `portfolioPieMAFundCategory` })}`
        )
      } else if (
        country === 'HK' &&
        params.groupType === FUND_GROUP_TYPE_HK_FUND_CATEGORY
      ) {
        portfolioPieResponse = await appAxios().post(
          `${getRoute({ route: `portfolioFundCategory` })}`
        )
      } else if (
        country === 'TW' &&
        params.accountType === FUND_ACCOUNT_TYPE_TW_MA &&
        params.type === FUND_TYPE_TW_LS
      ) {
        portfolioPieResponse = await appAxios().post(
          `${getRoute({ route: `portfolioPieMALS` })}`
        )
      } else if (
        country === 'TW' &&
        params.accountType === FUND_ACCOUNT_TYPE_TW_MA
      ) {
        portfolioPieResponse = await appAxios().post(
          `${getRoute({ route: `portfolioPieMA` })}`
        )
      } else if (country === 'TW' && params.type === FUND_TYPE_TW_LS) {
        portfolioPieResponse = await appAxios().post(
          `${getRoute({ route: `portfolioPieLS` })}`
        )
      } else if (
        country === 'HK' &&
        params.type === FUND_TYPE_HK_MASTER_ACCOUNT
      ) {
        portfolioPieResponse = await appAxios().post(
          `${getRoute({ route: `portfolioPieMA` })}`
        )
      } else if (country === 'HK' && params.type === FUND_TYPE_HK_REIP) {
        portfolioPieResponse = await appAxios().post(
          `${getRoute({ route: `portfolioPieREIP` })}`
        )
      }
    }
    // FOR development purpose only to mock the response for portfolio in a different currency and order type
    else if (params.currency && params.currency !== defaultCurrency) {
      portfolioPieResponse = await appAxios().post(
        `${getRoute({ route: `portfolioPieUsd` })}`
      )
    } else if (
      country === 'HK' &&
      params.groupType === FUND_GROUP_TYPE_HK_FUND_CATEGORY
    ) {
      portfolioPieResponse = await appAxios().get(
        `${getRoute({
          route: 'portfolioFundCategory',
        })}`
      )
    } else if (
      country === 'TW' &&
      params.groupType === FUND_GROUP_TYPE_TW_FUND_MARKET_TYPE
    ) {
      portfolioPieResponse = await appAxios().get(
        `${getRoute({
          route: 'portfolioFundMarket',
        })}`
      )
    } else if (
      country === 'TW' &&
      params.groupType === FUND_GROUP_TYPE_TW_FUND_CATEGORY
    ) {
      portfolioPieResponse = await appAxios().get(
        `${getRoute({
          route: 'portfolioFundCategory',
        })}`
      )
    } else if (
      country === 'TW' &&
      params.groupType === FUND_GROUP_TYPE_TW_FUND_BASE_CURRENCY
    ) {
      portfolioPieResponse = await appAxios().get(
        `${getRoute({
          route: 'portfolioFundCurrency',
        })}`
      )
    } else if (params.type === FUND_TYPE_TW_LS) {
      portfolioPieResponse = await appAxios().post(
        `${getRoute({ route: `portfolioPieLS` })}`
      )
    } else if (params.type === FUND_TYPE_TW_RSP) {
      portfolioPieResponse = await appAxios().post(
        `${getRoute({ route: `portfolioPieRSP` })}`
      )
    } else if (params.type === FUND_TYPE_HK_MASTER_ACCOUNT) {
      portfolioPieResponse = await appAxios().post(
        `${getRoute({ route: `portfolioPieMA` })}`
      )
    } else if (params.type === FUND_TYPE_HK_REIP) {
      portfolioPieResponse = await appAxios().post(
        `${getRoute({ route: `portfolioPieREIP` })}`
      )
    } else if (params.accountType === FUND_ACCOUNT_TYPE_TW_EC) {
      portfolioPieResponse = await appAxios().post(
        `${getRoute({ route: `portfolioPieEC` })}`
      )
    } else if (params.accountType === FUND_ACCOUNT_TYPE_TW_MA) {
      portfolioPieResponse = await appAxios().post(
        `${getRoute({ route: `portfolioPieMA` })}`
      )
    }

    // FUND_CATEGORY / FUND_MARKET / FUND_CURRENCY
    if (params.groupType !== FUND_GROUP_TYPE_HK_FUND_CODE) {
      dispatch(
        updateBreakdownData({
          responseData: portfolioPieResponse.data,
          label: params.groupType,
        })
      )
      return null
    }

    // FUND_CODE
    const transformedData = transformHoldingPreviewData(
      portfolioPieResponse.data,
      country,
      fundList
    )

    const sortedData = sorted({ data: transformedData })
    dispatch(updateInitialSortedFunds(sortedData))

    return transformedData
  }
)

export default loadPieData
