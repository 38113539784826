import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  BuyFundType,
  ErrorResponse,
  OrderConfirmationResponse,
  RedemptionFundType,
  SwitchFundType,
  TradingFundData,
  UpdateRedemptEstimateValueResponse,
  UrlTradingState,
} from '../../typings/UrlTrading'
import cancelTradingOrder from './actions/cancelTradingOrder'
import fetchTradingFundList from './actions/fetchUrlTradingFundList'
import submitTradingFundList from './actions/submitTradingFundList'
import updateRedemptEstimateValue from './actions/updateRedemptEstimateValue'
import updateUserConsent from './actions/updateUserConsent'
import validateAllotment, {
  ValidateAllotmentResponse,
} from './actions/validateAllotment'

interface UpdateBuyFundParams {
  fundCode: string
  amount: number
}

interface RemoveSingleOrderParams {
  fundType: 'allotment' | 'switch' | 'redemption'
  fundCode?: string
  inFundCode?: string
  outFundCode?: string
}

interface UpdateRedemptionValueParams {
  fundCode: string
  unit: number
  percent: number
}

interface UpdateSwitchFundValueParams {
  outFundCode: string
  unit: number
  percent: number
}

const initialState: UrlTradingState = {
  oid: '',
  clientId: '',
  orders: {
    isFirst: true,
    isActive: null,
    loading: true,
    isEmpty: false,
    isValidationLoading: false,
    list: {
      allotmentList: [],
      switchList: [],
      redemptionList: [],
    },
  },
  confirmedOrders: {
    isConfirmed: false,
    loading: false,
    error: {
      error: false,
      incorrectPin: false,
    },
    data: {
      error: false,
      orderReceivedDate: '',
      allotmentOrderList: [],
      switchOrderList: [],
      redemptionList: [],
    },
  },
}

export const urlTradingSlice = createSlice({
  name: 'urlTrading',
  initialState,
  reducers: {
    removeSingleOrder: (
      state,
      action: PayloadAction<RemoveSingleOrderParams>
    ) => {
      switch (action.payload.fundType) {
        case 'allotment':
          state.orders.list.allotmentList =
            state.orders.list.allotmentList.filter(
              (fund: BuyFundType) => fund.fundCode !== action.payload.fundCode
            )
          break
        case 'redemption':
          state.orders.list.redemptionList =
            state.orders.list.redemptionList.filter(
              (fund: RedemptionFundType) =>
                fund.fundCode !== action.payload.fundCode
            )
          break
        case 'switch':
          state.orders.list.switchList = state.orders.list.switchList.filter(
            (fund: SwitchFundType) =>
              fund.outFundCode !== action.payload.outFundCode &&
              fund.inFundCode !== action.payload.inFundCode
          )
          break
        default:
      }
      // check number of orders
      const numOfOrders: number =
        state.orders.list.allotmentList.length +
        state.orders.list.redemptionList.length +
        state.orders.list.switchList.length
      if (numOfOrders === 0) {
        state.orders.isEmpty = true
      }
    },
    updateBuyFundAmount: (
      state,
      action: PayloadAction<UpdateBuyFundParams>
    ) => {
      const targetFundIndex = state.orders.list.allotmentList.findIndex(
        (fund: BuyFundType) => fund.fundCode === action.payload.fundCode
      )
      if (targetFundIndex < 0) return
      const editFund = {
        ...state.orders.list.allotmentList[targetFundIndex],
        amount: action.payload.amount,
        validateResponse: {
          error: false,
          message: null,
        },
      }
      state.orders.list.allotmentList[targetFundIndex] = editFund
    },
    updateRedemptionValue: (
      state,
      action: PayloadAction<UpdateRedemptionValueParams>
    ) => {
      const targetFundIndex = state.orders.list.redemptionList.findIndex(
        (fund: RedemptionFundType) => fund.fundCode === action.payload.fundCode
      )
      const editFund = {
        ...state.orders.list.redemptionList[targetFundIndex],
        unit: action.payload.unit,
        percent: action.payload.percent,
      }
      state.orders.list.redemptionList[targetFundIndex] = editFund
    },
    updateSwitchFundValue: (
      state,
      action: PayloadAction<UpdateSwitchFundValueParams>
    ) => {
      const targetFundIndex = state.orders.list.switchList.findIndex(
        (fund: SwitchFundType) =>
          fund.outFundCode === action.payload.outFundCode
      )
      const editFund = {
        ...state.orders.list.switchList[targetFundIndex],
        switchUnit: action.payload.unit,
        percent: action.payload.percent,
      }
      state.orders.list.switchList[targetFundIndex] = editFund
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTradingFundList.pending, (state) => {
      state.orders.loading = true
    })
    builder.addCase(
      fetchTradingFundList.fulfilled,
      (state, action: PayloadAction<TradingFundData>) => {
        state.orders = action.payload.orders
        state.clientId = action.payload.clientId
        state.oid = action.payload.oid
      }
    )
    builder.addCase(submitTradingFundList.pending, (state) => {
      state.confirmedOrders.loading = true
    })
    builder.addCase(
      submitTradingFundList.fulfilled,
      (
        state,
        action: PayloadAction<OrderConfirmationResponse | ErrorResponse>
      ) => {
        if (action.payload.error) {
          const responseData = { ...(action.payload as ErrorResponse) }
          state.confirmedOrders.error = responseData
        } else {
          const resposneData = {
            ...(action.payload as OrderConfirmationResponse),
          }
          state.confirmedOrders.data = resposneData
          state.confirmedOrders.isConfirmed = true
          state.confirmedOrders.loading = false
        }
      }
    )
    builder.addCase(cancelTradingOrder.fulfilled, (state) => {
      state.orders.isActive = false
    })
    builder.addCase(updateUserConsent.fulfilled, (state) => {
      state.orders.isFirst = false
    })
    builder.addCase(validateAllotment.pending, (state) => {
      state.orders.isValidationLoading = true
    })
    builder.addCase(
      validateAllotment.fulfilled,
      (state, action: PayloadAction<ValidateAllotmentResponse>) => {
        const targetFundIndex = state.orders.list.allotmentList.findIndex(
          (fund: BuyFundType) => fund.fundCode === action.payload.fundCode
        )
        const updatedFund = {
          ...state.orders.list.allotmentList[targetFundIndex],
          validateResponse: {
            error: action.payload.error,
            message: action.payload.message,
          },
        }
        if (updatedFund) {
          state.orders.list.allotmentList[targetFundIndex] = updatedFund
        }
        state.orders.isValidationLoading = false
      }
    )
    builder.addCase(updateRedemptEstimateValue.pending, (state) => {
      state.orders.isValidationLoading = true
    })
    builder.addCase(
      updateRedemptEstimateValue.fulfilled,
      (state, action: PayloadAction<UpdateRedemptEstimateValueResponse>) => {
        const { fundType, fundCode, error, value } = action.payload
        if (fundType === 'redemption') {
          const targetFundIndex = state.orders.list.redemptionList.findIndex(
            (fund: RedemptionFundType) => fund.fundCode === fundCode
          )
          const updatedFund = {
            ...state.orders.list.redemptionList[targetFundIndex],
            redemptEstimateValue: value,
            unitError: error,
          }
          state.orders.list.redemptionList[targetFundIndex] = updatedFund
          state.orders.isValidationLoading = false
        } else {
          const targetFundIndex = state.orders.list.switchList.findIndex(
            (fund: SwitchFundType) => fund.outFundCode === fundCode
          )
          const updatedFund = {
            ...state.orders.list.switchList[targetFundIndex],
            outFund: {
              ...state.orders.list.switchList[targetFundIndex].outFund,
              redemptEstimateValue: value,
              unitError: error,
            },
          }
          state.orders.list.switchList[targetFundIndex] = updatedFund
          state.orders.isValidationLoading = false
        }
      }
    )
  },
})

export default urlTradingSlice.reducer
