import { FunctionComponent } from 'react'

import { IconProps } from '../../typings/Icon'
import { ICON_DEFAULT_COLOR } from './constants'

const FilterIcon: FunctionComponent<IconProps> = ({
  width,
  height,
  className,
  ...props
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox='0 0 25 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <line x1='0.163086' y1='11.5' x2='15.1631' y2='11.5' stroke='#1C1C1C' />
      <line x1='0.163086' y1='19.5' x2='5.16309' y2='19.5' stroke='#1C1C1C' />
      <circle cx='17.6631' cy='11.5' r='3' stroke='#1C1C1C' />
      <circle cx='7.66309' cy='19.5' r='3' stroke='#1C1C1C' />
      <line x1='0.163086' y1='3.5' x2='5.16309' y2='3.5' stroke='#1C1C1C' />
      <line x1='10.1631' y1='3.5' x2='24.1631' y2='3.5' stroke='#1C1C1C' />
      <circle cx='7.66309' cy='3.5' r='3' stroke='#1C1C1C' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.1631 12L24.1631 12V11L20.1631 11V12ZM10.1631 20H24.1631V19H10.1631V20Z'
        fill={ICON_DEFAULT_COLOR}
      />
    </svg>
  )
}

FilterIcon.defaultProps = {
  width: 25,
  height: 23,
  className: '',
}

export default FilterIcon
