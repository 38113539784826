import { questionGroups } from '../redux/general-suitability/data'
import {
  DependentOption,
  DependentOptionCondition,
  DependentQuestion,
  GeneralSuitabilityQuestion,
  QuestionAnswer,
  QuestionAnswers,
  UpdateType,
  ValueCompareConditionType,
} from '../typings/GeneralSuitability'
import compareValue from './compareValue'

// This function is to validate the dependOptions array
// No multiple triggers for same question is allowed
export const validateDependOptions = (
  dependOptions: DependentOption[] | undefined,
  currentQuestionNumber: number
): void => {
  if (!dependOptions) return
  let triggerQuestionsMap: {
    [questionNumber: number]: number
  } = {}
  dependOptions.forEach((dependOption: DependentOption) => {
    if (dependOption.trigger.questionNumber) {
      const triggerQuestionNo = dependOption.trigger.questionNumber
      if (triggerQuestionsMap[triggerQuestionNo]) {
        triggerQuestionsMap = {
          ...triggerQuestionsMap,
          [triggerQuestionNo]: triggerQuestionsMap[triggerQuestionNo] + 1,
        }
      } else {
        triggerQuestionsMap = {
          ...triggerQuestionsMap,
          [triggerQuestionNo]: 1,
        }
      }
    }
  })

  Object.entries(triggerQuestionsMap).forEach(([questionNumber, value]) => {
    if (value > 1) {
      throw new Error(
        `Multiple Triggers for Question ${questionNumber} from Question ${currentQuestionNumber} are detected. Please group all triggers for Question ${questionNumber} together.`
      )
    }
  })
}

export const updateAnswerAndMandatory = (
  originalAnswers: QuestionAnswers,
  updates: UpdateType[]
): QuestionAnswers => {
  let updatedAnswers = { ...originalAnswers }
  updates.forEach(({ questionNumber, ...answerObject }: UpdateType) => {
    updatedAnswers = {
      ...updatedAnswers,
      [questionNumber]: {
        ...updatedAnswers[questionNumber],
        ...answerObject,
      },
    }
  })

  return updatedAnswers
}

export const getQuestionByQuestionNumber = (
  questionNumber: number
): GeneralSuitabilityQuestion | DependentQuestion | null => {
  let question: GeneralSuitabilityQuestion | DependentQuestion | null = null
  questionGroups.forEach(({ questions }) =>
    questions.forEach((mQuestion: GeneralSuitabilityQuestion) => {
      if (mQuestion.questionNumber === questionNumber) {
        question = mQuestion
      } else if (mQuestion.dependentQuestions) {
        mQuestion.dependentQuestions.forEach((dQuestion) => {
          if (dQuestion.questionNumber === questionNumber) {
            question = dQuestion
          }
        })
      }
    })
  )

  return question
}

export const validateAnswerAndDependentQuestions = (
  originalAnswers: QuestionAnswers,
  selectedId: number,
  selectedOption: QuestionAnswer
): QuestionAnswers => {
  // get the updated questionAnswer object
  const updatedAnswer = {
    ...originalAnswers,
    [selectedId]: {
      ...originalAnswers[selectedId],
      answer: selectedOption,
    },
  }
  // triggers extra updates for dependent questions
  const triggers: UpdateType[] = []

  const revertPreviousCondition = (
    dependentQuestion: DependentQuestion | GeneralSuitabilityQuestion,
    dependentOption: DependentOption
  ) => {
    validateDependOptions(dependentQuestion.dependOptions, selectedId)
    triggers.push({
      questionNumber: dependentOption.trigger.questionNumber
        ? dependentOption.trigger.questionNumber
        : dependentQuestion.questionNumber,
      ...dependentOption.trigger,
      mandatory: dependentOption.trigger.mandatory
        ? !dependentOption.trigger.mandatory
        : true,
      autofilled: dependentOption.trigger.autofilled
        ? !dependentOption.trigger.autofilled
        : false,
    })
  }

  // 1. Get current question data
  const currentQuestion = getQuestionByQuestionNumber(selectedId)

  // 2. Check if there is affectedQuestions and get a list of affected questions
  if (
    currentQuestion?.affectedQuestions &&
    currentQuestion.affectedQuestions.length > 0
  ) {
    const listOfDependentQuestions = currentQuestion.affectedQuestions
      .map((affectedQuestionNumber: number) =>
        getQuestionByQuestionNumber(affectedQuestionNumber)
      )
      .filter(
        (
          dependentQuestion:
            | GeneralSuitabilityQuestion
            | DependentQuestion
            | null
        ) => dependentQuestion !== null
      )

    // 3. Map thru list of affected questions and see if current and previous answers falls into their options
    if (listOfDependentQuestions.length > 0) {
      listOfDependentQuestions.forEach(
        (
          dependentQuestion:
            | GeneralSuitabilityQuestion
            | DependentQuestion
            | null
        ) => {
          if (!dependentQuestion) return

          if (dependentQuestion.dependOptions) {
            try {
              dependentQuestion.dependOptions.forEach(
                (dependentOption: DependentOption) => {
                  // map thru and count the conditions array that match current questionAnswers Object
                  let conditionMatch = 0

                  dependentOption.conditions.forEach(
                    (
                      condition:
                        | DependentOptionCondition
                        | ValueCompareConditionType
                    ) => {
                      // IF DEPENDENT OPTION
                      if (
                        'questionNumber' in condition &&
                        'option' in condition
                      ) {
                        if (
                          !updatedAnswer[condition.questionNumber] ||
                          !updatedAnswer[condition.questionNumber].answer
                        )
                          return
                        // IF Answer is an array of string (i.e Multiselect)
                        if (
                          typeof updatedAnswer[condition.questionNumber]
                            .answer === 'object'
                        ) {
                          const answersArr = updatedAnswer[
                            condition.questionNumber
                          ].answer as string[]
                          const prevAnswerArr = originalAnswers[
                            condition.questionNumber
                          ].answer as string[]

                          const isContainCurrentAnswer =
                            answersArr?.filter(
                              (answer: string) =>
                                condition.option.indexOf(answer) >= 0
                            ).length > 0

                          const isContainPrevAnswer =
                            prevAnswerArr?.filter(
                              (answer: string) =>
                                condition.option.indexOf(answer) >= 0
                            ).length > 0

                          if (isContainCurrentAnswer) {
                            conditionMatch += 1
                          } else if (isContainPrevAnswer) {
                            // untrigger when the condition match any of the prev answer, meaning the current dependent question might be trigger by previous answer
                            revertPreviousCondition(
                              dependentQuestion,
                              dependentOption
                            )
                          }
                        }
                        if (
                          // if answer type is a string
                          typeof updatedAnswer[condition.questionNumber]
                            .answer === 'string'
                        ) {
                          const currentNewAnswer = updatedAnswer[
                            condition.questionNumber
                          ].answer as string

                          const prevConditionAnswer = originalAnswers[
                            condition.questionNumber
                          ].answer as string
                          if (condition.option.includes(currentNewAnswer)) {
                            conditionMatch += 1
                          } else if (
                            condition.option.includes(
                              prevConditionAnswer as string
                            )
                          ) {
                            // untrigger when the condition match any of the prev answer, meaning the current dependent question might be trigger by previous answer
                            revertPreviousCondition(
                              dependentQuestion,
                              dependentOption
                            )
                          }
                        }
                      }
                      // IF VALUE COMPARE OPTION
                      if (
                        'compare' in condition &&
                        'value1' in condition &&
                        'value2' in condition
                      ) {
                        const value1 =
                          updatedAnswer[condition.value1.questionNumber].answer
                        const value2 =
                          updatedAnswer[condition.value2.questionNumber].answer
                        if (value1 && value2) {
                          if (
                            compareValue({
                              value1,
                              value2,
                              compare: condition.compare,
                            })
                          ) {
                            conditionMatch += 1
                          } else {
                            // untrigger
                            revertPreviousCondition(
                              dependentQuestion,
                              dependentOption
                            )
                          }
                        }
                      }
                    }
                  )
                  if (
                    // check if all conditions match
                    conditionMatch === dependentOption.conditions.length
                  ) {
                    validateDependOptions(
                      dependentQuestion.dependOptions,
                      selectedId
                    )
                    let triggerOption = {
                      ...dependentOption.trigger,
                    }
                    // get trigger answer from answerFrom option
                    if (triggerOption.answerFrom) {
                      triggerOption = {
                        ...triggerOption,
                        answer: updatedAnswer[triggerOption.answerFrom].answer,
                      }
                    }
                    delete triggerOption.answerFrom
                    triggers.push({
                      questionNumber: dependentOption.trigger.questionNumber
                        ? dependentOption.trigger.questionNumber
                        : dependentQuestion.questionNumber,
                      ...triggerOption,
                    })
                  }
                }
              )
            } catch (error) {
              // eslint-disable-next-line no-console
              console.log(error)
            }
          }
        }
      )
    }
  }

  return updateAnswerAndMandatory(originalAnswers, [
    {
      questionNumber: selectedId,
      answer: selectedOption,
    },
    ...triggers,
  ])
}
