export const PAYMENT_TYPE_CODE_IS_INTEGRATED = 'OF_MA_ZZZ'

export const OFF_SHORE = 'off-shore'
export const ALL_PAYMENT_TYPE = 'all'
export const INTEGRATED = 'integrated'

export const PAYMENT_TYPE_CODE_ON_SHORE_TWD = 'ON_TWD'
export const PAYMENT_TYPE_CODE_ON_SHORE_FOREIGN = 'ON_ZZZ'

export const PAYMENT_TYPE_CODE_OFF_SHORE_TWD = 'OF_TWD'
export const PAYMENT_TYPE_CODE_OFF_SHORE_FOREIGN = 'OF_ZZZ'

export const IS_INTEGRATED = [PAYMENT_TYPE_CODE_IS_INTEGRATED]
export const IS_ON_SHORE = [
  PAYMENT_TYPE_CODE_ON_SHORE_TWD,
  PAYMENT_TYPE_CODE_ON_SHORE_FOREIGN,
]
export const IS_OFF_SHORE = [
  PAYMENT_TYPE_CODE_OFF_SHORE_TWD,
  PAYMENT_TYPE_CODE_OFF_SHORE_FOREIGN,
]

export const IS_ON_OFF_TWD = [
  PAYMENT_TYPE_CODE_ON_SHORE_TWD,
  PAYMENT_TYPE_CODE_OFF_SHORE_TWD,
]
export const IS_ON_OFF_ZZZ = [
  PAYMENT_TYPE_CODE_ON_SHORE_FOREIGN,
  PAYMENT_TYPE_CODE_OFF_SHORE_FOREIGN,
]

export const IS_OF_MA_ZZZ = [PAYMENT_TYPE_CODE_IS_INTEGRATED]

export const ON_OFF_TWD = 'ON_OFF_TWD' // 投信/境外帳戶 - 新臺幣交割
export const ON_OFF_ZZZ = 'ON_OFF_ZZZ' // 投信/境外帳戶 - 外幣交割
export const OF_MA_ZZZ = 'OF_MA_ZZZ' // 綜合理財帳戶(MA) - 外幣交割
export const PAYMENT_SWITCH_NULL = 'NULL'

export const SHOW_PRIORITY_PAYMENT_TYPE = [
  ON_OFF_TWD, // 投信/境外帳戶 - 新臺幣交割
  ON_OFF_ZZZ, // 投信/境外帳戶 - 外幣交割
  OF_MA_ZZZ, // 綜合帳戶 - 外幣交割
  PAYMENT_SWITCH_NULL,
]

// NEW PAYMENT TYPE SWITCH
export const ON_ZZZ = 'ON_ZZZ' // 投信帳戶 - 外幣交割
export const OFF_TWD = 'OFF_TWD' // 境外帳戶 - 新台幣交割
export const OFF_ZZZ = 'OFF_ZZZ' // 境外帳戶 - 外幣交割
