import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootStateOrAny } from 'react-redux'

import { appAxios, getRoute } from '../../../helpers'
import {
  FundResponseHKType,
  HoldingFundSetting,
  HoldingFundTypeWithSetting,
} from '../../../typings/Portfolio'

type ToggleBaseCurrencyParams = {
  viewInBaseCurrency: boolean
  fundCode: string
  baselineCurrency?: string
}

const viewFundInBaseCurrency = createAsyncThunk<
  { fundCode: string; fundData: HoldingFundSetting } | undefined,
  ToggleBaseCurrencyParams
>(
  'viewFundInBaseCurrency',
  async (params: ToggleBaseCurrencyParams, { getState }) => {
    const { fundCode, baselineCurrency, viewInBaseCurrency } = params
    const store: RootStateOrAny = getState()
    const { country } = store.system

    // TW portfolio site toggle view fund in base currency , do not need to call API to get baseline currency data
    if (country === 'TW')
      return {
        fundCode,
        fundData: {
          viewInBaseCurrency,
        },
      }

    // Check if the fund already possess inBaseLinCurrency data , if not, we will need to call api to update fund in baseline currency data
    const currentViewingFund = store.holding.data.fundList.filter(
      (holding: HoldingFundTypeWithSetting) => holding.fundCode === fundCode
    )[0]
    if (currentViewingFund.inBaseLineCurrency) return undefined

    const portfolioRequest = {
      type: 'ALL',
      msAccountNo: '',
      costPreference: 'S',
      settCostCcy: baselineCurrency,
      settCcy: baselineCurrency,
      profitCcy: baselineCurrency,
      showCcy: baselineCurrency,
      isPieChart: false,
      groupType: '',
    }

    // HK portfolio site, we will need to call the portfolio holdings in a different currency and get the values from that base currency
    const portfolioPieRes = await appAxios().post(
      `${getRoute({ route: `portfolio` })}`,
      {
        ...portfolioRequest,
      }
    )
    const currentFund: FundResponseHKType =
      portfolioPieRes.data.holdings.filter(
        (holding: FundResponseHKType) => holding.fundCode === fundCode
      )[0]

    return {
      fundCode,
      fundData: {
        viewInBaseCurrency,
        inBaseLineCurrency: {
          totalCost: currentFund.cost,
          value: currentFund.value,
          return: currentFund.returnPercent,
          profit: currentFund.profit,
          grossDividend: currentFund.grossDividend,
        },
      },
    }
  }
)

export default viewFundInBaseCurrency
