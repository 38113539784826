import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  PORTFOLIO_VIEW_DEFAULT,
  PortfolioViewTypes,
} from '../../widgets/my-portfolio/portfolio/constants'
import updateShowWelcomeOverlayStatus from './actions/updateShowWelcomeOverlayStatus'

type PortfolioState = {
  currentView: PortfolioViewTypes | string
  historyViews: PortfolioViewTypes[] | string[]
  shouldShowWelcomeOverlay: boolean | undefined
}

const initialState: PortfolioState = {
  currentView: PORTFOLIO_VIEW_DEFAULT,
  historyViews: [],
  shouldShowWelcomeOverlay: undefined,
}

export const portfolioSlice = createSlice({
  name: 'portfolio',
  initialState,
  reducers: {
    updateCurrentView: (
      state,
      action: PayloadAction<PortfolioViewTypes | string>
    ) => {
      state.historyViews = [state.currentView, ...state.historyViews]
      state.currentView = action.payload
    },
    goBackToPreviousView: (state) => {
      const previousView = state.historyViews[0]
      state.currentView = previousView
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      updateShowWelcomeOverlayStatus.fulfilled,
      (state, action: PayloadAction<boolean>) => {
        // eslint-disable-next-line no-param-reassign
        state.shouldShowWelcomeOverlay = action.payload
      }
    )
  },
})

export default portfolioSlice.reducer
