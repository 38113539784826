import { FunctionComponent } from 'react'

import { IconProps } from '../../typings/Icon'

const Alert: FunctionComponent<IconProps> = ({
  width,
  height,
  className,
  color,
  ...props
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 12 12'
      className={className}
      fill='none'
      {...props}
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='6' cy='6' r='6' fill={color} />
      <path
        d='M5.25753 5.20132V3.51172H6.85033V5.20132L6.36633 7.51572H5.74153L5.25753 5.20132ZM6.04953 9.72452C5.809 9.72452 5.60367 9.64825 5.43353 9.49572C5.26927 9.34319 5.18713 9.13785 5.18713 8.87972C5.18713 8.62159 5.26927 8.41625 5.43353 8.26372C5.60367 8.10532 5.809 8.02612 6.04953 8.02612C6.29007 8.02612 6.49247 8.10532 6.65673 8.26372C6.82687 8.41625 6.91193 8.62159 6.91193 8.87972C6.91193 9.13785 6.82687 9.34319 6.65673 9.49572C6.49247 9.64825 6.29007 9.72452 6.04953 9.72452Z'
        fill='white'
      />
    </svg>
  )
}

Alert.defaultProps = {
  width: 12,
  height: 12,
  className: '',
  color: '#B60000',
}

export default Alert
