import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import _merge from 'lodash/merge'

import { addLoading, removeLoading } from '../redux/loading/actions'
import { store } from '../store'

type AppAxiosParams = {
  requestConfigs?: AxiosRequestConfig
  showLoading?: boolean
}

const appAxios = (params: AppAxiosParams = {}): AxiosInstance => {
  const { system: country } = store.getState()
  const { showLoading = true, requestConfigs } = params

  const domain = process.env[`REACT_APP_API_DOMAIN_${country}`] || ''
  const instance = axios.create(
    _merge(
      {
        baseURL: domain,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      },
      requestConfigs
    )
  )

  // Add a request interceptor
  instance.interceptors.request.use(
    (config) => {
      // Do something before request is sent
      if (showLoading) {
        store.dispatch(addLoading())
      }

      return config
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error)
    }
  )

  // Add a response interceptor
  instance.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      if (showLoading) {
        store.dispatch(removeLoading())
      }

      return response
    },
    (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (showLoading) {
        store.dispatch(removeLoading())
      }
      return Promise.reject(error)
    }
  )

  return instance
}

export default appAxios
