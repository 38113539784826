import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'core-js/stable'
import 'core-js/features/string/repeat'
import 'fast-text-encoding/text'
import 'custom-event-polyfill'
import './i18n'
import './index.scss'

import { addLicense } from '@amcharts/amcharts4/core'
import { StrictMode } from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'

import App from './App'
import { store } from './store'
// import reportWebVitals from './reportWebVitals'

// amCharts 4 license
// DO NOT remove
addLicense('CH306025826')

render(
  <StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
