import { store } from '../store'
import { UserCountryType } from '../typings/UserInfo'

interface CountrySpecificDataType<T> {
  HK: T
  TW: T
}

const getCountriesSpecificData = <T>(data: CountrySpecificDataType<T>): T => {
  const state = store.getState()
  const { country }: { country: UserCountryType } = state.system

  return data[country]
}

export default getCountriesSpecificData
