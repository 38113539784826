import './NavListLegacy.scss'

import { FunctionComponent, useEffect, useState } from 'react'
import { Accordion } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { generatePath } from 'react-router'
import { useLocation } from 'react-router-dom'

import getCountriesSpecificData from '../../../helpers/getCountriesSpecificData'
import navigationLegacyRoutes from '../../../routes/navigation-legacy'
import profileRoutes from '../../../routes/navigation-legacy-profile'
import SiteRoutes from '../../../routes/SiteNavigation'
import { RouteType } from '../../../typings/Routes'
import DropdownLegacy from '../../dropdown-legacy/DropdownLegacy'
import Tab from '../../tab'

const NAV_ITEM_NOT_SELECTED = -1

const MAIN_NAV_ITEM_STYLES = 'underline'
const SUB_NAV_ITEM_STYLES = 'highlight'
const NAVLIST_VARIANT_MOBILE = 'mobile'
const NAVLIST_VARIANT_LG = 'lg'
const NAVLIST_VARIANT_XL = 'xl'

interface NavListProps {
  variant:
    | typeof NAVLIST_VARIANT_MOBILE
    | typeof NAVLIST_VARIANT_LG
    | typeof NAVLIST_VARIANT_XL
  closeMobileMenu: () => void
}

interface MobileNavListProps {
  routes: RouteType[]
  closeMobileMenu: () => void
  activeTab: string
}
const NavListMobile: FunctionComponent<MobileNavListProps> = ({
  routes,
  closeMobileMenu,
  activeTab,
}: MobileNavListProps) => {
  const { t } = useTranslation()
  const onClickTabHandler = () => {
    closeMobileMenu()
  }
  const { pathname } = useLocation()
  const [currentActiveKey, setCurrentActiveKey] = useState<string>('')
  const [subActiveTab, setSubActiveTab] = useState<string>('fund-search')
  const TAB_THEME_TERTIARY = 'darkblue'

  const toggleActiveKey = (key: string) => {
    setCurrentActiveKey(key)
  }

  useEffect(() => {
    if (pathname === '/') {
      setSubActiveTab('fund-search')
    }
  }, [pathname])

  return (
    <Accordion
      className='nav-items'
      defaultActiveKey={routes[0].translationKeyName}
      as='ul'
    >
      {routes.map((main) => (
        <li key={main.translationKeyName}>
          {main.subMenu ? (
            <>
              <Tab
                className='nav-items__item'
                id={main.routeName}
                activeStyle={MAIN_NAV_ITEM_STYLES}
                data-tab-id={main.routeName}
                active={activeTab === main.translationKeyName}
                navItem
                data-testid={`nav-tab-${main.translationKeyName}`}
                data-tab-type='nav-toggle-button'
              >
                <Accordion.Toggle
                  as='span'
                  eventKey={main.translationKeyName}
                  className={
                    currentActiveKey === main.translationKeyName
                      ? 'nav-items__item__selected'
                      : ''
                  }
                  onClick={() => toggleActiveKey(main.translationKeyName)}
                >
                  {t(`nav:${main.translationKeyName}`)}
                </Accordion.Toggle>
              </Tab>
              <Accordion.Collapse eventKey={main.translationKeyName}>
                <ul
                  id='sub-menu-portfolio'
                  className='nav-items__sub-items-div d-xl-none'
                >
                  {main.subMenu.map((subMenuItem) => (
                    <li key={subMenuItem.translationKeyName}>
                      <Tab
                        className='nav-items__sub-item'
                        activeStyle={SUB_NAV_ITEM_STYLES}
                        data-testid={`nav-sub-tab-${subMenuItem.translationKeyName}`}
                        data-tab-id={subMenuItem.translationKeyName}
                        data-tab-type='nav-sub-item'
                        onClick={onClickTabHandler}
                        href={generatePath(SiteRoutes[main.routeName])}
                        active={subActiveTab === subMenuItem.translationKeyName}
                        theme={TAB_THEME_TERTIARY}
                      >
                        {t(`nav:${subMenuItem.translationKeyName}`)}
                      </Tab>
                    </li>
                  ))}
                </ul>
              </Accordion.Collapse>
            </>
          ) : (
            <Tab
              className='nav-items__item'
              activeStyle={MAIN_NAV_ITEM_STYLES}
              onClick={onClickTabHandler}
              data-tab-id={main.translationKeyName}
              href={generatePath(SiteRoutes[main.routeName])}
              active={activeTab === main.translationKeyName}
              navItem
              data-testid={`nav-tab-${main.translationKeyName}`}
              title={main.translationKeyName}
            >
              {t(`nav:${main.translationKeyName}`)}
            </Tab>
          )}
        </li>
      ))}
    </Accordion>
  )
}

interface LGNavListProps {
  activeTab: string
  routes: RouteType[]
}

const NavListLG: FunctionComponent<LGNavListProps> = ({
  routes,
  activeTab,
}: LGNavListProps) => {
  const { t } = useTranslation()
  return (
    <ul className='nav-items'>
      {routes.map((main) => (
        <li
          id={main.translationKeyName}
          key={main.translationKeyName}
          className='nav-items__list'
        >
          {main.subMenu ? (
            <DropdownLegacy
              list={main}
              activeIndex={NAV_ITEM_NOT_SELECTED}
              translationIndexName='nav'
              className='nav-items__item'
              data-testid={`nav-tab-${main.translationKeyName}`}
            />
          ) : (
            <Tab
              className='nav-items__item'
              activeStyle={MAIN_NAV_ITEM_STYLES}
              active={main.translationKeyName === activeTab}
              href={generatePath(SiteRoutes[main.routeName])}
              navItem
              data-testid={`nav-tab-${main.translationKeyName}`}
            >
              {t(`nav:${main.translationKeyName}`)}
            </Tab>
          )}
        </li>
      ))}
    </ul>
  )
}

const NavList: FunctionComponent<NavListProps> = ({
  variant,
  closeMobileMenu,
}: NavListProps) => {
  const routes = getCountriesSpecificData(navigationLegacyRoutes)
  const profile = getCountriesSpecificData(profileRoutes)
  const isXL = useMediaQuery({ minWidth: 1200 })
  const displayRoutes = isXL ? routes : routes.concat(profile)
  const { pathname } = useLocation()
  const [activeTab, setActiveTab] = useState<string>('portfolio')

  useEffect(() => {
    if (pathname === '/') {
      setActiveTab('portfolio')
    } else {
      switch (pathname) {
        case pathname.match(/funds/)?.input:
        case pathname.match(/fund*/)?.input:
          setActiveTab('funds')
          break
        default:
          setActiveTab(pathname)
          break
      }
    }
  }, [pathname])

  if (variant === NAVLIST_VARIANT_MOBILE) {
    return (
      <NavListMobile
        routes={displayRoutes}
        closeMobileMenu={closeMobileMenu}
        activeTab={activeTab}
      />
    )
  }
  return <NavListLG activeTab={activeTab} routes={displayRoutes} />
}

export default NavList
