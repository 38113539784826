import classnames from 'classnames'
import { FunctionComponent, ReactNode } from 'react'
import { Modal as BootstrapModal } from 'react-bootstrap'

import { MODAL_CONTENT_SIZE_HEIGHT_AUTO } from '../../../constants/modal-elements'
import styles from './ModalComponents.module.scss'

interface ModalHeaderProps {
  title?: string
  noCloseIcon?: boolean
  withBackIcon?: boolean
  closeModalHandler?: () => void
  children?: ReactNode
  className?: string
}

export const ModalHeader: FunctionComponent<ModalHeaderProps> = ({
  title,
  noCloseIcon,
  withBackIcon,
  closeModalHandler,
  children,
  className = '',
}: ModalHeaderProps) => {
  return (
    <BootstrapModal.Header
      closeButton={!noCloseIcon}
      className={classnames(styles.oip__modalHeader, className)}
      data-testid='modal-heading'
    >
      {children || (
        <>
          {withBackIcon && closeModalHandler}
          <h2>{title}</h2>
        </>
      )}
    </BootstrapModal.Header>
  )
}

ModalHeader.defaultProps = {
  title: undefined,
  withBackIcon: undefined,
  closeModalHandler: undefined,
  noCloseIcon: false,
  children: undefined,
  className: '',
}

interface ModalFooterProps {
  children: ReactNode
  className?: string
}

export const ModalFooter: FunctionComponent<ModalFooterProps> = ({
  children,
  className,
}: ModalFooterProps) => {
  return (
    <div className={classnames(styles.oip__modalFooter, className)}>
      {children}
    </div>
  )
}

ModalFooter.defaultProps = {
  className: '',
}

interface ModalWrapperProps {
  children: ReactNode
  id: string
  className?: string
}

export const ModalWrapper: FunctionComponent<ModalWrapperProps> = ({
  children,
  id,
  className,
  ...props
}: ModalWrapperProps) => {
  return (
    <div
      className={classnames(styles.oip__modalWrapper, className)}
      id={id}
      {...props}
    >
      {children}
    </div>
  )
}

ModalWrapper.defaultProps = {
  className: '',
}

interface ModalBodyProps {
  contentHeight?: typeof MODAL_CONTENT_SIZE_HEIGHT_AUTO
  children: ReactNode
  modalBodyClassNames?: string
  modalContentClassNames?: string
}

export const ModalBody: FunctionComponent<ModalBodyProps> = ({
  contentHeight,
  children,
  modalBodyClassNames = '',
  modalContentClassNames = '',
}: ModalBodyProps) => {
  return (
    <BootstrapModal.Body
      className={classnames(
        styles.oip__modalBody,
        {
          [styles.oip__modalBody__heightAuto]:
            contentHeight === MODAL_CONTENT_SIZE_HEIGHT_AUTO,
        },
        modalBodyClassNames
      )}
    >
      <div
        className={classnames(styles.oip__modalContent, modalContentClassNames)}
      >
        {children}
      </div>
    </BootstrapModal.Body>
  )
}

ModalBody.defaultProps = {
  contentHeight: undefined,
  modalBodyClassNames: '',
  modalContentClassNames: '',
}
