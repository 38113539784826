import { SiteRoutes } from '../typings/Routes'

const routes: SiteRoutes = {
  login: '/login',
  home: '/',
  myPortfolio: '/',
  funds: '/funds',
  inbox: '/inbox',
  fundDetails: '/fund/:fundId',
  privacyStatement: '/privacy-statement',
  termsOfUse: '/terms-of-use',
  securityInformation: '/security-information',
  message: '/message',
  profile: '/profile',
  support: '/support',
  watchlist: '/watchlist',
  clientTier: '/client-tier',
  urlTrading: '/url-trading',
  eStatement: '/eStatement',
  orderStatus: '/order-status',
  enhancedSuitability: '/enhanced-suitability',
  generalSuitability: '/general-suitability',
}

export default routes
