import classnames from 'classnames'
import { FunctionComponent } from 'react'

import {
  CHEVRON_DEFAULT_COLOR,
  CHEVRON_ORIENTATION_DOWN,
  CHEVRON_ORIENTATION_LEFT,
  CHEVRON_ORIENTATION_UP,
} from '../../constants/chevron'
import { ChevronColorType, ChevronOrientationType } from '../../typings/Chevron'
import { IconProps } from '../../typings/Icon'
import styles from './Icon.module.scss'

interface ChevronIconProps extends IconProps {
  width?: number
  height?: number
  className?: string
  orientation: ChevronOrientationType
  color?: ChevronColorType
}

const Chevron: FunctionComponent<ChevronIconProps> = ({
  orientation,
  width,
  height,
  className,
  color,
  ...props
}: ChevronIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={classnames(
        {
          [styles.chevron__up]: orientation === CHEVRON_ORIENTATION_UP,
          [styles.chevron__down]: orientation === CHEVRON_ORIENTATION_DOWN,
          [styles.chevron__left]: orientation === CHEVRON_ORIENTATION_LEFT,
          [styles.chevron__right]: orientation === CHEVRON_ORIENTATION_LEFT,
        },
        className
      )}
      {...props}
      viewBox='0 0 8 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.00037 1.54883L6.8337 7.38216L1.00037 13.2155'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

Chevron.defaultProps = {
  width: 8,
  height: 15,
  className: '',
  color: CHEVRON_DEFAULT_COLOR,
}

export default Chevron
