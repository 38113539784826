import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'

import type { AppDispatch, RootState } from './store'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const useAppMediaQuery = (): {
  isSmUp: boolean
  isMdUp: boolean
  isMd: boolean
  isLg: boolean
  isLgUp: boolean
  isLarge: boolean
} => {
  return {
    isSmUp: useMediaQuery({ minWidth: 576 }),
    isMdUp: useMediaQuery({ minWidth: 768 }),
    isLgUp: useMediaQuery({ minWidth: 992 }),
    isMd: useMediaQuery({ minWidth: 768, maxWidth: 991 }),
    isLg: useMediaQuery({ minWidth: 992 }),
    isLarge: useMediaQuery({ minWidth: 1200 }),
  }
}
