/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
import classnames from 'classnames'
import { FunctionComponent } from 'react'
import { Loader } from 'react-bootstrap-typeahead'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import Button from '../../../components/button'
import ReminderMessageCard from '../../../components/reminder-message-card'
import { REMINDER_MESSAGE_CARD_THEME_TEAL } from '../../../components/reminder-message-card/ReminderMessageCard'
import RiskRatingBar from '../../../components/risk-rating-bar'
import { getLocaleDateString } from '../../../helpers/formatDateTime'
import routes from '../../../routes/SiteNavigation'
import {
  QuestionnaireStatus,
  RiskLevel,
  STATUS_COMPLETED,
  STATUS_FAILED,
  STATUS_INCOMPLETE,
  STATUS_LOADING,
  STATUS_PASSED,
} from '../../../typings/GeneralSuitability'
import styles from './RiskAssessmentInfo.module.scss'

interface RiskAssessmentInfoProps {
  isCorporateAccount: boolean
  generalSuitabilityStatus: QuestionnaireStatus
  riskLevel: RiskLevel
  lastAssessDate: string | number | null
  enhancedSuitabiilityStatus: QuestionnaireStatus
  startQuestionnaire: () => void
}

/*
風險屬性評估首頁 - Body Content
Content depends on 
1. whether current account is corporated account (i.e if it is corporated account, no "重新評估" button should be shown)
2. GeneralSuitability Questionnaire Status - Completed VS Incomplete 
3. risk level to indicate the Risk Bar and content
4. enhanced suitability status (i.e if enhanced suitability status is incompleted, a reminder message will be shown)
*/

const RiskAssessmentInfo: FunctionComponent<RiskAssessmentInfoProps> = ({
  isCorporateAccount,
  generalSuitabilityStatus,
  enhancedSuitabiilityStatus,
  riskLevel,
  lastAssessDate,
  startQuestionnaire,
}: RiskAssessmentInfoProps) => {
  const history = useHistory()
  const { t } = useTranslation()
  const riskLevelT = t(`riskRating:client-risk-rating-${riskLevel}`)
  if (generalSuitabilityStatus === STATUS_LOADING) {
    return <Loader />
  }

  const renderRiskAssessmentHeader = () => {
    return (
      <div
        className={styles.riskAssessment__header}
        data-testid='risk-assessment-header'
      >
        <span>{t('generalSuitability:risk-assessment.client-risk-title')}</span>
        <div className='d-flex align-items-flex-start justify-content-between'>
          <span
            className={classnames(styles.riskAssessment__header__description, {
              [styles.riskAssessment__header__strong]:
                generalSuitabilityStatus === STATUS_INCOMPLETE,
            })}
          >
            {t(
              `generalSuitability:${`risk-assessment.client-risk-description${
                generalSuitabilityStatus === STATUS_INCOMPLETE
                  ? ''
                  : '-no-blanket'
              }`}`,
              {
                level: t(`riskRating:client-risk-rating-${riskLevel}`),
              }
            )}
          </span>
          {generalSuitabilityStatus === STATUS_INCOMPLETE &&
            !isCorporateAccount && (
              <Button
                onClick={startQuestionnaire}
                outlined
                data-testid='start-questionnaire-button'
              >
                {t('generalSuitability:button.reassess')}
              </Button>
            )}
        </div>
      </div>
    )
  }

  const renderRiskAssessmentContent = () => {
    return (
      <>
        <div className={styles.riskAssessment__infoDiv}>
          <p className={styles.riskAssessment__infoDiv__title}>
            {t('generalSuitability:risk-assessment.client-risk-explanation')}：
          </p>
          <Trans
            i18nKey={`generalSuitability:risk-assessment.client-risk-assessment-info.${riskLevel}`}
            components={{
              fundExploreLink: (
                <a
                  href='https://am.jpmorgan.com/tw/zh/asset-management/per/products/fund-explorer/onshore-funds'
                  target='_blank'
                  rel='noreferrer'
                />
              ),
              fundRiskLink: (
                <a
                  href='https://am.jpmorgan.com/tw/zh/asset-management/per/funds/fund-risk/'
                  target='_blank'
                  rel='noreferrer'
                />
              ),
            }}
          />
        </div>
        <div className={styles.riskAssessment__infoDiv}>
          <p className={styles.riskAssessment__infoDiv__title}>
            {t(`generalSuitability:risk-assessment.available-fund-title`)}
          </p>
          <p>
            {t(
              `generalSuitability:risk-assessment.available-fund-info.${riskLevel}`
            )}
          </p>
        </div>
        <p>
          {' '}
          {lastAssessDate &&
            t('generalSuitability:risk-assessment.last-assessment-date', {
              date: getLocaleDateString(lastAssessDate),
            })}
        </p>
      </>
    )
  }

  const renderEnhancedSuitabilityMessage = () => {
    switch (enhancedSuitabiilityStatus) {
      case STATUS_INCOMPLETE:
        return (
          <div
            className={styles.riskAssessment__enhancedSuitabilityMessage}
            data-testid='enhanced-suitability-message-incomplete'
          >
            <span className='mb-4'>
              {t(
                'generalSuitability:enhanced-suitabliity-message.incompleted',
                {
                  level: riskLevelT,
                }
              )}
            </span>
            <Button onClick={() => history.push(routes.enhancedSuitability)}>
              {t('generalSuitability:enhanced-suitabliity-message.cta-button')}
            </Button>
          </div>
        )
      case STATUS_PASSED:
        return (
          <span data-testid='enhanced-suitability-message-pass'>
            <Trans
              i18nKey='generalSuitability:enhanced-suitabliity-message.passed'
              values={{ level: riskLevelT }}
              components={{
                fundExploreLink: (
                  <a
                    href='https://am.jpmorgan.com/tw/zh/asset-management/per/products/fund-explorer/onshore-funds'
                    target='_blank'
                    rel='noreferrer'
                  />
                ),
                fundRiskLink: (
                  <a
                    href='https://am.jpmorgan.com/tw/zh/asset-management/per/funds/fund-risk/'
                    target='_blank'
                    rel='noreferrer'
                  />
                ),
              }}
            />
          </span>
        )
      case STATUS_FAILED:
        return (
          <span data-testid='enhanced-suitability-message-failed'>
            <Trans
              i18nKey='generalSuitability:enhanced-suitabliity-message.failed'
              components={{
                fundExploreLink: (
                  <a
                    href='https://am.jpmorgan.com/tw/zh/asset-management/per/products/fund-explorer/onshore-funds'
                    target='_blank'
                    rel='noreferrer'
                  />
                ),
                fundRiskLink: (
                  <a
                    href='https://am.jpmorgan.com/tw/zh/asset-management/per/funds/fund-risk/'
                    target='_blank'
                    rel='noreferrer'
                  />
                ),
              }}
            />
          </span>
        )
      default:
        return <></>
    }
  }

  if (generalSuitabilityStatus === STATUS_COMPLETED && !isCorporateAccount) {
    return (
      <>
        <div className={styles.riskAssessment__accordionHeader}>
          {t('generalSuitability:risk-assessment.client-risk-header')}
        </div>
        {enhancedSuitabiilityStatus !== STATUS_COMPLETED && (
          <ReminderMessageCard
            cardClassName='mb-4'
            theme={REMINDER_MESSAGE_CARD_THEME_TEAL}
            body={renderEnhancedSuitabilityMessage()}
          />
        )}
        {renderRiskAssessmentHeader()}
        {renderRiskAssessmentContent()}
      </>
    )
  }

  return (
    <>
      {renderRiskAssessmentHeader()}
      <RiskRatingBar />
      {renderRiskAssessmentContent()}
    </>
  )
}

export default RiskAssessmentInfo
