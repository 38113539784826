import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootStateOrAny } from 'react-redux'

import { appAxios, getRoute } from '../../../helpers'
import { transformPortfolioHoldingFundHK } from '../../../helpers/transformPortfolioHoldingFund'
import { FundDetailsResponseData } from '../../../typings/FundDetails'
import { FundPriceData } from '../../../typings/FundPreview'
import { FundResponseHKType, HoldingFundType } from '../../../typings/Portfolio'

const fetchFundExcelDetails = createAsyncThunk<HoldingFundType[]>(
  'fetchFundExcelDetails',
  async (_, { getState }) => {
    const state: RootStateOrAny = getState()
    const { accountInfo } = state.user
    let portfolioRequest = {
      type: '',
      msAccountNo: '',
      isPieChart: false,
      groupType: '',
    }

    let allHoldingExportFunds: HoldingFundType[] = []
    let msFunds: HoldingFundType[][] = []
    const maFunds: HoldingFundType[] = []
    const allFundDetailRes = await appAxios().get(
      `${getRoute({ route: `fund` })}`
    )

    const { fundCategoryList } = state.holding

    if (accountInfo.accountNo) {
      // fetch and transform the funds
      portfolioRequest = {
        ...portfolioRequest,
        type: 'MA',
      }

      // FOR DEMO PURPOSE
      const res = await appAxios().post(
        `${getRoute({ route: `portfolioHoldingMA` })}`,
        {
          ...portfolioRequest,
        }
      )

      const fundPriceList: FundPriceData[] = await Promise.all(
        res.data.holdings.map(async (holding: FundResponseHKType) => {
          const fundPriceRes = await appAxios().get(
            `${getRoute({ route: `fundPrice` })}`,
            { params: { code: holding.fundCode } }
          )
          return {
            fundCode: holding.fundCode,
            bid: fundPriceRes.data.list[0].bid,
            date: fundPriceRes.data.list[0].date,
            baseCurrency: fundPriceRes.data.list[0].baseCurrency,
          }
        })
      )

      res.data.holdings.forEach((holding: FundResponseHKType) => {
        const currentFundDetails = allFundDetailRes.data.list.filter(
          (data: FundDetailsResponseData) =>
            data.fundInfo.code === holding.fundCode
        )[0]
        const currentFundPriceData: FundPriceData = fundPriceList.filter(
          (data: FundPriceData) => data.fundCode === holding.fundCode
        )[0]

        const fund: HoldingFundType = transformPortfolioHoldingFundHK(
          holding,
          currentFundDetails,
          fundCategoryList,
          currentFundPriceData
        )

        maFunds.push(fund)
      })
    }

    if (accountInfo.msAccountNo.length > 0) {
      // fetch and transform the funds

      msFunds = await Promise.all(
        accountInfo.msAccountNo.map(async (msAccountNo: string) => {
          portfolioRequest = {
            ...portfolioRequest,
            type: 'MS',
            msAccountNo,
          }
          // FOR DEMO PURPOSE
          const res = await appAxios().post(
            `${getRoute({ route: `portfolioHoldingMS` })}`,
            {
              ...portfolioRequest,
            }
          )
          const fundPriceList: FundPriceData[] = await Promise.all(
            res.data.holdings.map(async (holding: FundResponseHKType) => {
              const fundPriceRes = await appAxios().get(
                `${getRoute({ route: `fundPrice` })}`,
                { params: { code: holding.fundCode } }
              )
              return {
                fundCode: holding.fundCode,
                bid: fundPriceRes.data.list[0].bid,
                date: fundPriceRes.data.list[0].date,
                baseCurrency: fundPriceRes.data.list[0].baseCurrency,
              }
            })
          )
          const funds: HoldingFundType[] = res.data.holdings.map(
            (holding: FundResponseHKType) => {
              const currentFundDetails = allFundDetailRes.data.list.filter(
                (data: FundDetailsResponseData) =>
                  data.fundInfo.code === holding.fundCode
              )[0]
              const currentFundPriceData: FundPriceData = fundPriceList.filter(
                (data: FundPriceData) => data.fundCode === holding.fundCode
              )[0]

              const fund: HoldingFundType = transformPortfolioHoldingFundHK(
                holding,
                currentFundDetails,
                fundCategoryList,
                currentFundPriceData
              )
              return fund
            }
          )
          return [...funds]
        })
      )
    }
    allHoldingExportFunds = allHoldingExportFunds.concat([...maFunds])
    msFunds.forEach((funds: HoldingFundType[]) => {
      allHoldingExportFunds = allHoldingExportFunds.concat([...funds])
    })

    return allHoldingExportFunds
  }
)

export default fetchFundExcelDetails
