import inboxImage1 from '../../assets/images/inbox/inbox-image-1.png'
import inboxImage2 from '../../assets/images/inbox/inbox-image-2.png'
import inboxImage3 from '../../assets/images/inbox/inbox-image-3.png'
import {
  MESSAGE_TYPE_NOTIFICATION,
  MESSAGE_TYPE_PROMOTION,
  NOTIFICATION_IMPORTANT_NOTICE,
  NOTIFICATION_INVESMENT,
  NOTIFICATION_TRANSACTIONS,
  PROMOTION_EVENT,
  PROMOTION_PRODUCT,
  PROMOTION_SALES_MARKETING,
} from '../../components/inbox-card/constants'
import { InboxMessageDataType } from '../../components/inbox-card/Type'

const dummyData: InboxMessageDataType[] = [
  {
    messageId: 'reminder-1',
    isMessageRead: false,
    title: 'Reminder: Time to migrate your Regular Investment Plan',
    date: '2022/02/22',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
    longDescription:
      'This is a long long description. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor..Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor..Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor..Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor..Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor..Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
    reminder: ' Please respond by: 20 Mar 2022',
    type: MESSAGE_TYPE_NOTIFICATION,
    category: NOTIFICATION_IMPORTANT_NOTICE,
    isUrgent: false,
    ctaLabel: 'Learn more',
    ctaLink: '/',
  },
  {
    messageId: 'reminder-2',
    isMessageRead: true,
    title: 'Reminder: Time to migrate your Regular Investment Plan',
    date: '2022/02/22',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
    reminder: ' Please respond by: 20 Mar 2022',
    type: MESSAGE_TYPE_NOTIFICATION,
    category: NOTIFICATION_IMPORTANT_NOTICE,
    isUrgent: false,
  },
  {
    messageId: 'reminder-3',
    isMessageRead: true,
    title: 'Reminder: Time to migrate your Regular Investment Plan',
    date: '2022/02/22',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
    longDescription:
      'This is a long long description. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor..Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor..Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor..Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor..Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor..Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
    reminder: ' Please respond by: 20 Mar 2022',
    type: MESSAGE_TYPE_NOTIFICATION,
    category: NOTIFICATION_IMPORTANT_NOTICE,
    isUrgent: false,
  },
  {
    messageId: 'reminder-4',
    isMessageRead: true,
    title: 'Reminder: Time to migrate your Regular Investment Plan',
    date: '2022/02/22',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
    reminder: ' Please respond by: 20 Mar 2022',
    type: MESSAGE_TYPE_NOTIFICATION,
    category: NOTIFICATION_IMPORTANT_NOTICE,
    isUrgent: false,
  },
  {
    messageId: 'reminder-5',
    isMessageRead: true,
    title: 'Reminder: Time to migrate your Regular Investment Plan',
    date: '2022/02/22',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
    reminder: ' Please respond by: 20 Mar 2022',
    type: MESSAGE_TYPE_NOTIFICATION,
    category: NOTIFICATION_IMPORTANT_NOTICE,
    isUrgent: false,
  },
  {
    messageId: 'reminder-6',
    isMessageRead: true,
    title: 'Reminder: Time to migrate your Regular Investment Plan',
    date: '2022/02/22',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
    reminder: ' Please respond by: 20 Mar 2022',
    type: MESSAGE_TYPE_NOTIFICATION,
    category: NOTIFICATION_IMPORTANT_NOTICE,
    isUrgent: false,
  },
  {
    messageId: 'reminder-7',
    isMessageRead: true,
    title: 'Reminder: Time to migrate your Regular Investment Plan',
    date: '2022/02/22',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
    reminder: ' Please respond by: 20 Mar 2022',
    type: MESSAGE_TYPE_NOTIFICATION,
    category: NOTIFICATION_IMPORTANT_NOTICE,
    isUrgent: false,
  },
  {
    messageId: 'reminder-8',
    isMessageRead: true,
    title: 'Reminder: Time to migrate your Regular Investment Plan',
    date: '2022/02/22',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
    reminder: ' Please respond by: 20 Mar 2022',
    type: MESSAGE_TYPE_NOTIFICATION,
    category: NOTIFICATION_IMPORTANT_NOTICE,
    isUrgent: false,
  },
  {
    messageId: 'reminder-9',
    isMessageRead: true,
    title: 'Reminder: Time to migrate your Regular Investment Plan',
    date: '2022/02/22',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
    reminder: ' Please respond by: 20 Mar 2022',
    type: MESSAGE_TYPE_NOTIFICATION,
    category: NOTIFICATION_IMPORTANT_NOTICE,
    isUrgent: false,
  },
  {
    messageId: 'transactions-1',
    isMessageRead: false,
    title: 'Transactional Message Title',
    date: '2022/02/22',
    longDescription:
      'This is a long long description. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor..Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor..Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor..Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor..Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor..Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
    reminder: ' Please respond by: 20 Mar 2022',
    type: MESSAGE_TYPE_NOTIFICATION,
    category: NOTIFICATION_TRANSACTIONS,
    isUrgent: false,
  },
  {
    messageId: 'transactions-2',
    isMessageRead: true,
    title: 'Transactional Message Title',
    date: '2022/02/22',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
    reminder: 'Please respond by: 20 Mar 2022',
    type: MESSAGE_TYPE_NOTIFICATION,
    category: NOTIFICATION_TRANSACTIONS,
    isUrgent: true,
  },
  // {
  //   messageId: 'investment-1',
  //   isMessageRead: false,
  //   title: 'Investment Message Title',
  //   date: '2022/02/22',
  //   description:
  //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
  //   reminder: ' Please respond by: 20 Mar 2022',
  //   type: MESSAGE_TYPE_NOTIFICATION,
  //   category: NOTIFICATION_INVESMENT,
  //   isUrgent: false,
  // },
  {
    messageId: 'investment-2',
    isMessageRead: true,
    title: 'Investment Message Title',
    date: '2022/02/22',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
    reminder: 'Please respond by: 20 Mar 2022',
    type: MESSAGE_TYPE_NOTIFICATION,
    category: NOTIFICATION_INVESMENT,
    isUrgent: true,
  },
  {
    messageId: 'sales-marketing-1',
    isMessageRead: false,
    title: 'Sales & Marketing Title',
    date: '2022/02/22',
    longDescription:
      'This is a long long description. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor..Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor..Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor..Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor..Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor..Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
    description:
      'Enjoy 0% initial or switching charge privilege* for online subscription and switching**, valid until 31 Oct 2021 without frequency limit and amount limit (please note that investing in Enjoy 0% initial or switching charge privilege Enjoy 0% initial or switching charge privilege* for online subscription Enjoy 0% initial or switching charge privilege* for online subscription',
    reminder: 'Offer ends: 20 Feb 2022',
    type: MESSAGE_TYPE_PROMOTION,
    category: PROMOTION_SALES_MARKETING,
    imageUrl: inboxImage1,
    ctaLink: '/',
    isUrgent: false,
  },
  {
    messageId: 'sales-marketing-2',
    isMessageRead: true,
    title: 'Sales & Marketing Title',
    date: '2022/02/22',
    longDescription:
      'This is a long long description. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor..Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor..Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor..Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor..Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor..Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
    description:
      'Enjoy 0% initial or switching charge privilege* for online subscription and switching**, valid until 31 Oct 2021',
    reminder: 'Offer ends: 20 Feb 2022',
    type: MESSAGE_TYPE_PROMOTION,
    category: PROMOTION_SALES_MARKETING,
    imageUrl: inboxImage1,
    isUrgent: false,
  },
  {
    messageId: 'sales-marketing-3',
    isMessageRead: true,
    title: 'Sales & Marketing Title',
    date: '2022/02/22',
    description:
      'Enjoy 0% initial or switching charge privilege* for online subscription and switching**, valid until 31 Oct 2021 without frequency limit and amount limit (please note that investing in Enjoy 0% initial or switching charge privilege Enjoy 0% initial or switching charge privilege* for online subscription Enjoy 0% initial or switching charge privilege* for online subscription',
    reminder: 'Offer ends: 20 Feb 2022',
    type: MESSAGE_TYPE_PROMOTION,
    category: PROMOTION_SALES_MARKETING,
    imageUrl: inboxImage1,
    isUrgent: false,
  },
  {
    messageId: 'sales-marketing-4',
    isMessageRead: true,
    title: 'Sales & Marketing Title',
    date: '2022/02/22',
    description:
      'Enjoy 0% initial or switching charge privilege* for online subscription and switching**, valid until 31 Oct 2021 without frequency limit and amount limit (please note that investing in Enjoy 0% initial or switching charge privilege Enjoy 0% initial or switching charge privilege* for online subscription Enjoy 0% initial or switching charge privilege* for online subscription',
    reminder: 'Offer ends: 20 Feb 2022',
    type: MESSAGE_TYPE_PROMOTION,
    category: PROMOTION_SALES_MARKETING,
    imageUrl: inboxImage1,
    isUrgent: false,
  },
  {
    messageId: 'products-1',
    isMessageRead: false,
    title: 'Product Info Promotion Title',
    date: '2022/02/22',
    longDescription:
      'Enjoy 0% initial or switching charge privilege* for online subscription and switching**, valid until 31 Oct 2021 without frequency limit and amount limit (please note that investing in Enjoy 0% initial or switching charge privilege Enjoy 0% initial or switching charge privilege* for online subscription Enjoy 0% initial or switching charge privilege* for online subscription',
    description:
      'Enjoy 0% initial or switching charge privilege* for online subscription and switching**, valid until 31 Oct 2021 without frequency limit and amount limit (please note that investing in Enjoy 0% initial or switching charge privilege Enjoy 0% initial or switching charge privilege* for online subscription Enjoy 0% initial or switching charge privilege* for online subscription',
    reminder: 'Offer ends: 20 Feb 2022',
    type: MESSAGE_TYPE_PROMOTION,
    category: PROMOTION_PRODUCT,
    imageUrl: inboxImage2,
    ctaLink: '/',
    isUrgent: false,
  },
  {
    messageId: 'products-2',
    isMessageRead: true,
    title: 'Product Info Promotion Title',
    date: '2022/02/22',
    description:
      'Enjoy 0% initial or switching charge privilege* for online subscription and switching**, valid until 31 Oct 2021 without frequency limit and amount limit (please note that investing in Enjoy 0% initial or switching charge privilege Enjoy 0% initial or switching charge privilege* for online subscription Enjoy 0% initial or switching charge privilege* for online subscription',
    reminder: 'Offer ends: 20 Feb 2022',
    type: MESSAGE_TYPE_PROMOTION,
    category: PROMOTION_PRODUCT,
    imageUrl: inboxImage2,
    isUrgent: false,
  },
  {
    messageId: 'events-1',
    isMessageRead: false,
    title: 'Event Promotion Title',
    date: '2022/02/22',
    longDescription:
      'Enjoy 0% initial or switching charge privilege* for online subscription and switching**, valid until 31 Oct 2021 without frequency limit and amount limit (please note that investing in Enjoy 0% initial or switching charge privilege Enjoy 0% initial or switching charge privilege* for online subscription Enjoy 0% initial or switching charge privilege* for online subscription',
    description:
      'Enjoy 0% initial or switching charge privilege* for online subscription and switching**, valid until 31 Oct 2021 without frequency limit and amount limit (please note that investing in Enjoy 0% initial or switching charge privilege Enjoy 0% initial or switching charge privilege* for online subscription Enjoy 0% initial or switching charge privilege* for online subscription',
    reminder: 'Offer ends: 20 Feb 2022',
    type: MESSAGE_TYPE_PROMOTION,
    category: PROMOTION_EVENT,
    imageUrl: inboxImage3,
    ctaLink: '/',
    isUrgent: false,
  },
  // {
  //   messageId: 'events-2',
  //   isMessageRead: true,
  //   title: 'Event Promotion Title',
  //   date: '2022/02/22',
  //   description:
  //     'Enjoy 0% initial or switching charge privilege* for online subscription and switching**, valid until 31 Oct 2021 without frequency limit and amount limit (please note that investing in Enjoy 0% initial or switching charge privilege Enjoy 0% initial or switching charge privilege* for online subscription Enjoy 0% initial or switching charge privilege* for online subscription',
  //   reminder: 'Offer ends: 20 Feb 2022',
  //   type: MESSAGE_TYPE_PROMOTION,
  //   category: PROMOTION_EVENT,
  //   imageUrl: 'assets/images/smart-tips-3.png',
  //   isUrgent: false,
  // },
]

export default dummyData
