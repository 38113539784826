import { createAsyncThunk } from '@reduxjs/toolkit'

import { appAxios, getRoute } from '../../../helpers'
import { UserRspMigrationType } from '../../../typings/UserInfo'

type UserRspMigrationApiHKResponseData = {
  withActiveRSP: 'Y' | 'N'
  migrationEligible: 'Y' | 'N'
  illegibleReasons: string[]
}

type UserRspMigrationApiResponseType = UserRspMigrationApiHKResponseData

const transformUserDataHK = (
  responseData: UserRspMigrationApiHKResponseData
): UserRspMigrationType => {
  return {
    ...responseData,
    showRspMigrationMessage: 'Y', // will be handled by JPM team
  }
}

const transformUserData = (
  responseData: UserRspMigrationApiResponseType
): UserRspMigrationType => {
  return transformUserDataHK(responseData as UserRspMigrationApiHKResponseData)
}

const fetchActiveRsp = createAsyncThunk<UserRspMigrationType>(
  'fetchActiveRsp',
  async () => {
    const rspMigrationRes = await appAxios().get(
      getRoute({ route: 'rspMigration' })
    )

    const responseData = rspMigrationRes.data

    return transformUserData(responseData)
  }
)

export default fetchActiveRsp
