import './NavigationLegacy.scss'

import _debounce from 'lodash/debounce'
import { FunctionComponent, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import { BUTTON_SIZE_MEDIUM } from '../../constants/button'
import Button from '../button'
import { CloseIcon, HamburgerIcon, Icon } from '../icons'
import Logo from '../logo'
import InboxButton from './inbox-button'
// import LanguageBlock from './language-block'
import MobileMenu from './mobile/MobileMenuLegacy'
import NavBanner from './nav-banner'
import NavList from './navlist'

interface NavigationLegacyProps {
  showInboxAlert: boolean
}

const NavigationLegacy: FunctionComponent<NavigationLegacyProps> = ({
  showInboxAlert,
}: NavigationLegacyProps) => {
  const [menuOpened, setMenuOpened] = useState<boolean>(false)
  const { t } = useTranslation()
  const isXL = useMediaQuery({ minWidth: 1200 })

  /**
   * Toggling mobile menu
   */
  const toggleMobileMenu = (): void => {
    setMenuOpened((prevmenuOpened) => !prevmenuOpened)
  }

  /**
   * set position for body, header and lock body scrolling when mobile menu is opened
   *
   * @param {number} [position]
   */
  const setBodyPosition = (position?: number): void => {
    const { body } = document
    const header: HTMLElement | null = document.querySelector('#header')

    if (!header) return

    if (position) {
      body.style.right = `${position}px`
      body.style.overflow = 'hidden'
      header.style.right = `${position}px`
    } else {
      body.style.right = '0'
      body.style.overflow = ''
      header.style.right = '0'
    }
  }

  useEffect(() => {
    const { body } = document
    const mobileMenu: HTMLDivElement | null =
      document.querySelector('#mobile-menu')

    /**
     * Update body position when mobile menu is opened
     */
    const updateBodyPosition = (): void => {
      // bail if mobile menu is not yet rendered
      // bail if mobile menu is not opened
      if (!mobileMenu || !menuOpened) return

      // close menu on desktop view
      if (menuOpened && window.innerWidth > 1200) {
        setMenuOpened(false)
        setBodyPosition(0)
        return
      }

      // prevent updating the same value on body
      const mobileMenuWidth: number = mobileMenu.offsetWidth
      const bodyMarginLeft: number = parseInt(body.style.marginLeft, 10)
      if (mobileMenuWidth === Math.abs(bodyMarginLeft)) return

      setBodyPosition(mobileMenuWidth)
    }

    // debounced version of updateBodyPosition
    const updateBodyPositionDebounced = _debounce(updateBodyPosition, 150)

    if (menuOpened) {
      updateBodyPosition()
    } else {
      setBodyPosition(0)
    }

    window.addEventListener('resize', updateBodyPositionDebounced)

    return () => {
      window.removeEventListener('resize', updateBodyPositionDebounced)
    }
  }, [menuOpened])

  return (
    <>
      <NavBanner showInboxAlert={showInboxAlert} />
      <Row
        as='nav'
        className='align-items-center nav navbar-expand-xl'
        noGutters
      >
        <Col
          className='nav__main align-items-center'
          data-testid='nav-main'
          id='nav-main'
        >
          <Logo />
          <div className='d-none d-xl-flex'>
            <NavList variant='lg' closeMobileMenu={() => {}} />
          </div>
        </Col>
        {!isXL ? (
          <Col xs='auto'>
            <div
              className='nav__left justify-content-end align-items-center'
              id='nav-left'
              data-testid='nav-left'
            >
              {false && <InboxButton showBadge={showInboxAlert} />}
              <Button size={BUTTON_SIZE_MEDIUM} className='nav-banner__button'>
                {t(`common:logout`)}
              </Button>
              <Icon
                data-testid='nav-hamburger'
                className='nav__icon-div d-xl-none'
                clickable
                onClick={toggleMobileMenu}
                id='nav-hamburger'
              >
                {menuOpened ? (
                  <CloseIcon className='nav__icon' height={28} width={28} />
                ) : (
                  <HamburgerIcon className='nav__icon' />
                )}
              </Icon>
            </div>
          </Col>
        ) : null}
        <MobileMenu
          open={menuOpened}
          closeMobileMenu={() => setMenuOpened(false)}
        />
      </Row>
    </>
  )
}

export default NavigationLegacy
