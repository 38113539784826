import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootStateOrAny } from 'react-redux'

import { appAxios, getRoute } from '../../../helpers'
import {
  transformFundPreviewDataHK,
  transformFundPreviewDataTW,
} from '../../../helpers/transformFundPreviewData'
import {
  FundDetailsResponseData,
  FundDetailsResponseDataTW,
} from '../../../typings/FundDetails'
import {
  FundPreviewResponseData,
  FundPreviewType,
  FundPriceData,
} from '../../../typings/FundPreview'
import { UserCountryType } from '../../../typings/UserInfo'

const transformResponseDataHK = (
  responseData: FundPreviewResponseData[],
  fundDetails: FundDetailsResponseData[],
  fundPriceData: FundPriceData[]
): FundPreviewType[] => {
  const topFiveFunds: FundPreviewType[] = transformFundPreviewDataHK(
    responseData,
    fundDetails,
    fundPriceData
  )
  return topFiveFunds
}
const transformResponseDataTW = (
  responseData: FundPreviewResponseData[],
  fundDetails: FundDetailsResponseDataTW[],
  fundPriceData: FundPriceData[]
): FundPreviewType[] => {
  const topFiveFunds: FundPreviewType[] = transformFundPreviewDataTW(
    responseData,
    fundDetails,
    fundPriceData
  )
  return topFiveFunds
}

const transformResponseData = (
  responseData: FundPreviewResponseData[],
  fundDetails: FundDetailsResponseData[] | FundDetailsResponseDataTW[],
  fundPriceData: FundPriceData[],
  country: UserCountryType
): FundPreviewType[] => {
  if (country === 'HK') {
    return transformResponseDataHK(
      responseData,
      fundDetails as FundDetailsResponseData[],
      fundPriceData
    )
  }
  return transformResponseDataTW(
    responseData,
    fundDetails as FundDetailsResponseDataTW[],
    fundPriceData
  )
}

const loadTopFiveFunds = createAsyncThunk<FundPreviewType[]>(
  'loadTopFiveFunds',
  async (_, { getState }) => {
    const state: RootStateOrAny = getState()
    const { country } = state.system

    const res = await appAxios().get(`${getRoute({ route: `topFiveFunds` })}`)
    const allFundsDetails = await appAxios().get(
      `${getRoute({ route: `fund` })}`
    )
    const fundList = res.data.topFundList.map(
      (list: FundPreviewResponseData) => list.fundInfo.code
    )

    const fundPriceData: FundPriceData[] = await Promise.all(
      fundList.map(async (fundCode: string) => {
        const fundPriceRes = await appAxios().get(
          `${getRoute({ route: `fundPrice` })}`,
          { params: { code: fundCode } }
        )
        return {
          fundCode,
          bid: fundPriceRes.data.list[0].bid,
          date: fundPriceRes.data.list[0].date,
          baseCurrency: fundPriceRes.data.list[0].baseCurrency,
        }
      })
    )

    return transformResponseData(
      res.data.topFundList,
      allFundsDetails.data.list,
      fundPriceData,
      country
    )
  }
)

export default loadTopFiveFunds
