import './Modal.scss'

import { FunctionComponent, ReactElement, useEffect, useState } from 'react'
import { Modal as BootstrapModal } from 'react-bootstrap'

import { MODAL_CONTENT_SIZE_DEFAULT } from '../../constants/modal-elements'
import {
  ModalProps,
  OnCloseHandlerType,
  OnOpenHandlerType,
} from '../../typings/Modal'

/**
 *
 * @param button: optional - controller for the open state of the modal, default props - onOpenHandler
 * @param modalElement: mandatory - modal content, default props - onCloseHandler
 * @param modalContentSize: optional - control the size of modal container
 * @param options: additional options
 * options - initialOpen which show the modal by the default
 * options - closeModal which will close the modal
 */

const Modal: FunctionComponent<ModalProps> = ({
  button,
  modalElement,
  options,
  modalContentSize = MODAL_CONTENT_SIZE_DEFAULT,
}: ModalProps) => {
  const initialOpen = options?.initialOpen || false
  const closeModal = options?.closeModal
  const userDefinedOnCloseHandler = options?.onCloseHandler
  const [open, setOpen] = useState<boolean>(initialOpen)

  useEffect(() => {
    if (options && options.initialOpen) {
      setOpen(options.initialOpen)
    }
  }, [options])

  const onOpenHandler: OnOpenHandlerType = () => setOpen(true)
  const onCloseHandler: OnCloseHandlerType = () => {
    if (userDefinedOnCloseHandler) {
      userDefinedOnCloseHandler()
    } else {
      setOpen(false)
    }
  }

  useEffect(() => {
    if (typeof closeModal === 'undefined') return
    setOpen(!closeModal)
  }, [closeModal])

  return (
    <>
      {button && button(onOpenHandler)}
      <BootstrapModal
        centered
        scrollable
        show={open}
        animation={false}
        onEscapeKeyDown={onCloseHandler}
        onHide={onCloseHandler}
        className={`modal-background--${modalContentSize}`}
      >
        {modalElement(onCloseHandler) as ReactElement}
      </BootstrapModal>
    </>
  )
}

export default Modal
