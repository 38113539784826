// eslint-disable-next-line import/prefer-default-export
export const NAV = 'nav'
export const SORT_TRANSLATION_INDEX = 'sort'
export const CURRENCIES_TRANSLATION_INDEX = 'currencies'
export const COMMON = 'common'

// TODO: TBC
export const FUND_DETAILS_DROPDOWN = 'fundsDetailsDropdown'
export const PERIOD_SELECTOR_DROPDOWN = 'periodSelector'

export const TIER_INFO_TAB = 'tier'

// TAB Container Default Translation Index
export const TAB_DEFAULT_INDEX = COMMON

export const FUND_ACTIONS = 'selected'

export const TYPE_DROPDOWN = 'typeDropdown'
export const FUND_CATEGORY_DROPDOWN_FILTER = 'fundCategoryFilterDropdown'
