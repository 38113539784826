import { createAsyncThunk } from '@reduxjs/toolkit'

const updateCurrenciesPreference = createAsyncThunk<string, string>(
  'updateCurrenciesPreference',
  async (currency: string) => {
    return currency
  }
)

export default updateCurrenciesPreference
