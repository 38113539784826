import classnames from 'classnames'
import { FunctionComponent, MouseEvent, useEffect, useState } from 'react'
import { Dropdown as BootstrapDropdown } from 'react-bootstrap'

import {
  CHEVRON_COLOR_DARK,
  CHEVRON_COLOR_DISABLED,
  CHEVRON_ORIENTATION_DOWN,
  CHEVRON_ORIENTATION_UP,
} from '../../constants/chevron'
import { ChevronIcon } from '../icons'
import styles from './DropdownSelect.module.scss'

interface DropdownSelectProps {
  headerText: string
  list: string[]
  className?: string
  onChangeSelect: (newSelect: string) => void
  error: boolean
  disabled?: boolean
}

const DropdownSelect: FunctionComponent<DropdownSelectProps> = ({
  headerText,
  list,
  onChangeSelect,
  className,
  error,
  disabled,
}: DropdownSelectProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const [currentSelect, setCurrentSelect] = useState<string>(headerText)

  const onClickHandler: (event: MouseEvent<HTMLAnchorElement>) => void = (
    event: MouseEvent<HTMLAnchorElement>
  ) => {
    setCurrentSelect(event.currentTarget.id)
    if (currentSelect !== event.currentTarget.id) {
      onChangeSelect(event.currentTarget.id)
    }
  }
  useEffect(() => {
    setCurrentSelect(headerText)
  }, [headerText])
  return (
    <BootstrapDropdown
      className={classnames(className, styles.dropdownSelectDiv)}
      onToggle={() => setIsMenuOpen(!isMenuOpen)}
      data-testid='dropdown-select'
    >
      <BootstrapDropdown.Toggle
        data-testid='dropdown-select-header-button'
        disabled={disabled}
        as='button'
        bsPrefix={classnames(styles.dropdownSelectButton, {
          [styles.dropdownActive]: isMenuOpen,
          [styles.optionSelected]: list.includes(headerText) && !disabled,
          [styles.error]: error,
        })}
      >
        <span>{headerText}</span>{' '}
        <ChevronIcon
          orientation={
            isMenuOpen ? CHEVRON_ORIENTATION_UP : CHEVRON_ORIENTATION_DOWN
          }
          color={disabled ? CHEVRON_COLOR_DISABLED : CHEVRON_COLOR_DARK}
        />
      </BootstrapDropdown.Toggle>
      <BootstrapDropdown.Menu
        flip={false}
        show={isMenuOpen}
        rootCloseEvent='click'
        bsPrefix={styles.dropdownSelectMenu}
        data-testid='dropdown-select-menu'
      >
        {list.map((choice: string) => (
          <BootstrapDropdown.Item
            className={classnames(
              styles.dropdownSelectMenuItem,
              styles.dropdownSelectItem,
              {
                [styles.active]: currentSelect === choice,
              }
            )}
            key={choice}
            id={choice}
            onClick={onClickHandler}
            data-testid='dropdown-select-menu-item'
          >
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: choice }} />
          </BootstrapDropdown.Item>
        ))}
      </BootstrapDropdown.Menu>
    </BootstrapDropdown>
  )
}

DropdownSelect.defaultProps = {
  className: '',
  disabled: false,
}

export default DropdownSelect
