const updateContainerHeight = (): void => {
  let event

  if (typeof Event === 'function') {
    event = new Event('resize')
  } else {
    // IE11 Event API polyfill
    event = document.createEvent('Event')
    event.initEvent('resize', true, true)
  }

  window.dispatchEvent(event)
}

export default updateContainerHeight
