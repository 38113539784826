import { FunctionComponent } from 'react'

import {
  ALERT_BELL_COLOR_ALERT,
  ALERT_BELL_COLOR_DEFAULT,
  ALERT_BELL_COLOR_LIGHT,
} from '../../constants/icons'
import { IconProps } from '../../typings/Icon'

interface AlertBellProps extends IconProps {
  width?: number
  height?: number
  className?: string
  color?: typeof ALERT_BELL_COLOR_ALERT | typeof ALERT_BELL_COLOR_LIGHT
}

const AlertBell: FunctionComponent<AlertBellProps> = ({
  width,
  height,
  className,
  color,
  ...props
}: AlertBellProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      {...props}
      viewBox='0 0 14 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.6237 8.28837V6.87026C11.6237 4.8015 10.2486 3.04808 8.36446 2.47537V1.83331C8.36446 1.09812 7.76634 0.5 7.03115 0.5C6.29597 0.5 5.69784 1.09812 5.69784 1.83331V2.47537C3.81373 3.04808 2.43864 4.80147 2.43864 6.87026V8.28837C2.43864 10.1056 1.74597 11.8287 0.48824 13.1403C0.364983 13.2689 0.330346 13.4585 0.400212 13.6223C0.470077 13.7861 0.630964 13.8924 0.809035 13.8924H4.85368C5.06013 14.9054 5.95802 15.6701 7.03115 15.6701C8.10432 15.6701 9.00214 14.9054 9.20863 13.8924H13.2533C13.4313 13.8924 13.5922 13.7861 13.6621 13.6223C13.7319 13.4585 13.6973 13.2689 13.5741 13.1403C12.3163 11.8287 11.6237 10.1056 11.6237 8.28837ZM6.58672 1.83331C6.58672 1.58825 6.78609 1.38887 7.03115 1.38887C7.27622 1.38887 7.47559 1.58825 7.47559 1.83331V2.29932C7.32931 2.28521 7.18108 2.27775 7.03115 2.27775C6.88123 2.27775 6.733 2.28521 6.58672 2.29932V1.83331ZM7.03115 14.7812C6.45176 14.7812 5.95775 14.4097 5.77428 13.8924H8.28802C8.10456 14.4097 7.61055 14.7812 7.03115 14.7812ZM1.76694 13.0035C2.77911 11.6467 3.32751 10.0048 3.32751 8.28837V6.87026C3.32751 4.82808 4.98896 3.16662 7.03115 3.16662C9.07334 3.16662 10.7348 4.82808 10.7348 6.87026V8.28837C10.7348 10.0048 11.2832 11.6467 12.2954 13.0035H1.76694Z'
        fill={color}
      />
      <path
        d='M12.809 6.87004C12.809 7.11549 13.008 7.31448 13.2535 7.31448C13.4989 7.31448 13.6979 7.11549 13.6979 6.87004C13.6979 5.08933 13.0045 3.41519 11.7453 2.15604C11.5718 1.98251 11.2904 1.98248 11.1168 2.15604C10.9432 2.32961 10.9432 2.611 11.1168 2.78457C12.2081 3.87584 12.809 5.32675 12.809 6.87004Z'
        fill={color}
      />
      <path
        d='M0.809 7.31446C1.05445 7.31446 1.25344 7.11547 1.25344 6.87002C1.25344 5.32676 1.85443 3.87585 2.94567 2.78458C3.11924 2.61101 3.11924 2.32962 2.94567 2.15605C2.77214 1.98249 2.49072 1.98249 2.31715 2.15605C1.058 3.4152 0.364563 5.08931 0.364563 6.87002C0.364563 7.11547 0.563552 7.31446 0.809 7.31446Z'
        fill={color}
      />
    </svg>
  )
}

AlertBell.defaultProps = {
  width: 14,
  height: 16,
  className: '',
  color: ALERT_BELL_COLOR_DEFAULT,
}

export default AlertBell
