import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { HoldingFundType } from '../../typings/Portfolio'
import fetchFundExcelDetails from './actions/fetchFundExcelDetails'

type ExcelFundState = {
  fundList: HoldingFundType[]
}

const initialState: ExcelFundState = {
  fundList: [],
}

export const fundExcelExportSlice = createSlice({
  name: 'fundExcelExport',
  initialState,
  reducers: {
    updateFundExcelList: (state, action: PayloadAction<HoldingFundType[]>) => {
      state.fundList = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchFundExcelDetails.fulfilled,
      (state, action: PayloadAction<HoldingFundType[] | undefined>) => {
        if (action.payload) {
          state.fundList = action.payload
        }
      }
    )
  },
})

export default fundExcelExportSlice.reducer
