import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootStateOrAny } from 'react-redux'

import { appAxios, getRoute } from '../../../helpers'
import { UserCountryType } from '../../../typings/UserInfo'
import {
  ImportantMessageType,
  WarningMessageResponse,
} from '../../../typings/WarningMessage'

type WarningMessageResponseHKAPIData = {
  enable: boolean
  [message: string]: WarningMessageResponse | boolean | null
}

type WarningMessageResponseTWAPIData = {
  enable: boolean
  [message: string]: WarningMessageResponse | boolean | null
}

type WarningMessageAPIResponse =
  | WarningMessageResponseHKAPIData
  | WarningMessageResponseTWAPIData

const transformResponseDataHK = (
  responseData: WarningMessageResponseHKAPIData
): ImportantMessageType[] => {
  const importantMessageList: ImportantMessageType[] = []
  Object.entries(responseData).forEach(([key, value]) => {
    if (key === 'enable' || !value) return
    const { code, locale } = value as WarningMessageResponse

    const importantMessageObj: ImportantMessageType = {
      code,
      locale,
    }
    importantMessageList.push(importantMessageObj)
  })
  return importantMessageList
}

const transformResponseDataTW = (
  responseData: WarningMessageResponseTWAPIData
): ImportantMessageType[] => {
  const importantMessageList: ImportantMessageType[] = []
  Object.entries(responseData).forEach(([key, value]) => {
    if (key === 'enable' || !value) return
    const { code, locale } = value as WarningMessageResponse

    const importantMessageObj: ImportantMessageType = {
      code,
      locale,
    }
    importantMessageList.push(importantMessageObj)
  })
  return importantMessageList
}
const transformResponseData = (
  resposneData: WarningMessageAPIResponse,
  country: UserCountryType
): ImportantMessageType[] => {
  if (country === 'HK') {
    return transformResponseDataHK(
      resposneData as WarningMessageResponseHKAPIData
    )
  }
  return transformResponseDataTW(
    resposneData as WarningMessageResponseTWAPIData
  )
}

const loadWarningMessage = createAsyncThunk<ImportantMessageType[]>(
  'loadWarningMessage',
  async (_, { getState }) => {
    const state: RootStateOrAny = getState()
    const { country } = state.system

    const res = await appAxios().get(`${getRoute({ route: `warningMessage` })}`)

    // If the response enable value is false, FE will not show any message
    if (!res.data.enable) {
      return []
    }

    return transformResponseData(res.data, country)
  }
)

export default loadWarningMessage
