import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// import getInvestmentTier from '../../helpers/getInvestmentTier'
import {
  PortfolioRiskRating,
  PriceAlertType,
  SettingResponse,
  UserCountryType,
  UserEPointsType,
  UserInfoType,
  UserRiskRatingObject,
  UserRiskRatingType,
  UserRspMigrationType,
  UserTierType,
} from '../../typings/UserInfo'
import fetchActiveRsp from './actions/fetchActiveRsp'
import fetchClientTierInfo from './actions/fetchClientTierInfo'
import fetchPriceAlert from './actions/fetchPriceAlert'
import fetchSetting from './actions/fetchSetting'
import fetchUserAccountInfo from './actions/fetchUserAccountInfo'
import fetchUserEPointsInfo from './actions/fetchUserEPoints'
import fetchUserRiskRating from './actions/fetchUserRiskRating'
import updateCurrenciesPreference from './actions/updateCurrenciesPreference'

export interface UserState {
  accountInfo?: UserInfoType
  membershipTier?: UserTierType
  ePoints?: UserEPointsType
  riskRating?: UserRiskRatingObject
  preference: {
    currency: string
  }
  rspMigration?: UserRspMigrationType
  priceAlert?: {
    list: PriceAlertType[]
  }
  isAuth: boolean
  setting?: SettingResponse
}

const initialState: UserState = {
  accountInfo: undefined,
  membershipTier: undefined,
  ePoints: undefined,
  riskRating: undefined,
  preference: {
    currency:
      (process.env.REACT_APP_COUNTRY as UserCountryType) === 'TW'
        ? 'twd'
        : 'hkd' || 'hkd',
  },
  rspMigration: undefined,
  priceAlert: undefined,
  isAuth: false,
  setting: undefined,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserTier: (state, action: PayloadAction<UserTierType>) => {
      const updatedUserTier = { ...action.payload }
      state.membershipTier = updatedUserTier
    },
    updateUserRiskRating: (
      state,
      action: PayloadAction<UserRiskRatingType>
    ) => {
      if (state.riskRating) {
        state.riskRating.clientRiskRating = action.payload
      }
    },
    updatePortfolioRiskRating: (
      state,
      action: PayloadAction<PortfolioRiskRating>
    ) => {
      if (state.riskRating) {
        state.riskRating.portfolioRiskRating = action.payload
      }
    },
    updateAuthenticationStatus: (state, action: PayloadAction<boolean>) => {
      state.isAuth = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchUserAccountInfo.fulfilled,
      (state, action: PayloadAction<UserInfoType>) => {
        state.accountInfo = action.payload
        state.isAuth = true
      }
    )
    builder.addCase(
      fetchUserEPointsInfo.fulfilled,
      (state, action: PayloadAction<UserEPointsType>) => {
        state.ePoints = action.payload
      }
    )
    builder.addCase(
      fetchClientTierInfo.fulfilled,
      (state, action: PayloadAction<UserTierType>) => {
        state.membershipTier = action.payload
      }
    )
    builder.addCase(
      fetchUserRiskRating.fulfilled,
      (state, action: PayloadAction<UserRiskRatingObject>) => {
        state.riskRating = action.payload
      }
    )
    builder.addCase(
      fetchActiveRsp.fulfilled,
      (state, action: PayloadAction<UserRspMigrationType>) => {
        state.rspMigration = action.payload
      }
    )
    builder.addCase(
      updateCurrenciesPreference.fulfilled,
      (state, action: PayloadAction<string>) => {
        state.preference.currency = action.payload
      }
    )
    builder.addCase(
      fetchPriceAlert.fulfilled,
      (state, action: PayloadAction<{ list: PriceAlertType[] }>) => {
        state.priceAlert = action.payload
      }
    )
    builder.addCase(
      fetchSetting.fulfilled,
      (state, action: PayloadAction<SettingResponse>) => {
        state.setting = action.payload
        state.preference.currency = action.payload.showCcy
      }
    )
  },
})

export default userSlice.reducer
