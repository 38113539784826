import { ValueCompareOperator } from '../typings/GeneralSuitability'

// parse the option string (i.e 210萬以上(含) - 未滿300萬) to 300
export const parseOptionToNumber = (option: string | number): number => {
  if (typeof option === 'number') return option
  if (typeof option === 'string') {
    const numberPattern = /\d+/g
    const optionArr = option.split('-')
    const arrLastItem = optionArr[optionArr.length - 1].match(numberPattern)
    if (arrLastItem) {
      if (optionArr.length === 1) {
        return parseInt(arrLastItem[0], 10) + 1
      }
      return parseInt(arrLastItem[0], 10)
    }
  }
  return 0
}

const compareValue = (valueComparisonObj: {
  value1: string | number | string[]
  value2: string | number | string[]
  compare: ValueCompareOperator
}): boolean => {
  const { value1, value2, compare } = valueComparisonObj

  const value1InNumber = parseOptionToNumber(value1 as string)
  const value2InNumber = parseOptionToNumber(value2 as string)

  if (compare === 'gt') {
    return value1InNumber > value2InNumber
  }
  if (compare === 'gte') {
    return value1InNumber >= value2InNumber
  }
  if (compare === 'lte') {
    return value1InNumber <= value2InNumber
  }
  if (compare === 'lt') {
    return value1InNumber < value2InNumber
  }
  return false
}

export default compareValue
