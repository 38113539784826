import { FunctionComponent } from 'react'

import { IconProps } from '../../typings/Icon'

const Settings: FunctionComponent<IconProps> = ({
  className,
  height,
  width,
  ...props
}: IconProps) => {
  return (
    <svg
      height={height}
      width={width}
      className={className}
      {...props}
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='18' cy='18' r='18' fill='none' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.3077 15.6923C11.5822 15.6923 12.6154 16.7255 12.6154 18C12.6154 19.2745 11.5822 20.3077 10.3077 20.3077C9.03319 20.3077 8 19.2745 8 18C8 16.7255 9.03319 15.6923 10.3077 15.6923ZM20.3077 18C20.3077 16.7255 19.2745 15.6923 18 15.6923C16.7255 15.6923 15.6923 16.7255 15.6923 18C15.6923 19.2745 16.7255 20.3077 18 20.3077C19.2745 20.3077 20.3077 19.2745 20.3077 18ZM28 18C28 16.7255 26.9668 15.6923 25.6923 15.6923C24.4178 15.6923 23.3846 16.7255 23.3846 18C23.3846 19.2745 24.4178 20.3077 25.6923 20.3077C26.9668 20.3077 28 19.2745 28 18Z'
        fill='black'
      />
    </svg>
  )
}

Settings.defaultProps = {
  height: 36,
  width: 36,
  className: '',
}

export default Settings
