import {
  FundDetailsResponseData,
  FundDetailsResponseDataTW,
} from '../typings/FundDetails'
import {
  FundPreviewResponseData,
  FundPreviewType,
  FundPriceData,
} from '../typings/FundPreview'

export const transformFundPreviewDataTW = (
  resposneData: FundPreviewResponseData[],
  fundDetails: FundDetailsResponseDataTW[],
  fundPriceData: FundPriceData[]
): FundPreviewType[] => {
  const fundPreview: FundPreviewType[] = resposneData.map(
    (data: FundPreviewResponseData) => {
      const currentFundDetails = fundDetails.filter(
        (fundDetail: FundDetailsResponseDataTW) =>
          fundDetail.fundInfo.code === data.fundInfo.code
      )[0]
      const fundObj: FundPreviewType = {
        ...data,
        nav: currentFundDetails.navPrecision,
        lastUpdate: currentFundDetails.performanceEffectiveDate,
        riskRating: currentFundDetails.fundRiskRating,
        performance: {
          oneYear: currentFundDetails.oneYearPerformance,
          twoYear: currentFundDetails.twoYearPerformance,
          threeYear: currentFundDetails.threeYearPerformance,
          fiveYear: currentFundDetails.fiveYearPerformance, // Missing five year
        },
        performanceEffectiveDate: currentFundDetails.performanceEffectiveDate,
        fundPrice: fundPriceData.filter(
          (fundPirceEntry: FundPriceData) =>
            fundPirceEntry.fundCode === data.fundInfo.code
        )[0],
      }
      return fundObj
    }
  )
  return fundPreview
}

export const transformFundPreviewDataHK = (
  responseData: FundPreviewResponseData[],
  fundDetails: FundDetailsResponseData[],
  fundPriceData: FundPriceData[]
): FundPreviewType[] => {
  const fundPreview: FundPreviewType[] = responseData.map(
    (data: FundPreviewResponseData) => {
      const currentFundDetails = fundDetails.filter(
        (fundDetail: FundDetailsResponseData) =>
          fundDetail.fundInfo.code === data.fundInfo.code
      )[0]
      const fundObj: FundPreviewType = {
        ...data,
        nav: currentFundDetails.navPrecision,
        lastUpdate: null, // TBC: in sample data lacking this field
        riskRating: currentFundDetails.fundRiskRating,
        performance: {
          oneYear: currentFundDetails.oneYearPerformance,
          twoYear: currentFundDetails.twoYearPerformance,
          threeYear: currentFundDetails.threeYearPerformance,
          fiveYear: currentFundDetails.fiveYearPerformance, // Missing five year
        },
        performanceEffectiveDate: currentFundDetails.performanceEffectiveDate,
        fundPrice: fundPriceData.filter(
          (fundPirceEntry: FundPriceData) =>
            fundPirceEntry.fundCode === data.fundInfo.code
        )[0],
      }
      return fundObj
    }
  )
  return fundPreview
}
