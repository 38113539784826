import classnames from 'classnames'
import { Dispatch, FunctionComponent, ReactNode, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import Checkbox from '../../../components/checkbox'
import {
  FILLING_ROLE_AGENT_TWO,
  FILLING_ROLE_SELF,
  FillingRoleType,
  QuestionnaireSubStepType,
} from '../../../typings/GeneralSuitability'
import styles from './SubStepContentWrapper.module.scss'

// Content wrapper for different questionnaire main step and substep header and header description
interface SubStepContentWrapperProps {
  currentStepFillingRole: FillingRoleType
  currentStepTitle: QuestionnaireSubStepType['title'] | undefined
  setSkipCurrentStep: Dispatch<SetStateAction<boolean>>
  skipCurrentStep: boolean
  children: ReactNode
  age: number | undefined
}

const SubStepContentWrapper: FunctionComponent<SubStepContentWrapperProps> = ({
  currentStepFillingRole,
  currentStepTitle,
  setSkipCurrentStep,
  skipCurrentStep,
  age,
  children,
}: SubStepContentWrapperProps) => {
  const { t } = useTranslation()
  return (
    <>
      {currentStepFillingRole === FILLING_ROLE_SELF &&
        currentStepTitle === '基本資料' && (
          <>
            <p className={styles.subStepContent__description}>
              {t('generalSuitability:questionnaire-1.description')}
            </p>
            <div className='mdoip__general-suitability__divider--thick' />
          </>
        )}
      <div className={styles.subStepContent__header}>
        <p>
          {currentStepTitle === '基本資料' &&
            t(`generalSuitability:role.${currentStepFillingRole}`)}
          {currentStepTitle}
        </p>
      </div>
      {currentStepTitle === '貿易往來資訊確認書' && (
        <>
          <p className={classnames('my-4', styles.subStepContent__description)}>
            {t('generalSuitability:questionnaire-4d.description-1')}
          </p>
          <div className='mdoip__general-suitability__divider--thick' />
        </>
      )}

      {/* 法定代理人2's Option */}
      {currentStepFillingRole === FILLING_ROLE_AGENT_TWO &&
        currentStepTitle === '基本資料' && (
          <div className={styles.agentSkipControl}>
            <input
              type='checkbox'
              id='skip-agent-two-checkbox'
              onClick={() => setSkipCurrentStep(!skipCurrentStep)}
            />
            <Checkbox
              active={skipCurrentStep}
              className={styles.agentSkipControl__checkbox}
            />

            <label htmlFor='skip-agent-two-checkbox'>
              <span>
                {t('generalSuitability:questionnaire-1.agent2-description')}
              </span>
            </label>
          </div>
        )}

      {currentStepTitle === '風險屬性評估問卷' && age && age < 20 && (
        <>
          <p className={classnames('my-4', styles.subStepContent__description)}>
            {t('generalSuitability:questionnaire-2.description-for-minor')}
          </p>
          <div className='mdoip__general-suitability__divider--thick' />
        </>
      )}
      {children}
    </>
  )
}

export default SubStepContentWrapper
