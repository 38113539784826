import { FunctionComponent } from 'react'
import { Nav, NavLinkProps } from 'react-bootstrap'

import { TAB_CONTAINER_ITEM_ACTIVE_STYLES } from '../../../constants/tab'
import Tab from '../index'
import styles from './Item.module.scss'

interface TabItem extends NavLinkProps {
  active?: boolean
  className?: string
}

const Item: FunctionComponent<TabItem> = ({
  active,
  children,
  className,
  ...props
}: TabItem) => {
  return (
    <Nav.Link href={undefined} {...props} className={styles.item} role='tab'>
      <Tab
        active={active}
        activeStyle={TAB_CONTAINER_ITEM_ACTIVE_STYLES}
        className={className}
      >
        {children}
      </Tab>
    </Nav.Link>
  )
}
Item.defaultProps = {
  active: false,
  className: '',
}

export default Item
