import classnames from 'classnames'
import { ButtonHTMLAttributes, FunctionComponent } from 'react'

import styles from './RadioButton.module.scss'

interface RadioButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
  active: boolean
  error?: boolean
}

const RadioButton: FunctionComponent<RadioButtonProps> = ({
  active,
  className,
  error,
  ...props
}: RadioButtonProps) => {
  const classNames = classnames(
    styles.radioButton,
    {
      [styles.active]: active,
      [styles.disable]: props.disabled,
      [styles.error]: error,
    },
    className
  )
  return <button className={classNames} {...props} data-testid='radio-button' />
}

RadioButton.defaultProps = {
  className: '',
  error: false,
}

export default RadioButton
