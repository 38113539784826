import classnames from 'classnames'
import { AnchorHTMLAttributes, FunctionComponent, HTMLAttributes } from 'react'
import { Link } from 'react-router-dom'

import styles from './Icon.module.scss'

type IconTypes = HTMLDivElement | HTMLAnchorElement

interface IconDivProps<T extends IconTypes>
  extends HTMLAttributes<T>,
    AnchorHTMLAttributes<T> {
  href?: string
  clickable?: boolean
  className?: string
  border?: boolean
}

const Icon: FunctionComponent<IconDivProps<IconTypes>> = <T extends IconTypes>({
  children,
  clickable,
  border,
  href,
  className,
  ...otherProps
}: IconDivProps<T>) => {
  const props: IconDivProps<T> = {
    className: classnames(styles.icon, className, {
      [styles.clickable]: clickable,
      [styles.border]: border,
    }),
    ...otherProps,
  }
  if (href) {
    if (href.includes('https://')) {
      return (
        <a {...(props as AnchorHTMLAttributes<HTMLAnchorElement>)} href={href}>
          {children}
        </a>
      )
    }
    return (
      <Link to={href} {...(props as AnchorHTMLAttributes<HTMLAnchorElement>)}>
        {children}
      </Link>
    )
  }
  return <div {...(props as HTMLAttributes<HTMLDivElement>)}>{children}</div>
}

Icon.defaultProps = {
  href: undefined,
  className: '',
  clickable: false,
  border: false,
}

export default Icon
