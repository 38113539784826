import { RouteType } from '../../typings/Routes'

const PROFILE_HK: RouteType[] = [
  {
    translationKeyName: 'profile',
    routeName: 'profile',
    subMenu: [
      {
        translationKeyName: 'profile-details',
        routeName: 'profile-details',
      },
      {
        translationKeyName: 'profile-payment',
        routeName: 'profile-payment',
      },
      {
        translationKeyName: 'profile-preference',
        routeName: 'profile-preference',
      },
      {
        translationKeyName: 'profile-pin',
        routeName: 'profile-pin',
      },
      {
        translationKeyName: 'profile-alert',
        routeName: 'profile-alert',
      },
      {
        translationKeyName: 'profile-questionnaire',
        routeName: 'profile-questionnaire',
      },
    ],
  },
]

export default PROFILE_HK
