export const CurrencyListHK = [
  'aud',
  'cad',
  'cny',
  'eur',
  'gbp',
  'hkd',
  'jpy',
  'nzd',
  'sgd',
  'twd',
  'usd',
]

export const CurrencyListTW = ['twd']

export const DEFAULT_CURRENCY_HK = 'hkd'
export const DEFAULT_CURRENCY_TW = 'twd'
