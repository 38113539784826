import parser from 'fast-xml-parser'
import escape from 'xml-escape'

import { AnnouncementXML } from '../typings/Announcement'
import { PromotionDataType, PromotionDataWithXML } from '../typings/Promotion'
import { TutorialStep, TutorialStepWithXML } from '../typings/TutorialStep'

const sanitizeXMLString = (
  xml: string
): {
  validated: boolean
  sanitizedXML: string
} => {
  const ignore = '< >' // by passing < > will allow the function ignore sanitizing < >
  const sanitizedXML = escape(xml, ignore)

  return {
    validated: parser.validate(sanitizedXML) === true,
    sanitizedXML,
  }
}

const decodeHTMLString = (string: string): string => {
  const ampersand = /&amp;/g
  const ampersandString = '&'
  const singleQuote = /&apos;/g
  const singleQuoteString = `'`
  const doubleQuote = /&quot;/g
  const doubleQuoteString = `"`

  return string
    .replace(ampersand, ampersandString)
    .replace(singleQuote, singleQuoteString)
    .replace(doubleQuote, doubleQuoteString)
}

export const parsePromotionContent = (
  xmlData: string
): PromotionDataType | null => {
  const { validated, sanitizedXML } = sanitizeXMLString(xmlData)
  if (!validated) return null

  const parsedData = parser.parse(sanitizedXML, {
    parseNodeValue: true,
    tagValueProcessor: (val) => decodeHTMLString(val),
  })

  const { promotion } = parsedData as PromotionDataWithXML

  return {
    promoType: promotion.promo_type as string,
    tagline: promotion.tagline ? (promotion.tagline as string) : null,
    header: promotion.title as string,
    descriptionShort: promotion.short_desc
      ? (promotion.short_desc as string)
      : null,
    descriptionLong: promotion.long_desc as string,
    disclaimerShort: promotion.short_disclaimer as string,
    disclaimerLong: promotion.long_disclaimer as string,
    image: promotion.img_url as string,
    heroImage: promotion.img_url_hero
      ? (promotion.img_url_hero as string)
      : null,
    url: {
      href: promotion.url as string,
      target: '_blank',
    },
    fullView: promotion.full_view as boolean,
    urlDesc: promotion.url_desc ? (promotion.url_desc as string) : null,
    urlLabel: promotion.url_label ? (promotion.url_label as string) : null,
  }
}

export const parseAnnouncementData = (
  xmlData: string
): {
  longDescription: string
  fullView: boolean
  urlLabel: string | null
  date: string
  shortDescription: string | null
  url: string | null
} => {
  const { validated, sanitizedXML } = sanitizeXMLString(xmlData)
  if (!validated)
    return {
      shortDescription: null,
      longDescription: '',
      fullView: false,
      urlLabel: null,
      date: '',
      url: null,
    }
  const parsedData = parser.parse(sanitizedXML, {
    parseNodeValue: true,
    tagValueProcessor: (val) => decodeHTMLString(val),
  })

  const { announcement } = parsedData as AnnouncementXML

  return {
    shortDescription: announcement.short_desc
      ? (announcement.short_desc as string)
      : null,
    longDescription: announcement.long_desc as string,
    date: announcement.date as string,
    fullView: announcement.full_view as boolean,
    urlLabel: announcement.url_label
      ? (announcement.url_label as string)
      : null,
    url: announcement.url ? (announcement.url as string) : null,
  }
}

export const parseTutorialStep = (xmlData: string): TutorialStep | null => {
  const { validated, sanitizedXML } = sanitizeXMLString(xmlData)
  if (!validated) return null

  const parsedData = parser.parse(sanitizedXML, {
    parseNodeValue: true,
    tagValueProcessor: (val) => decodeHTMLString(val),
  })

  const { step } = parsedData as TutorialStepWithXML

  return { ...step }
}
