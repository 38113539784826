// import { FunctionComponent } from 'react'

import { store } from '../store'
import { UserCountryType } from '../typings/UserInfo'

interface CountrySpecificComponentType<P> {
  HK: P
  TW: P
}

export const getCountry: () => UserCountryType = () => {
  const state = store.getState()
  const { country }: { country: UserCountryType } = state.system

  return country
}

export const getCurrentCountry = (): {
  isTW: boolean
  isHK: boolean
} => {
  const state = store.getState()
  const { country }: { country: UserCountryType } = state.system

  return {
    isTW: country === 'TW',
    isHK: country === 'HK',
  }
}

const getCountriesSpecific = <P>(
  components: CountrySpecificComponentType<P>
): P => {
  const state = store.getState()
  const { country }: { country: UserCountryType } = state.system

  return components[country]
}

export default getCountriesSpecific
