import ApiRoutes from '../routes/Api'
import { store } from '../store'
import { APIRouteKeyType } from '../typings/APIRoute'

interface GetRouteParams {
  route: APIRouteKeyType
}

const getRoute = (params: GetRouteParams): string => {
  const { route } = params
  const { country } = store.getState().system

  return ApiRoutes[route][country]
}

export default getRoute
