import {
  FOREIGN_TWD,
  FOREIGN_ZZZ,
  LOCAL,
  MA,
} from '../../../constants/portfolio'
import { PortfolioSummaryBreakdown } from '../../../typings/Portfolio'

// SAMPLE Data
// eslint-disable-next-line import/prefer-default-export
export const samplePortfolioBreakdownData: PortfolioSummaryBreakdown = [
  {
    category: LOCAL,
    data: [
      {
        paymentType: 'ON_TWD',
        currency: 'TWD',
        cost: 11307776,
        value: 12981642.6024,
        returnPercent: '14.80%',
        profit: 1673866.6024,
        netDividendPercent: '9.8%',
        netDividend: 10000,
        grossDividend: 1839443,
        foreignValue: null,
        foreignCurrency: null,
      },
      {
        paymentType: 'ON_ZZZ',
        currency: 'USD',
        cost: 11307776,
        value: 12981642.6024,
        returnPercent: '14.80%',
        profit: 1673866.6024,
        grossDividend: 1839443,
        netDividendPercent: '9.8%',
        netDividend: 10000,
        foreignValue: null,
        foreignCurrency: null,
      },
      {
        paymentType: 'ON_ZZZ',
        currency: 'CNY',
        cost: 11307776,
        value: 12981642.6024,
        returnPercent: '14.80%',
        profit: 1673866.6024,
        grossDividend: 1839443,
        netDividendPercent: '-',
        netDividend: 0,
        foreignValue: null,
        foreignCurrency: null,
      },
    ],
  },
  {
    category: FOREIGN_TWD,
    data: [
      {
        paymentType: 'OF_TWD',
        currency: 'TWD',
        cost: 11307776,
        value: 12981642.6024,
        returnPercent: '14.80%',
        profit: 1673866.6024,
        grossDividend: 1839443,
        netDividendPercent: '-',
        netDividend: 0,
        foreignValue: null,
        foreignCurrency: null,
      },
    ],
  },
  {
    category: FOREIGN_ZZZ,
    data: [
      {
        paymentType: 'OF_ZZZ',
        currency: 'USD',
        cost: 11307776,
        value: 12981642.6024,
        returnPercent: '14.80%',
        profit: 1673866.6024,
        grossDividend: 1839443,
        netDividendPercent: '-',
        netDividend: 0,
        foreignValue: null,
        foreignCurrency: null,
      },
      {
        paymentType: 'OF_ZZZ',
        currency: 'EUR',
        cost: 11307776,
        value: 12981642.6024,
        returnPercent: '14.80%',
        profit: 1673866.6024,
        grossDividend: 1839443,
        netDividendPercent: '9.8%',
        netDividend: 10000,
        foreignValue: null,
        foreignCurrency: null,
      },
      {
        paymentType: 'OF_ZZZ',
        currency: 'JPY',
        cost: 11307776,
        value: 12981642.6024,
        returnPercent: '14.80%',
        profit: 1673866.6024,
        grossDividend: 1839443,
        netDividendPercent: '9.8%',
        netDividend: 10000,
        foreignValue: null,
        foreignCurrency: null,
      },
      {
        paymentType: 'OF_ZZZ',
        currency: 'AUD',
        cost: 11307776,
        value: 12981642.6024,
        returnPercent: '14.80%',
        profit: 1673866.6024,
        grossDividend: 1839443,
        netDividendPercent: '-',
        netDividend: 0,
        foreignValue: null,
        foreignCurrency: null,
      },
    ],
  },
  {
    category: MA,
    data: [
      {
        paymentType: 'OF_MA_ZZZ',
        currency: 'USD',
        cost: 11307776,
        value: 12981642.6024,
        returnPercent: '14.80%',
        profit: 1673866.6024,
        grossDividend: 1839443,
        netDividendPercent: '-',
        netDividend: 0,
        foreignValue: null,
        foreignCurrency: null,
      },
      {
        paymentType: 'OF_MA_ZZZ',
        currency: 'EUR',
        cost: 11307776,
        value: 12981642.6024,
        returnPercent: '14.80%',
        profit: 1673866.6024,
        grossDividend: 1839443,
        netDividendPercent: '9.8%',
        netDividend: 10000,
        foreignValue: null,
        foreignCurrency: null,
      },
      {
        paymentType: 'OF_MA_ZZZ',
        currency: 'AUD',
        cost: 11307776,
        value: 12981642.6024,
        returnPercent: '14.80%',
        profit: 1673866.6024,
        grossDividend: 1839443,
        netDividendPercent: '-',
        netDividend: 0,
        foreignValue: null,
        foreignCurrency: null,
      },
      {
        paymentType: 'OF_MA_ZZZ',
        currency: 'JPY',
        cost: 11307776,
        value: 12981642.6024,
        returnPercent: '14.80%',
        profit: 1673866.6024,
        grossDividend: 1839443,
        netDividendPercent: '0',
        netDividend: 0,
        foreignValue: null,
        foreignCurrency: null,
      },
      {
        paymentType: 'OF_MA_ZZZ',
        currency: 'HKD',
        cost: 11307776,
        value: 12981642.6024,
        returnPercent: '14.80%',
        profit: 1673866.6024,
        grossDividend: 1839443,
        netDividendPercent: '9.8%',
        netDividend: 10000,
        foreignValue: null,
        foreignCurrency: null,
      },
    ],
  },
]
