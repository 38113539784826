import { createAsyncThunk } from '@reduxjs/toolkit'

import { FiltersType } from '../../../typings/Filters'
import loadPieData from '../../holding/actions/loadPieData'

const updateFilters = createAsyncThunk<FiltersType, FiltersType>(
  'updateFilters',
  async (filtersParams: FiltersType, { dispatch }) => {
    dispatch(loadPieData({ ...filtersParams }))

    return filtersParams
  }
)

export default updateFilters
