import { FunctionComponent } from 'react'

import { IconProps } from '../../typings/Icon'

const Minus: FunctionComponent<IconProps> = ({
  width = 15,
  height = 16,
  className = '',
  ...props
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      {...props}
      viewBox='0 0 12 2'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.059701 0.896875C0.0624943 0.588699 0.314584 0.336609 0.62276 0.333816L10.6668 0.242774C10.9749 0.23998 11.2225 0.487541 11.2197 0.795717C11.2169 1.10389 10.9648 1.35598 10.6567 1.35878L0.612645 1.44982C0.30447 1.45261 0.056908 1.20505 0.059701 0.896875Z'
        fill='#096183'
      />
    </svg>
  )
}

Minus.defaultProps = {
  width: 12,
  height: 2,
  className: '',
}

export default Minus
