import { FunctionComponent } from 'react'

import {
  SCREEN_VARIANT_LG,
  SCREEN_VARIANT_MD,
  SCREEN_VARIANT_XL,
} from '../../../constants/screen-variant'
import getCountriesSpecific from '../../../helpers/countriesSpecific'
import LanguageBlockHK from './LanguageBlockHK'

interface LanguageBlockProps {
  variant:
    | typeof SCREEN_VARIANT_XL
    | typeof SCREEN_VARIANT_LG
    | typeof SCREEN_VARIANT_MD
}

const LanguageBlock: FunctionComponent<LanguageBlockProps> = ({
  variant,
}: LanguageBlockProps) => {
  const LanguageBlockComp = getCountriesSpecific({
    HK: LanguageBlockHK,
    TW: () => {
      return null
    },
  })
  return <LanguageBlockComp variant={variant} />
}

export default LanguageBlock
