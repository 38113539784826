import { FunctionComponent } from 'react'

import { IconProps } from '../../typings/Icon'

const Expand: FunctionComponent<IconProps> = ({
  width = 15,
  height = 16,
  className = '',
  ...props
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      {...props}
      viewBox='0 0 13 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.67745 6.07698C1.36927 6.07977 1.11718 6.33186 1.11439 6.64004C1.1116 6.94821 1.35916 7.19577 1.66733 7.19298L6.08656 7.15292L6.04559 11.672C6.0428 11.9801 6.29036 12.2277 6.59853 12.2249C6.90671 12.2221 7.1588 11.97 7.16159 11.6618L7.20256 7.14281L11.7113 7.10194C12.0195 7.09915 12.2716 6.84706 12.2744 6.53888C12.2772 6.2307 12.0296 5.98314 11.7215 5.98594L7.21268 6.02681L7.25266 1.61784C7.25545 1.30966 7.00789 1.0621 6.69971 1.06489C6.39154 1.06769 6.13945 1.31978 6.13665 1.62795L6.09668 6.03692L1.67745 6.07698Z'
        fill='#096183'
      />
    </svg>
  )
}

Expand.defaultProps = {
  width: 13,
  height: 13,
  className: '',
}

export default Expand
