import { FunctionComponent } from 'react'

import {
  RISK_BAR_VARIANT_CLIENT_RISK,
  RISK_BAR_VARIANT_PORTFOLIO_RISK,
} from '../../constants/risk-rating'
import { useAppSelector } from '../../hooks'
import { RootState } from '../../store'
import Bar from './common/Bar'
import styles from './common/RiskBar.module.scss'

const RISK_RATING_UNDEFINED = 'undefined'

const RiskRatingBarHK: FunctionComponent = () => {
  const {
    riskRating: {
      clientRiskRating = RISK_RATING_UNDEFINED,
      portfolioRiskRating = RISK_RATING_UNDEFINED,
    } = {},
  } = useAppSelector((state: RootState) => state.user)
  if (clientRiskRating === RISK_RATING_UNDEFINED) return null

  return (
    <div
      data-testid='risk-rating-bar-hk'
      className={styles.riskRatingBarWrapper}
    >
      <Bar
        variant={RISK_BAR_VARIANT_CLIENT_RISK}
        riskLevel={clientRiskRating}
        data-testid='tolerance-bar'
        country='HK'
      />
      <Bar
        variant={RISK_BAR_VARIANT_PORTFOLIO_RISK}
        riskLevel={portfolioRiskRating}
        data-testid='PPR-bar'
        country='HK'
      />
    </div>
  )
}
export default RiskRatingBarHK
