import { createAsyncThunk } from '@reduxjs/toolkit'

import { SortValueType } from '../../../typings/Sorts'

const updateSortValue = createAsyncThunk<SortValueType, SortValueType>(
  'updateSortValue',
  async (sort: SortValueType) => {
    return sort
  }
)

export default updateSortValue
