import { createAsyncThunk } from '@reduxjs/toolkit'

import { AssetClassData } from '../../../typings/Portfolio'

const updateFundMarketList = createAsyncThunk<
  AssetClassData[],
  AssetClassData[]
>('updateFundMarketList', async (data: AssetClassData[]) => {
  return data
})

export default updateFundMarketList
