import { createAsyncThunk } from '@reduxjs/toolkit'

import { appAxios, getRoute } from '../../../helpers'
import { PriceAlertType } from '../../../typings/UserInfo'

type PriceAlertApiHKResponseData = {
  list: PriceAlertType[]
}

type PriceAlertApiResponseType = PriceAlertApiHKResponseData

const transformUserDataHK = (
  responseData: PriceAlertApiHKResponseData
): PriceAlertApiHKResponseData => {
  return responseData
}

const transformUserData = (
  responseData: PriceAlertApiResponseType
): PriceAlertApiHKResponseData => {
  return transformUserDataHK(responseData as PriceAlertApiHKResponseData)
}

const fetchPriceAlert = createAsyncThunk<PriceAlertApiHKResponseData>(
  'fetchPriceAlert',
  async () => {
    const rspMigrationRes = await appAxios().get(
      getRoute({ route: 'priceAlert' })
    )

    const responseData = rspMigrationRes.data

    return transformUserData(responseData)
  }
)

export default fetchPriceAlert
