import { createAsyncThunk } from '@reduxjs/toolkit'

import sorted from '../../../helpers/sorted'
import { HoldingFundPreviewType } from '../../../typings/PortfolioPie'

const updateInitialSortedFunds = createAsyncThunk<
  HoldingFundPreviewType[],
  HoldingFundPreviewType[]
>('updateInitialSortedFunds', async (data: HoldingFundPreviewType[]) => {
  return sorted({ data })
})

export default updateInitialSortedFunds
