import classnames from 'classnames'
import { FunctionComponent } from 'react'

const questionIdDivider = ['2', '11', '12', '13']

interface GSQuestionDividerProps {
  questionId: string
  className?: string
}

const GSQuestionDivider: FunctionComponent<GSQuestionDividerProps> = ({
  questionId,
  className,
}: GSQuestionDividerProps) => {
  if (questionIdDivider.includes(questionId)) {
    return (
      <div
        className={classnames('mdoip__general-suitability__divider', className)}
      />
    )
  }
  return <></>
}
GSQuestionDivider.defaultProps = {
  className: '',
}

export default GSQuestionDivider
