import { createAsyncThunk } from '@reduxjs/toolkit'

import { appAxios, getRoute } from '../../../helpers'
import { LocaleDataType } from '../../../typings/Locale'

interface ValidateAllotmentParams {
  amount: number
  fundCode: string
  currency: string
}

export interface ValidateAllotmentResponse {
  fundCode: string
  error: boolean
  message: LocaleDataType | null
}

const validateAllotment = createAsyncThunk<
  ValidateAllotmentResponse,
  ValidateAllotmentParams
>('validateAllotment', async (params: ValidateAllotmentParams) => {
  let res
  // Mock the failed amount to be 30,000,000// TO BE REMOVED
  if (params.amount > 30000000) {
    res = await appAxios({ showLoading: false }).post(
      '/tw/validate-allotment-failed.json'
    )
  } else {
    res = await appAxios({ showLoading: false }).post(
      getRoute({ route: 'validateAllotment' }),
      { ...params }
    )
  }

  if (res.data.code) {
    return {
      fundCode: params.fundCode,
      error: true,
      message: res.data.errors[0],
    }
  }
  return {
    fundCode: params.fundCode,
    error: false,
    message: null,
  }
})

export default validateAllotment
