import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AllFundsTableData } from '../../typings/AllFundsTableDataResponse'
import fetchAllFundTableData from './actions/fetchAllFundTableData'

type AllFundTableDataState = {
  allFundTableDataList: AllFundsTableData[] | undefined
}

const initialState: AllFundTableDataState = {
  allFundTableDataList: undefined,
}

export const allFundTableDataSlice = createSlice({
  name: 'allFundTableData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchAllFundTableData.fulfilled,
      (state, action: PayloadAction<AllFundsTableData[] | undefined>) => {
        if (action.payload) {
          state.allFundTableDataList = action.payload
        }
      }
    )
  },
})

export default allFundTableDataSlice.reducer
