import classnames from 'classnames'
import { FunctionComponent, HTMLAttributes } from 'react'

import TickIcon, { TICK_ICON_THEME_WHITE } from '../icons/Tick'
import styles from './Checkbox.module.scss'

interface CheckboxProps extends HTMLAttributes<HTMLDivElement> {
  className?: string
  active: boolean
}

const Checkbox: FunctionComponent<CheckboxProps> = ({
  active,
  className,
  ...props
}: CheckboxProps) => {
  const classNames = classnames(
    styles.checkbox,
    { [styles[`checkbox--active`]]: active },
    className
  )
  return (
    <div className={classNames} {...props}>
      {active && <TickIcon theme={TICK_ICON_THEME_WHITE} />}
    </div>
  )
}

Checkbox.defaultProps = {
  className: '',
}

export default Checkbox
