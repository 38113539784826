import { FunctionComponent } from 'react'

import { IconProps } from '../../typings/Icon'

const Factsheet: FunctionComponent<IconProps> = ({
  width,
  height,
  className,
  ...props
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      style={{ marginRight: '-3px', marginBottom: '-2px' }}
      {...props}
      viewBox='0 0 21 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.62919 16.1023H11.6306C12.7194 16.1023 13.6059 15.2167 13.6059 14.127V2.87529C13.6059 1.78562 12.7194 0.9 11.6306 0.9H2.62919C1.54033 0.9 0.653906 1.78562 0.653906 2.87529V14.127C0.653906 15.2167 1.54033 16.1023 2.62919 16.1023ZM1.60402 2.87529C1.60402 2.31017 2.06408 1.85011 2.62919 1.85011H11.6306C12.1957 1.85011 12.6557 2.31017 12.6557 2.87529V14.127C12.6557 14.6921 12.1957 15.1522 11.6306 15.1522H2.62919C2.06408 15.1522 1.60402 14.6921 1.60402 14.127V2.87529Z'
        fill='#3E3E3E'
        stroke='#3E3E3E'
        strokeWidth='0.2'
      />
      <path
        d='M4.12506 10.8516H10.126C10.3882 10.8516 10.601 10.6388 10.601 10.3765C10.601 10.1143 10.3882 9.90146 10.126 9.90146H4.12506C3.8628 9.90146 3.65 10.1143 3.65 10.3765C3.65 10.6388 3.8628 10.8516 4.12506 10.8516Z'
        fill='#3E3E3E'
        stroke='#3E3E3E'
        strokeWidth='0.2'
      />
      <path
        d='M4.12506 13.853H10.126C10.3882 13.853 10.601 13.6402 10.601 13.378C10.601 13.1157 10.3882 12.9029 10.126 12.9029H4.12506C3.8628 12.9029 3.65 13.1157 3.65 13.378C3.65 13.6402 3.8628 13.853 4.12506 13.853Z'
        fill='#3E3E3E'
        stroke='#3E3E3E'
        strokeWidth='0.2'
      />
      <path
        d='M4.12506 7.85158H10.126C10.3882 7.85158 10.601 7.63878 10.601 7.37652C10.601 7.11426 10.3882 6.90146 10.126 6.90146H4.12506C3.8628 6.90146 3.65 7.11426 3.65 7.37652C3.65 7.63878 3.8628 7.85158 4.12506 7.85158Z'
        fill='#3E3E3E'
        stroke='#3E3E3E'
        strokeWidth='0.2'
      />
      <path
        d='M4.12506 4.85207H10.126C10.3882 4.85207 10.601 4.63927 10.601 4.37701C10.601 4.11475 10.3882 3.90195 10.126 3.90195H4.12506C3.8628 3.90195 3.65 4.11475 3.65 4.37701C3.65 4.63927 3.8628 4.85207 4.12506 4.85207Z'
        fill='#3E3E3E'
        stroke='#3E3E3E'
        strokeWidth='0.2'
      />
      <path
        d='M16.2528 13.9712C15.9599 13.6783 15.485 13.6783 15.1921 13.9712L15.021 14.1422V10C15.021 9.58579 14.6853 9.25 14.271 9.25H13.5072C13.0929 9.25 12.7572 9.58579 12.7572 10V14.1422L12.5861 13.9712C12.2932 13.6783 11.8183 13.6783 11.5254 13.9712L10.9853 14.5113C10.8446 14.652 10.7656 14.8428 10.7656 15.0417C10.7656 15.2406 10.8446 15.4313 10.9853 15.572L13.3588 17.9455C13.6517 18.2384 14.1265 18.2384 14.4194 17.9455L16.7929 15.572C16.9336 15.4313 17.0126 15.2406 17.0126 15.0417C17.0126 14.8428 16.9336 14.652 16.7929 14.5113L16.2528 13.9712Z'
        fill='black'
        stroke='white'
        strokeWidth='1.5'
        strokeLinejoin='round'
      />
      <path
        d='M18.0139 15.2275C17.5997 15.2275 17.2639 15.5633 17.2639 15.9775V18.264H10.5139V15.9775C10.5139 15.5633 10.1781 15.2275 9.76389 15.2275H9C8.58579 15.2275 8.25 15.5633 8.25 15.9775V18.6321C8.25 19.6781 9.09981 20.5279 10.1458 20.5279H17.6319C18.678 20.5279 19.5278 19.6781 19.5278 18.6321V15.9775C19.5278 15.5633 19.192 15.2275 18.7778 15.2275H18.0139Z'
        fill='black'
        stroke='white'
        strokeWidth='1.5'
        strokeLinejoin='round'
      />
    </svg>
  )
}

Factsheet.defaultProps = {
  width: 21,
  height: 22,
  className: '',
}

export default Factsheet
