import { createAsyncThunk } from '@reduxjs/toolkit'
import _cloneDeep from 'lodash/cloneDeep'

import { appAxios, getRoute } from '../../../helpers'
import fetchRedemptEstimateValue from '../../../helpers/fetchRedemptEstimateValue'
import fetchUrlRedemptionList, {
  RedemptionTWResponseType,
} from '../../../helpers/fetchUrlTradingRedemptionList'
import {
  BuyFundType,
  RedemptionFundType,
  RedemptionResponseType,
  SwitchFundType,
  TradingFundData,
} from '../../../typings/UrlTrading'
// placeholder of the agreements data
const agreements = [
  {
    categoryCode: 'OF_FUNDFEE_FUND_NOTES',
    approved: 'Y',
  },
  {
    categoryCode: 'OF_CHEL_FEE_FUND_NOTES',
    approved: 'Y',
  },
  {
    categoryCode: 'OF_PROSPECTUS_FUND_NOTES',
    approved: 'Y',
  },
  {
    categoryCode: 'OF_RISK_STATEMENT_ABC',
    approved: 'Y',
  },
]

/**
 * Currently only TW has url trading feature available
 */

type TradingVOFundType = {
  type: 'B' | 'R' | 'S'
  amcCode: string
  fundCode: string
  switchFromFundCode: string | null
  switchToFundCodeCode: string | null
  currency: string
  bankCode: string
  bankAccount: string
  buyAmount: number | null
  unit: number | null
}

interface UrlTradingResponseTWType {
  oid: string
  clientId: string
  tradingVOList: TradingVOFundType[]
  feeRate: number
  updateTime: Date | string
  active: boolean
  isFirst: boolean
  discountCode: string
}

/**
 * Currently only TW has url trading redemption fetching
 */

const transformUrlTradingRedemptionData = (
  dataList: RedemptionTWResponseType,
  key: keyof RedemptionResponseType,
  value: boolean | number | string
): RedemptionResponseType => {
  const redemptionIndex: number = dataList.list.findIndex(
    (redemption: RedemptionResponseType) => redemption[key] === value
  )
  return dataList.list[redemptionIndex]
}

const transformUrlTradingFundData = async (
  responseData: UrlTradingResponseTWType,
  redemptionDataList: RedemptionTWResponseType
): Promise<TradingFundData> => {
  const allotmentList: BuyFundType[] = []
  const switchList: SwitchFundType[] = []
  const redemptionList: RedemptionFundType[] = []

  for (let index = 0; index < responseData.tradingVOList.length; index++) {
    const urlTradingFund = responseData.tradingVOList[index]

    if (urlTradingFund.type === 'R') {
      // return redemption data and mapping
      // temp key: accountNo = temp value: A100565650
      // will be modifed after common key is known
      const redemptionData: RedemptionResponseType =
        transformUrlTradingRedemptionData(
          redemptionDataList,
          'accountNo',
          'A100565650'
        )
      /* eslint-disable no-await-in-loop */
      const redemptionCalculator = await fetchRedemptEstimateValue({
        fundType: 'redemption',
        fundCode: urlTradingFund.fundCode,
        units: urlTradingFund.unit || 0,
        currency: urlTradingFund.currency,
        availableHoldings: redemptionData.availableHoldings,
      })
      const redemptionFundObject: RedemptionFundType = {
        type: 'R',
        fundCode: urlTradingFund.fundCode,
        fundName: {
          locale: {
            zh_TW: '摩根龍揚基金',
            en_US: '',
          },
        }, // fetch from api details
        jfFundCode: redemptionData.fundInfo.jFundCode, // fetch from fund details api
        isinCode: null, // fetch from fund details api
        currency: urlTradingFund.currency,
        fundFeeType: redemptionData.fundFeesType, // fetch from another api
        baselineCurrency: 'usd', // fetch from api details (key "baselineCurrency")
        fee: '-',
        feeRate: `${(100 * responseData.feeRate).toFixed(0)}%`,
        unit: urlTradingFund.unit || 0,
        discountCode: responseData.discountCode,
        availableHoldings: redemptionData.availableHoldings,
        redUnitPrecision: redemptionData.redUnitPrecision,
        redUnitPricePrecision: redemptionData.redUnitPricePrecision,
        percent:
          ((urlTradingFund.unit || 0) / redemptionData.availableHoldings) * 100, // calculate from units and available holdings
        bankAccountNo: `${urlTradingFund.bankCode}-${urlTradingFund.bankAccount}`,
        redemptEstimateValue: redemptionCalculator.value,
      }
      redemptionList.push(redemptionFundObject)
    }

    if (urlTradingFund.type === 'B') {
      const buyFundObject: BuyFundType = {
        type: 'B',
        fundCode: urlTradingFund.fundCode,
        fundName: {
          locale: {
            zh_TW: '摩根中國基金',
            en_US: '',
          },
        }, // fetch from api details
        currency: urlTradingFund.currency,
        baselineCurrency: 'usd', // fetch from api details (key "baselineCurrency")
        fee:
          responseData.feeRate === 0
            ? '-'
            : (urlTradingFund.buyAmount || 0 * responseData.feeRate)
                .toFixed(0)
                .toLocaleString(),
        feeRate: `${(100 * responseData.feeRate).toFixed(0)}%`,
        amount: urlTradingFund.buyAmount || 0,
        discountCode: responseData.discountCode,
        minHolding: 4000, // fetch from api
        agreements, // fetch from api
      }
      allotmentList.push(buyFundObject)
    }

    if (urlTradingFund.type === 'S') {
      const redemptionData: RedemptionResponseType =
        transformUrlTradingRedemptionData(
          redemptionDataList,
          'accountNo',
          'A100565650'
        )
      const redemptionCalculator = await fetchRedemptEstimateValue({
        fundType: 'switch',
        fundCode: urlTradingFund.fundCode,
        units: urlTradingFund.unit || 0,
        currency: urlTradingFund.currency,
        availableHoldings: redemptionData.availableHoldings,
      })
      const switchFundObject: SwitchFundType = {
        type: 'S',
        outFundCode: urlTradingFund.switchFromFundCode || '',
        inFundCode: urlTradingFund.switchToFundCodeCode || '',
        switchUnit: urlTradingFund.unit || 0,
        currency: urlTradingFund.currency,
        percent: ((urlTradingFund.unit || 0) / 200) * 100, // TBC
        discountCode: responseData.discountCode,
        agreements,
        outFund: {
          type: 'SF',
          fundCode: urlTradingFund.switchFromFundCode || '',
          fundName: {
            locale: {
              zh_TW: '摩根龍揚基金',
              en_US: '',
            },
          }, // fetch from api details
          currency: urlTradingFund.currency,
          baselineCurrency: 'usd', // fetch from api details (key "baselineCurrency")
          fee: '-', // Awaiting for the formula
          feeRate: `${(100 * responseData.feeRate).toFixed(0)}%`,
          unit: urlTradingFund.unit || 0,
          discountCode: responseData.discountCode,
          availableHoldings: redemptionData.averageUnitCost, // assume fetch from redemption api
          redUnitPrecision: redemptionData.redUnitPrecision, // assume fetch from redemption api
          redUnitPricePrecision: redemptionData.redUnitPricePrecision, // assume fetch from redemption api
          percent:
            ((urlTradingFund.unit || 0) / redemptionData.averageUnitCost) * 100, // assume fetch from redemption api
          bankAccountNo: `${urlTradingFund.bankCode}-${urlTradingFund.bankAccount}`,
          jfFundCode: redemptionData.fundInfo.jFundCode, // assume fetch from redemption api
          isinCode: null, // fetch from fund details api
          fundFeeType: redemptionData.fundFeesType, // assume fetch from redemption api
          redemptEstimateValue: redemptionCalculator.value,
        },
        inFund: {
          type: 'ST',
          fundCode: urlTradingFund.switchToFundCodeCode || '',
          fundName: {
            locale: {
              zh_TW: '摩根中國雙息平衡基金-累積型',
              en_US: '',
            },
          }, // fetch from api details
          currency: urlTradingFund.currency,
          baselineCurrency: 'usd', // fetch from api details (key "baselineCurrency")
          fee: '-', // Awaiting for the formula
          feeRate: `${(100 * responseData.feeRate).toFixed(0)}%`,
          amount: urlTradingFund.buyAmount || 0,
          discountCode: responseData.discountCode,
          minHolding: 4000, // fetch from api
          agreements, // fetch from api
        },
      }
      switchList.push(switchFundObject)
    }
  }

  return {
    oid: responseData.oid,
    clientId: responseData.oid,
    orders: {
      loading: false,
      isActive: responseData.active,
      isEmpty: false,
      isValidationLoading: false,
      list: {
        allotmentList: _cloneDeep(allotmentList),
        switchList: _cloneDeep(switchList),
        redemptionList: _cloneDeep(redemptionList),
      },
      isFirst: responseData.isFirst,
    },
  }
}

const fetchUrlTradingFundList = createAsyncThunk<TradingFundData>(
  'fetchUrlTradingFundList',
  async () => {
    const res = await appAxios().get(getRoute({ route: 'urlTrading' }))

    const redemptionDataList = await fetchUrlRedemptionList()
    const responseData = res.data

    return transformUrlTradingFundData(responseData, redemptionDataList)
  }
)

export default fetchUrlTradingFundList
