import './Section.scss'

import classnames from 'classnames'
import PropTypes from 'prop-types'
import { FunctionComponent, ReactNode } from 'react'
import { Col, Container, Row } from 'react-bootstrap'

interface SectionProps {
  className?: string
  children: ReactNode
  id?: string
  title?: string | ReactNode
  narrow?: boolean
  noPadding?: boolean
}
const Section: FunctionComponent<SectionProps> = ({
  children,
  className,
  id,
  title,
  narrow,
  noPadding,
  ...props
}: SectionProps) => {
  return (
    <section
      className={classnames('section', className, {
        'section--narrow': narrow,
      })}
      id={id}
      data-testid={`section-${id}`}
      {...props}
    >
      <Container>
        {title && (
          <Row className='section__header' data-testid='section-title'>
            {typeof title === 'string' ? (
              <Col>
                <h2>{title}</h2>
              </Col>
            ) : (
              title
            )}
          </Row>
        )}
        <Row className='section__main'>
          <Col className={noPadding ? 'column-noPadding' : ''}>{children}</Col>
        </Row>
      </Container>
    </section>
  )
}

Section.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
}

Section.defaultProps = {
  className: '',
  id: undefined,
  title: undefined,
  narrow: false,
  noPadding: false,
}

export default Section
