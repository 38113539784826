import { FunctionComponent, useEffect, useState } from 'react'

import Button from '../../../components/button'
import ProgressIndicator from '../../../components/progress-indicator'
import { BUTTON_SIZE_LARGE } from '../../../constants/button'
import { useAppSelector } from '../../../hooks'
import { RootState } from '../../../store'
import styles from './QuestionnaireControl.module.scss'

interface QuestionnaireControlProps {
  currentStep: number
  disabled?: boolean
  onClickContinue: () => void
  buttonLabel: string
}

const QuestionnaireControl: FunctionComponent<QuestionnaireControlProps> = ({
  currentStep,
  disabled,
  onClickContinue,
  buttonLabel,
}) => {
  const [currentStepNumber, setCurrentStepNumber] =
    useState<number>(currentStep)
  const { stepQuestionnaireData } = useAppSelector(
    (state: RootState) => state.generalSuitability
  )

  useEffect(() => {
    setCurrentStepNumber(currentStep)
  }, [currentStep])

  return (
    <div className={styles.questionnaireControl}>
      {/* +3 refers to 
      1. home page
      2. review page
      3. result page */}
      <ProgressIndicator
        totalSteps={stepQuestionnaireData.length + 3}
        currentStep={currentStepNumber}
      />
      <Button
        disabled={disabled}
        onClick={onClickContinue}
        size={BUTTON_SIZE_LARGE}
      >
        {buttonLabel}
      </Button>
    </div>
  )
}

QuestionnaireControl.defaultProps = {
  disabled: false,
}

export default QuestionnaireControl
