import './GeneralSuitability.scss'

import { FunctionComponent } from 'react'

import getCountriesSpecific from '../../helpers/countriesSpecific'
import GeneralSuitabilityHK from './country-specific/GeneralSuitabilityHK'
import GeneralSuitabilityTW from './country-specific/GeneralSuitabilityTW'

const GeneralSuitability: FunctionComponent = () => {
  const CountrySpecificGeneralSuitabilityPage = getCountriesSpecific({
    HK: GeneralSuitabilityHK,
    TW: GeneralSuitabilityTW,
  })

  return <CountrySpecificGeneralSuitabilityPage />
}

export default GeneralSuitability
