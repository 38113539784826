import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { FundPreviewType } from '../../typings/FundPreview'
import { FundSearchState, SearchResultType } from '../../typings/Search'
import fetchSearch from './actions/fetchSearch'
import fetchTopFundList from './actions/fetchTopFundList'

const initialState: FundSearchState = {
  searchResult: [],
  topFundList: [],
}

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchSearch.fulfilled,
      (state, action: PayloadAction<SearchResultType>) => {
        state.searchResult = action.payload
      }
    )
    builder.addCase(
      fetchTopFundList.fulfilled,
      (state, action: PayloadAction<FundPreviewType[]>) => {
        state.topFundList = action.payload
      }
    )
  },
})

export { fetchSearch }

export default searchSlice.reducer
