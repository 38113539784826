import {
  MULTI_SELECT_MULTIPLE_CHOICE,
  SINGLE_SELECT_MULTIPLE_CHOICE,
} from '../../constants/question-types'
import {
  FILLING_ROLE_AGENT_ONE,
  FILLING_ROLE_AGENT_TWO,
  FILLING_ROLE_SELF,
  FORM_INSTRUCTION_OCCUPATION,
  FORM_INSTRUCTION_POLITICAL_RELATIONSHIP,
  GeneralSuitabilityQuestion,
  GeneralSuitabilityQuestionGroup,
  QUESTION_CATEGORY_BASIC_INFO,
  QUESTION_CATEGORY_EXTRA,
  QUESTION_CATEGORY_FINANICAL_INFO,
  QUESTION_CATEGORY_HEALTH_INFO,
  QUESTION_CATEGORY_RISK_ASSESSMENT,
  QUESTION_CATEGORY_TRADING,
  QUESTION_DISPLAY_CHECKBOX,
  QUESTION_DISPLAY_DROPDOWN,
  QUESTION_DISPLAY_RADIO,
  QUESTION_DISPLAY_TEXT_FIELD,
  QUESTION_DISPLAY_TRUE_OR_FALSE,
  QUESTION_TYPE_FREE_INPUT,
  QUESTION_VALIDATION_DATE,
  QuestionnaireStepType,
  QuestionnaireSubStepType,
  RADIO_TEXT_INPUT_COMBO,
} from '../../typings/GeneralSuitability'

export const countryList = ['香港', '中國', '台灣']

// 基本資料 問題
export const basicInfoQuestions: GeneralSuitabilityQuestion[] = [
  {
    question: '教育程度',
    questionNumber: 1,
    questionId: '1',
    options: ['國中(含)以下', '高中職/專科', '大學', '碩士/博士'],
    questionType: SINGLE_SELECT_MULTIPLE_CHOICE,
    display: QUESTION_DISPLAY_RADIO,
    dependentQuestions: [],
  },
  {
    question: '行業',
    questionNumber: 2,
    questionId: '2',
    options: [
      '銀行、金融及保險',
      '娛樂、傳媒及出版',
      '進出口貿易',
      '製造/建築/營建工程',
      '公共及政府服務',
      '房地產/不動產經紀業',
      '教育及醫療',
      '資訊/科技及通訊',
      '貨幣服務行業(如：外幣收兌處、提供匯款服務或虛擬貨幣交易商/虛擬資產業)',
      '律師/會計師或投資仲介為其客戶提供金融關係服務',
      '公證人/地政士',
      '餐飲/酒店/旅遊/飯店業',
      '批發及零售',
      '非營利機構、宗教團體及慈善組織',
      '酒類/菸草/交通工具經銷商',
      '當鋪/拍賣行業(文物藝術品)/銀樓業/寶石/古董/貴金屬',
      '博弈業/線上遊戲事業',
      '農/林/漁/牧業',
      '退休人士',
      '家管',
      '學生',
      '待業',
      '政治性職業',
      '廢棄物清除/處理及資源回收處理業',
      '運輸及倉儲業',
      '軍火/武器設備製造/經銷商',
      '一般投資業',
      '電子支付機構/第三方支付服務業',
      '指定外籍移工匯兌公司',
      '其他',
    ],
    questionType: SINGLE_SELECT_MULTIPLE_CHOICE,
    display: QUESTION_DISPLAY_DROPDOWN,
    placeholder: '請選擇行業',
    dependentQuestions: [
      {
        isDependent: true,
        question: '請詳細說明您的行業',
        questionId: '2a',
        questionNumber: 27,
        options: [],
        display: QUESTION_DISPLAY_TEXT_FIELD,
        questionType: QUESTION_TYPE_FREE_INPUT,
        dependentQuestions: [],
      },
      {
        isDependent: true,
        question: '您是否為台灣彩券經銷商？',
        questionId: '2b',
        questionNumber: 28,
        options: ['是', '否'],
        display: QUESTION_DISPLAY_TRUE_OR_FALSE,
        questionType: SINGLE_SELECT_MULTIPLE_CHOICE,
        dependentQuestions: [],
        affectedQuestions: [29],
      },
      {
        isDependent: true,
        question: '您是否對您任職的機構擁有控制權？',
        questionId: '2e',
        questionNumber: 29,
        options: ['是', '否'],
        display: QUESTION_DISPLAY_TRUE_OR_FALSE,
        questionType: SINGLE_SELECT_MULTIPLE_CHOICE,
        dependentQuestions: [],
      },
      {
        ageFilter: {
          role: FILLING_ROLE_SELF,
          value: 25,
          compare: 'gte',
        },

        isDependent: true,
        question: '請提供您前一任職機構名稱',
        questionId: '2c',
        questionNumber: 40,
        options: [],
        display: QUESTION_DISPLAY_TEXT_FIELD,
        questionType: QUESTION_TYPE_FREE_INPUT,
        dependentQuestions: [],
        placeholder: '請填寫完整機構名稱',
      },
      {
        ageFilter: {
          role: FILLING_ROLE_SELF,
          value: 45,
          compare: 'gt',
        },
        isDependent: true,
        question: '請提供您退休前任職機構名稱',
        questionId: '2d',
        questionNumber: 44,
        options: [],
        display: QUESTION_DISPLAY_TEXT_FIELD,
        questionType: QUESTION_TYPE_FREE_INPUT,
        dependentQuestions: [],
        placeholder: '請填寫完整機構名稱',
      },
    ],
    affectedQuestions: [3, 4, 27, 28, 29, 33, 35, 36, 40, 41, 44],
  },
  {
    dependOptions: [
      {
        conditions: [
          {
            questionNumber: 2,
            option: ['退休人士', '家管', '學生', '待業'],
          },
        ],
        trigger: {
          questionNumber: 3,
          autofilled: true,
          answer: '',
          answerFrom: 2,
        },
      },
    ],
    question: '任職機構名稱',
    questionNumber: 3,
    questionId: '3',
    options: [],
    questionType: QUESTION_TYPE_FREE_INPUT,
    display: QUESTION_DISPLAY_TEXT_FIELD,
    placeholder: '請填寫完整公司名稱，若無任職機構，請填「無」',
    dependentQuestions: [],
  },
  {
    dependOptions: [
      {
        conditions: [
          {
            questionNumber: 2,
            option: ['退休人士', '家管', '學生', '待業'],
          },
        ],
        trigger: {
          questionNumber: 4,
          autofilled: true,
          answer: '無職業/退休/家管/學生/待業/無業',
        },
      },
    ],
    question: '職稱',
    questionNumber: 4,
    questionId: '4',
    options: [
      '一般職員',
      '基層主管',
      '中階主管',
      '高階主管',
      '企業負責人/自僱',
      '無職業/退休/家管/學生/待業/無業',
      '其他',
    ],
    placeholder: '請選擇職稱',
    questionType: SINGLE_SELECT_MULTIPLE_CHOICE,
    display: QUESTION_DISPLAY_DROPDOWN,
    instructionPopup: FORM_INSTRUCTION_OCCUPATION,
    affectedQuestions: [30, 31, 32, 37],
    dependentQuestions: [
      {
        isDependent: true,
        question: '請說明您的職稱',
        questionId: '4a',
        questionNumber: 30,
        options: [],
        display: QUESTION_DISPLAY_TEXT_FIELD,
        questionType: QUESTION_TYPE_FREE_INPUT,
        dependentQuestions: [],
      },
      {
        isDependent: true,
        question: '請提供公司統一編號(8碼數字)',
        questionId: '4b',
        questionNumber: 31,
        options: [],
        display: {
          type: 'INDIVIDUAL_CHAR',
          numOfChar: 8,
        },
        questionType: QUESTION_TYPE_FREE_INPUT,
        dependentQuestions: [],
      },
      {
        // External condition for rendering
        isDependent: true,
        question: '您於近期多次變更職業相關資訊的原因？',
        questionId: '4c',
        questionNumber: 41,
        options: ['工作轉換', '其他'],
        extraDisplayOptions: {
          display: RADIO_TEXT_INPUT_COMBO,
          displayTextFieldOnAnswer: '其他',
        },
        questionType: SINGLE_SELECT_MULTIPLE_CHOICE,
        display: QUESTION_DISPLAY_RADIO,
        dependentQuestions: [],
      },
    ],
  },
  {
    question: '與政治人物之關係',
    instructionPopup: FORM_INSTRUCTION_POLITICAL_RELATIONSHIP,
    questionNumber: 5,
    questionId: '5',
    options: [
      '與政治人物無關',
      '本人是現任政治人物',
      '本人曾任政治人物',
      '政治人物之家庭成員',
      '與政治人物有密切關係',
    ],
    placeholder: '請選擇與政治人物之關係',
    questionType: SINGLE_SELECT_MULTIPLE_CHOICE,
    display: QUESTION_DISPLAY_DROPDOWN,
    affectedQuestions: [100, 1001, 1002, 1003, 1004, 1005, 1006],
    dependentQuestions: [
      {
        isDependent: true,
        question: '政治人物資訊*',
        questionId: '5a',
        questionNumber: 100,
        options: [],
        questionType: QUESTION_TYPE_FREE_INPUT,
        display: QUESTION_DISPLAY_TEXT_FIELD,
        placeholder: '',
        dependentQuestions: [],
      },
      {
        isDependent: true,
        question: '姓名',
        questionId: '5ai',
        questionNumber: 1001,
        options: [],
        questionType: QUESTION_TYPE_FREE_INPUT,
        display: QUESTION_DISPLAY_TEXT_FIELD,
        placeholder: '',
        dependentQuestions: [],
      },
      {
        isDependent: true,
        question: '出生年月日 (西元)',
        questionId: '5aii',
        questionNumber: 1002,
        options: [],
        questionType: QUESTION_TYPE_FREE_INPUT,
        display: QUESTION_DISPLAY_TEXT_FIELD,
        placeholder: '例如：yyyymmdd',
        dependentQuestions: [],
        validation: QUESTION_VALIDATION_DATE,
        inputFieldProps: {
          maxLength: '8',
        },
      },
      {
        isDependent: true,
        question: '國籍',
        questionId: '5aiii',
        questionNumber: 1003,
        options: countryList,
        questionType: SINGLE_SELECT_MULTIPLE_CHOICE,
        display: QUESTION_DISPLAY_DROPDOWN,
        placeholder: '請選擇國籍',
        dependentQuestions: [],
      },
      {
        isDependent: true,
        question: '政治性職務任職機構名稱',
        questionId: '5aiv',
        questionNumber: 1004,
        options: [],
        questionType: QUESTION_TYPE_FREE_INPUT,
        display: QUESTION_DISPLAY_TEXT_FIELD,
        placeholder: '請填寫完整機構名稱',
        dependentQuestions: [],
      },
      {
        isDependent: true,
        question: '政治性職務職稱',
        questionId: '5av',
        questionNumber: 1005,
        options: [],
        questionType: QUESTION_TYPE_FREE_INPUT,
        display: QUESTION_DISPLAY_TEXT_FIELD,
        placeholder: '',
        dependentQuestions: [],
      },
      {
        isDependent: true,
        question: '關係',
        questionId: '5avi',
        questionNumber: 1006,
        options: [],
        questionType: QUESTION_TYPE_FREE_INPUT,
        display: QUESTION_DISPLAY_TEXT_FIELD,
        placeholder: '',
        dependentQuestions: [],
      },
    ],
  },
]

// 個人財務狀況 問題
export const finanicalInfoQuestions: GeneralSuitabilityQuestion[] = [
  {
    question: '預計與本公司往來的投資交易頻率(單筆)',
    questionId: '6',
    questionNumber: 6,
    options: ['一年以上', '半年', '每季', '每月', '每日', '每週', '不定期'],
    questionType: SINGLE_SELECT_MULTIPLE_CHOICE,
    display: QUESTION_DISPLAY_DROPDOWN,
    placeholder: '請選擇交易頻率',
    dependentQuestions: [],
  },
  {
    question: '個人年收入(新台幣)',
    questionId: '7',
    questionNumber: 7,
    options: [
      '未滿70萬',
      '70萬以上(含) - 未滿120萬',
      '120萬以上(含) - 未滿210萬',
      '210萬以上(含) - 未滿300萬',
      '300萬(含)以上',
    ],
    questionType: SINGLE_SELECT_MULTIPLE_CHOICE,
    display: QUESTION_DISPLAY_DROPDOWN,
    placeholder: '請選擇個人年收入',
    dependentQuestions: [],
  },
  {
    question: '扣除負債後家庭總資產(新台幣)',
    questionId: '8',
    questionNumber: 8,
    options: [
      '未滿50萬',
      '50萬以上(含) - 未滿250萬',
      '250萬以上(含) - 未滿500萬',
      '500萬以上(含) - 未滿1000萬',
      '1000萬以上(含) - 未滿2500萬',
      '2500萬(含)以上',
    ],
    questionType: SINGLE_SELECT_MULTIPLE_CHOICE,
    display: QUESTION_DISPLAY_DROPDOWN,
    placeholder: '請選擇扣除負債後家庭總資產',
    dependentQuestions: [],

    affectedQuestions: [32],
  },
  {
    question: '預計與本公司未來一年新增投資金額(新台幣)',
    questionId: '9',
    questionNumber: 9,
    options: [
      '未滿50萬',
      '50萬以上(含) - 未滿100萬',
      '100萬以上(含) - 未滿300萬',
      '300萬以上(含) - 未滿1000萬',
      '1000萬(含)以上',
    ],
    questionType: SINGLE_SELECT_MULTIPLE_CHOICE,
    display: QUESTION_DISPLAY_DROPDOWN,
    placeholder: '請選擇預計與本公司未來一年新增投資金額',

    affectedQuestions: [32, 33],
    dependentQuestions: [
      {
        isDependent: true,
        questionId: '9a',
        questionNumber: 32,
        question:
          '您填寫的資料 [預計與本公司未來一年新增投資金額] 大於 [家庭總資產] 。請補充說明前述之資金來源為何？ ',
        options: [
          '預估有非經常性薪資收入，如年終獎金、績效獎金',
          '預估有投資獲利所得之資金，如股票、期貨權證等金融商品',
          '預估有房地產買賣之不動產獲利',
          '其他',
        ],
        display: QUESTION_DISPLAY_RADIO,
        questionType: SINGLE_SELECT_MULTIPLE_CHOICE,
        dependentQuestions: [],
        extraDisplayOptions: {
          display: RADIO_TEXT_INPUT_COMBO,
          displayTextFieldOnAnswer: '其他',
        },
      },
      {
        isDependent: true,
        questionId: '9b',
        questionNumber: 33,
        question:
          '您所提供的職業為 [家管/待業/學生] 且預計與本公司未來一年新增投資金額(新台幣)為 [300萬以上] 。請補充說明前述之資金來源為何？',
        options: [
          '配偶之收入',
          '過去工作薪資累積',
          '家人資產',
          '家人退休金',
          '遺產/餽贈',
          '其他',
        ],
        display: QUESTION_DISPLAY_RADIO,
        questionType: SINGLE_SELECT_MULTIPLE_CHOICE,
        dependentQuestions: [],
        extraDisplayOptions: {
          display: RADIO_TEXT_INPUT_COMBO,
          displayTextFieldOnAnswer: '其他',
        },
      },
    ],
  },
  {
    questionId: '10',
    questionNumber: 10,
    question: '投資主要資金來源(可複選)',
    options: [
      '薪資',
      '儲蓄',
      '退休金',
      '租金',
      '經營事業收入',
      '投資獲利所得',
      '遺產/餽贈',
      '零用金/家用準備金',
      '兼職收入',
      '利息收入',
      '其他非上述經常性收入',
    ],
    questionType: MULTI_SELECT_MULTIPLE_CHOICE,
    display: QUESTION_DISPLAY_CHECKBOX,
    affectedQuestions: [34, 35, 36, 37],
    dependentQuestions: [
      {
        isDependent: true,
        question: '請問您其他的投資資金來源為何？',
        questionId: '10a',
        questionNumber: 34,
        options: [],
        questionType: QUESTION_TYPE_FREE_INPUT,
        display: QUESTION_DISPLAY_TEXT_FIELD,
        dependentQuestions: [],
      },
      {
        isDependent: true,
        question:
          '您填寫的資料為 [行業：非退休人士] 且 [投資主要資金來源：退休金] 。請補充說明前述之資金來源為何？',
        questionId: '10b',
        questionNumber: 35,
        options: [
          '前份工作已退休',
          '勞保已經申請退休，但仍在原職繼續任職',
          '其他',
        ],
        display: QUESTION_DISPLAY_RADIO,
        questionType: SINGLE_SELECT_MULTIPLE_CHOICE,
        dependentQuestions: [],
        extraDisplayOptions: {
          display: RADIO_TEXT_INPUT_COMBO,
          displayTextFieldOnAnswer: '其他',
        },
      },
      {
        isDependent: true,
        question:
          '您填寫的資料為 [職業：退休/家管/待業/學生] 且 [投資主要資金來源：薪資] 。請補充說明前述之資金來源為何？',
        questionId: '10c',
        questionNumber: 36,
        options: ['家人之收入', '過去工作薪資', '其他'],
        display: QUESTION_DISPLAY_RADIO,
        questionType: SINGLE_SELECT_MULTIPLE_CHOICE,
        dependentQuestions: [],
        extraDisplayOptions: {
          display: RADIO_TEXT_INPUT_COMBO,
          displayTextFieldOnAnswer: '其他',
        },
      },
      {
        isDependent: true,
        question:
          '您填寫的資料為 [職業：非企業負責人/自僱] 且 [投資主要資金來源：經營事業收入] 。請補充說明前述之資金來源為何？',
        questionId: '10d',
        questionNumber: 37,
        options: ['網拍經營', '兼職接案', '合夥開店/公司', '其他'],
        display: QUESTION_DISPLAY_RADIO,
        questionType: SINGLE_SELECT_MULTIPLE_CHOICE,
        dependentQuestions: [],
        extraDisplayOptions: {
          display: RADIO_TEXT_INPUT_COMBO,
          displayTextFieldOnAnswer: '其他',
        },
      },
    ],
  },
  {
    question: '如何取得投資相關訊息(可複選)',
    questionId: '11',
    questionNumber: 11,
    options: [
      '書報/雜誌',
      '廣播/電視',
      '網際網路',
      '社群媒體(如：FB)',
      '銀行通路',
      '客服中心',
      '專業理財人員',
      '其他',
    ],
    questionType: MULTI_SELECT_MULTIPLE_CHOICE,
    display: QUESTION_DISPLAY_CHECKBOX,
    affectedQuestions: [38],
    dependentQuestions: [
      {
        isDependent: true,
        question: '你是如何從其他來源取得投資相關訊息？',
        questionId: '11a',
        questionNumber: 38,
        options: [],
        questionType: QUESTION_TYPE_FREE_INPUT,
        display: QUESTION_DISPLAY_TEXT_FIELD,
        dependentQuestions: [],
      },
    ],
  },
  {
    question: '投資目的與需求(可複選)',
    questionId: '12',
    questionNumber: 12,
    options: ['儲蓄', '購屋', '結婚', '子女教育', '退休', '其他'],
    questionType: MULTI_SELECT_MULTIPLE_CHOICE,
    display: QUESTION_DISPLAY_CHECKBOX,
    affectedQuestions: [39],
    dependentQuestions: [
      {
        isDependent: true,
        question: '請說明其他的投資目的與需求？',
        questionId: '12a',
        questionNumber: 39,
        options: [],
        questionType: QUESTION_TYPE_FREE_INPUT,
        display: QUESTION_DISPLAY_TEXT_FIELD,
        dependentQuestions: [],
      },
    ],
  },
  {
    ageFilter: {
      role: FILLING_ROLE_SELF,
      value: 20,
      compare: 'lt',
    },
    question: '請問受益人之資金來源是否來自法定代理人？',
    questionId: '13',
    questionNumber: 13,
    options: ['是', '否'],
    questionType: SINGLE_SELECT_MULTIPLE_CHOICE,
    display: QUESTION_DISPLAY_TRUE_OR_FALSE,
    affectedQuestions: [42, 43],
    dependentQuestions: [
      {
        isDependent: true,
        question: '如否，資金來源',
        questionId: '13a',
        questionNumber: 42,
        options: [],
        questionType: QUESTION_TYPE_FREE_INPUT,
        display: QUESTION_DISPLAY_RADIO,
        dependentQuestions: [],
      },
    ],
  },
]

// 個人健康狀況  問題
export const healthInfoQuestions: GeneralSuitabilityQuestion[] = [
  {
    question: '是否領有重大傷病證明(中央健康保險署核發)？',
    questionId: '14',
    questionNumber: 14,
    options: ['是', '否'],
    questionType: SINGLE_SELECT_MULTIPLE_CHOICE,
    display: QUESTION_DISPLAY_TRUE_OR_FALSE,
    dependentQuestions: [],
  },
  {
    ageFilter: {
      role: FILLING_ROLE_SELF,
      value: 65,
      compare: 'gte',
    },
    question: '請問是否領有身心障礙手冊或證明',
    questionId: '15',
    questionNumber: 15,
    options: ['是', '否'],
    questionType: SINGLE_SELECT_MULTIPLE_CHOICE,
    display: QUESTION_DISPLAY_TRUE_OR_FALSE,
    dependentQuestions: [],
  },
  {
    ageFilter: {
      role: FILLING_ROLE_SELF,
      value: 65,
      compare: 'gte',
    },
    question: '請問目前社群關係及照護狀況',
    questionId: '16',
    questionNumber: 16,
    options: [
      '與親友同住',
      '與親友同住並有居家照護或其他長照服務',
      '單獨居住',
      '單獨居住並有居家照護或其他長照服務',
    ],
    questionType: SINGLE_SELECT_MULTIPLE_CHOICE,
    display: QUESTION_DISPLAY_DROPDOWN,
    placeholder: '請問目前社群關係及照護狀況',
    dependentQuestions: [],
  },
]

// 風險屬性評估問卷  問題
export const riskAssessmentQuestionsMinor: GeneralSuitabilityQuestion[] = [
  {
    question: '請問您的年齡組別？',
    questionNumber: 17,
    questionId: '17',
    options: ['65歲以上', '60 - 64歲', '50 - 59歲', '20 - 29歲', '30 - 49歲'],
    questionType: SINGLE_SELECT_MULTIPLE_CHOICE,
    display: QUESTION_DISPLAY_RADIO,
    dependentQuestions: [],
  },
  {
    question: '金融商品投資經驗 (如共同基金、債券、股票...)',
    questionNumber: 18,
    questionId: '18',
    options: [
      '未滿1年',
      '1年以上(含) - 未滿4年',
      '4年以上(含) - 未滿7年',
      '7年以上(含) - 未滿11年',
      '11年(含)以上',
    ],
    questionType: SINGLE_SELECT_MULTIPLE_CHOICE,
    display: QUESTION_DISPLAY_RADIO,
    dependentQuestions: [],
  },
  {
    question: '投資金融商品的態度',
    questionNumber: 19,
    questionId: '19',
    options: [
      '本金完全不能損失，不可以承擔任何風險',
      '如果報酬合理，可以承擔少量風險',
      '為追求高一點報酬，願意承擔中度的風險',
      '只要投資報酬好，願意承擔中高程度風險',
      '只要有很高的報酬，願意承擔高度風險',
    ],
    questionType: SINGLE_SELECT_MULTIPLE_CHOICE,
    display: QUESTION_DISPLAY_RADIO,
    dependentQuestions: [],
  },
  {
    question: '投資結果對生活的影響程度',
    questionId: '20',
    questionNumber: 20,
    options: [
      '非常高度的影響',
      '高度的影響',
      '有點顯著的影響',
      '有一點點影響',
      '沒有影響',
    ],
    questionType: SINGLE_SELECT_MULTIPLE_CHOICE,
    display: QUESTION_DISPLAY_RADIO,
    dependentQuestions: [],
  },

  {
    question: '未來投資金額可能有大幅動用時間(如退休、購屋、創業...)',
    questionNumber: 21,
    questionId: '21',
    options: [
      '未滿1年',
      '1年以上(含) - 未滿3年',
      '3年以上(含) - 未滿6年',
      '6年以上(含) - 未滿11年',
      '11年(含)以上',
    ],
    questionType: SINGLE_SELECT_MULTIPLE_CHOICE,
    display: QUESTION_DISPLAY_RADIO,
    dependentQuestions: [],
  },
  {
    question: '期望平均年投資報酬率',
    questionNumber: 22,
    questionId: '22',
    options: ['定存利率', '3% - 5%', '6% - 8%', '9% - 12%', '13%以上'],
    questionType: SINGLE_SELECT_MULTIPLE_CHOICE,
    display: QUESTION_DISPLAY_RADIO,
    dependentQuestions: [],
  },
  {
    question: '投資金融商品可以接受的最大下跌範圍 (如共同基金、債券、股票...)',
    questionNumber: 23,
    questionId: '23',
    options: ['5%以下', '6% - 10%', '11% - 20%', '21% - 30%', '31%以上'],
    questionType: SINGLE_SELECT_MULTIPLE_CHOICE,
    display: QUESTION_DISPLAY_RADIO,
    dependentQuestions: [],
  },
  {
    question: '投資理財主要目的',
    questionNumber: 24,
    questionId: '24',
    options: [
      '純粹為資產保值',
      '大部分為資產保值少部分為資產增值',
      '資產保值與資產增值各半',
      '大部分為資產增值少部分為資產保值',
      '純粹為資產增值',
    ],
    questionType: SINGLE_SELECT_MULTIPLE_CHOICE,
    display: QUESTION_DISPLAY_RADIO,
    dependentQuestions: [],
  },
  {
    question: '除基金外，最常使用的投資理財工具',
    questionNumber: 25,
    questionId: '25',
    options: [
      '儲蓄保險/台幣存款',
      '外幣存款',
      '不動產/債券',
      '股票/投資型保單',
      '期貨權證/其他衍生性商品',
    ],
    questionType: MULTI_SELECT_MULTIPLE_CHOICE,
    display: QUESTION_DISPLAY_CHECKBOX,
    dependentQuestions: [],
  },
  {
    question: '對基金投資之偏好',
    questionNumber: 26,
    questionId: '26',
    options: ['貨幣型', '債券型', '平衡型', '多重資產型', '股票型'],
    questionType: SINGLE_SELECT_MULTIPLE_CHOICE,
    display: QUESTION_DISPLAY_RADIO,
    dependentQuestions: [],
  },
]

export const riskAssessmentQuestionsNonMinor: GeneralSuitabilityQuestion[] =
  riskAssessmentQuestionsMinor.filter(
    ({ questionNumber }) => questionNumber !== 17
  )

// 貿易往來資訊確認書  問題
export const tradingQuestions: GeneralSuitabilityQuestion[] = [
  {
    question:
      '<p>一、客戶包括任何多數股權或受控制的子公司或聯營公司，直接或間接以下述方式參與，或計劃參與活動：</p><br/><span>1. 有實體存在於OFAC受制裁的國家/地區</span>',
    questionNumber: 99,
    questionId: '4d1',
    options: ['是', '否'],
    questionType: SINGLE_SELECT_MULTIPLE_CHOICE,
    display: QUESTION_DISPLAY_TRUE_OR_FALSE,
    dependentQuestions: [],
  },
  {
    question: '2. 在被OFAC受制裁的國家/地區或與受制裁方有業務關係或有交易對手',
    questionNumber: 98,
    questionId: '4d2',
    options: ['是', '否'],
    questionType: SINGLE_SELECT_MULTIPLE_CHOICE,
    display: QUESTION_DISPLAY_TRUE_OR_FALSE,
    dependentQuestions: [],
  },
  {
    question:
      '3. 是否有董事、高階管理人員或持股10%或以上的股東為受制裁國家的居民，或被僱用或代表OFAC受制裁國家/地區的政府機構/當局；或受制裁方的人？',
    questionNumber: 97,
    questionId: '4d3',
    options: ['是', '否'],
    questionType: SINGLE_SELECT_MULTIPLE_CHOICE,
    display: QUESTION_DISPLAY_TRUE_OR_FALSE,
    dependentQuestions: [],
  },
  {
    question:
      '二、是否存在任何其他制裁風險，例如客戶依據當地制裁法律中被偵測到或討論到？',
    questionNumber: 96,
    questionId: '4d4',
    options: ['是', '否'],
    questionType: SINGLE_SELECT_MULTIPLE_CHOICE,
    display: QUESTION_DISPLAY_TRUE_OR_FALSE,
    dependentQuestions: [],
  },
]

// 重要提示 問題
export const specialCriteriaPopupQuestion: GeneralSuitabilityQuestion = {
  question:
    '<p>請確認您本次的風險屬性評估結果為高度風險，若風險屬性由低....以完成變更流程。</p>請選擇變更原因，風險屬性即生效：',
  questionNumber: 0,
  questionId: '0',
  questionType: SINGLE_SELECT_MULTIPLE_CHOICE,
  display: QUESTION_DISPLAY_RADIO,
  options: ['風險承受能力變高', '看好某...', '操作更新...', '其他理由'],
  extraDisplayOptions: {
    display: RADIO_TEXT_INPUT_COMBO,
    displayTextFieldOnAnswer: '其他理由',
  },
  dependentQuestions: [],
}

export const questionGroups: GeneralSuitabilityQuestionGroup[] = [
  {
    category: QUESTION_CATEGORY_BASIC_INFO,
    questions: basicInfoQuestions,
  },
  {
    category: QUESTION_CATEGORY_FINANICAL_INFO,
    questions: finanicalInfoQuestions,
  },
  {
    category: QUESTION_CATEGORY_HEALTH_INFO,
    questions: healthInfoQuestions,
  },
  {
    category: QUESTION_CATEGORY_RISK_ASSESSMENT,
    questions: riskAssessmentQuestionsMinor,
  },
  { category: QUESTION_CATEGORY_TRADING, questions: tradingQuestions },
]

const questionNumberExclueAgent = [13] // 法定代理人不需回答的問題編號

// 受益人 基本資料 SUB STEP
const userBasicInfoSubStepData: QuestionnaireSubStepType = {
  currentFillingRole: FILLING_ROLE_SELF,
  title: '基本資料',
  mandatory: true,
  questionGroups: [
    {
      category: QUESTION_CATEGORY_BASIC_INFO,
      questions: basicInfoQuestions,
    },
    {
      category: QUESTION_CATEGORY_FINANICAL_INFO,
      questions: finanicalInfoQuestions,
    },
    {
      category: QUESTION_CATEGORY_HEALTH_INFO,
      questions: healthInfoQuestions,
    },
  ],
}
// 代理人 基本資料 SUB STEP
const agentBasicInfoSubStepData: QuestionnaireSubStepType = {
  mandatory: true,
  currentFillingRole: FILLING_ROLE_AGENT_ONE,
  title: '基本資料',
  questionGroups: [
    {
      category: QUESTION_CATEGORY_BASIC_INFO,
      questions: basicInfoQuestions.filter(
        (mainQuestion) =>
          !questionNumberExclueAgent.includes(mainQuestion.questionNumber)
      ), // filter out those questions are not applicable to agent
    },
    {
      category: QUESTION_CATEGORY_FINANICAL_INFO,
      questions: finanicalInfoQuestions.filter(
        (mainQuestion) =>
          !questionNumberExclueAgent.includes(mainQuestion.questionNumber) // filter out those questions are not applicable to agent
      ),
    },
    {
      category: QUESTION_CATEGORY_HEALTH_INFO,
      questions: healthInfoQuestions.filter(
        (mainQuestion) =>
          !questionNumberExclueAgent.includes(mainQuestion.questionNumber)
      ),
    },
  ],
}

// 貿易往來資訊確認書 SUB STEP
const tradingInfoSubStepData: QuestionnaireSubStepType = {
  currentFillingRole: FILLING_ROLE_SELF,
  mandatory: true,
  // COMMENT OUT FOR DEMO PURPOSE
  // {
  //   // below conditions that determine whether this substep should be shown
  //   conditions: [
  //     {
  //       role: FILLING_ROLE_SELF,
  //       questionNumber: 2,
  //       option: ['進出口貿易'],
  //     },
  //     {
  //       role: FILLING_ROLE_SELF,
  //       questionNumber: 4,
  //       option: ['企業負責人/自僱'],
  //     },
  //   ],
  // },
  title: '貿易往來資訊確認書',
  questionGroups: [
    {
      category: QUESTION_CATEGORY_TRADING,
      questions: tradingQuestions,
    },
  ],
}

// 受益人 風險評估 SUB STEP
const userRiskAssessmentQuizSubStepDataMinor: QuestionnaireSubStepType = {
  currentFillingRole: FILLING_ROLE_SELF,
  mandatory: true,
  title: '風險屬性評估問卷',
  questionGroups: [
    {
      category: QUESTION_CATEGORY_RISK_ASSESSMENT,
      questions: riskAssessmentQuestionsMinor,
    },
  ],
}
const userRiskAssessmentQuizSubStepData: QuestionnaireSubStepType = {
  currentFillingRole: FILLING_ROLE_SELF,
  mandatory: true,
  title: '風險屬性評估問卷',
  questionGroups: [
    {
      category: QUESTION_CATEGORY_RISK_ASSESSMENT,
      questions: riskAssessmentQuestionsNonMinor,
    },
  ],
}

export const stepQuestionnaireDataNonMinor: QuestionnaireStepType[] = [
  [
    // step 1
    {
      // substep 1 (i.e 受益人(本人)基本資料)
      ...userBasicInfoSubStepData,
    },
    {
      // substep 2 (i.e 貿易往來資訊確認書)
      ...tradingInfoSubStepData,
      currentFillingRole: FILLING_ROLE_SELF,
    },
  ],
  [
    // step 2
    {
      // substep 1 (i.e 風險評估)
      ...userRiskAssessmentQuizSubStepData,
    },
  ],
]

export const stepQuestionnaireDataMinor: QuestionnaireStepType[] = [
  [
    // step 1
    {
      // substep 1 (i.e 受益人(本人)基本資料)
      ...userBasicInfoSubStepData,
    },
    {
      // substep 2 (i.e 貿易往來資訊確認書)
      ...tradingInfoSubStepData,
      currentFillingRole: FILLING_ROLE_SELF,
    },
    {
      // substep 3 (i.e 法定代理人1基本資料)
      ...agentBasicInfoSubStepData,
      currentFillingRole: FILLING_ROLE_AGENT_ONE,
    },
    {
      // substep 4 （i.e 法定代理人1 貿易往來資訊確認書）
      ...tradingInfoSubStepData,
      currentFillingRole: FILLING_ROLE_AGENT_ONE,
    },
    {
      // substep 5 (i.e 法定代理人2基本資料)
      ...agentBasicInfoSubStepData,
      currentFillingRole: FILLING_ROLE_AGENT_TWO,
    },
    {
      // substep 6 （i.e 法定代理人2 貿易往來資訊確認書）
      ...tradingInfoSubStepData,
      currentFillingRole: FILLING_ROLE_AGENT_TWO,
    },
    {
      // substep 7 （i.e 追問）
      currentFillingRole: FILLING_ROLE_SELF,
      mandatory: true,
      // COMMENT OUT FOR DEMO PURPOSE
      // {
      //   conditions: [
      //     {
      //       role: FILLING_ROLE_SELF,
      //       questionNumber: 13,
      //       option: ['是'],
      //     },
      //     {
      //       value1: {
      //         questionNumber: 9,
      //         role: FILLING_ROLE_SELF,
      //       },
      //       value2: {
      //         questionNumber: 7,
      //         role: FILLING_ROLE_AGENT_ONE,
      //       },
      //       compare: 'gt',
      //     },
      //   ],
      // },
      title: '追問',
      questionGroups: [
        {
          category: QUESTION_CATEGORY_EXTRA,
          questions: [
            {
              question:
                '您預計與本公司未來一年新增投資金額超過法定代理人個人年收入，請協助補充說明收入來源',
              questionId: '13b',
              questionNumber: 43,
              options: [
                '薪資',
                '儲蓄',
                '租金',
                '投資獲利所得',
                '遺產/餽贈',
                '零用金/家用準備金',
                '兼職收入',
                '利息收入',
                '其他非上述經常性收入',
              ],
              questionType: SINGLE_SELECT_MULTIPLE_CHOICE,
              display: QUESTION_DISPLAY_RADIO,
              dependentQuestions: [],
              extraDisplayOptions: {
                display: RADIO_TEXT_INPUT_COMBO,
                displayTextFieldOnAnswer: '其他非上述經常性收入',
              },
            },
          ],
        },
      ],
    },
  ],
  [
    // step 2
    {
      // substep 1 (i.e 風險評估)
      ...userRiskAssessmentQuizSubStepDataMinor,
    },
  ],
]
