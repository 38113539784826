import './Footer.scss'

import { FunctionComponent } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import routes from '../../routes/SiteNavigation'
import Link from '../link'

const Footer: FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <Container
      data-testid='footer'
      fluid
      as='footer'
      className='footer'
      id='footer'
    >
      <Row className='align-items-center footer__content-container' noGutters>
        <Col xs={12} lg={6}>
          <span className='footer__text'>{t('footer:footer-text')}</span>
          <p className='footer__breakline' />
        </Col>
        <Col xs={12} lg={6}>
          <Row className='footer__nav-ul' noGutters>
            <Link className='footer__nav-item' to={routes.privacyStatement}>
              <span>{t('footer:privacy-statement')}</span>
            </Link>
            <Link className='footer__nav-item' to={routes.termsOfUse}>
              <span> {t('footer:terms-of-use')}</span>
            </Link>
            <Link className='footer__nav-item' to={routes.securityInformation}>
              <span>{t('footer:security-information')}</span>
            </Link>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default Footer
