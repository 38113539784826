import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootStateOrAny } from 'react-redux'

import { appAxios, getRoute } from '../../../helpers'
import { parsePromotionContent } from '../../../helpers/xmlParser'
import { PromotionDataType, PromotionType } from '../../../typings/Promotion'
import { UserCountryType } from '../../../typings/UserInfo'

const NEW_CODE = 'NEW'
const ORSO_CODE = 'ORSO'

type PromotionResponseData = {
  code: string
  position: number
  locale: {
    [locale: string]: string
  }
}

type PromotionResponseHK = {
  list: PromotionResponseData[]
}

type PromotionResponseTW = {
  list: PromotionResponseData[]
}

type PromotionResponse = PromotionResponseHK | PromotionResponseTW

type PromotionReturnType = {
  promotions: PromotionType[]
  heroPromotion: PromotionType[]
}

const transformPromotionDataHK = (
  data: PromotionResponse
): PromotionReturnType => {
  const promotionList: PromotionType[] = data.list.map(
    (entry: PromotionResponseData) => {
      const languages = Object.keys(entry.locale)
      let locale: {
        [locale: string]: PromotionDataType | null
      } = {}
      languages.forEach((language: string) => {
        locale = {
          ...locale,
          [language]: parsePromotionContent(entry.locale[language]),
        }
      })

      const promotionDataObj: PromotionType = {
        code: entry.code,
        position: entry.position,
        isHero: locale?.en_US?.promoType === 'hero' || false,
        isSticky: locale?.en_US?.promoType === 'sticky' || false,
        isNewClientPromo:
          !!entry.code.match(NEW_CODE) ||
          !!entry.code.match(ORSO_CODE) ||
          false,
        locale,
      }
      return promotionDataObj
    }
  )
  const sortedPromotionList = promotionList.sort(
    (a, b) => a.position - b.position
  )
  return {
    promotions: sortedPromotionList,
    heroPromotion: sortedPromotionList.filter(
      (item: PromotionType) => item.isHero
    ),
  }
}

const transformPromotionDataTW = (
  data: PromotionResponse
): PromotionReturnType => {
  const promotionList: PromotionType[] = data.list.map(
    (entry: PromotionResponseData) => {
      const languages = Object.keys(entry.locale)
      let locale: {
        [locale: string]: PromotionDataType | null
      } = {}
      languages.forEach((language: string) => {
        locale = {
          ...locale,
          [language]: parsePromotionContent(entry.locale[language]),
        }
      })

      const promotionDataObj: PromotionType = {
        code: entry.code,
        position: entry.position,
        isHero: locale?.zh_TW?.promoType === 'hero' || false,
        isSticky: locale?.zh_TW?.promoType === 'sticky' || false,
        isNewClientPromo: false,
        locale,
      }
      return promotionDataObj
    }
  )
  const sortedPromotionList = promotionList.sort(
    (a, b) => a.position - b.position
  )
  return {
    promotions: sortedPromotionList,
    heroPromotion: sortedPromotionList.filter(
      (item: PromotionType) => item.isHero
    ),
  }
}

const transformPromotionData = (
  data: PromotionResponse,
  country: UserCountryType
): PromotionReturnType => {
  if (country === 'HK') {
    return transformPromotionDataHK(data)
  }
  return transformPromotionDataTW(data)
}

const loadPromotions = createAsyncThunk<{
  promotions: PromotionType[]
  heroPromotion: PromotionType[]
}>('loadPromotions', async (_, { getState }) => {
  const state: RootStateOrAny = getState()

  const { country } = state.system
  const promotionRes = await appAxios().get(
    `${getRoute({ route: 'promotions' })}`
  )

  return transformPromotionData(promotionRes.data, country)
})

export default loadPromotions
