import classnames from 'classnames'
import { ChangeEvent, FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import Checkbox from '../checkbox'
import ErrorMessage from '../error-message'
import {
  GENERAL_SUITABILITY_QUESTION,
  SingleChoiceQuestionProps,
} from '../single-choice-question/SingleChoiceQuestion'
import styles from './MultiSelectQuestion.module.scss'

export type MultiSelectQuestionSelectedOptionType = {
  selectedId: number
  selectedOption: string[]
}
type MultiSelectQuestionType = Omit<
  SingleChoiceQuestionProps,
  'display' | 'onValueChange' | 'selectedOption'
>

interface MultiSelectQuestionProps extends MultiSelectQuestionType {
  onValueChange: (args: MultiSelectQuestionSelectedOptionType) => void
  selectedOptions: string[] | undefined
}

const MultiSelectQuestion: FunctionComponent<MultiSelectQuestionProps> = ({
  className,
  question,
  questionNumber,
  displayQuestionNumber,
  variant,
  error,
  options,
  selectedOptions,
  onValueChange,
  ...props
}: MultiSelectQuestionProps) => {
  const { t } = useTranslation()
  const onClickHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    const valueSplit = e.target.id.split('-')
    const option: string = valueSplit[valueSplit.length - 1]
    let newSelected: string[] = []
    if (!selectedOptions) {
      newSelected = [option]
    } else if (selectedOptions.includes(option)) {
      newSelected = selectedOptions.filter((selected) => selected !== option)
    } else {
      newSelected = [...selectedOptions, option]
    }
    onValueChange({
      selectedId: questionNumber,
      selectedOption: newSelected,
    })
  }
  return (
    <div
      className={classnames(className, styles.multiSelectQuestion, {
        [styles.generalSuitability]: variant === GENERAL_SUITABILITY_QUESTION,
      })}
      {...props}
    >
      <>
        <div className={styles.multiSelectQuestion__questionContainer}>
          {displayQuestionNumber && (
            <span
              data-testid='multi-select-question-number'
              className={styles.multiSelectQuestion__questionNumber}
            >
              {displayQuestionNumber}.
            </span>
          )}
          {question && (
            <span
              data-testid='multi-select-question-question'
              className={styles.multiSelectQuestion__questionText}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: question }}
            />
          )}
        </div>
        {options &&
          options.map((option, index) => {
            const key = `${questionNumber}-option-${index}-${option}`
            return (
              <div
                key={key}
                className={styles.multiSelectQuestion__optionWrapper}
              >
                <input
                  id={key}
                  type='checkbox'
                  className={styles.multiSelectQuestion__checkboxInput}
                  onChange={onClickHandler}
                  checked={
                    (selectedOptions && selectedOptions.includes(option)) ||
                    false
                  }
                />
                <label htmlFor={key} data-testid='multi-select-question-option'>
                  <Checkbox
                    className={classnames(
                      styles.multiSelectQuestion__checkbox,
                      {
                        [styles.error]: error,
                      }
                    )}
                    active={
                      (selectedOptions && selectedOptions.includes(option)) ||
                      false
                    }
                  />
                  {/* eslint-disable-next-line react/no-danger */}
                  <span dangerouslySetInnerHTML={{ __html: option }} />
                </label>
              </div>
            )
          })}

        {error && (
          <ErrorMessage
            containerClassName={styles.errorContainer}
            message={t('questions:error.question-required')}
          />
        )}
      </>
    </div>
  )
}

export default MultiSelectQuestion
