import { createAsyncThunk } from '@reduxjs/toolkit'
import _groupBy from 'lodash/groupBy'

import { appAxios, getRoute } from '../../../helpers'
import { CoolKnowledgeDataType } from '../../../typings/CoolKnowledge'
import { CoolKnowledgeHKAPIResponse } from './loadCoolKnowledge'

const transformResponseDataHK = (
  responseData: CoolKnowledgeHKAPIResponse
): CoolKnowledgeDataType[] => {
  const groupedByPosition = _groupBy(responseData.smartTips, 'position')
  const coolKnowledgeList: CoolKnowledgeDataType[] = []
  Object.entries(groupedByPosition).forEach(([position, smartTipsList]) => {
    const enUSData = smartTipsList.filter(
      (smartTips) => smartTips.locale === 'en_US'
    )[0]
    const zhHKData = smartTipsList.filter(
      (smartTips) => smartTips.locale === 'zh_HK'
    )[0]
    const coolKnowledgeObject: CoolKnowledgeDataType = {
      code: position,
      locale: {
        en_US: {
          header: enUSData.tips || '',
          link: enUSData.tipsURL || undefined,
          target:
            enUSData.tipsTarget === '_new'
              ? '_blank'
              : enUSData.tipsTarget || '_blank',
          description: enUSData.descText || '',
          image: enUSData.image || undefined,
        },
        zh_HK: {
          header: zhHKData.tips || '',
          link: zhHKData.tipsURL || undefined,
          target:
            zhHKData.tipsTarget === '_new'
              ? '_blank'
              : zhHKData.tipsTarget || '_blank',
          description: zhHKData.descText || '',
          image: zhHKData.image || undefined,
        },
      },
    }
    coolKnowledgeList.push(coolKnowledgeObject)
  })
  return coolKnowledgeList
}

const loadCoolKnowledgeLogin = createAsyncThunk<CoolKnowledgeDataType[]>(
  'loadCoolKnowledgeLogin',
  async () => {
    const res = await appAxios().get(
      `${getRoute({ route: 'coolKnowledgeLogin' })}`
    )

    return transformResponseDataHK(res.data)
  }
)

export default loadCoolKnowledgeLogin
