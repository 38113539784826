import { createAsyncThunk } from '@reduxjs/toolkit'

import { appAxios, getRoute } from '../../../helpers'
import { SettingResponse } from '../../../typings/UserInfo'

const transformSettingDataHK = (
  responseData: SettingResponse
): SettingResponse => {
  return responseData
}

const transformSettingData = (
  responseData: SettingResponse
): SettingResponse => {
  return transformSettingDataHK(responseData as SettingResponse)
}

const fetchSetting = createAsyncThunk<SettingResponse>(
  'fetchSetting',
  async () => {
    const settingResponse = await appAxios().get(getRoute({ route: 'setting' }))
    const { data: responseData } = settingResponse

    return transformSettingData(responseData)
  }
)

export default fetchSetting
