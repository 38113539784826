import { FunctionComponent, useState } from 'react'
import { Modal as BootstrapModal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import Button from '../../../components/button'
import GSQuestion from '../../../components/gs-question'
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalWrapper,
} from '../../../components/modal/modal-components/ModalComponents'
import { BUTTON_SIZE_MEDIUM } from '../../../constants/button'
import {
  GeneralSuitabilityQuestion,
  QUESTION_CATEGORY_EXTRA,
  QuestionAnswer,
} from '../../../typings/GeneralSuitability'
import styles from './SpecialCriteriaPopup.module.scss'

interface SpecialCriteriaPopupProps {
  onSubmit: (option: string) => void // callback function when confirm button is clicked
  onCancel: () => void // callback function when cancel button is clicked
  questionData: GeneralSuitabilityQuestion | undefined // question to be displayed inside the modal
}

const SpecialCriteriaPopup: FunctionComponent<SpecialCriteriaPopupProps> = ({
  onSubmit,
  onCancel,
  questionData,
}) => {
  const { t } = useTranslation()
  const [option, setOption] = useState<string | undefined>(undefined)
  const [error, setError] = useState<boolean>(false)
  const onCloseHandler = () => {
    onCancel()
  }
  const onClickConfirm = () => {
    if (!option) {
      setError(true)
    } else {
      onSubmit(option)
    }
  }
  const onClickSelect = ({
    selectedOption,
  }: {
    selectedOption: QuestionAnswer
  }) => {
    setOption(selectedOption as string)
    if (error) {
      setError(false)
    }
  }
  return (
    <BootstrapModal
      onHide={onCloseHandler}
      show
      centered
      backdrop
      contentClassName={styles.popupModal}
    >
      <ModalWrapper id='special-criteria-popup'>
        <ModalHeader
          title={t('generalSuitability:special-criteria-popup.title')}
          closeModalHandler={onCloseHandler}
        />

        <ModalBody modalBodyClassNames={styles.popupModal__body}>
          {questionData && (
            <GSQuestion
              onValueChange={onClickSelect}
              question={questionData}
              index={0}
              category={QUESTION_CATEGORY_EXTRA}
              answers={
                {
                  answer: option as string,
                  mandatory: true,
                } || undefined
              }
              error={error}
            />
          )}
        </ModalBody>
        <ModalFooter className={styles.popupModal__footer}>
          <Button
            size={BUTTON_SIZE_MEDIUM}
            outlined
            onClick={onCloseHandler}
            className={styles.popupModal__button}
          >
            {t('generalSuitability:special-criteria-popup.button-cancel')}
          </Button>
          <Button
            size={BUTTON_SIZE_MEDIUM}
            onClick={onClickConfirm}
            className={styles.popupModal__button}
          >
            {t('generalSuitability:special-criteria-popup.button-confirm')}
          </Button>
        </ModalFooter>
      </ModalWrapper>
    </BootstrapModal>
  )
}

export default SpecialCriteriaPopup
