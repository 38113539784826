import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootStateOrAny } from 'react-redux'

import {
  FUND_GROUP_TYPE_HK_FUND_CATEGORY,
  FUND_GROUP_TYPE_TW_FUND_BASE_CURRENCY,
  FUND_GROUP_TYPE_TW_FUND_CATEGORY,
  FUND_GROUP_TYPE_TW_FUND_MARKET_TYPE,
} from '../../../constants/funds-group-type'
import { BreakdownData, FundGroupType } from '../../../typings/Filters'
import { AssetClassData } from '../../../typings/Portfolio'
import {
  HoldingFundPreviewType,
  PortfolioPieData,
  PortfolioPieResponse,
} from '../../../typings/PortfolioPie'

const transformBreakdownData = (
  responseData: PortfolioPieResponse,
  fundPreviewList: HoldingFundPreviewType[],
  breakdownDataList: AssetClassData[] | null
): BreakdownData[] => {
  const breakdownData: BreakdownData[] = responseData.portfolioPieResponse.map(
    (resAny: PortfolioPieData) => {
      const fundIdsList = resAny.subData.map((subData) => {
        return {
          fundCode: subData.nodeData.fundCode,
          label: subData.nodeData.label,
          valueInDouble: subData.nodeData.valueInDouble,
        }
      })

      const subList = fundIdsList.map((subData) => {
        return {
          ...fundPreviewList.filter(
            (currentFundPreview: HoldingFundPreviewType) =>
              subData.fundCode === currentFundPreview.fundCode
          )[0],
        }
      })

      const sortedFundList = subList.sort(
        (a, b) => b.valueInDouble - a.valueInDouble
      )

      const breakdownInfo: BreakdownData = {
        info: {
          code: resAny.nodeData.accountNo,
          locale: breakdownDataList
            ? breakdownDataList.filter(
                (fundCategory: AssetClassData) =>
                  fundCategory.code === resAny.nodeData.accountNo
              )[0].locale
            : {},
        },
        value: {
          string: resAny.nodeData.value,
          double: resAny.nodeData.valueInDouble,
        },
        distribution: parseFloat(resAny.nodeData.label),
        noOfFunds: resAny.subData.length,
        fundList: sortedFundList,
        profit: resAny.nodeData.profit,
        returnPercent: resAny.nodeData.returnPercent,
      }

      return breakdownInfo
    }
  )
  return breakdownData
}

type IParams = {
  responseData: PortfolioPieResponse
  label: FundGroupType
}

const updateBreakdownData = createAsyncThunk(
  'updateBreakdownData',
  async (params: IParams, { getState }) => {
    const state: RootStateOrAny = getState()
    const { country } = state.system
    const {
      fundCategoryList,
      fundPreviewList: currentFundList,
      fundMarketList,
    } = state.holding

    let breakdownData: BreakdownData[] = []

    if (country === 'HK' && params.label === FUND_GROUP_TYPE_HK_FUND_CATEGORY) {
      breakdownData = transformBreakdownData(
        params.responseData,
        currentFundList,
        fundCategoryList
      )
    }
    if (
      country === 'TW' &&
      params.label === FUND_GROUP_TYPE_TW_FUND_MARKET_TYPE
    ) {
      breakdownData = transformBreakdownData(
        params.responseData,
        currentFundList,
        fundCategoryList
      )
    }
    if (country === 'TW' && params.label === FUND_GROUP_TYPE_TW_FUND_CATEGORY) {
      breakdownData = transformBreakdownData(
        params.responseData,
        currentFundList,
        fundMarketList
      )
    }
    if (
      country === 'TW' &&
      params.label === FUND_GROUP_TYPE_TW_FUND_BASE_CURRENCY
    ) {
      breakdownData = transformBreakdownData(
        params.responseData,
        currentFundList,
        null
      )
    }
    const sortedData = breakdownData.sort(
      (a, b) => b.value.double - a.value.double
    )
    return sortedData
  }
)

export default updateBreakdownData
