import { userSlice } from '../userSlice'

export { default as fetchActiveRsp } from './fetchActiveRsp'
export { default as fetchClientTierInfo } from './fetchClientTierInfo'
export { default as fetchPriceAlert } from './fetchPriceAlert'
export { default as fetchSetting } from './fetchSetting'
export { default as fetchUser } from './fetchUser'
export { default as fetchUserAccountInfo } from './fetchUserAccountInfo'
export { default as fetchUserEPoints } from './fetchUserEPoints'
export { default as fetchUserRiskRating } from './fetchUserRiskRating'
export { default as updateCurrenciesPreference } from './updateCurrenciesPreference'

export const {
  updateUserTier,
  updateUserRiskRating,
  updatePortfolioRiskRating,
  updateAuthenticationStatus,
} = userSlice.actions
