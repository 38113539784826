import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  AssetClassData,
  HoldingFundSetting,
  HoldingFundType,
  PortfolioState,
} from '../../typings/Portfolio'
import { HoldingFundPreviewType } from '../../typings/PortfolioPie'
import { loadMyPortfolioInitial, loadPieData } from './actions'
import testLoadEmptyPortfolio from './actions/__test__/__loadEmptyPortfolio'
import updateFundCategoryList from './actions/updateFundCategoryList'
import updateFundMarketList from './actions/updateFundMarketList'
import updatePortfolioHoldings from './actions/updatePortfolioHoldings'
import viewFundInBaseCurrency from './actions/viewFundInBaseCurrency'

type HoldingState = {
  data: PortfolioState
  loading: boolean
  currentViewingFund?: HoldingFundType
  fundPreviewList: HoldingFundPreviewType[]
  fundCategoryList: AssetClassData[]
  fundMarketList: AssetClassData[]
}

const initialState: HoldingState = {
  loading: false,
  data: {
    portfolioTotalCost: '',
    portfolioTotalValue: {
      string: '',
      double: 0,
    },
    portfolioReturnPercent: '',
    portfolioProfit: '',
    fundList: [],
    portfolioSummaryBreakdown: null,
  },
  currentViewingFund: undefined,
  fundPreviewList: [],
  fundCategoryList: [],
  fundMarketList: [],
}

export const holdingSlice = createSlice({
  name: 'holding',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadMyPortfolioInitial.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      loadMyPortfolioInitial.fulfilled,
      (
        state,
        action: PayloadAction<{
          data: PortfolioState
          fundPreviewList: HoldingFundPreviewType[]
        }>
      ) => {
        state.data = action.payload.data
        state.fundPreviewList = action.payload.fundPreviewList
        state.loading = false
      }
    )
    builder.addCase(
      updatePortfolioHoldings.fulfilled,
      (state, action: PayloadAction<PortfolioState>) => {
        state.data = action.payload
      }
    )
    builder.addCase(loadPieData.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      loadPieData.fulfilled,
      (state, action: PayloadAction<HoldingFundPreviewType[] | null>) => {
        if (action.payload) {
          state.fundPreviewList = action.payload
        }
      }
    )
    builder.addCase(
      updateFundCategoryList.fulfilled,
      (state, action: PayloadAction<AssetClassData[]>) => {
        state.fundCategoryList = action.payload
      }
    )
    builder.addCase(
      updateFundMarketList.fulfilled,
      (state, action: PayloadAction<AssetClassData[]>) => {
        state.fundMarketList = action.payload
      }
    )
    builder.addCase(viewFundInBaseCurrency.pending, (state) => {
      state.currentViewingFund = undefined
    })
    builder.addCase(
      viewFundInBaseCurrency.fulfilled,
      (
        state,
        action: PayloadAction<
          { fundCode: string; fundData: HoldingFundSetting } | undefined
        >
      ) => {
        if (action.payload) {
          const { fundCode, fundData } = action.payload
          const updateFundList = [...state.data.fundList]
          const updateFundIndex = updateFundList.findIndex(
            (fund) => fund.fundCode === fundCode
          )
          updateFundList[updateFundIndex] = {
            ...updateFundList[updateFundIndex],
            ...fundData,
          }
          state.data.fundList = updateFundList
        }
      }
    )
    builder.addCase(testLoadEmptyPortfolio.fulfilled, (state) => {
      state.data = {
        portfolioTotalCost: '',
        portfolioTotalValue: {
          string: '',
          double: 0,
        },
        portfolioReturnPercent: '',
        portfolioProfit: '',
        fundList: [],
        portfolioSummaryBreakdown: null,
      }
    })
  },
})

export default holdingSlice.reducer
