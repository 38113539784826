import { FunctionComponent } from 'react'

import { IconProps } from '../../typings/Icon'

const ProfileIcon: FunctionComponent<IconProps> = ({
  className,
  height,
  width,
  ...props
}: IconProps) => (
  <svg
    height={height}
    width={width}
    className={className}
    {...props}
    viewBox='0 0 36 36'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='18' cy='18' r='18' fill='white' />
    <path d='M22.2073 16.6643C21.9368 17.0049 21.6329 17.3193 21.2839 17.6032C23.2761 18.7459 24.6211 20.8944 24.6211 23.3511C24.6211 24.6785 21.7126 25.7184 17.9996 25.7184C14.2867 25.7184 11.3782 24.6785 11.3782 23.3511C11.3782 20.8944 12.7232 18.7459 14.7154 17.6032C14.3664 17.3193 14.0626 17.005 13.792 16.6643C11.5735 18.0653 10.0967 20.5388 10.0967 23.3511C10.0967 24.203 10.5631 25.3902 12.7855 26.2084C14.1718 26.7189 16.0235 26.9999 17.9997 26.9999C19.9759 26.9999 21.8276 26.7189 23.2139 26.2084C25.4362 25.3902 25.9027 24.203 25.9027 23.3511C25.9026 20.5388 24.4258 18.0653 22.2073 16.6643Z' />
    <path d='M17.9994 17.5088C20.4901 17.5088 22.408 15.1013 22.408 12.2544C22.408 9.40626 20.4891 7 17.9994 7C15.5087 7 13.5908 9.40753 13.5908 12.2544C13.5908 15.1026 15.5098 17.5088 17.9994 17.5088ZM17.9994 8.28155C19.7236 8.28155 21.1265 10.0638 21.1265 12.2544C21.1265 14.445 19.7236 16.2272 17.9994 16.2272C16.2752 16.2272 14.8724 14.445 14.8724 12.2544C14.8724 10.0638 16.2752 8.28155 17.9994 8.28155Z' />
  </svg>
)

ProfileIcon.defaultProps = {
  className: '',
  height: 18,
  width: 16,
}

export default ProfileIcon
