import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootStateOrAny } from 'react-redux'

import { appAxios, getRoute } from '../../../helpers'
import { FundDetailsData } from '../../../typings/FundDetailsResponse'

interface FundDetailsResponse {
  list: FundDetailsData[]
}

const transformAllFunds = (
  responseData: FundDetailsResponse
): FundDetailsData[] => {
  return responseData.list
}

const fetchAllFundDetails = createAsyncThunk<FundDetailsData[] | undefined>(
  'fetchAllFundDetails',
  async (_, { getState }) => {
    const state: RootStateOrAny = getState()
    const { fundDetailsList } = state.allFund
    if (fundDetailsList) {
      return undefined
    }
    const fundRes = await appAxios().get(getRoute({ route: 'fund' }))
    return transformAllFunds(fundRes.data)
  }
)

export default fetchAllFundDetails
