import { generalSuitabilitySlice } from '../generalSuitabilitySlice'

// export { default as submitQuestionnaire } from './submitQuestionniare'
// export { default as submitSpecialCriteriaOption } from './submitSpecialCriteriaOption'

export const {
  updateGSStatus,
  updateESStatus,
  updateRiskLevel,
  updateIsCorporateAccount,
  updateQuestionAnswer,
  updateShowResultFeedback,
  updateUserAge,
  addQuestionsMandatory,
  updateQuestionGroupsMandatory,
  removeAgentTwo,
  testRandomAnswers,
  updateShowSpecialCriteriaPopup,
  resetQuestionAnswers,
  update4CQuestionRenderingStatus,
} = generalSuitabilitySlice.actions
