import { configureStore } from '@reduxjs/toolkit'

import allFundReducer from './redux/all-fund/allFundSlice'
import allFundTableDataReducer from './redux/all-fund-table-data/allFundTableDataSlice'
import announcementReducer from './redux/announcement/announcementSlice'
import coolKnowledgeReducer from './redux/cool-knowledge/coolKnowledgeSlice'
import enhancedSuitabilityReducer from './redux/enhanced-suitability/enhancedSuitabilitySlice'
import fundExcelExportReducer from './redux/fund-excel-export/fundExcelExportSlice'
import fundFilterReducer from './redux/fund-filter/filterSlice'
import fundSearchReducer from './redux/fund-search/searchSlice'
import generalSuitabilityReducer from './redux/general-suitability/generalSuitabilitySlice'
import holdingReducer from './redux/holding/holdingSlice'
import inboxReducer from './redux/inbox/inboxSlice'
import loadingReducer from './redux/loading/loadingSlice'
import loginReducer from './redux/login/loginSlice'
import orderHistoryReducer from './redux/order-history/orderHistorySlice'
import portfolioReducer from './redux/portfolio/portfolioSlice'
import promotionsReducer from './redux/promotions/promotionsSlice'
import recentlyViewedReducer from './redux/recently-viewed/recentlyViewedSlice'
import selectedFundsReducer from './redux/selected-funds/selectedSlice'
import systemReducer from './redux/system/systemSlice'
import topFiveFundsReducer from './redux/top-five-funds/topFiveFundsSlice'
import urlTradingReducer from './redux/url-trading/urlTradingSlice'
import userReducer from './redux/user/userSlice'
import warningMessageReducer from './redux/warning-message/warningMessageSlice'

export const store = configureStore({
  reducer: {
    selectedFunds: selectedFundsReducer,
    fundFilter: fundFilterReducer,
    holding: holdingReducer,
    user: userReducer,
    fundSearch: fundSearchReducer,
    loading: loadingReducer,
    system: systemReducer,
    urlTrading: urlTradingReducer,
    recentlyViewed: recentlyViewedReducer,
    topFiveFunds: topFiveFundsReducer,
    announcement: announcementReducer,
    warningMessage: warningMessageReducer,
    promotions: promotionsReducer,
    coolKnowledge: coolKnowledgeReducer,
    orderHistory: orderHistoryReducer,
    login: loginReducer,
    portfolio: portfolioReducer,
    allFund: allFundReducer,
    allFundTableData: allFundTableDataReducer,
    fundExcelExport: fundExcelExportReducer,
    inbox: inboxReducer,
    enhancedSuitability: enhancedSuitabilityReducer,
    generalSuitability: generalSuitabilityReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
