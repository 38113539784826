import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { FundPreviewType } from '../../typings/FundPreview'
import loadRecentlyViewed from './actions/loadRecentlyViewed'

type RecentlyViwedState = {
  list: FundPreviewType[]
  loading: boolean
}

const initialState: RecentlyViwedState = {
  list: [],
  loading: false,
}

export const recentlyViewedSlice = createSlice({
  name: 'recentlyViewed',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      loadRecentlyViewed.fulfilled,
      (state, action: PayloadAction<FundPreviewType[]>) => {
        // eslint-disable-next-line no-param-reassign
        state.list = action.payload
      }
    )
  },
})

export default recentlyViewedSlice.reducer
