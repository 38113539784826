import classnames from 'classnames'
import {
  ChangeEvent,
  FunctionComponent,
  InputHTMLAttributes,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import ErrorMessage from '../error-message'
import UserInputMultiDisplay from '../user-input-multi-display'
import styles from './TextField.module.scss'

export type UserInputQuestionInputType = {
  selectedId: number
  selectedOption: number | string
}

type ValueType<T> = T extends 'text' ? string : number
export const INDIVIDUAL_CHAR = 'INDIVIDUAL_CHAR'
export type MultiInputDisplayType = {
  type: typeof INDIVIDUAL_CHAR
  numOfChar: number
}

interface UserInputProps extends InputHTMLAttributes<HTMLInputElement> {
  question?: string
  questionNumber?: number
  displayQuestionNumber?: boolean | number
  display?: MultiInputDisplayType | 'NORMAL'
  placeholder?: string
  onValueChange: (input: string | number | undefined) => void
  value: Exclude<
    InputHTMLAttributes<HTMLInputElement>['value'],
    'readonly string[]'
  >
  error: boolean
  errorMessage?: string
  inputProps?: InputHTMLAttributes<HTMLInputElement>
}

const UserInput: FunctionComponent<UserInputProps> = ({
  question,
  questionNumber,
  displayQuestionNumber,
  type,
  display,
  onValueChange,
  value,
  error,
  inputProps,
  errorMessage,
  ...props
}: UserInputProps) => {
  const [isFieldActive, setIsFieldActive] = useState<boolean>(false)
  const [textInput, setTextInput] = useState<string>('')
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setTextInput(e.target.value)
    onValueChange(e.target.value as ValueType<typeof type>)
  }

  useEffect(() => {
    if (!value) {
      setTextInput('')
    }
    setTextInput(value as string)
  }, [value])

  const { t } = useTranslation()
  const renderInputField = () => {
    if (inputProps && inputProps.type === 'date') {
      return (
        <div
          className={classnames(styles.userInput__inputDiv, {
            [styles.active]: isFieldActive,
            [styles.error]: error,
          })}
        >
          <input
            type='date'
            onChange={onChangeHandler}
            value={value}
            {...props}
            {...inputProps}
          />
        </div>
      )
    }
    if (display === 'NORMAL') {
      return (
        <div
          className={classnames(styles.userInput__inputDiv, {
            [styles.active]: isFieldActive,
            [styles.error]: error,
          })}
        >
          <input
            type={type}
            value={textInput}
            onChange={onChangeHandler}
            onFocus={() => setIsFieldActive(true)}
            onBlur={() => setIsFieldActive(false)}
            {...inputProps}
            {...props}
          />
        </div>
      )
    }
    if (display && display.type === INDIVIDUAL_CHAR && display.numOfChar > 0) {
      return (
        <UserInputMultiDisplay
          length={display.numOfChar}
          type='text'
          onValueChange={onValueChange}
          id={question || 'question'}
          error={error}
          value={(value as string) || undefined}
        />
      )
    }
    return <></>
  }
  return (
    <>
      <div className={styles.userInput} data-testid='user-input-div'>
        <div>
          {questionNumber && displayQuestionNumber && (
            <span
              data-testid='user-input-question-number'
              className={styles.userInput__questionNumber}
            >
              {questionNumber}.
            </span>
          )}
          {question && (
            <span
              data-testid='user-input-question'
              className={styles.userInput__question}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: question }}
            />
          )}
        </div>
        {renderInputField()}
      </div>
      {error && (
        <ErrorMessage
          containerClassName={styles.errorContainer}
          message={errorMessage || t('questions:error.question-required')}
        />
      )}
    </>
  )
}

UserInput.defaultProps = {
  question: undefined,
  questionNumber: undefined,
  displayQuestionNumber: true,
  display: 'NORMAL',
  placeholder: undefined,
  inputProps: undefined,
  errorMessage: '',
}

export default UserInput
