// eslint-disable-next-line import/no-cycle
import { TierInfoType } from '../typings/TierInfo'

export const TIER_LEVEL_NAME_SMART = 'smart' // 智富理財  (old name)
export const TIER_LEVEL_NAME_DIGITAL = 'digital' // 數位理財 (below 1M)
export const TIER_LEVEL_NAME_PRESTIGE = 'prestige' // 尊榮理財 (over 10M)
export const TIER_LEVEL_NAME_ELITE = 'elite' // 菁英理財 (1M - 10M)

export const USER_TIER_DETAILS = 'user-tier-details'
export const TIER_INSTRUCTION = 'tier-instructions'

export const TIER_INFO_CONTAINER_TABS = [USER_TIER_DETAILS, TIER_INSTRUCTION]

export const TOP_TIER_LEVEL = '10M'

export const INVESTMENT_TIER_TW_TIER_BASE: TierInfoType = {
  tierLevel: 'baseTW',
  name: TIER_LEVEL_NAME_DIGITAL,
  tierAmount: 0,
  adminFee: '0.6-1.2',
  prevLevel: null,
  nextLevel: ['1M', '3M', '10M'],
}

export const INVESTMENT_TIER_TW_TIER_1M: TierInfoType = {
  tierLevel: '1M',
  name: TIER_LEVEL_NAME_ELITE,
  tierAmount: 1000000,
  adminFee: '0.2',
  prevLevel: ['baseTW'],
  nextLevel: ['3M', '10M'],
}

export const INVESTMENT_TIER_TW_TIER_3M: TierInfoType = {
  tierLevel: '3M',
  name: TIER_LEVEL_NAME_ELITE,
  tierAmount: 3000000,
  adminFee: '0',
  prevLevel: ['baseTW', '1M'],
  nextLevel: ['10M'],
}

export const INVESTMENT_TIER_TW_TIER_10M_SALES_CODE_DIGITAL: TierInfoType = {
  tierLevel: '10M',
  name: TIER_LEVEL_NAME_ELITE,
  tierAmount: 10000000,
  adminFee: '0',
  prevLevel: ['baseTW', '1M', '3M'],
  nextLevel: null,
}

export const INVESTMENT_TIER_TW_TIER_10M_SALES_CODE_DIRECT: TierInfoType = {
  tierLevel: '10M',
  name: TIER_LEVEL_NAME_PRESTIGE,
  tierAmount: 10000000,
  adminFee: '0',
  prevLevel: ['baseTW', '1M', '3M'],
  nextLevel: null,
}

export const INVESTMENT_TIER_TW: TierInfoType[] = [
  INVESTMENT_TIER_TW_TIER_BASE,
  INVESTMENT_TIER_TW_TIER_1M,
  INVESTMENT_TIER_TW_TIER_3M,
  INVESTMENT_TIER_TW_TIER_10M_SALES_CODE_DIGITAL,
]

// HK

export const MEMBERSHIP_TIER_SILVER = 'silver'
export const MEMBERSHIP_TIER_GOLD = 'gold'
export const MEMBERSHIP_TIER_PLATINUM = 'platinum'
export const MEMBERSHIP_TIER_SELECT = 'select'
export const TIER_LEVEL_HK_BASE = 'baseHK'
export const TIER_LEVEL_HK_200K = '200K'
export const TIER_LEVEL_HK_7500K = '7500K'
export const TIER_LEVEL_HK_SELECT = 'select'

export const INVESTMENT_TIER_HK_TIER_SILVER: TierInfoType = {
  tierLevel: TIER_LEVEL_HK_BASE,
  name: MEMBERSHIP_TIER_SILVER,
  tierAmount: 0,
  adminFee: '0.88',
  prevLevel: null,
  nextLevel: [TIER_LEVEL_HK_200K, TIER_LEVEL_HK_7500K, TIER_LEVEL_HK_SELECT],
}

export const INVESTMENT_TIER_HK_TIER_GOLD: TierInfoType = {
  tierLevel: TIER_LEVEL_HK_200K,
  name: MEMBERSHIP_TIER_GOLD,
  tierAmount: 200000,
  adminFee: '0.58',
  prevLevel: [TIER_LEVEL_HK_BASE],
  nextLevel: [TIER_LEVEL_HK_7500K, TIER_LEVEL_HK_SELECT],
}

export const INVESTMENT_TIER_HK_TIER_PLATINUM: TierInfoType = {
  tierLevel: TIER_LEVEL_HK_7500K,
  name: MEMBERSHIP_TIER_PLATINUM,
  tierAmount: 7500000,
  adminFee: '0.18',
  prevLevel: [TIER_LEVEL_HK_BASE, TIER_LEVEL_HK_200K],
  nextLevel: null,
}

export const INVESTMENT_TIER_HK_TIER_SELECT: TierInfoType = {
  tierLevel: TIER_LEVEL_HK_SELECT,
  name: MEMBERSHIP_TIER_SELECT,
  tierAmount: 1000000000,
  adminFee: '0',
  prevLevel: [TIER_LEVEL_HK_BASE, TIER_LEVEL_HK_200K, TIER_LEVEL_HK_7500K],
  nextLevel: null,
}
export const INVESTMENT_TIER_HK: TierInfoType[] = [
  INVESTMENT_TIER_HK_TIER_SILVER,
  INVESTMENT_TIER_HK_TIER_GOLD,
  INVESTMENT_TIER_HK_TIER_PLATINUM,
  INVESTMENT_TIER_HK_TIER_SELECT,
]

export const INVESTMENT_TIER: TierInfoType[] =
  INVESTMENT_TIER_TW.concat(INVESTMENT_TIER_HK)
