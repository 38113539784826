import { createAsyncThunk } from '@reduxjs/toolkit'

import { appAxios, getRoute } from '../../../helpers'
import getFundDetail from '../../../helpers/getFundDetail'
import { FundPreviewType } from '../../../typings/FundPreview'
import { FundSearchResponse } from '../../../typings/Search'
import { fetchAllFundDetails } from '../../all-fund/actions'

interface FundSearchResponseAPI {
  list: FundSearchResponse[]
}

const transformTopFundsList = (
  responseData: FundSearchResponseAPI
): FundPreviewType[] => {
  const list: FundPreviewType[] = responseData.list.map(
    (response: FundSearchResponse) => {
      const fundNameLocale = getFundDetail(response.fundCode)?.fundInfo?.locale
      const fundPreviewDataObj: FundPreviewType = {
        fundInfo: {
          code: response.fundCode,
          locale: {
            en_US: response.fundName,
            ...(fundNameLocale && fundNameLocale),
          },
        },
        lastUpdate: response.dealingDate,
        riskRating: response.fundRiskRating,
        performance: {
          oneYear: response.oneYearPerformance,
          twoYear: null, // TODO: update this function when sample api response is changed
          threeYear: response.threeYearPerformance,
          fiveYear: response.fiveYearPerformance,
        },
        fundPrice: {
          fundCode: response.fundCode,
          bid: response.nav,
          date: response.dealingDate,
          baseCurrency: response.baseCurrency,
        },
        performanceEffectiveDate: response.dealingDate,
        url: null,
        ...response,
      }
      return fundPreviewDataObj
    }
  )
  const sortedList: FundPreviewType[] = list.sort(
    (a, b) =>
      parseFloat(b.performance.oneYear || '0') -
      parseFloat(a.performance.oneYear || '0')
  )
  return sortedList
}

const fetchTopFundList = createAsyncThunk<FundPreviewType[]>(
  'fetchTopFundList',
  async (_, { dispatch }) => {
    await dispatch(fetchAllFundDetails())
    const topFundListRes = await appAxios().get(
      getRoute({ route: 'topFundSearchList' })
    )

    return transformTopFundsList(topFundListRes.data)
  }
)

export default fetchTopFundList
