import { createSlice } from '@reduxjs/toolkit'

import { EnhancedSuitabilityQuestionGroupType } from '../../typings/EnhancedSuitability'
import data from './data'

type EnhancedSuitabilityState = {
  data: EnhancedSuitabilityQuestionGroupType[]
}

const initialState: EnhancedSuitabilityState = {
  data,
}

export const EnhancedSuitabilitySlice = createSlice({
  name: 'enhancedSuitability',
  initialState,
  reducers: {},
  extraReducers: () => {},
})

export default EnhancedSuitabilitySlice.reducer
