import { FunctionComponent } from 'react'

import { IconProps } from '../../typings/Icon'

const QuestionMark: FunctionComponent<IconProps> = ({
  height,
  width,
  className,
  ...props
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      {...props}
      viewBox='0 0 20 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19 10.3182C19 15.3852 14.9566 19.4675 10 19.4675C5.04335 19.4675 1 15.3852 1 10.3182C1 5.25119 5.04335 1.16895 10 1.16895C14.9566 1.16895 19 5.25119 19 10.3182ZM20 10.3182C20 15.9235 15.5228 20.4675 10 20.4675C4.47715 20.4675 0 15.9235 0 10.3182C0 4.71292 4.47715 0.168945 10 0.168945C15.5228 0.168945 20 4.71292 20 10.3182ZM6.37354 8.354V8.50049C6.37354 8.5542 6.39795 8.58105 6.44678 8.58105H7.72852C7.77734 8.58105 7.80176 8.5542 7.80176 8.50049V8.354C7.80176 7.90479 7.97266 7.52393 8.31445 7.21143C8.65625 6.89404 9.11523 6.73535 9.69141 6.73535C10.3457 6.73535 10.8584 6.91846 11.2295 7.28467C11.6055 7.65088 11.7935 8.06836 11.7935 8.53711C11.7935 8.72266 11.7739 8.88379 11.7349 9.02051C11.7007 9.15723 11.6274 9.30371 11.5151 9.45996C11.4077 9.61133 11.2417 9.75781 11.0171 9.89941C10.7925 10.041 10.5044 10.1851 10.1528 10.3315C9.43506 10.6294 9.07617 11.1104 9.07617 11.7744V12.9609C9.07617 13.0098 9.10059 13.0342 9.14941 13.0342H10.4238C10.4727 13.0342 10.4971 13.0098 10.4971 12.9609V11.8916C10.5117 11.7842 10.5361 11.7061 10.5703 11.6572C10.6045 11.6035 10.6704 11.5522 10.7681 11.5034C10.9829 11.396 11.1465 11.313 11.2588 11.2544C11.3711 11.1909 11.52 11.1079 11.7056 11.0054C11.8911 10.8979 12.0327 10.8052 12.1304 10.7271C12.228 10.644 12.3428 10.5415 12.4746 10.4194C12.6113 10.2974 12.7139 10.1753 12.7822 10.0532C12.8555 9.93115 12.9287 9.79199 13.002 9.63574C13.0752 9.47949 13.1265 9.31104 13.1558 9.13037C13.1851 8.94482 13.1997 8.74707 13.1997 8.53711C13.1997 7.66797 12.8774 6.93799 12.2329 6.34717C11.5933 5.75635 10.7461 5.46094 9.69141 5.46094C8.71973 5.46094 7.92383 5.73193 7.30371 6.27393C6.68359 6.81592 6.37354 7.50928 6.37354 8.354ZM8.80518 14.2646V16.0957C8.80518 16.1445 8.82959 16.1689 8.87842 16.1689H10.6802C10.729 16.1689 10.7534 16.1445 10.7534 16.0957V14.2646C10.7534 14.2158 10.729 14.1914 10.6802 14.1914H8.87842C8.82959 14.1914 8.80518 14.2158 8.80518 14.2646Z'
        fill='#096183'
      />
    </svg>
  )
}

QuestionMark.defaultProps = {
  width: 20,
  height: 21,
  className: '',
}

export default QuestionMark
