import { createAsyncThunk } from '@reduxjs/toolkit'
import _groupBy from 'lodash/groupBy'
import { RootStateOrAny } from 'react-redux'

import { appAxios, getRoute } from '../../../helpers'
import { parseAnnouncementData } from '../../../helpers/xmlParser'
import {
  AnnouncementData,
  AnnouncementType,
} from '../../../typings/Announcement'
import { UserCountryType } from '../../../typings/UserInfo'

type AnnouncementResponse = {
  iconFile: string
  headerText: string
  headerURL: string
  headerTarget: string
  descText: string
  descTarget: string
  descURL: string | null
  locale: string
  position: string
}

type AnnouncementsHKAPIResponse = {
  announcements: AnnouncementResponse[]
}
type AnnouncementsTWAPIResponse = {
  announcements: AnnouncementResponse[]
}

type AnnouncementsAPIResponse =
  | AnnouncementsHKAPIResponse
  | AnnouncementsTWAPIResponse

const transformResponseDataHK = (
  resposneData: AnnouncementsHKAPIResponse
): AnnouncementType[] => {
  const groupResponseByPosition = _groupBy(
    resposneData.announcements,
    'position'
  )
  const announcements: AnnouncementType[] = Object.entries(
    groupResponseByPosition
  ).map(([position, data]) => {
    const languages = _groupBy(data, 'locale')
    let locale: {
      [locale: string]: AnnouncementData
    } = {}
    Object.entries(languages).forEach(([language, localeData]) => {
      locale = {
        ...locale,
        [language]: {
          headerText: localeData[0].headerText,
          headerURL: localeData[0].headerURL,
          ...parseAnnouncementData(localeData[0].descText),
        },
      }
    })
    const announcementObject: AnnouncementType = {
      position,
      locale,
    }
    return announcementObject
  })

  const sortedAnnouncements = announcements.sort(
    (a: AnnouncementType, b: AnnouncementType) =>
      parseInt(b.position, 10) - parseInt(a.position, 10)
  )

  return sortedAnnouncements
}
const transformResponseDataTW = (
  resposneData: AnnouncementsTWAPIResponse
): AnnouncementType[] => {
  const groupResponseByPosition = _groupBy(
    resposneData.announcements,
    'position'
  )
  const announcements: AnnouncementType[] = Object.entries(
    groupResponseByPosition
  ).map(([position, data]) => {
    const languages = _groupBy(data, 'locale')
    let locale: {
      [locale: string]: AnnouncementData
    } = {}
    Object.entries(languages).forEach(([language, localeData]) => {
      locale = {
        ...locale,
        [language]: {
          headerText: localeData[0].headerText,
          ...parseAnnouncementData(localeData[0].descText),
          headerURL: parseAnnouncementData(localeData[0].descText).url,
        },
      }
    })
    const announcementObject: AnnouncementType = {
      position,
      locale,
    }
    return announcementObject
  })

  const sortedAnnouncements = announcements.sort(
    (a: AnnouncementType, b: AnnouncementType) =>
      parseInt(b.position, 10) - parseInt(a.position, 10)
  )

  return sortedAnnouncements
}

const transformResponseData = (
  resposneData: AnnouncementsAPIResponse,
  country: UserCountryType
): AnnouncementType[] => {
  if (country === 'HK') {
    return transformResponseDataHK(resposneData as AnnouncementsHKAPIResponse)
  }
  return transformResponseDataTW(resposneData as AnnouncementsTWAPIResponse)
}

const loadAnnouncement = createAsyncThunk<AnnouncementType[]>(
  'loadAnnouncement',
  async (_, { getState }) => {
    const state: RootStateOrAny = getState()
    const { country } = state.system

    const res = await appAxios().get(`${getRoute({ route: 'announcement' })}`)

    return transformResponseData(res.data, country)
  }
)

export default loadAnnouncement
