import {
  FUND_ACCOUNT_TYPE_TW_ALL,
  FUND_ACCOUNT_TYPE_TW_DEFAULT,
} from '../../../../constants/funds-account-type'
import {
  FUND_GROUP_TYPE_HK_DEFAULT,
  FUND_GROUP_TYPE_TW_DEFAULT,
} from '../../../../constants/funds-group-type'
import {
  FUND_TYPE_HK_DEFAULT,
  FUND_TYPE_TW_DEFAULT,
} from '../../../../constants/funds-type'

export const PORTFOLIO_REQUEST_DEFAULT_TW = {
  currency: 'TWD',
  type: FUND_TYPE_TW_DEFAULT,
  accountType: FUND_ACCOUNT_TYPE_TW_DEFAULT,
  groupType: FUND_GROUP_TYPE_TW_DEFAULT,
}
export const PORTFOLIO_REQUEST_DEFAULT_HK = {
  currency: 'HKD',
  type: FUND_TYPE_HK_DEFAULT,
  accountType: FUND_ACCOUNT_TYPE_TW_ALL,
  groupType: FUND_GROUP_TYPE_HK_DEFAULT,
  costPreference: 'S',
}
