import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { HoldingFundPreviewType } from '../../typings/PortfolioPie'

type SelectedFundType = HoldingFundPreviewType

export interface SelectedFundState {
  fund: SelectedFundType[]
  currentSelectedFund: SelectedFundType | undefined
  currentSelectedRows: { [id: string]: string }
  selectedDataItems: SelectedDataItems
}

const initialState: SelectedFundState = {
  fund: [],
  currentSelectedFund: undefined,
  currentSelectedRows: {},
  selectedDataItems: {},
}

export interface SelectedDataItems {
  [key: string]: string[] // key is the table ID, value is an array of selected items
}

export const selectedSlice = createSlice({
  name: 'selected',
  initialState,
  reducers: {
    addFund: (state, action: PayloadAction<HoldingFundPreviewType>) => {
      state.fund.push(action.payload)
    },
    removeFund: (state, action: PayloadAction<HoldingFundPreviewType>) => {
      state.fund = state.fund.filter(
        (fund) => fund.fundCode !== action.payload.fundCode
      )
    },
    setSelectedDataItems: (
      state,
      action: PayloadAction<{ tableId: string; items: [] }>
    ) => {
      state.selectedDataItems[action.payload.tableId] = action.payload.items
    },
    selectFund: (state, action: PayloadAction<HoldingFundPreviewType>) => {
      state.currentSelectedFund = action.payload
    },
    removeCurrentSelectedFund: (state) => {
      state.currentSelectedFund = undefined
    },
    setCurrentSelectedRow: (
      state,
      action: PayloadAction<{ tableId: string; rowId: string }>
    ) => {
      state.currentSelectedRows[action.payload.tableId] = action.payload.rowId
    },
    removeCurrentSelectedRow: (state, action: PayloadAction<string>) => {
      delete state.currentSelectedRows[action.payload]
    },
  },
})

export default selectedSlice.reducer
