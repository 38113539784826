import { FunctionComponent } from 'react'

import { IconProps } from '../../typings/Icon'

const Download: FunctionComponent<IconProps> = ({
  width,
  height,
  className,
  ...props
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      {...props}
      viewBox='0 0 37 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='1.32617'
        y='0.5'
        width='35'
        height='35'
        rx='17.5'
        fill='white'
        stroke='black'
      />
      <path
        d='M25.5762 19.7812V24.125C25.5762 24.4696 25.2958 24.75 24.9512 24.75H12.7012C12.3565 24.75 12.0762 24.4696 12.0762 24.125V19.7812H10.8262V24.125C10.8262 25.1589 11.6673 26 12.7012 26H24.9512C25.985 26 26.8262 25.1589 26.8262 24.125V19.7812H25.5762Z'
        fill='black'
      />
      <path
        d='M21.8253 17.3661L19.4503 19.7411V10H18.2003V19.7411L15.8253 17.3661L14.9414 18.25L18.8253 22.1339L22.7092 18.25L21.8253 17.3661Z'
        fill='black'
      />
    </svg>
  )
}

Download.defaultProps = {
  width: 37,
  height: 36,
  className: undefined,
}

export default Download
