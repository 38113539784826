import { RouteType } from '../../typings/Routes'

const MAIN_ROUTE_TW: RouteType[] = [
  {
    translationKeyName: 'portfolio',
    routeName: 'my-portfolio',
  },
  {
    translationKeyName: 'funds',
    routeName: 'funds',
  },
  {
    translationKeyName: 'orders',
    routeName: 'orders',
    subMenu: [
      {
        translationKeyName: 'orders-single',
        routeName: 'orders',
      },
      {
        translationKeyName: 'orders-buy',
        routeName: 'orders',
      },
      {
        translationKeyName: 'orders-schedule',
        routeName: 'orders',
      },
      {
        translationKeyName: 'orders-mpf-change',
        routeName: 'orders',
      },
    ],
  },
  {
    translationKeyName: 'rsp-schedule',
    routeName: 'rspschedule',
    subMenu: [
      { translationKeyName: 'rsp-schedule-order', routeName: 'rspschedule' },
      { translationKeyName: 'rsp-schedule-change', routeName: 'rspschedule' },
    ],
  },
  {
    translationKeyName: 'ersp-schedule',
    routeName: 'erspschedule',
    subMenu: [
      { translationKeyName: 'ersp-schedule-order', routeName: 'erspschedule' },
      { translationKeyName: 'ersp-schedule-change', routeName: 'erspschedule' },
    ],
  },
  {
    translationKeyName: 'switch',
    routeName: 'switch',
    subMenu: [
      { translationKeyName: 'switch-order', routeName: 'switch' },
      { translationKeyName: 'switch-change', routeName: 'switch' },
    ],
  },
  {
    translationKeyName: 'orders-query',
    routeName: 'orderquery',
    subMenu: [
      {
        translationKeyName: 'orders-query-confirmation',
        routeName: 'orderquery',
      },
      {
        translationKeyName: 'orders-query-monthly-invoice',
        routeName: 'orderquery',
      },
      {
        translationKeyName: 'orders-query-cancel',
        routeName: 'orderquery',
      },
      {
        translationKeyName: 'orders-query-history',
        routeName: 'orderquery',
      },
    ],
  },
  {
    translationKeyName: 'self-service',
    routeName: 'selfservice',
    subMenu: [
      {
        translationKeyName: 'self-service-instruction',
        routeName: 'selfservice',
      },
      {
        translationKeyName: 'self-service-faq',
        routeName: 'selfservice',
      },
      {
        translationKeyName: 'self-service-document',
        routeName: 'selfservice',
      },
    ],
  },
]

export default MAIN_ROUTE_TW
