import { createAsyncThunk } from '@reduxjs/toolkit'

import {
  FillingRoleType,
  QuestionAnswers,
  QuestionnaireResult,
  RISK_HIGH,
  STATUS_COMPLETED,
} from '../../../typings/GeneralSuitability'
import { specialCriteriaPopupQuestion } from '../data'

const submitQuestionnaire = createAsyncThunk<
  QuestionnaireResult,
  {
    [key in FillingRoleType]: QuestionAnswers
  }
>('submitQuestionnaire', async (questionAnswers) => {
  // Submit Questionnaire
  // DO Something with questionAnswers
  // eslint-disable-next-line no-console
  console.log(questionAnswers)

  return {
    generalSuitabilityStatus: STATUS_COMPLETED,
    enhancedSuitabilityStatus: STATUS_COMPLETED,
    riskLevel: RISK_HIGH,
    shouldShowSpecialCriteriaPopup: {
      show: true,
      question: specialCriteriaPopupQuestion,
    },
    shouldShowFeedbackModal: false,
  }
})

export default submitQuestionnaire
