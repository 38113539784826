import { RouteType } from '../../typings/Routes'

const MAIN_ROUTE_HK: RouteType[] = [
  {
    translationKeyName: 'portfolio',
    routeName: 'myPortoflio',
  },
  {
    translationKeyName: 'lumpsum-investment',
    routeName: 'lumpsum',
    subMenu: [
      {
        translationKeyName: 'fund-search',
        routeName: 'lumpsum',
      },
      {
        translationKeyName: 'fund-buy',
        routeName: 'lumpsum',
      },
      {
        translationKeyName: 'fund-switch',
        routeName: 'lumpsum',
      },
      {
        translationKeyName: 'fund-sell',
        routeName: 'lumpsum',
      },
    ],
  },
  {
    translationKeyName: 'escheduler',
    routeName: 'escheduler',
    subMenu: [
      {
        translationKeyName: 'fund-search',
        routeName: 'escheduler',
      },
      {
        translationKeyName: 'schedule-buy',
        routeName: 'escheduler',
      },
      {
        translationKeyName: 'schedule-switch',
        routeName: 'escheduler',
      },
      {
        translationKeyName: 'existing-schedule',
        routeName: 'escheduler',
      },
    ],
  },
  {
    translationKeyName: 'regular-investment-plan',
    routeName: 'regularPlan',
    subMenu: [
      {
        translationKeyName: 'regularPlan-switch',
        routeName: 'regularPlan',
      },
      {
        translationKeyName: 'regularPlan-sell',
        routeName: 'regularPlan',
      },
    ],
  },
  {
    translationKeyName: 'holdings-transcations',
    routeName: 'holdTrans',
    subMenu: [
      {
        translationKeyName: 'econtract-note',
        routeName: 'holdTrans',
      },
      {
        translationKeyName: 'estatement',
        routeName: 'eStatement',
      },
      {
        translationKeyName: 'order-status',
        routeName: 'orderStatus',
      },
      {
        translationKeyName: 'order-history',
        routeName: 'orderHistory',
      },
    ],
  },
  {
    translationKeyName: 'support',
    routeName: 'support',
    subMenu: [
      {
        translationKeyName: 'faq',
        routeName: 'support',
      },
      {
        translationKeyName: 'forms-literature',
        routeName: 'support',
      },
      {
        translationKeyName: 'risk-rating',
        routeName: 'support',
      },
      {
        translationKeyName: 'dealing-calendar',
        routeName: 'support',
      },
      {
        translationKeyName: 'distribution-history',
        routeName: 'support',
      },
    ],
  },
]

export default MAIN_ROUTE_HK
